import { Tooltip } from "bootstrap";
// import  Tooltip from "bootstrap/js/src/tooltip.js";
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-buttons';
import Helpers from "../../../../dist/_utils/helpers.js";
import _Translation from "../../utils/_translation.js";
import ButtonStatic from "./button/button-static.js";
import { DtEditor } from "../editor/dt-editor.js";
import DataTableStatic from "./data-table-static.js";
import ColumnStatic from "./column/column-static.js";
import { DtColumn } from "./column/dt-column.js";
import { DtEditorFn } from "../editor/dt-editor-fn.js";
import DtTableStatic from "./dt-table-static.js";
import { EnumDTEditorEvent } from "../model/dt-enum.js";
export class DtTable {
    constructor(idSelector, tableName, title) {
        this.idSelector = "dataTable";
        this.idSrc = "";
        this.imageSrc = "";
        this.title = "";
        this.tableName = "";
        this.tableIndex = "";
        this._rowReorder = "";
        this.arrayColumn = [];
        this.arrayButton = [];
        this.ajaxUrl = DataTableStatic.ajaxUrl;
        this.create = true;
        this.edit = true;
        this.remove = true;
        this.pdf = false;
        this.excel = false;
        this.print = false;
        this.filterHead = false;
        this.controlColumn = false;
        this.editType = DtTableStatic._editType.def;
        this.language = undefined;
        this._language = DtTableStatic._language.def;
        this.editorInstance = null;
        this.dataTableInstance = null;
        this.lengthMenu = [];
        this.columns = [];
        if (idSelector) {
            this.idSelector = idSelector;
        }
        if (tableName) {
            this.tableName = tableName;
        }
        if (title) {
            this.title = title;
        }
    }
    setIdSrc(value) {
        if (DtTable.debug) {
            console.log("Table.setIdSrc(value) called", { "value": value });
        }
        this.idSrc = value;
        return this;
    }
    ;
    setIdSelector(value) {
        if (DtTable.debug) {
            console.log("Table.setIdSelector(value) called", { "value": value });
        }
        this.idSelector = value;
        return this;
    }
    ;
    setTitle(value) {
        if (DtTable.debug) {
            console.log("Table.setTitle(value) called", { "value": value });
        }
        this.title = value;
        return this;
    }
    ;
    setTableIndex(value) {
        if (DtTable.debug) {
            console.log("Table.setTableIndex(value) called", { "value": value });
        }
        this.tableIndex = value;
        return this;
    }
    ;
    setArrayButton(value) {
        if (DtTable.debug) {
            console.log("Table.setArrayButton(value) called", { "value": value });
        }
        this.arrayButton = value;
        return this;
    }
    ;
    setArrayColumn(value) {
        if (DtTable.debug) {
            console.log("Table.setArrayColumn(value) called", { "value": value });
        }
        this.arrayColumn = value;
        return this;
    }
    ;
    setCreate(value) {
        if (DtTable.debug) {
            console.log("Table.setCreate(value) called", { "value": value });
        }
        this.create = Boolean(value);
        return this;
    }
    ;
    setEdit(value) {
        if (DtTable.debug) {
            console.log("Table.setEdit(value) called", { "value": value });
        }
        this.edit = Boolean(value);
        return this;
    }
    ;
    setRemove(value) {
        if (DtTable.debug) {
            console.log("Table.setRemove(value) called", { "value": value });
        }
        this.remove = Boolean(value);
        return this;
    }
    ;
    setPdf(value) {
        if (DtTable.debug) {
            console.log("Table.setPdf(value) called", { "value": value });
        }
        this.pdf = Boolean(value);
        return this;
    }
    ;
    setExcel(value) {
        if (DtTable.debug) {
            console.log("Table.setExcel(value) called", { "value": value });
        }
        this.excel = Boolean(value);
        return this;
    }
    ;
    setPrint(value) {
        if (DtTable.debug) {
            console.log("Table.setPrint(value) called", { "value": value });
        }
        this.print = Boolean(value);
        return this;
    }
    ;
    setAjaxUrl(value) {
        if (DtTable.debug) {
            console.log("Table.setAjaxUrl(value) called", { "value": value });
        }
        this.ajaxUrl = value;
        return this;
    }
    ;
    setRowReorder(value) {
        if (DtTable.debug) {
            console.log("Table.setFilterHead(value) called", { "value": value });
        }
        this._rowReorder = value;
        return this;
    }
    ;
    /**
     *
     * @param value
     * @returns {Table}
     */
    setFilterHead(value) {
        if (DtTable.debug) {
            console.log("Table.setFilterHead(value) called", { "value": value });
        }
        this.filterHead = Boolean(value);
        return this;
    }
    ;
    setControlColumn(value) {
        if (DtTable.debug) {
            console.log("Table.setControlColumn(value) called", { "value": value });
        }
        this.controlColumn = Boolean(value);
        return this;
    }
    ;
    editInline() {
        if (DtTable.debug) {
            console.log("Table.editInline() called");
        }
        return this.setEditType(DtTableStatic._editType.inline);
    }
    ;
    editBubble() {
        if (DtTable.debug) {
            console.log("Table.editBubble() called");
        }
        return this.setEditType(DtTableStatic._editType.bubble);
    }
    ;
    setEditType(value) {
        if (DtTable.debug) {
            console.log("Table.setEditType(value) called", { "value": value });
        }
        if (Helpers._inObject(value, DtTableStatic._editType)) {
            this.editType = value;
        }
        return this;
    }
    ;
    setLanguage(value) {
        if (Helpers._inObject(value, DataTableStatic._language)) {
            this._language = value;
        }
        else {
            this._language = DtTableStatic._language.def;
        }
        if (DtTable.debug) {
            console.log("Table.setLanguage(value) called", { "value": value }, { language: this.language });
        }
        return this;
    }
    ;
    button(button) {
        if (DtTable.debug) {
            console.log("Table.button() called", { "button": button });
        }
        if (Helpers._isFunction(button.action)) {
            return this.addButton(button);
        }
        return this;
    }
    ;
    setImageSrc(imageSrc) {
        imageSrc = Helpers._isStringNotEmpty(imageSrc) ? imageSrc : "";
        this.imageSrc = imageSrc;
        return this;
    }
    ;
    column(data, title, editable, orderable, searchable, visible, type, options, def, className = "") {
        if (DtTable.debug) {
            console.log("Table.column() called", {
                "data": data,
                "title": title,
                "editable": editable,
                "orderable": orderable,
                "searchable": searchable,
                "visible": visible,
                "type": type,
                "options": options,
                "def": def,
                "className": className
            });
        }
        let column = new DtColumn(data, title);
        column
            .setOrderable(orderable)
            .setEditable(editable)
            .setSearchable(searchable)
            .setVisible(visible)
            .setType(type, options, def)
            .addClass(className);
        if (Helpers._isNotUndefined(type) && Helpers._isNotUndefined(options) && Helpers._isNotUndefined(def)) {
            column.setType(type, options, def);
        }
        if (Helpers._isNotUndefined(className)) {
            column.addClass(className);
        }
        return this.addColumn(column);
    }
    ;
    /**
     * @returns {number}
     */
    getColumnIndexOrder() {
        if (DtTable.debug) {
            console.log("Table.getColumnIndexOrder() called");
        }
        let columns = this.arrayColumn;
        let found = false;
        for (let index = 0; index < columns.length; index++) {
            let element = columns[index];
            if (element.orderable === true && element.visible === true) {
                found = true;
                return index;
            }
        }
        return -1;
    }
    ;
    /**
     *
     * @returns {DataTable}
     */
    dataTable(editor) {
        if (DtTable.debug) {
            console.log("Table.dataTable() called");
        }
        let arrayDebug = [];
        let dataTable;
        let tableId = this.idSelector;
        let $table = $("#" + tableId);
        arrayDebug.table = $table;
        let tableIndex = this.tableIndex;
        arrayDebug.tableIndex = tableIndex;
        let columns = this.arrayColumn;
        arrayDebug.columns = columns;
        if (this.controlColumn) {
            let controlColumn = null;
            let controlColumnContent = "";
            if (this.edit) {
                controlColumnContent += "<i class='far fa-edit edit text-confirm text-sm'></i>";
            }
            if (this.remove) {
                controlColumnContent += "<i class='far fa-trash-alt remove text-confirm text-sm'></i>";
            }
            if (this.edit || this.remove) {
                controlColumn = ColumnStatic._initSpecialColumn();
                controlColumn.addClass("editRemove").setDefaultContent(controlColumnContent).setWidth("40px");
            }
            if (controlColumn !== null && controlColumn instanceof DtColumn) {
                columns.unshift(controlColumn);
            }
        }
        let language = this._language;
        arrayDebug.language = language;
        if (columns.length > 0 &&
            typeof tableIndex === typeof "" &&
            tableIndex !== "" &&
            $table.length > 0) {
            let translation = _Translation._get(language);
            $.each(columns, function (index, element) {
                let columnRender = ColumnStatic._getColumnRender(element.type, element.options, editor, element);
                element.setRender(columnRender);
            });
            let options = this.getOptions(tableIndex, columns, language);
            if (Helpers._isStringNotEmpty(this._rowReorder) && editor) {
                options.rowReorder = {
                    selector: '.reorder',
                    dataSrc: this._rowReorder,
                    editor: editor
                };
            }
            DtTableStatic._clearHeadHtml(tableId);
            DtTableStatic._printTableTitle(this.title);
            dataTable = $table.DataTable(options);
            if (dataTable !== null) {
                ColumnStatic._detailControlInit(tableId, dataTable, translation);
                DtTableStatic._initDataTableEvent(dataTable);
            }
            if (this.filterHead) {
                DataTableStatic._displayFilterHead(this.idSelector, dataTable, columns);
            }
        }
        return this.dataTableInstance = dataTable;
    }
    ;
    /**
     * Create data editor with this options
     * @returns {Editor}
     */
    editor() {
        if (DtTable.debug) {
            console.log("Table.editor() called");
        }
        let tableIndex = this.tableIndex;
        let idSrc = this.idSrc;
        let imageSrc = this.imageSrc;
        let idSelector = this.idSelector;
        let url = this.ajaxUrl;
        let language = this._language;
        let arrayColumn = this.arrayColumn;
        let obj = null;
        if (Helpers._isStringNotEmpty(tableIndex) && arrayColumn.length > 0) {
            let editor = new DtEditor(tableIndex, idSrc, idSelector, language, url, imageSrc);
            obj = editor.dataEditor(arrayColumn);
        }
        if (DtTable.debug) {
            console.log("Table.editor() called", { Table: this });
        }
        this.editorInstance = obj;
        return obj;
    }
    ;
    getEditor() {
        return this.editorInstance;
    }
    ;
    getDataTable() {
        return this.dataTableInstance;
    }
    ;
    /**
     *
     */
    draw() {
        if (DtTable.debug) {
            console.log("Table.draw() called");
        }
        let edit = this.edit;
        // let create = this.create;
        //
        // let remove = this.remove;
        let tableId = this.idSelector;
        let translation = _Translation._get(this._language);
        let tableEditType = this.editType;
        let editor = this.editor();
        if (editor !== null) {
            let dataTable = this.dataTable(editor);
            switch (tableEditType) {
                case DtTableStatic._editType.inline:
                    DtEditorFn._inlineEdit(tableId, editor);
                    break;
                case DtTableStatic._editType.bubble:
                    DtEditorFn._bubbleEdit(tableId, editor);
                    break;
            }
            if (tableEditType !== DtTableStatic._editType.def && edit) {
                DtTableStatic._editableCellTooltip(tableId, translation);
            }
            editor.on(EnumDTEditorEvent.initEdit, function ( /*e: JQuery.Event, json: object, data: any, id: string*/) {
                console.log(EnumDTEditorEvent.initEdit + " called()");
                DtTableStatic._collapseAllRow(tableId, dataTable);
            });
            let buttons = this.getButtons(editor);
            if (DtTable.debug) {
                console.log({
                    "buttons": buttons
                });
            }
            if (buttons.length > 0) {
                ButtonStatic._appendControlButton(dataTable, buttons);
                // $('[data-toggle="tooltip"]').tooltip();
                const tooltip = new Tooltip($('[data-toggle="tooltip"]')[0], {
                    title: translation.edit.button
                });
                tooltip.show();
            }
            ColumnStatic._editRemoveControlInit(tableId, editor, translation);
        }
        if (DtTable.debug) {
            console.log("Table() called", { Table: this });
        }
        return this;
    }
    ;
    /**
     *
     * @param column
     * @returns {Table}
     */
    addColumn(column) {
        if (DtTable.debug) {
            console.log("Table.addColumn() called");
        }
        // if (column instanceof DtColumn) {
        let arrayColumn = this.arrayColumn;
        let found = false;
        arrayColumn.forEach(function (item) {
            if (item instanceof DtColumn) {
                if (item.data === column.data) {
                    found = true;
                }
            }
        });
        if (!found) {
            arrayColumn.push(column);
        }
        this.setArrayColumn(arrayColumn);
        // }
        return this;
    }
    /**
     *
     * @param {object} button
     * @returns {Table} return this for chaining
     */
    addButton(button) {
        if (DtTable.debug) {
            console.log("Table.addButton() called", { "button": button });
        }
        let arrayButton = this.arrayButton;
        arrayButton.push(button);
        return this.setArrayButton(arrayButton);
    }
    /**
     *
     * @param {Editor} editor
     * @return {StButton[]}
     * @private
     */
    getButtons(editor) {
        if (DtTable.debug) {
            console.log("Table.getButtons(editor) called");
            console.log({
                "editor": editor
            });
            console.log("");
        }
        let buttons = [];
        let translation = _Translation._get(this._language);
        if (this.create) {
            buttons.push(ButtonStatic._extend(editor, "create", '<i class="fas fa-plus-circle text-confirm text-sm"></i>', translation.create.button));
        }
        if (this.edit) {
            buttons.push(ButtonStatic._extend(editor, "edit", '<i class="far fa-edit text-confirm text-sm"></i>', translation.edit.button));
        }
        if (this.remove) {
            buttons.push(ButtonStatic._extend(editor, "remove", '<i class="far fa-trash-alt text-confirm text-sm"></i>', translation.remove.button));
        }
        if (this.print) {
            buttons.push(ButtonStatic._extend(editor, "print", '<i class="fas fa-print text-confirm text-sm"></i>', translation.print.button));
        }
        if (this.pdf) {
            buttons.push(ButtonStatic._extend(editor, "pdf", '<i class="far fa-file-pdf text-confirm text-sm"></i>', translation.pdf.button));
        }
        if (this.excel) {
            buttons.push(ButtonStatic._extend(editor, "excel", '<i class="fa fa-file-excel text-confirm text-sm"></i>', translation.excel.button));
        }
        buttons.push(ButtonStatic._extend(editor, "selectAll", `<span class="fa-stack fa-xs text-sm">  <i class="far fa-square fa-stack-2x"></i> <i class="fas fa-check-double fa-stack-1x fa-inverse"></i> </span>`, translation._custom.button.selectAll));
        buttons.push(ButtonStatic._extend(editor, "selectNone", '<i class="far fa-square text-confirm text-sm"></i>', translation._custom.button.selectNone));
        let arrayButton = this.arrayButton;
        arrayButton.forEach(function (item) {
            buttons.push(item);
        });
        return buttons;
    }
    /**
     * @returns {object}
     */
    getOptions(tableIndex, columns, language) {
        if (DtTable.debug) {
            console.log("Table.getOptions() called");
        }
        let arrayDebug = [];
        let options = DtTableStatic._defOptions;
        options.ajax = {
            url: this.ajaxUrl,
            type: "POST",
            data: function (d) {
                d._t = tableIndex;
                d._lang = language;
            }
        };
        options.tableIndex = tableIndex;
        options.columns = columns;
        let orderColumnIndex = this.getColumnIndexOrder();
        if (orderColumnIndex > -1) {
            let orderColumn = columns[orderColumnIndex];
            if (DataTableStatic.debug) {
                console.log(orderColumn);
            }
            let orderSequence = 'asc';
            let orderType = Helpers._getObjByProp(orderColumn, "orderType", null);
            if (orderType) {
                orderSequence = orderType;
                // orderSequence = orderSequence[0];
                if (DataTableStatic.debug) {
                    console.log(orderSequence);
                }
            }
            options.order = [[orderColumnIndex, orderSequence]];
        }
        let translation = _Translation._get(language);
        options.language = translation;
        options.lengthMenu = [[25, 50, 100, -1], [25, 50, 100, translation.menuText.all]];
        arrayDebug.tableOptions = options;
        if (DataTableStatic.debug) {
            console.log(options);
        }
        return options;
    }
}
DtTable.debug = false;
