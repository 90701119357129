import '../styles/scss/datacard/control/controls.css';
import $ from "jquery";
import Helpers from "../../../../dist/_utils/helpers.js";
import __PROP from "../../utils/_prop.js";
import DcEditorFn from "../editor/dc-editor-fn.js";
import DcPaginationFn from "../grid/pagination/dc-pagination-fn.js";
import { cardModel, controlModel, dcModel } from "../model/dc-model.js";
export default class DcControlRender {
    static _createControlPanel(dataCard) {
        if (dataCard !== null) {
            let settings = dataCard.settings();
            if (Helpers._isStringNotEmpty(settings.controlPanel)) {
                let $controlPanel = $(settings.controlPanel);
                if (!$controlPanel.hasClass(controlModel.controlPanelContainer.attr.class)) {
                    $controlPanel.addClass(controlModel.controlPanelContainer.attr.class);
                }
                $controlPanel.attr('id', controlModel.controlPanelContainer.attr.class).addClass(dcModel.activeClass);
                let $btnRow = DcControlRender._createBtnRow(dataCard);
                let $filterRow = DcControlRender._createFilterRow(dataCard);
                let $filterSortPanel = DcControlRender._createFilterSort(dataCard);
                $controlPanel
                    // .append($group)
                    .append($btnRow)
                    .append($filterRow)
                    .append($filterSortPanel);
            }
        }
    }
    static _createBtnRow(dataCard) {
        let settings = dataCard.settings();
        let $btnPanel = $("<div/>")
            .attr(controlModel.btnPanel.attr)
            .addClass(controlModel.btn.btnGroup.attr.class)
            .addClass("btn-group btn-group-sm");
        // let arrayBtn = Helpers._getObjByProp(settings, __PROP.buttons, []);
        let arrayBtn = settings.buttons;
        $.each(arrayBtn, function (index, btn) {
            if (!$.isEmptyObject(btn) && typeof btn === 'object') {
                $btnPanel.append(DcControlRender._getDefBtn(settings, btn));
            }
        });
        return $("<div/>").addClass("col-12").append($btnPanel);
    }
    static _createFilterRow(dataCard) {
        let $lengthPanel = DcControlRender._createLengthMenu(dataCard);
        let $generalSearchPanel = DcControlRender._createGeneralSearch(dataCard);
        return $("<div/>").addClass("row m-t-10")
            .append($lengthPanel)
            .append($generalSearchPanel);
    }
    static _createFilterSort(dataCard) {
        let $filterSortContainer = $('<div/>')
            .addClass("col-xs-12 table-responsive");
        $filterSortContainer = DcControlRender._createSortFilterField($filterSortContainer, dataCard);
        return $filterSortContainer;
    }
    static _getDefBtn(settings, btn) {
        return $('<button/>')
            .attr(controlModel.btn.item.attr)
            .attr(__PROP.title, btn.title)
            .addClass(btn.className)
            .append($(btn.icon));
    }
    static _createLengthMenu(dataCard) {
        let settings = dataCard.settings();
        let $lengthPanel = null;
        if (settings.lengthMenu) {
            $lengthPanel = $("<div/>")
                // .css({textAlign : "right"})
                .addClass(controlModel.filterClass)
                .addClass("col-lg-6 col-md-12");
            let $lengthMenu = null;
            if (settings.lengthMenu.length > 0) {
                let $select = $('<select/>')
                    .attr(controlModel.selectLength.attr)
                    .addClass('form-control form-control-sm');
                let isArrayOfArray = true;
                let isArrayOfInteger = true;
                // let lengthMenu = Helpers._getObjByProp(settings, __PROP.lengthMenu, []);
                let lengthMenu = settings.lengthMenu || [];
                $.each(lengthMenu, function (index, element) {
                    if (typeof element !== typeof []) {
                        isArrayOfArray = false;
                    }
                    if (typeof element !== typeof 1) {
                        isArrayOfInteger = false;
                    }
                });
                if (isArrayOfArray) {
                    let arrayVal = lengthMenu[0];
                    let arrayText = lengthMenu.length > 1 ? lengthMenu[1] : null;
                    $.each(arrayVal, function (index, element) {
                        let text;
                        if (arrayText !== null && typeof arrayText[index] !== 'undefined') {
                            text = arrayText[index];
                        }
                        else {
                            text = element;
                        }
                        let option = $('<option/>').val(element.toString()).text(text.toString());
                        $select.append(option);
                    });
                }
                if (isArrayOfInteger) {
                    $.each(lengthMenu, function (index, element) {
                        let translation = dataCard.i18n();
                        let text = element === -1 ? translation.menuText.all : element;
                        let option = $('<option/>').val(element).text(text);
                        $select.append(option);
                    });
                }
                $lengthMenu =
                    $('<div>' +
                        '<label>Afficher ' +
                        $select[0].outerHTML +
                        ' éléments' +
                        '</label>' +
                        '</div>');
            }
            $lengthPanel.append($lengthMenu);
        }
        return $lengthPanel;
    }
    static _createGeneralSearch(dataCard) {
        let settings = dataCard.settings();
        let i18n = dataCard.i18n();
        let $searchPanel = $("<div/>")
            .addClass(controlModel.filterClass)
            .addClass("col-lg-6 col-md-12");
        let $searchFilter = null;
        if (settings.search) {
            $searchFilter = $('<div/>')
                .css({ textAlign: "right" });
            let $input = $('<input/>').attr(controlModel.inputSearch.attr)
                .addClass('form-control form-control-sm');
            let $label = $('<label/>').text(i18n.sSearch).append($input);
            $searchFilter.append($label);
        }
        return $searchPanel.append($searchFilter);
    }
    static _createSortFilterField($filterSortContainer, dataCard) {
        let settings = dataCard.settings();
        let isRowReOrderValid = !DcEditorFn._isRowReorderValid(settings);
        if (settings.search || settings.sort) {
            let arrayField = [];
            dataCard.fields().forEach(function (element) {
                // console.log({element})
                if ((element.search || (element.sort && isRowReOrderValid)) && element.visible) {
                    arrayField.push(element);
                }
            });
            dataCard.multiFields().forEach(function (element) {
                if ((element.search || (element.sort && isRowReOrderValid)) && element.visible) {
                    arrayField.push(element);
                }
            });
            // console.log({arrayField})
            if (arrayField.length > 0) {
                let $trSort = $('<tr/>').attr("role", "row");
                let $trFilter = $('<tr/>').attr("role", "row");
                arrayField.forEach(function (element) {
                    if ((element.search || element.sort) && element.visible) {
                        let $thSort = $('<th/>');
                        let $thFilter = $('<th/>');
                        let options = element.options;
                        $thSort.html(element.label);
                        if (element.search) {
                            let $search;
                            if (Helpers._isNotUndefined(options) && !$.isEmptyObject(options)) {
                                $search = DcControlRender._drawSelectFilter(dataCard, dataCard.i18n().menuText.all, element, options);
                            }
                            else {
                                $search = DcControlRender._drawInputFilter(dataCard);
                            }
                            $search
                                .attr(dcModel.dataMultiFieldAttr, element._isMultiField.toString())
                                .attr(controlModel.search.fieldNameAttr, element.name)
                                .attr(controlModel.search.fieldIdAttr, element._uniqueId)
                                .appendTo($thFilter);
                        }
                        if ((element.sort || isRowReOrderValid) && element.visible) {
                            $thSort
                                .addClass(controlModel.sort.class)
                                .attr(dcModel.dataMultiFieldAttr, element._isMultiField.toString())
                                .attr(controlModel.sort.fieldNameAttr, element.name)
                                .attr(controlModel.sort.fieldIdAttr, element._uniqueId);
                        }
                        $trSort.append($thSort);
                        $trFilter.append($thFilter);
                    }
                });
                let $tHead = $("<thead/>")
                    .append($trSort)
                    .append($trFilter);
                let $table = $("<table/>").addClass('table table-bordered');
                $table.append($tHead);
                $filterSortContainer.append($table);
            }
        }
        return $filterSortContainer;
    }
    static _drawSelectFilter(dataCard, title, field, options) {
        let $select = $('<select class="filter form-control"/>');
        if (!field._isGroup) {
            $select.append('<option value="">' + title + '</option>');
        }
        if (Array.isArray(options)) {
            $.each(options, function (index, opt) {
                const { label, value } = opt;
                $select.append('<option value="' + value + '">' + label + '</option>');
            });
        }
        else {
            $.each(options, function (name, elementId) {
                $select.append('<option value="' + elementId + '">' + name + '</option>');
            });
        }
        return $select;
    }
    static _drawInputFilter(dataCard) {
        let $input = $('<input/>');
        $input.addClass('form-control');
        return $input;
    }
    static _createPageInfoRow(dataCard) {
        let settings = dataCard.settings();
        if (settings.pageLength) {
            let $infoRow = $('<div/>').addClass("row");
            let $info = $('<div/>').attr(cardModel.pageInfo.attr);
            let $infoItem = $('<span/>').attr({ 'class': 'info-item' });
            let $infoSelected = $('<span/>').attr({ 'class': 'selected-item' }).addClass("m-l-5");
            let text = dataCard.i18n().select.rows["0"];
            $infoSelected.text(text);
            $info.append($infoItem).append($infoSelected);
            $infoRow.append($info).appendTo(settings.jquerySelector);
            $('<div/>').attr(cardModel.pagination.attr).appendTo($infoRow);
        }
    }
    static _drawPagination(dataCard, segmentedData, currentPage, pageLength, displayData, data) {
        DcPaginationFn._drawPageList(dataCard, segmentedData, currentPage);
        let elementLength = segmentedData[currentPage - 1].length;
        let start = 0;
        if (elementLength > 0) {
            start = 1;
        }
        let end = elementLength;
        start += (currentPage - 1) * pageLength;
        end += (currentPage - 1) * pageLength;
        DcPaginationFn._updateInfo(dataCard, start, end, displayData, data);
    }
}
DcControlRender.debug = false;
