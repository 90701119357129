var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import $ from "jquery";
import { EnumFormQuestionType } from "../../../../../../server/model/_general/form/_types.js";
const appFormResponseEditorItem = 'app_form_responses';
const appFormResponseI18nEditorItem = 'app_form_response_i18ns';
export { appFormResponseEditorItem, appFormResponseI18nEditorItem };
export default class FormResponseBuilderStatic {
    static _renderResponses(formBuilder, formQuestion, responses, $responseAccordion) {
        return __awaiter(this, void 0, void 0, function* () {
            const { formQuestionId, questionType, required } = formQuestion;
            switch (questionType) {
                case EnumFormQuestionType.text:
                    yield FormResponseBuilderStatic._renderResponseInput(formBuilder, formQuestion, $responseAccordion);
                    break;
                case EnumFormQuestionType.email:
                    yield FormResponseBuilderStatic._renderResponseInputEmail(formBuilder, formQuestion, $responseAccordion);
                    break;
                case EnumFormQuestionType.number:
                    yield FormResponseBuilderStatic._renderResponseInputNumber(formBuilder, formQuestion, $responseAccordion);
                    break;
                case EnumFormQuestionType.checkbox:
                    yield FormResponseBuilderStatic._renderResponseCheckbox(formBuilder, formQuestion, responses, $responseAccordion);
                    break;
                case EnumFormQuestionType.select:
                    yield FormResponseBuilderStatic._renderResponseSelect(formBuilder, formQuestion, responses, $responseAccordion);
                    break;
                case EnumFormQuestionType.radio:
                    yield FormResponseBuilderStatic._renderResponseRadio(formBuilder, formQuestion, responses, $responseAccordion);
                    break;
            }
            console.log({ formQuestion, responses, $responseAccordion });
            // const $formQuestionElement = $(`
            //     <div class="response">
            //
            //     </div>
            // `);
            // $responseAccordion.append($formQuestionElement)
            // console.log(`FormQuestionStatic._renderFormQuestion`, {
            //     formBuilder, response, $formGroupQuestionBody
            // });
        });
    }
    static _renderResponseInput(formBuilder, formQuestion, $responseAccordion) {
        return __awaiter(this, void 0, void 0, function* () {
            const { formQuestionId, questionType, required } = formQuestion;
        });
    }
    static _renderResponseInputEmail(formBuilder, formQuestion, $responseAccordion) {
        return __awaiter(this, void 0, void 0, function* () {
        });
    }
    static _renderResponseInputNumber(formBuilder, formQuestion, $responseAccordion) {
        return __awaiter(this, void 0, void 0, function* () {
        });
    }
    static _renderResponseCheckbox(formBuilder, formQuestion, responses, $responseAccordion) {
        return __awaiter(this, void 0, void 0, function* () {
            if (responses.length) {
            }
            else {
                yield FormResponseBuilderStatic._renderEmptyResponsePanel(formBuilder, formQuestion, $responseAccordion);
            }
        });
    }
    static _renderResponseSelect(formBuilder, formQuestion, responses, $responseAccordion) {
        return __awaiter(this, void 0, void 0, function* () {
            if (responses.length) {
            }
            else {
                yield FormResponseBuilderStatic._renderEmptyResponsePanel(formBuilder, formQuestion, $responseAccordion);
            }
        });
    }
    static _renderResponseRadio(formBuilder, formQuestion, responses, $responseAccordion) {
        return __awaiter(this, void 0, void 0, function* () {
            if (responses.length) {
            }
            else {
                yield FormResponseBuilderStatic._renderEmptyResponsePanel(formBuilder, formQuestion, $responseAccordion);
            }
        });
    }
    static _renderEmptyResponsePanel(formBuilder, formQuestion, $responseAccordion) {
        return __awaiter(this, void 0, void 0, function* () {
            $responseAccordion.append($('<div class="">empty<div/>'));
        });
    }
}
