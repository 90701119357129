var EnumLanguage;
(function (EnumLanguage) {
    EnumLanguage["en"] = "en";
    EnumLanguage["de"] = "de";
    EnumLanguage["fr"] = "fr";
    EnumLanguage["nl"] = "nl";
    EnumLanguage["def"] = "fr";
})(EnumLanguage || (EnumLanguage = {}));
var EnumColumnType;
(function (EnumColumnType) {
    EnumColumnType["boolean"] = "boolean";
    EnumColumnType["checkbox"] = "checkbox";
    EnumColumnType["color"] = "color";
    EnumColumnType["date"] = "date";
    EnumColumnType["file"] = "file";
    EnumColumnType["daterange"] = "daterange";
    EnumColumnType["datetime"] = "datetime";
    EnumColumnType["flag"] = "flag";
    EnumColumnType["float"] = "float";
    EnumColumnType["icon"] = "icon";
    EnumColumnType["image"] = "image";
    EnumColumnType["integer"] = "integer";
    EnumColumnType["password"] = "password";
    EnumColumnType["price"] = "price";
    EnumColumnType["radio"] = "radio";
    EnumColumnType["select"] = "select";
    EnumColumnType["textarea"] = "textarea";
    EnumColumnType["text"] = "text";
    EnumColumnType["time"] = "time";
    EnumColumnType["timestep"] = "timestep";
    EnumColumnType["upload"] = "upload";
    EnumColumnType["uploadMany"] = "uploadMany";
})(EnumColumnType || (EnumColumnType = {}));
var EnumDtEvent;
(function (EnumDtEvent) {
    EnumDtEvent["colSizing"] = "column-sizing.dt";
    EnumDtEvent["colVisibility"] = "column-visibility.dt";
    EnumDtEvent["destroy"] = "destroy.dt";
    EnumDtEvent["draw"] = "draw";
    EnumDtEvent["error"] = "error.dt";
    EnumDtEvent["init"] = "init.dt";
    EnumDtEvent["length"] = "length.dt";
    EnumDtEvent["order"] = "order.dt";
    EnumDtEvent["page"] = "page.dt";
    EnumDtEvent["preDraw"] = "preDraw.dt";
    EnumDtEvent["preInit"] = "preInit.dt";
    EnumDtEvent["preXhr"] = "preXhr.dt";
    EnumDtEvent["processing"] = "processing.dt";
    EnumDtEvent["search"] = "search.dt";
    EnumDtEvent["stateLoadParams"] = "stateLoadParams.dt";
    EnumDtEvent["stateSaveParams"] = "stateSaveParams.dt";
    EnumDtEvent["xhr"] = "xhr.dt";
    EnumDtEvent["responsiveDisplay"] = "responsive-display.dt";
    EnumDtEvent["responsiveResize"] = "responsive-resize.dt";
})(EnumDtEvent || (EnumDtEvent = {}));
var EnumDTEditorEvent;
(function (EnumDTEditorEvent) {
    EnumDTEditorEvent["read"] = "read";
    EnumDTEditorEvent["preOpen"] = "preOpen";
    EnumDTEditorEvent["open"] = "open";
    EnumDTEditorEvent["initSubmit"] = "initSubmit";
    EnumDTEditorEvent["preSubmit"] = "preSubmit";
    EnumDTEditorEvent["postSubmit"] = "postSubmit";
    EnumDTEditorEvent["preClose"] = "preClose";
    EnumDTEditorEvent["close"] = "close";
    EnumDTEditorEvent["submitSuccess"] = "submitSuccess";
    EnumDTEditorEvent["processing"] = "processing";
    EnumDTEditorEvent["submitComplete"] = "submitComplete";
    EnumDTEditorEvent["submitError"] = "submitError";
    EnumDTEditorEvent["submitUnsuccessful"] = "submitUnsuccessful";
    EnumDTEditorEvent["initCreate"] = "initCreate";
    EnumDTEditorEvent["preCreate"] = "preCreate";
    EnumDTEditorEvent["create"] = "create";
    EnumDTEditorEvent["postCreate"] = "postCreate";
    EnumDTEditorEvent["initEdit"] = "initEdit";
    EnumDTEditorEvent["preEdit"] = "preEdit";
    EnumDTEditorEvent["edit"] = "edit";
    EnumDTEditorEvent["postEdit"] = "postEdit";
    EnumDTEditorEvent["initRemove"] = "initRemove";
    EnumDTEditorEvent["preRemove"] = "preRemove";
    EnumDTEditorEvent["remove"] = "remove";
    EnumDTEditorEvent["postRemove"] = "postRemove";
    EnumDTEditorEvent["initEditor"] = "initEditor";
    EnumDTEditorEvent["preUpload"] = "preUpload";
    EnumDTEditorEvent["preUploadCancelled"] = "preUploadCancelled";
    EnumDTEditorEvent["uploadXhrError"] = "uploadXhrError";
    EnumDTEditorEvent["uploadXhrSuccess"] = "uploadXhrSuccess";
    EnumDTEditorEvent["postUpload"] = "postUpload";
})(EnumDTEditorEvent || (EnumDTEditorEvent = {}));
export { EnumLanguage, EnumColumnType, EnumDtEvent, EnumDTEditorEvent };
