import $ from 'jquery';
import "datatables.net";
import Helpers from "../../../../../dist/_utils/helpers.js";
import DtTableStatic from "../dt-table-static.js";
import _separator from "../../../utils/_separator.js";
import _timeHelper from "../../../utils/_time-helper.js";
import DataTableStatic from "../data-table-static.js";
import { DtColumn } from "./dt-column.js";
import moment from 'moment';
import { Tooltip } from "bootstrap";
// import  Tooltip from "bootstrap/js/src/tooltip.js";
const renderCallerName = "ColumnStatic.render.";
export default class ColumnStatic {
    //TODO change field type
    static _getColumnRender(type, fieldOptions, editor, field) {
        if (ColumnStatic.debug) {
            console.log(renderCallerName + Helpers._getCallerName(arguments), arguments);
        }
        let allRender = ColumnStatic.render;
        if (allRender.hasOwnProperty(type) && Helpers._isFunction(allRender[type])) {
            return allRender[type](fieldOptions, editor, field);
        }
        else {
            return null;
        }
    }
    /*static _getColumns(tableId : string) {
        let arrayColumn : DtColumn[]= [];

        $("#" + tableId + " thead tr:first th").each(function (index, element) {
            let $element = $(element);
            let columnIndex = DataTableStatic._getAttribute($element, ColumnStatic._const.attrColumnIndex);
            if (columnIndex !== "") {
                let column;
                if (columnIndex === "NULL") {
                    column = ColumnStatic._getSpecialColumn(DataTableStatic._getAttribute($element, ColumnStatic._const.attrClassName).trim());
                    column
                        .setCreate(false)
                        .setEditable(false)
                        .setOrderable(false)
                        .setSearchable(false)
                        .setVisible(DataTableStatic._getBooleanAttribute($element, ColumnStatic._const.attrVisible))
                    ;
                } else {

                    let dataOptions = DataTableStatic._getAttribute($element, ColumnStatic._const.attrOptions);
                    let options = null;
                    if (dataOptions !== "") {

                        options = JSON.parse(dataOptions);
                    }

                    let dataImageSrc = DataTableStatic._getAttribute($element, ColumnStatic._const.attrImageSrc), imageSrc = [];

                    if (Helpers._isStringNotEmpty(dataImageSrc)) {

                        imageSrc = JSON.parse(dataImageSrc);
                    }
                    column = new DtColumn(columnIndex, DataTableStatic._getAttribute($element, ColumnStatic._const.attrTitle));

                    column
                        .addClass(DataTableStatic._getAttribute($element, ColumnStatic._const.attrClassName))
                        .setClassName(DataTableStatic._getAttribute($element, ColumnStatic._const.attrClassName))
                        .setEditable(DataTableStatic._getBooleanAttribute($element, ColumnStatic._const.attrEditable))
                        .setCreate(DataTableStatic._getBooleanAttribute($element, ColumnStatic._const.attrCreate))
                        .setOrderable(DataTableStatic._getBooleanAttribute($element, ColumnStatic._const.attrOrderable))
                        .setOrderSequence(DataTableStatic._getAttribute($element, ColumnStatic._const.attrOrderSequence))
                        .setSearchable(DataTableStatic._getBooleanAttribute($element, ColumnStatic._const.attrSearchable))
                        .setImageSrc(imageSrc)
                        .setVisible(DataTableStatic._getBooleanAttribute($element, ColumnStatic._const.attrVisible))
                        .setType(
                            DataTableStatic._getAttribute($element, ColumnStatic._const.attrType),
                            options,
                            DataTableStatic._getAttribute($element, ColumnStatic._const.attrDefaultValue)
                        )
                    ;
                    let width = DataTableStatic._getAttribute($element, ColumnStatic._const.attrWidth);
                    if (Helpers._isStringNotEmpty(width)) {
                        column.setWidth(
                            DataTableStatic._getAttribute($element, ColumnStatic._const.attrWidth)
                        );
                    }
                }
                if (column instanceof DtColumn) {

                    arrayColumn.push(column);
                }
            }
        });
        if (ColumnStatic.debug) {
            console.log(arrayColumn);
        }
        return arrayColumn;
    }*/
    static _validColor(color) {
        return /^#[0-9A-F]{6}$/i.test(color);
    }
    static _renderOption(options) {
        return function render(data) {
            let returnValue = data;
            if (options !== null && typeof options === typeof {}) {
                // console.log(`_renderOption`, {data, options})
                $.each(options, function (valueName, element) {
                    if (options[valueName] === data) {
                        returnValue = valueName;
                    }
                });
            }
            return returnValue;
        };
    }
    static _getSpecialColumn(columnClass) {
        switch (columnClass.trim()) {
            case "details":
                return ColumnStatic._getDetailColumn(columnClass);
            case "editRemove":
                return ColumnStatic._getEditRemoveColumn();
            default:
                return new DtColumn(columnClass);
        }
    }
    static _getEditRemoveColumn() {
        let col = ColumnStatic._initSpecialColumn();
        return col
            .addClass("editRemove")
            .setDefaultContent('<i class="far fa-edit edit text-confirm text-sm"></i><i class="far fa-trash-alt remove text-confirm text-sm"></i>')
            .setOrderable(false);
    }
    static _getDetailColumn(className) {
        let col = ColumnStatic._initSpecialColumn();
        return col.addClass(className).setWidth("30px").setOrderable(false);
    }
    static _initSpecialColumn() {
        return new DtColumn("NULL");
    }
    static _editRemoveControlInit(tableId, editor, translation) {
        let $table = $('#' + tableId + ' tbody');
        if ($table.length > 0) {
            $table
                .on('click', 'tr td i.edit', function (e) {
                e.preventDefault();
                // @ts-ignore
                editor.edit($(this).closest('tr'), {
                    title: translation.edit.title,
                    buttons: translation.edit.submit
                });
            })
                .on('mouseenter', "td i.edit", function () {
                // $(this).tooltip({
                //     title: translation.edit.button
                // }).tooltip('show');
                const tooltip = new Tooltip(this, {
                    title: translation.edit.button
                });
                tooltip.show();
            });
            // Delete a record
            $table
                .on('click', 'i.remove', function (e) {
                e.preventDefault();
                // @ts-ignore
                editor.remove($(this).closest('tr'), {
                    title: translation.remove.title,
                    message: translation.remove.confirm[1],
                    buttons: translation.remove.submit,
                    submit: translation.remove.submit
                });
            })
                .on('mouseenter', "td i.remove", function () {
                // $(this).tooltip({
                //     title: translation.remove.button
                // }).tooltip('show');
                const tooltip = new Tooltip(this, {
                    title: translation.remove.button
                });
                tooltip.show();
            });
        }
    }
    static _detailControlInit(tableId, dataTable, translation) {
        let shownClass = "shown";
        let $table = $('#' + tableId + ' tbody');
        $table
            .on('click', "tr td.details", function (e) {
            e.preventDefault();
            let tr = $(this).closest('tr');
            let row = dataTable.row(tr);
            if (row.child.isShown()) {
                row.child.remove();
                tr.removeClass(shownClass);
                DtTableStatic._collapseAllRow(tableId, dataTable);
            }
            else {
                const params = dataTable.ajax.params();
                let ajaxData = {
                    _t: params._t,
                    _lang: params._lang,
                    data: row.data()
                };
                $.post("/admin/_api/details/", ajaxData)
                    .done(function (data) {
                    if (data !== "") {
                        DtTableStatic._collapseAllRow(tableId, dataTable);
                        row.child(data).show();
                        tr.addClass(shownClass);
                    }
                });
            }
        })
            .on('mouseenter', "td.details", function () {
            // $(this).tooltip("dispose");
            // $(this).tooltip({
            //     title: translation._custom.tooltip.details
            // }).tooltip('show');
            const tooltip = new Tooltip(this, {
                title: translation._custom.tooltip.details
            });
            tooltip.show();
        });
    }
}
ColumnStatic.debug = false;
ColumnStatic._callerName = "ColumnStatic.";
ColumnStatic._editableClass = "editable ";
ColumnStatic._const = {
    attrColumnIndex: `data-column`,
    attrDefaultValue: `data-def`,
    attrDefContent: "data-content",
    attrEditable: "data-edit",
    attrCreate: "data-create",
    attrOptions: "data-options",
    attrOrderDataType: "data-order-data-type",
    attrOrderable: "data-orderable",
    attrOrderSequence: "data-order-sequence",
    attrSearchable: "data-searchable",
    attrImageSrc: "data-image-src",
    attrTitle: "data-title",
    attrType: "data-type",
    attrWidth: "data-width",
    attrVisible: "data-visible",
    attrClassName: "data-classname"
};
ColumnStatic.render = {
    boolean: function () {
        return function render(data, type, row, meta) {
            if (ColumnStatic.debug) {
                console.log(renderCallerName + Helpers._getCallerName(arguments), arguments);
            }
            let check = data === 1 || data === "1" || data === true || data === "true" ? "checked" : "";
            return `
                <div class="form-check form-check-inline form-switch mb-0 flex-fill">
                  <input class="form-check-input float-end" type="checkbox" id="flexSwitchCheckChecked" ${check} disabled>
<!--                      <label class="form-check-label" for="flexSwitchCheckChecked">Checked switch checkbox input</label>-->
                </div>`;
            // return '<div class="text-center switch switch-sm" style="width:auto;">' +
            //     '<label class="switch p-switch-sm v-a-m m-auto">' +
            //     '<input type="checkbox" name="switch" ' + check + ' disabled="disabled"><span class="p-switch-style"></span>' +
            //     '</label>' +
            //     '</div>';
        };
    },
    color: function () {
        return function render(data, type, row, meta) {
            if (ColumnStatic.debug) {
                console.log(renderCallerName + Helpers._getCallerName(arguments), arguments);
            }
            let color = ColumnStatic._validColor(data) ? data : "";
            let backgroundColor = color !== "" ? 'style="background-color : ' + color + ';" ' : "";
            return '<span class="color-circle"' + backgroundColor + '></span> <span>' + data + '</span>';
        };
    },
    checkbox: function (options) {
        return function checkbox(data, type, row, meta) {
            if (ColumnStatic.debug) {
                console.log(renderCallerName + Helpers._getCallerName(arguments), arguments);
                console.log({
                    options: options, data: data, type: type, row: row, meta: meta
                });
            }
            let arrayData = data.split("|");
            let optionsArray = [];
            $.each(arrayData, function (index, dataElement) {
                if (Helpers._isSameType(options, {})) {
                    let badge = "";
                    $.each(options, function (key, value) {
                        if (dataElement === value) {
                            badge += '<span class="badge badge-pill badge-secondary">' + key.toString() + '</span>';
                            optionsArray.push(badge);
                        }
                    });
                }
            });
            return optionsArray.join("");
        };
    },
    daterange: function () {
        if (ColumnStatic.debug) {
            console.log(renderCallerName + Helpers._getCallerName(arguments), arguments);
        }
        //TODO daterange
        return () => {
            return null;
        };
    },
    date: function () {
        return function render(data, type, row, meta) {
            if (ColumnStatic.debug) {
                console.log(renderCallerName + Helpers._getCallerName(arguments), arguments);
            }
            if (moment) {
                console.log("has moment");
                let mom = moment(data, DataTableStatic.format.timeStamp);
                if (mom.isValid()) {
                    return mom.format(DataTableStatic.format.date);
                }
                else {
                    return "";
                }
            }
            else {
                return Helpers._intToDate(data);
            }
        };
    },
    time: function () {
        return function render(data, type, row, meta) {
            if (ColumnStatic.debug) {
                console.log(renderCallerName + Helpers._getCallerName(arguments), arguments);
            }
            if (moment) {
                let mom = moment(data, DataTableStatic.format.timeStamp);
                if (mom.isValid()) {
                    return mom.utc().format(DataTableStatic.format.time);
                }
                else {
                    return "";
                }
            }
            else {
                return Helpers._intToDate(data);
            }
        };
    },
    datetime: function () {
        return function render(data, type, row, meta) {
            if (ColumnStatic.debug) {
                console.log(renderCallerName + Helpers._getCallerName(arguments), arguments);
            }
            if (moment) {
                let mom = moment(data, DataTableStatic.format.timeStamp);
                if (mom.isValid()) {
                    return mom.format(DataTableStatic.format.datetime);
                }
                else {
                    return "";
                }
            }
            else {
                return Helpers._intToDate(data);
            }
        };
    },
    day_of_week: function (options) {
        if (ColumnStatic.debug) {
            console.log(renderCallerName + Helpers._getCallerName(arguments), arguments);
        }
        return ColumnStatic.render.checkbox(options);
    },
    flag: function () {
        return function render(data, type, row, meta) {
            if (ColumnStatic.debug) {
                console.log(renderCallerName + Helpers._getCallerName(arguments), arguments);
            }
            let arrayDebug = [];
            arrayDebug.data = data;
            arrayDebug.type = type;
            arrayDebug.row = row;
            arrayDebug.meta = meta;
            return '<div class="text-center"><span class="flag-icon ' + row['_col_icon'] + '"></span></div>';
        };
    },
    float: function () {
        return function render(data, type, row, meta) {
            if (ColumnStatic.debug) {
                console.log(renderCallerName + Helpers._getCallerName(arguments), arguments);
            }
            data = Helpers._isStringNotEmpty(data) && Helpers._isNumeric(data) ? parseInt(data) : 0;
            return Helpers._intToDec(data);
        };
    },
    icon: function () {
        return function render(data, type, row, meta) {
            if (ColumnStatic.debug) {
                console.log(renderCallerName + Helpers._getCallerName(arguments), arguments);
            }
            if (ColumnStatic.debug || true) {
                console.log({
                    data: data, type: type, row: row, meta: meta
                });
            }
            return '<div class="text-center text-light social-icons social-icons-rounded social-icons-small social-icons-colored d-block">' +
                '<ul>' +
                '<li>' +
                '<a href="javascript:void(0)" style="background-color: ' + row['_col_color'] + '; cursor: default;" class="elevation">' +
                '<i class="' + row['_col_icon'] + '"></i>' +
                '</a>' +
                '</li>' +
                '</ul>' +
                '</div>';
        };
    },
    image: function (options, editor, field) {
        return function render(fileId, type, row, meta) {
            if (ColumnStatic.debug) {
                console.log(renderCallerName + Helpers._getCallerName(arguments), arguments);
            }
            if (fileId) {
                let filePath = "";
                // let imageSrc = Helpers._getObjByProp(field, "imageSrc");
                let imageSrc = field.imageSrc;
                let dataSrc = "";
                let filePathSrc = "";
                if (Helpers._isSameType(imageSrc, [])) {
                    if (imageSrc.length > 0 && Helpers._isStringNotEmpty(imageSrc[0])) {
                        dataSrc = imageSrc[0];
                    }
                    if (imageSrc.length > 1 && Helpers._isStringNotEmpty(imageSrc[1])) {
                        filePathSrc = imageSrc[1];
                    }
                }
                let file = editor.file(dataSrc, fileId);
                if (Helpers._isStringNotEmpty(filePathSrc) && file !== null && Helpers._isSameType(file, {})) {
                    filePath = Helpers._getObjByProp(file, filePathSrc, "");
                }
                if (ColumnStatic.debug) {
                    console.log({
                        file_id: fileId,
                        type: type,
                        row: row,
                        meta: meta,
                        editor: editor,
                        imageSrc: imageSrc,
                        file: file,
                        field: field
                    });
                }
                if (file) {
                    let host = Helpers._getHostName();
                    return fileId ?
                        '<img class="img-fluid" src="' + host + filePath + '" alt=""/>' :
                        null;
                }
            }
            return null;
        };
    },
    imageFinder: function () {
        return function render(data, type, row, meta) {
            if (ColumnStatic.debug) {
                console.log(renderCallerName + Helpers._getCallerName(arguments), arguments);
            }
            return '<div class="imgContainer"><img src="' + data + '" alt="" class="img-fluid"></div>';
        };
    },
    integer: function () {
        return function render(data, type, row, meta) {
            if (ColumnStatic.debug) {
                console.log(renderCallerName + Helpers._getCallerName(arguments), arguments);
            }
            data = Helpers._isStringNotEmpty(data) ? parseInt(data) : 0;
            // return parseInt(data);
            return data;
        };
    },
    password: function () {
        return function (data) {
            if (ColumnStatic.debug) {
                console.log(renderCallerName + Helpers._getCallerName(arguments), arguments);
            }
            return "*****";
        };
    },
    price: function () {
        return function render(data, type, row, meta) {
            if (ColumnStatic.debug) {
                console.log(renderCallerName + Helpers._getCallerName(arguments), arguments);
            }
            data = Helpers._isStringNotEmpty(data) ? parseInt(data) : 0;
            return Helpers._intToPrice(parseInt(data));
        };
    },
    select: function (options) {
        if (ColumnStatic.debug) {
            console.log(renderCallerName + Helpers._getCallerName(arguments), arguments);
        }
        return ColumnStatic._renderOption(options);
    },
    textarea: function () {
        return null;
    },
    timestep: function (options, editor) {
        return function render(data, type, row, meta) {
            if (ColumnStatic.debug) {
                console.log(renderCallerName + Helpers._getCallerName(arguments), arguments);
            }
            // @ts-ignore TODO
            let translation = editor.i18n;
            let returnVal = data;
            if (returnVal !== "" && returnVal.includes(_separator.array) && translation) {
                let val = returnVal.split(_separator.array);
                if (val.length === 2) {
                    let inputVal = val[0];
                    let selectVal = val[1];
                    switch (selectVal) {
                        case _timeHelper.type.hour:
                            selectVal = translation.datetime.hour;
                            break;
                        case _timeHelper.type.min:
                            selectVal = translation.datetime.minute;
                            break;
                        case _timeHelper.type.day:
                            selectVal = translation.datetime.day;
                            break;
                        default:
                            selectVal = '';
                            break;
                    }
                    if (Helpers._isStringNotEmpty(inputVal) && Helpers._isStringNotEmpty(selectVal)) {
                        returnVal = inputVal + " " + selectVal;
                    }
                }
            }
            return returnVal;
        };
    },
    uploadMany: function (options, editor, field) {
        return function render(fileId, counter) {
            if (ColumnStatic.debug) {
                console.log(renderCallerName + Helpers._getCallerName(arguments), arguments);
            }
            let filePath = "";
            // let imageSrc = Helpers._getObjByProp(field, "imageSrc");
            let imageSrc = field.imageSrc;
            let dataSrc = "";
            let filePathSrc = "";
            if (Helpers._isSameType(imageSrc, [])) {
                if (imageSrc.length > 0 && Helpers._isStringNotEmpty(imageSrc[0])) {
                    dataSrc = imageSrc[0];
                }
                if (imageSrc.length > 1 && Helpers._isStringNotEmpty(imageSrc[1])) {
                    filePathSrc = imageSrc[1];
                }
            }
            let file = editor.file(dataSrc, fileId);
            if (Helpers._isStringNotEmpty(filePathSrc)) {
                filePath = Helpers._isStringNotEmpty(file[filePathSrc]) ? file[filePathSrc] : "";
            }
            if (file) {
                return fileId ?
                    '<img class="img-fluid" src="' + filePath + '" alt=""/>' :
                    null;
            }
            else {
                return null;
            }
        };
    }
};
