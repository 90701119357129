var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import $ from "jquery";
import { formBuilderDefaultsOptions } from "./defaults.js";
import FormGroupQuestionStatic from "./form-group-question-builder/form-group-question-static.js";
import FormBuilderEditorStatic from "./form-builder-editor-static.js";
const appFormEditorItem = 'app_forms';
const appFormI18nEditorItem = 'app_form_i18ns';
export { appFormEditorItem, appFormI18nEditorItem };
export class FormBuilderStatic {
    static _mergeSetting(settings, formBuilder) {
        let mergedSettings = $.extend(true, {}, formBuilderDefaultsOptions, settings);
        return mergedSettings;
    }
    static _renderForm(formBuilder) {
        return __awaiter(this, void 0, void 0, function* () {
            const formData = formBuilder.data();
            // console.log(`FormBuilderStatic._renderForm`, {
            //     formBuilder
            // })
            const jquerySelector = formBuilder._jquerySelector;
            const $formBuilderCard = $(`
            <div class="card form-builder border"></div>
        `);
            $(jquerySelector).append($formBuilderCard);
            return FormBuilderStatic._renderFormHeader(formBuilder, formData, $formBuilderCard)
                .then(() => {
                // console.log(`FormBuilderStatic._renderForm.then()`, {
                //     formBuilder
                // })
                return FormBuilderStatic._renderFormBody(formBuilder, formData, $formBuilderCard);
            });
            //    TODO render the form
        });
    }
    static _renderFormHeader(formBuilder, formData, $formBuilderCard) {
        return __awaiter(this, void 0, void 0, function* () {
            const { formIdCode, translations, isEnabled, isActive, order, label, formGroupQuestions, formCategoryId } = formData;
            // return new Promise(async resolve => {
            // const queryString = window.location.search;
            // const urlParams = new URLSearchParams(queryString);
            // // console.log({formId : urlParams.get('formId'), search : window.location.search})
            // if(Helpers._isStringNotEmpty(formId)){
            //
            // }
            // resolve(()=>{
            // console.log(`FormBuilderStatic._renderFormHeader`, {
            //     formBuilder, formData
            // });
            const $header = $(`
                <div class="card-header py-5">
                    <div class="form-builder-header">
                        <div class="d-flex align-items-center">
                            <img src="/logo.png" style="max-height: 150px;" alt="">
                            <div style="margin-left: 20px">
        
                                <h3 class="main_question wizard-header">
                                    Form Label : ${FormBuilderEditorStatic._createEditorField(appFormEditorItem, formIdCode, 'label', formData.label)}
                                </h3>
                                <h4 style="color: #cd150e">Troubles cardio-vasculaires ?</h4>
                            </div>
                        </div>
                    </div>
                </div>
            `);
            $formBuilderCard.append($header);
            // })
            // })
        });
    }
    static _renderFormBody(formBuilder, formData, $formBuilderCard) {
        return __awaiter(this, void 0, void 0, function* () {
            const { formIdCode, translations, isEnabled, isActive, order, label, formGroupQuestions, formCategoryId } = formData;
            // return new Promise(async resolve => {
            // console.log(`FormBuilderStatic._renderFormBody`, {
            //     formBuilder, formData, formGroupQuestions: formData.formGroupQuestions
            // })
            const $cardBody = $(`
                <div class="card-body">
                    
                </div>
            `);
            $formBuilderCard.append($cardBody);
            yield FormBuilderStatic._renderFormContent(formBuilder, formData, $cardBody);
            if (formGroupQuestions) {
                const formParentAccordionId = `form-accordion-id-${formIdCode}`;
                const $formAccordionElement = FormBuilderEditorStatic._createAccordion('form-accordion', formParentAccordionId);
                // const $formAccordionElement = $(`
                //             <div class="accordion accordion-flush border" id="${formParentAccordionId}">
                //
                //             </div>
                //         `)
                $cardBody.append($formAccordionElement);
                for (const formGroupQuestion of formData.formGroupQuestions) {
                    yield FormGroupQuestionStatic._renderFormGroupQuestion(formBuilder, formGroupQuestion, $formAccordionElement, formParentAccordionId);
                    // console.log(`FormGroupQuestionStatic._renderFormGroupQuestion`, {
                    //     formBuilder, formGroupQuestion
                    // })
                }
            }
            // })
        });
    }
    static _renderFormContent(formBuilder, formData, $cardBody) {
        return __awaiter(this, void 0, void 0, function* () {
            // console.log(`FormBuilderStatic._renderFormContent`, {
            //     formBuilder, formData
            // })
            const $formContent = $(`<div class="form-content"></div>`);
            $cardBody.append($formContent);
            yield FormBuilderStatic._renderFormContentTranslations(formBuilder, formData, $formContent);
        });
    }
    static _renderFormContentTranslations(formBuilder, formData, $formContent) {
        return __awaiter(this, void 0, void 0, function* () {
            // console.log(`FormBuilderStatic._renderFormContentTranslations`, {
            //     formBuilder, formData
            // })
            if (formData.translations) {
                const formIdCode = formData.formIdCode;
                const $formContentTranslations = $(`<div class="form-content-translations"/>`);
                $formContent.append($formContentTranslations);
                const $navTabs = $(`<ul class="nav nav-tabs nav-fill" id="form-navs-${formIdCode}" role="tablist"/>`);
                $formContentTranslations.append($navTabs);
                const $tabContent = $(`<div class="tab-content" id="form-tab-content-${formIdCode}"/>`);
                $formContentTranslations.append($tabContent);
                for (const [language, formDataTranslation] of Object.entries(formData.translations)) {
                    const tabPaneId = `form-tab-pane-${formIdCode}-${language}`;
                    const $navList = $(`<li class="nav-item">
                        <button class="nav-link" data-bs-toggle="pill" data-bs-target="#${tabPaneId}" 
                            type="button" role="tab" aria-controls="pills-home" aria-selected="false">
                                ${language}
                        </button>
                    </li>`);
                    $navTabs.append($navList);
                    const $tabPane = $(`<div class="tab-pane p-5 border border-top-0" id="${tabPaneId}" />`);
                    $tabContent.append($tabPane);
                    yield FormBuilderStatic._renderFormContentTranslationElement(formBuilder, language, formDataTranslation, $tabPane);
                }
                $('.nav-link', $navTabs).first().addClass('active').attr({ 'aria-selected': 'true' });
                $('.tab-pane', $tabContent).first().addClass('show active');
            }
        });
    }
    static _renderFormContentTranslationElement(formBuilder, language, formDataTranslation, $tabPane) {
        return __awaiter(this, void 0, void 0, function* () {
            const { formName, formIdCode, formDescription, formMessage, formTitle, id } = formDataTranslation;
            const $formI18n = $(`<div class="app_form_i18ns row"/>`);
            $tabPane.append($formI18n);
            const $formName = $(`
            <div data-field="formName">Form Name : ${yield FormBuilderEditorStatic._createTranslationEditorField(appFormI18nEditorItem, id, language, `formName`, formName)}</div>
        `);
            $formI18n.append($formName);
            const $formTitle = $(`
            <div data-field="formTitle">Form title : ${yield FormBuilderEditorStatic._createTranslationEditorField(appFormI18nEditorItem, id, language, `formTitle`, formTitle)}</div>
        `);
            $formI18n.append($formTitle);
            // const $formDescription = $(``);
            // const $formTitle = $(``);
            // const $formMessage = $(``);
            // const $formIdCode = $(``);
            // console.log(`FormBuilderStatic._renderFormContentTranslationElement`, {
            //     formBuilder, language, formDataTranslation
            // })
        });
    }
}
