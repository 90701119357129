import $ from 'jquery';
import Helpers from "../../../dist/_utils/helpers.js";
export default class JqueryHelpers {
    static isValidSelector(selector) {
        if (typeof (selector) !== 'string') {
            return false;
        }
        try {
            const $element = $(selector);
        }
        catch (error) {
            return false;
        }
        return true;
    }
    ;
    static _disableElem($element, disable) {
        disable = Helpers._convertToBoolean(disable);
        if ($element.length) {
            $element.prop("disabled", disable.toString());
        }
    }
    ;
    static _requireElem($element, required) {
        required = Helpers._convertToBoolean(required);
        if ($element.length) {
            $element.attr("required", required.toString());
        }
    }
    ;
    static _createJqueryElement(tag, attr) {
        if (Helpers._isStringNotEmpty(tag)) {
            const $elem = $('<' + tag + '/>');
            if (Helpers._isSameType(attr, {}) && attr !== undefined) {
                $elem.attr(attr);
            }
            return $elem;
        }
        return null;
    }
    ;
}
JqueryHelpers.debug = false;
