import $ from 'jquery';
import { Tooltip } from "bootstrap";
// import  Tooltip from "bootstrap/js/src/tooltip.js";
import 'datatables.net';
import Helpers from "../../../../dist/_utils/helpers.js";
import BootstrapNotify from "../../app/notification/bootstrap-notify.js";
import { EnumDtEvent, EnumLanguage } from "../model/dt-enum.js";
import DtRowRender from "./row/dt-row-render.js";
import DtTableRender from "./dt-table-render.js";
import { DtTable } from "./dt-table.js";
const DtEvents = {
    'column-sizing.dt': (e, settings) => {
    },
    'column-visibility.dt': function (e, settings, column, state, recalculation) {
    },
    'destroy.dt': function (e, settings) {
    },
    'draw.dt': function (e, settings) {
    },
    'error.dt': function (e, settings, techNote, message) {
    },
    'init.dt': function (e, settings, json) {
    },
    'length.dt': function (e, settings, length) {
    },
    'order.dt': function (e, settings, ordArr) {
    },
    'page.dt': function (e, settings) {
    },
    'preDraw': function (e, settings) {
    },
    'preInit.dt': function (e, settings) {
    },
    'preXhr.dt': function (e, settings, data) {
    },
    'processing.dt': function (e, settings, processing) {
    },
    'search.dt': function (e, settings) {
    },
    'stateLoadParams.dt': function (e, settings, data) {
    },
    'stateSaveParams.dt': function (e, settings, data) {
    },
    'xhr.dt': function (e, settings, json, xhr) {
        if (json.status === false) {
            BootstrapNotify._notify(json.message);
        }
    },
    'responsive-display.dt': function (e, settings, row, showHide, update) {
    },
    'responsive-resize.dt': function (e, settings, columns) {
    }
};
const defaultOptions = {
    lengthMenu: [[25, 50, 100], [25, 50, 100]],
    fixedHeader: {
        headerOffset: 52
    },
    searching: true,
    select: true,
    responsive: true,
    autoWidth: true,
    columns: []
};
const DtTableStatic = {
    _tableTitleDef: "tableTitle",
    debug: false,
    /**

     * @type {object}
     */
    _defOptions: defaultOptions,
    /**
     *
     * @type {{def: number, inline: number, bubble: number}}
     * @private
     */
    _editType: {
        def: 0,
        inline: 1,
        bubble: 2
    },
    /**
     *
     * @public
     */
    _language: {
        def: EnumLanguage.fr
    },
    _clearHeadHtml: function (idSelector) {
        if (DtTableStatic.debug) {
            console.log("_Table._clearHeadHtml() called");
        }
        $("#" + idSelector + " thead").html("");
    },
    /**
     *
     * @private
     */
    /**
     *
     * @private
     */
    event: EnumDtEvent,
    _events: DtEvents,
    /**
     *
     * @param dataTable
     * @private
     */
    _initDataTableEvent: function (dataTable) {
        let arrayEvent = DtTableStatic._events;
        if (DtTableStatic.debug) {
            console.log("_Table._initDataTableEvent() called", arrayEvent);
        }
        $.each(DtTableStatic._events, function (event, fn) {
            if (Helpers._isStringNotEmpty(event) && Helpers._isFunction(fn))
                dataTable.on(event, fn);
        });
    },
    /**
     *
     * @param tableId
     * @param translation
     * @private
     */
    _editableCellTooltip: function (tableId, translation) {
        if (DtTableStatic.debug) {
            console.log(DtTable.name + " " + DtTableStatic._editableCellTooltip.name + "() called");
        }
        let $table = $('table#' + tableId + ' tbody');
        let tdEditable = 'tr td.editable';
        $table
            .on('mouseenter', tdEditable, function () {
            let title = translation._custom.tooltip.clickToEdit;
            if ($('div.DTE_Inline', this).length > 0) {
                title = translation._custom.tooltip.enterToSave;
            }
            const tooltip = new Tooltip(this, { title });
            tooltip.dispose();
            tooltip.show();
            // $(this)
            //     .tooltip("dispose")
            //     .tooltip({
            //         title: title
            //     })
            //     .tooltip("show")
            // ;
        })
            .on('click', tdEditable, function () {
            const tooltip = new Tooltip(this, { title: translation._custom.tooltip.enterToSave });
            tooltip.dispose();
            tooltip.show();
            // $(this).tooltip("dispose");
            // $(this).tooltip({
            //     title: translation._custom.tooltip.enterToSave
            // }).tooltip("show");
        })
            .on('mouseleave', tdEditable, function () {
            const tooltip = new Tooltip(this, { title: translation._custom.tooltip.enterToSave });
            tooltip.dispose();
            // $(this).tooltip("dispose");
        });
    },
    /**
     *
     * @param tableId
     * @param dataTable
     * @public
     */
    _collapseAllRow: DtRowRender._collapseAllRow,
    /**
     *
     */
    _printTableTitle: DtTableRender._printTableTitle
};
export default DtTableStatic;
export { DtEvents };
