const appActiveLanguageApiRoute = '/app-active-language/';
const appFormApiRoute = '/app-form/';
const appFormBuilderApiRoute = '/app-form-builder/';
const appFormCategoryApiRoute = '/app-form-category/';
const appFormGroupQuestionApiRoute = '/app-form-group-question/';
const appFormQuestionApiRoute = '/app-form-question/';
const appFormResponseApiRoute = '/app-form-response/';
const appFormResponseTypeApiRoute = '/app-form-response-type/';
const appFormResponseRelatedQuestionApiRoute = `${appFormResponseApiRoute}related-question/`;
const portalActiveLanguageApiRoute = '/portal-active-language/';
const portalDashBoardApiRoute = '/portal-dashboard/';
const portalClientApiRoute = '/portal-client/';
const portalCompanyApiRoute = '/portal-company/';
const portalUserApiRoute = '/portal-user/';
const portalCompanyAddressApiRoute = '/portal-company-address/';
const portalCompanyFtpConnectionApiRoute = '/portal-company-ftp-connection/';
export { appActiveLanguageApiRoute, appFormApiRoute, appFormBuilderApiRoute, appFormCategoryApiRoute, appFormGroupQuestionApiRoute, appFormQuestionApiRoute, appFormResponseApiRoute, appFormResponseRelatedQuestionApiRoute, appFormResponseTypeApiRoute, };
export { portalActiveLanguageApiRoute, portalDashBoardApiRoute, portalClientApiRoute, portalCompanyApiRoute, portalCompanyAddressApiRoute, portalUserApiRoute, portalCompanyFtpConnectionApiRoute, };
