import $ from 'jquery';
import { Toast } from "bootstrap";
// import  Toast from "bootstrap/js/src/toast.js";
import "./bootstrap-notify.css";
import Helpers from "../../../../dist/_utils/helpers.js";
var EnumNotificationType;
(function (EnumNotificationType) {
    EnumNotificationType["none"] = "";
    EnumNotificationType["info"] = "text-info";
    EnumNotificationType["success"] = "text-success";
    EnumNotificationType["warning"] = "text-warning";
    EnumNotificationType["danger"] = "text-danger";
    EnumNotificationType["light"] = "text-light";
    EnumNotificationType["dark"] = "text-dark";
})(EnumNotificationType || (EnumNotificationType = {}));
const toastOptions = {
    autoHide: true,
    delay: 30000
};
export default class BootstrapNotify {
    static _getContainer() {
        let $notificationContainer = $(`#${BootstrapNotify.containerId}`);
        if (!$notificationContainer.length) {
            $notificationContainer = $(`<div id="${BootstrapNotify.containerId}" class="position-fixed top-0 end-0 p-3"/>`).appendTo($('body'));
        }
        return $notificationContainer;
    }
    static _notify(messageBody, title = "", type = EnumNotificationType.info) {
        let $notificationContainer = BootstrapNotify._getContainer();
        let $toastMessage;
        if (Helpers._isStringNotEmpty(title)) {
            $toastMessage = $(`
            <div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header">
                  <strong class="me-auto ${type}">${title}</strong>
                  <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                <div class="toast-body text-primary">
                  ${messageBody}
                </div>
            </div>
        `);
        }
        else {
            $toastMessage = $(`
            <div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header">
                  <strong class="me-auto ${type}">${messageBody}</strong>
                  <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
            </div>
        `);
        }
        $toastMessage.appendTo($notificationContainer);
        const toast = new Toast($toastMessage[0], toastOptions);
        // @ts-ignore
        toast.show();
        setTimeout(() => {
            toast.dispose();
            $toastMessage.remove();
        }, toastOptions.delay);
    }
}
/**
 *
 * @type {string}
 */
BootstrapNotify.containerId = "my-patient-notification-container";
export { EnumNotificationType };
