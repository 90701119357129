import $ from 'jquery';
import 'datatables.net';
// import 'datatables.net-editor';
import Editor from "datatables.net-editor";
import Helpers from "../../../../dist/_utils/helpers.js";
import _Translation from "../../utils/_translation.js";
import { EnumColumnType } from "../model/dt-enum.js";
import DataTableStatic from "../table/data-table-static.js";
import DtTableStatic from "../table/dt-table-static.js";
import { DtEditorFn } from "./dt-editor-fn.js";
import { DtColumn } from "../table/column/dt-column.js";
import DtEditorField from "./field/dt-editor-field.js";
export class ExtendEditor extends Editor {
    // i18n : typeof Editor.defaults.i18n
    constructor(option) {
        super(option);
        this.imageSrc = option.imageSrc;
    }
}
export class DtEditor {
    constructor(tableIndex, idSrc, idSelector, language, ajaxUrl, imageSrc) {
        this.imageSrc = null;
        this.language = DtTableStatic._language.def;
        this.fields = [];
        this.tableIndex = tableIndex;
        this.table = idSelector;
        this.imageSrc = Helpers._isStringNotEmpty(imageSrc) ? imageSrc : "";
        this.ajaxUrl = ajaxUrl;
        this.idSrc = idSrc;
        if (Helpers._inObject(language, DataTableStatic._language)) {
            this.language = language;
        }
    }
    addField(field) {
        if (field instanceof DtEditorField) {
            let arrayFields = this.fields;
            let found = false;
            arrayFields.forEach(function (item) {
                if (item instanceof DtEditorField) {
                    if (item.name === field.name) {
                        found = true;
                    }
                }
            });
            if (!found) {
                arrayFields.push(field);
            }
            this.fields = arrayFields;
        }
        return this;
    }
    dataEditor(arrayColumn) {
        let editor = null;
        if (DtEditorFn.debug) {
            console.log("Editor.dataEditor() called");
        }
        let tableName = this.tableIndex;
        const that = this;
        arrayColumn.forEach(function (item) {
            if (item instanceof DtColumn) {
                if (item.data !== null) {
                    let field = new DtEditorField(item.data, item.title || "", item.editable, item.create, item.type, item.options, item.defaultValue, item.imageSrc);
                    that.addField(field);
                }
            }
        });
        let arrayFields = this.fields;
        let idSelector = this.table;
        let language = this.language;
        let imageSrc = this.imageSrc;
        let ajaxUrl = this.ajaxUrl;
        if (tableName !== "" &&
            arrayFields.length > 0 &&
            idSelector !== "" &&
            ajaxUrl !== "") {
            let options = {
                // ajax: ajaxUrl + tableName + "?" + "action=upload&_lang=" + language,
                ajax: {
                    url: ajaxUrl,
                    type: "POST",
                    data: function (d) {
                        d._t = tableName;
                        d._lang = language;
                    }
                },
                fields: arrayFields,
                imageSrc: imageSrc,
                table: "#" + idSelector,
            };
            let translation = _Translation._get(language);
            console.log(options);
            if (Helpers._isObject(translation)) {
                options.i18n = translation;
            }
            editor = new ExtendEditor(this.processOption(editor, options));
            // editor = new $.fn.dataTable.Editor(processOption(options));
            editor.imageSrc = imageSrc;
            /*function processOption(options ) {
                let fields = options.fields;
                $.each(fields, function (index, element) {
                    switch (element.type) {
                        case EnumColumnType.image:
                            if (DataTableStatic.debug) {
                                console.log("processOption", element);
                            }
                            let imageSrc = Helpers._getObjByProp(element, "imageSrc");
                            let dataSrc = "";
                            let filePathSrc = "";
                            if (Helpers._isSameType(imageSrc, [])) {
                                if (imageSrc.length > 0 && Helpers._isStringNotEmpty(imageSrc[0])) {
                                    dataSrc = imageSrc[0];
                                }
                                if (imageSrc.length > 1 && Helpers._isStringNotEmpty(imageSrc[1])) {
                                    filePathSrc = imageSrc[1];
                                }
                            }
                            if (translation !== null) {
                                element.clearText = translation.upload.clearText;
                                element.noImageText = translation.upload.noImageText;
                                element.dragDropText = translation.upload.dragDropText;
                                element.uploadText = translation.upload.uploadText;
                                element.noFileText = translation.upload.noFileText;
                                element.fileReadText = translation.upload.uploadText;
                            }
                            element.ajax = "/upload.php";
                            element.display = function (file_id : string) {
                                console.log(file_id);
                                let file = editor.file(dataSrc, file_id);
                                let filePath = Helpers._isStringNotEmpty(file[filePathSrc]) ? file[filePathSrc] : "";
                                if (Helpers._isStringNotEmpty(filePath)) {
                                    return file_id ?
                                        '<img class="img-fluid" alt="" src="' + Helpers._getHostName() + filePath + '"/>' :
                                        null;
                                }
                            };
                            break;
                        case EnumColumnType.uploadMany:
                            if (DataTableStatic.debug) {
                                console.log("processOption", element);
                            }
                            let src = Helpers._getObjByProp(element, "imageSrc");
                            let uploadDataSrc = "";
                            let uploadFilePathSrc = "";
                            if (Helpers._isSameType(src, [])) {
                                if (src.length > 0 && Helpers._isStringNotEmpty(src[0])) {
                                    uploadDataSrc = src[0];
                                }
                                if (src.length > 1 && Helpers._isStringNotEmpty(src[1])) {
                                    uploadFilePathSrc = src[1];
                                }
                            }
                            if (translation !== null) {
                                element.clearText = translation.upload.clearText;
                                element.noImageText = translation.upload.noImageText;
                                element.dragDropText = translation.upload.dragDropText;
                                element.uploadText = translation.upload.uploadText;
                                element.noFileText = translation.upload.noFileText;
                                element.fileReadText = translation.upload.uploadText;
                            }
                            element.display = function (file_id : string) {
                                let file = editor.file(uploadDataSrc, file_id);
                                let filePath = Helpers._isStringNotEmpty(file[uploadFilePathSrc]) ? file[uploadFilePathSrc] : "";
                                if (Helpers._isStringNotEmpty(filePath)) {
                                    return file_id ?
                                        '<img class="img-fluid" src="' + Helpers._getHostName() + filePath + '" alt=""/>' :
                                        null;
                                }
                            };
                            break;
                        case EnumColumnType.select :
                            element.placeholder = translation.selectPlaceHolder;
                            break;
                    }
                    if (EditorFn.debug) {
                        console.log(element);
                    }
                });
                return options;
            }*/
            DtEditorFn._enableField(editor, arrayFields);
            if (DtEditorFn.debug) {
                console.log({ editor: editor, thisEditor: this, arrayFields: arrayFields });
            }
            // let allEvent = EditorStatic._getAllEvent();
            // console.log(allEvent);
            // $.each(allEvent,function (index, event) {
            //     dataEditor.on(event, function () {
            //         console.log("");
            //         console.log("editor " + event);
            //     });
            // });
            return editor;
        }
        return editor;
    }
    ;
    processOption(editor, options) {
        let fields = options.fields;
        const translation = options.i18n;
        $.each(fields, function (index, element) {
            let imageSrc = element.imageSrc;
            switch (element.type) {
                case EnumColumnType.image:
                    if (DataTableStatic.debug) {
                        console.log("processOption", element);
                    }
                    // let imageSrc = Helpers._getObjByProp(element, "imageSrc");
                    // let imageSrc = element.imageSrc;
                    let dataSrc = "";
                    let filePathSrc = "";
                    if (Helpers._isSameType(imageSrc, []) && imageSrc) {
                        if (imageSrc.length > 0 && Helpers._isStringNotEmpty(imageSrc[0])) {
                            dataSrc = imageSrc[0];
                        }
                        if (imageSrc.length > 1 && Helpers._isStringNotEmpty(imageSrc[1])) {
                            filePathSrc = imageSrc[1];
                        }
                    }
                    if (translation) {
                        element.clearText = translation.upload.clearText;
                        element.noImageText = translation.upload.noImageText;
                        element.dragDropText = translation.upload.dragDropText;
                        element.uploadText = translation.upload.uploadText;
                        element.noFileText = translation.upload.noFileText;
                        element.fileReadText = translation.upload.uploadText;
                    }
                    element.ajax = "/upload.php";
                    element.display = function (file_id) {
                        console.log(file_id);
                        let file = editor.file(dataSrc, file_id);
                        let filePath = Helpers._isStringNotEmpty(file[filePathSrc]) ? file[filePathSrc] : "";
                        if (Helpers._isStringNotEmpty(filePath)) {
                            return file_id ?
                                '<img class="img-fluid" alt="" src="' + Helpers._getHostName() + filePath + '"/>' :
                                null;
                        }
                    };
                    break;
                case EnumColumnType.uploadMany:
                    if (DataTableStatic.debug) {
                        console.log("processOption", element);
                    }
                    let src = Helpers._getObjByProp(element, "imageSrc");
                    let uploadDataSrc = "";
                    let uploadFilePathSrc = "";
                    if (Helpers._isSameType(src, []) && imageSrc) {
                        if (imageSrc.length > 0 && Helpers._isStringNotEmpty(imageSrc[0])) {
                            uploadDataSrc = imageSrc[0];
                        }
                        if (imageSrc.length > 1 && Helpers._isStringNotEmpty(imageSrc[1])) {
                            uploadFilePathSrc = imageSrc[1];
                        }
                    }
                    if (translation) {
                        element.clearText = translation.upload.clearText;
                        element.noImageText = translation.upload.noImageText;
                        element.dragDropText = translation.upload.dragDropText;
                        element.uploadText = translation.upload.uploadText;
                        element.noFileText = translation.upload.noFileText;
                        element.fileReadText = translation.upload.uploadText;
                    }
                    element.display = function (file_id) {
                        let file = editor.file(uploadDataSrc, file_id);
                        let filePath = Helpers._isStringNotEmpty(file[uploadFilePathSrc]) ? file[uploadFilePathSrc] : "";
                        if (Helpers._isStringNotEmpty(filePath)) {
                            return file_id ?
                                '<img class="img-fluid" src="' + Helpers._getHostName() + filePath + '" alt=""/>' :
                                null;
                        }
                    };
                    break;
                case EnumColumnType.select:
                    if (translation) {
                        element.placeholder = translation.selectPlaceHolder;
                    }
                    break;
            }
            if (DtEditorFn.debug) {
                console.log(element);
            }
        });
        return options;
    }
    //TODO
    setElementTextForFileTransaction() {
    }
}
