/**
 * Form Question
 */
var EnumFormQuestionType;
(function (EnumFormQuestionType) {
    EnumFormQuestionType["text"] = "text";
    EnumFormQuestionType["multi"] = "multi";
    EnumFormQuestionType["email"] = "email";
    EnumFormQuestionType["number"] = "number";
    EnumFormQuestionType["select"] = "select";
    EnumFormQuestionType["checkbox"] = "checkbox";
    EnumFormQuestionType["radio"] = "radio";
    EnumFormQuestionType["textArea"] = "textArea";
})(EnumFormQuestionType || (EnumFormQuestionType = {}));
var EnumFormQuestionGenderTarget;
(function (EnumFormQuestionGenderTarget) {
    EnumFormQuestionGenderTarget["all"] = "all";
    EnumFormQuestionGenderTarget["male"] = "male";
    EnumFormQuestionGenderTarget["female"] = "female";
})(EnumFormQuestionGenderTarget || (EnumFormQuestionGenderTarget = {}));
/**
 * Form Response
 */
var EnumFormResponseType;
(function (EnumFormResponseType) {
    EnumFormResponseType["text"] = "text";
    EnumFormResponseType["number"] = "number";
    EnumFormResponseType["email"] = "email";
})(EnumFormResponseType || (EnumFormResponseType = {}));
export { EnumFormQuestionType, EnumFormQuestionGenderTarget, EnumFormResponseType, };
