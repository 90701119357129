(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("bootstrap"), require("io"), require("jQuery"));
	else if(typeof define === 'function' && define.amd)
		define(["bootstrap", "io", "jQuery"], factory);
	else if(typeof exports === 'object')
		exports["DataCard"] = factory(require("bootstrap"), require("io"), require("jQuery"));
	else
		root["DataCard"] = factory(root["bootstrap"], root["io"], root["jQuery"]);
})(self, (__WEBPACK_EXTERNAL_MODULE_bootstrap__, __WEBPACK_EXTERNAL_MODULE_socket_io_client__, __WEBPACK_EXTERNAL_MODULE_jquery__) => {
return 