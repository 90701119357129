var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import $ from "jquery";
import FormBuilderEditorStatic from "../form-builder-editor-static.js";
import FormQuestionBuilderStatic from "./form-question-builder/form-question-builder-static.js";
const appFormGroupQuestionEditorItem = 'app_form_group_questions';
const appFormGroupQuestionI18nEditorItem = 'app_form_group_question_i18ns';
export { appFormGroupQuestionEditorItem, appFormGroupQuestionI18nEditorItem };
export default class FormGroupQuestionStatic {
    static _renderFormGroupQuestion(formBuilder, formGroupQuestion, $accordionElement, formParentAccordionId) {
        return __awaiter(this, void 0, void 0, function* () {
            const { questions, translations, isEnabled, isActive, order, label, formGroupQuestionId } = formGroupQuestion;
            // const jquerySelector = formBuilder._jquerySelector;
            const formGroupQuestionAccordionBodyId = `form-group-question-accordion-collapse-id-${formGroupQuestionId}`;
            const $formGroupQuestionElement = $(`
            <div class="form-group-question accordion-item border">
                
            </div>
        `);
            $accordionElement.append($formGroupQuestionElement);
            console.log(`FormGroupQuestionStatic._renderFormGroupQuestion`, {
                formBuilder, formGroupQuestion, $accordionElement
            });
            yield FormGroupQuestionStatic._renderFormGroupQuestionHeader(formBuilder, formGroupQuestion, $formGroupQuestionElement, formGroupQuestionAccordionBodyId);
            yield FormGroupQuestionStatic._renderFormGroupQuestionBody(formBuilder, formGroupQuestion, $formGroupQuestionElement, formGroupQuestionAccordionBodyId, formParentAccordionId);
        });
    }
    static _renderFormGroupQuestionHeader(formBuilder, formGroupQuestion, $formGroupQuestionElement, formGroupQuestionAccordionBodyId) {
        return __awaiter(this, void 0, void 0, function* () {
            const { questions, translations, isEnabled, isActive, order, label, formGroupQuestionId } = formGroupQuestion;
            const questionId = formGroupQuestion.formGroupQuestionId;
            const $formGroupQuestionHeader = $(`
            <div class="form-group-question-header accordion-header d-flex align-items-center border ps-2">
                <h4 class="flex-fill">
                    Group Question Label : ${FormBuilderEditorStatic._createEditorField(appFormGroupQuestionEditorItem, questionId, 'label', label)}
                </h4>
                <button 
                    style="width: auto" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" 
                    data-bs-target="#${formGroupQuestionAccordionBodyId}" aria-expanded="false" aria-controls="${formGroupQuestionAccordionBodyId}">
                
               
                </button>
            </div>
        `);
            console.log(`FormGroupQuestionStatic._renderFormGroupQuestionHeader`, {
                formBuilder, formGroupQuestion, $formGroupQuestionElement
            });
            $formGroupQuestionElement.append($formGroupQuestionHeader);
        });
    }
    static _renderFormGroupQuestionBody(formBuilder, formGroupQuestion, $formGroupQuestionElement, formGroupQuestionAccordionBodyId, formParentAccordionId) {
        return __awaiter(this, void 0, void 0, function* () {
            const { questions, translations, isEnabled, isActive, order, label, formGroupQuestionId } = formGroupQuestion;
            const $formGroupQuestionAccordionCollapse = FormBuilderEditorStatic._createAccordionCollapse('form-group-question-body', formGroupQuestionAccordionBodyId, formParentAccordionId);
            // const $formGroupQuestionAccordionCollapse = $(`
            //     <div class="form-group-question-body accordion-collapse collapse"  id="${formGroupQuestionAccordionBodyId}" data-bs-parent="#${formParentAccordionId}">
            //         <div class="accordion-body">
            //         </div>
            //     </div>
            // `);
            $formGroupQuestionElement.append($formGroupQuestionAccordionCollapse);
            const $formGroupQuestionBody = $('.accordion-body', $formGroupQuestionAccordionCollapse);
            yield FormGroupQuestionStatic._renderFormGroupQuestionContent(formBuilder, formGroupQuestion, $formGroupQuestionBody);
            console.log(`FormGroupQuestionStatic._renderFormGroupQuestionBody`, {
                formBuilder, formGroupQuestion, $formGroupQuestionElement
            });
            if (questions) {
                const formGroupQuestionParentAccordionId = `form-group-question-accordion-id-${formGroupQuestionId}`;
                const $formQuestionElement = $(`<div class="question"/>`);
                $formGroupQuestionBody.append($formQuestionElement);
                const $questionAccordion = FormBuilderEditorStatic._createAccordion('questions-accordion', formGroupQuestionParentAccordionId);
                //     const $questionAccordion = $(`
                //         <div class="accordion accordion-flush border" id="${formGroupQuestionParentAccordionId}">
                //
                //         </div>
                // `);
                $formQuestionElement.append($questionAccordion);
                for (const question of questions) {
                    yield FormQuestionBuilderStatic._renderQuestion(formBuilder, question, $questionAccordion, formGroupQuestionParentAccordionId);
                }
            }
        });
    }
    static _renderFormGroupQuestionContent(formBuilder, formGroupQuestion, $formGroupQuestionBody) {
        return __awaiter(this, void 0, void 0, function* () {
            const { questions, translations, isEnabled, isActive, order, label, formGroupQuestionId } = formGroupQuestion;
            console.log(`FormBuilderStatic._renderFormContent`, {
                formBuilder, formGroupQuestion
            });
            const $formGroupQuestionContent = $(`<div class="form-group-question-content"></div>`);
            $formGroupQuestionBody.append($formGroupQuestionContent);
            yield FormGroupQuestionStatic._renderFormGroupQuestionContentTranslations(formBuilder, formGroupQuestion, $formGroupQuestionContent);
        });
    }
    static _renderFormGroupQuestionContentTranslations(formBuilder, formGroupQuestion, $formGroupQuestionContent) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log(`FormBuilderStatic._renderFormContentTranslations`, {
                formBuilder, formGroupQuestion
            });
            if (formGroupQuestion.translations) {
                const { questions, translations, isEnabled, isActive, order, label, formGroupQuestionId } = formGroupQuestion;
                // const formIdCode = formGroupQuestion.formIdCode;
                const $formContentTranslations = $(`<div class="form-group-question-content-translations"/>`);
                $formGroupQuestionContent.append($formContentTranslations);
                const $navTabs = $(`<ul class="nav nav-tabs nav-fill" id="form-group-question-navs-${formGroupQuestionId}" role="tablist"/>`);
                $formContentTranslations.append($navTabs);
                const $tabContent = $(`<div class="tab-content" id="form-group-question-tab-content-${formGroupQuestionId}"/>`);
                $formContentTranslations.append($tabContent);
                for (const [language, formGroupQuestionDataTranslation] of Object.entries(formGroupQuestion.translations)) {
                    const tabPaneId = `form-group-question-tab-pane-${formGroupQuestionId}-${language}`;
                    const $navList = $(`<li class="nav-item">
                        <button class="nav-link" data-bs-toggle="pill" data-bs-target="#${tabPaneId}" 
                            type="button" role="tab" aria-controls="pills-home" aria-selected="false">
                                ${language}
                        </button>
                    </li>`);
                    $navTabs.append($navList);
                    const $tabPane = $(`<div class="tab-pane p-5 border border-top-0" id="${tabPaneId}" />`);
                    $tabContent.append($tabPane);
                    yield FormGroupQuestionStatic._renderFormGroupQuestionContentTranslationElement(formBuilder, language, formGroupQuestionDataTranslation, $tabPane);
                }
                $('.nav-link', $navTabs).first().addClass('active').attr({ 'aria-selected': 'true' });
                $('.tab-pane', $tabContent).first().addClass('show active');
            }
        });
    }
    static _renderFormGroupQuestionContentTranslationElement(formBuilder, language, formGroupQuestionDataTranslation, $tabPane) {
        return __awaiter(this, void 0, void 0, function* () {
            const { formGroupQuestionId, groupQuestionDescription, groupQuestionMessage, groupQuestionName, groupQuestionTitle, id } = formGroupQuestionDataTranslation;
            const $formI18n = $(`<div class="app_form_group_question_i18ns row"/>`);
            $tabPane.append($formI18n);
            const $formName = $(`
            <div data-field="groupQuestionName">Group Question Name : ${yield FormBuilderEditorStatic._createTranslationEditorField(appFormGroupQuestionI18nEditorItem, id, language, `groupQuestionName`, groupQuestionName)}</div>
        `);
            $formI18n.append($formName);
            const $formTitle = $(`
            <div data-field="groupQuestionTitle">Group Question Title : ${yield FormBuilderEditorStatic._createTranslationEditorField(appFormGroupQuestionI18nEditorItem, id, language, `groupQuestionTitle`, groupQuestionTitle)}</div>
        `);
            $formI18n.append($formTitle);
            // const $formDescription = $(``);
            // const $formTitle = $(``);
            // const $formMessage = $(``);
            // const $formIdCode = $(``);
            console.log(`FormBuilderStatic._renderFormContentTranslationElement`, {
                formBuilder, language, formGroupQuestionDataTranslation
            });
        });
    }
}
