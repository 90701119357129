import $ from "jquery";
import Helpers from "../../../../../dist/_utils/helpers.js";
import DataTableStatic from "../data-table-static.js";
import ColumnStatic from "./column-static.js";
import { DtColumn } from "./dt-column.js";
export default class DtColumnFn {
    static _getColumns(tableId) {
        let arrayColumn = [];
        $("#" + tableId + " thead tr:first th").each(function (index, element) {
            let $element = $(element);
            let columnIndex = DataTableStatic._getAttribute($element, ColumnStatic._const.attrColumnIndex);
            if (columnIndex !== "") {
                let column;
                if (columnIndex === "NULL") {
                    column = ColumnStatic._getSpecialColumn(DataTableStatic._getAttribute($element, ColumnStatic._const.attrClassName).trim());
                    column
                        .setCreate(false)
                        .setEditable(false)
                        .setOrderable(false)
                        .setSearchable(false)
                        .setVisible(DataTableStatic._getBooleanAttribute($element, ColumnStatic._const.attrVisible));
                }
                else {
                    let dataOptions = DataTableStatic._getAttribute($element, ColumnStatic._const.attrOptions);
                    let options = null;
                    if (dataOptions !== "") {
                        options = JSON.parse(dataOptions);
                    }
                    let dataImageSrc = DataTableStatic._getAttribute($element, ColumnStatic._const.attrImageSrc), imageSrc = [];
                    if (Helpers._isStringNotEmpty(dataImageSrc)) {
                        imageSrc = JSON.parse(dataImageSrc);
                    }
                    column = new DtColumn(columnIndex, DataTableStatic._getAttribute($element, ColumnStatic._const.attrTitle));
                    column
                        .addClass(DataTableStatic._getAttribute($element, ColumnStatic._const.attrClassName))
                        .setClassName(DataTableStatic._getAttribute($element, ColumnStatic._const.attrClassName))
                        .setEditable(DataTableStatic._getBooleanAttribute($element, ColumnStatic._const.attrEditable))
                        .setCreate(DataTableStatic._getBooleanAttribute($element, ColumnStatic._const.attrCreate))
                        .setOrderable(DataTableStatic._getBooleanAttribute($element, ColumnStatic._const.attrOrderable))
                        .setOrderSequence(DataTableStatic._getAttribute($element, ColumnStatic._const.attrOrderSequence))
                        .setSearchable(DataTableStatic._getBooleanAttribute($element, ColumnStatic._const.attrSearchable))
                        .setImageSrc(imageSrc)
                        .setVisible(DataTableStatic._getBooleanAttribute($element, ColumnStatic._const.attrVisible))
                        .setType(DataTableStatic._getAttribute($element, ColumnStatic._const.attrType), options, DataTableStatic._getAttribute($element, ColumnStatic._const.attrDefaultValue));
                    let width = DataTableStatic._getAttribute($element, ColumnStatic._const.attrWidth);
                    if (Helpers._isStringNotEmpty(width)) {
                        column.setWidth(DataTableStatic._getAttribute($element, ColumnStatic._const.attrWidth));
                    }
                }
                if (column instanceof DtColumn) {
                    arrayColumn.push(column);
                }
            }
        });
        if (DataTableStatic.debug) {
            console.log(arrayColumn);
        }
        return arrayColumn;
    }
}
