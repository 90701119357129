import $ from "jquery";
import Helpers from "../../../../../dist/_utils/helpers.js";
import __PROP from "../../../utils/_prop.js";
import DcEditorFn from "../../editor/dc-editor-fn.js";
import DcFieldFn from "../../editor/field/dc-field-fn.js";
import { cardModel, controlModel, dcModel, fieldModel } from "../../model/dc-model.js";
import DcPaginationFn from "../pagination/dc-pagination-fn.js";
import DcCardFn from "./dc-card-fn.js";
import '../../styles/scss/datacard/card/cards.css';
export default class DcCardRender {
    static _createCardTemplate(dataCard) {
        let settings = dataCard.settings();
        let $cardElement = $("<div/>")
            .attr(cardModel.item.attr);
        let $cardContainer = $("<div/>").attr(cardModel.container.attr)
            .addClass('card shadow m-b-10 m-t-10');
        let cardButtons = settings.cardButtons;
        if (cardButtons.length > 0) {
            let $btnContainer = $('<div/>').attr(cardModel.btnContainer.attr).addClass("card-header");
            $.each(cardButtons, function (index, element) {
                if (typeof element === 'object') {
                    let cardBtnRender = element.render;
                    let render = null;
                    if (Helpers._isFunction(cardBtnRender)) {
                        render = cardBtnRender();
                    }
                    let $btn = $('<span/>')
                        .attr(controlModel.btn.item.attr)
                        .append($(element.icon));
                    if (Helpers._isNotUndefined(render) && render !== null) {
                        $btn = render;
                    }
                    $btn
                        .attr(__PROP.title, element.title)
                        .addClass(element.className)
                        .addClass(dcModel.btnXsClass);
                    $btnContainer.append($btn);
                }
            });
            $cardContainer.prepend($btnContainer);
        }
        let $cardBody = $('<div class="card-body"/>');
        let arrayFields = dataCard.fields();
        let cardTitle = settings.cardTitle;
        if (Helpers._isStringNotEmpty(cardTitle)) {
            $("<div/>").attr({
                class: "dc-card-title"
            }).text(cardTitle).prependTo($cardContainer);
        }
        let $cardBodyData = $('<div class="card-body-data"/>');
        let $fieldContainer = DcCardRender._createFieldTemplate(arrayFields), $multiFieldContainer = DcCardRender._createMultiFieldTemplate(dataCard);
        $cardBodyData.append($fieldContainer).append($multiFieldContainer);
        let cardTemplateSelector = settings.cardTemplate;
        let $cardBodyInner = $("<div class='card-body-template' />");
        if (typeof cardTemplateSelector === "string" && Helpers._isStringNotEmpty(cardTemplateSelector)) {
            // console.log('card template selector === string')
            $cardBodyInner.append($(cardTemplateSelector));
        }
        else if (typeof cardTemplateSelector === 'function') {
            // console.log('card template selector === function')
            $cardBodyInner.append(cardTemplateSelector(dataCard));
        }
        else if (cardTemplateSelector instanceof jQuery) {
            // console.log('card template instanceof jQuery');
            $cardBodyInner.append(cardTemplateSelector.removeAttr('id'));
        }
        if (cardTemplateSelector) {
            $cardBody.append($cardBodyInner);
            $cardBodyData.hide();
        }
        $cardBody.append($cardBodyData);
        $cardContainer.append($cardBody).appendTo($cardElement);
        if (DcCardRender.debug) {
            console.log({
                arrayFields: arrayFields,
                $cardTemplate: $cardElement,
                $cardBodyTemplate: $cardBodyInner,
            });
        }
        return $cardElement;
    }
    //TODO Verify
    static _createCardBody(dataCard) {
    }
    static _createFieldTemplate(arrayFields) {
        if (Helpers._isSameType(arrayFields, []) && arrayFields.length > 0) {
            let $fieldContainer = $('<div/>').attr(fieldModel.fieldContainer.attr);
            $.each(arrayFields, function (index, field) {
                let fieldLabel = field.labelText, fieldData = field.name, fieldClass = field.className, fieldUniqueId = field._uniqueId, $fieldElement = $('<div/>').attr(fieldModel.item.attr).attr(dcModel.dataEditorAttr, fieldUniqueId);
                if (!field.visible) {
                    $fieldElement.addClass(dcModel.hideClass);
                }
                let $fieldLabel = $('<div/>').attr(fieldModel.item.label.attr).text(Helpers._ucFirst(fieldLabel));
                let $fieldDisplay = $('<div/>').attr(fieldModel.item.display.attr).html("&nbsp;").addClass(fieldClass);
                let $fieldValue = $('<div/>').attr(fieldModel.item.value.attr).attr(dcModel.dataEditorFieldAttr, fieldData);
                $fieldElement
                    .append($fieldLabel)
                    .append($fieldDisplay)
                    .append($fieldValue)
                    .appendTo($fieldContainer);
            });
            return $fieldContainer;
        }
        return null;
    }
    /**
     *
     * @param {DataCard} dataCard
     * @returns {JQuery<HTMLElement> | null}
     */
    static _createMultiFieldTemplate(dataCard) {
        let settings = dataCard.settings(), multiField = settings.multiFields, multiFieldDataSrc = multiField.dataSrc, itemSrc = multiField.itemSrc, arrayFieldsMulti = dataCard.multiFields();
        if (Helpers._isSameType(arrayFieldsMulti, []) && arrayFieldsMulti.length > 0 &&
            Helpers._isSameType(itemSrc, []) && itemSrc.length > 0 &&
            Helpers._isStringNotEmpty(multiFieldDataSrc)) {
            let $multiFieldContainer = $('<div/>').attr(fieldModel.multiFieldContainer.attr);
            $.each(itemSrc, function (index, src) {
                let itemData = src.data, title = src.title;
                if (Helpers._isStringNotEmpty(itemData)) {
                    let $panelMulti = $('<fieldset/>').attr(fieldModel.item.fieldSet.attr);
                    let $titleLegend = $('<legend/>').attr(fieldModel.item.legend.attr).text(Helpers._ucFirst(title));
                    let visibleMultiFields = 0;
                    $panelMulti.append($titleLegend).appendTo($multiFieldContainer);
                    $.each(arrayFieldsMulti, function (index, fieldMulti) {
                        let fieldLabel = fieldMulti.labelText, fieldClass = fieldMulti.className, fieldData = fieldMulti.name, $fieldMultiElement = $('<div/>').attr(fieldModel.item.multi.attr);
                        if (Helpers._isStringNotEmpty(fieldData)) {
                            let itemDataSrc = "", fieldUniqueId = fieldMulti._uniqueId;
                            if (Helpers._isStringNotEmpty(itemData)) {
                                itemDataSrc = itemData + ".";
                            }
                            let dataEditorField = multiFieldDataSrc + '.' + itemDataSrc + fieldData;
                            $fieldMultiElement.attr(dcModel.dataEditorAttr, itemDataSrc + fieldUniqueId);
                            let $fieldLabel = $('<div/>').attr(fieldModel.item.label.attr).text(Helpers._ucFirst(fieldLabel));
                            let $fieldDisplay = $('<div/>').attr(fieldModel.item.display.attr).html("&nbsp;").addClass(fieldClass);
                            let $fieldValue = $('<div/>').attr(fieldModel.item.value.attr).attr(dcModel.dataEditorFieldAttr, dataEditorField);
                            $fieldMultiElement
                                .append($fieldLabel)
                                .append($fieldDisplay)
                                .append($fieldValue);
                            $panelMulti.append($fieldMultiElement);
                            if (!fieldMulti.visible) {
                                $fieldMultiElement.addClass(dcModel.hideClass);
                            }
                            else {
                                visibleMultiFields++;
                            }
                        }
                    });
                    if (visibleMultiFields < 1 || !src.visible) {
                        $panelMulti.addClass(dcModel.hideClass);
                    }
                }
            });
            return $multiFieldContainer;
        }
        return null;
    }
    /**
     * process the response from the ajax data
     * @param {DataCard} dataCard
     * @param {TypeRowDataWithId[]} arrayData
     */
    static _displayCards(dataCard, arrayData) {
        $('.' + cardModel.container.attr.class).tooltip("hide");
        let settings = dataCard.settings(), arrayFields = dataCard.fields(), multiField = settings.multiFields, multiFieldDataSrc = multiField.dataSrc, arrayFieldsMulti = dataCard.multiFields(), itemSrc = multiField.itemSrc, $rowContainer = $('.' + cardModel.rowCardContainer.attr.class, settings.jquerySelector), $template = dataCard.template();
        $rowContainer.addClass(DcCardFn._getBsRowColsClass(settings.columnDisplay));
        // let _cardRender = settings._cardRender;
        // const isValidCardRender = typeof _cardRender === 'string' || typeof _cardRender === 'function';
        //
        // console.log({_cardRender, isValidCardRender});
        if ($template !== null) {
            $rowContainer.empty();
            if ($rowContainer.length) {
                // if(isValidCardRender && Helpers._isStringNotEmpty(_cardRender)){
                //     $rowContainer.append($(_cardRender as string));
                // }
                // else{
                // }
                if (arrayData.length) {
                    $.each(arrayData, function (index, dataRow) {
                        $rowContainer.append(DcCardRender._updateCardData(settings, dataRow, $template, arrayFields, multiFieldDataSrc, arrayFieldsMulti, itemSrc, dataCard));
                    });
                }
                else {
                    $rowContainer.append($(`
                        <div class='col col-12 col-md-12 col-lg-12 col-xl-12 mb-5'>
                            <div class="card">
                                <div class="card-body text-center">This no data</div>
                            </div>
                        </div>
                    `));
                }
            }
        }
        DcPaginationFn._updateSelectedItemInfo(dataCard);
        let page = dataCard.page(), dataLength = dataCard.data().length, length = dataCard.dataLength();
        if (DcCardRender.debug) {
            console.log({
                page: page,
                dataLength: dataLength,
                length: length
            });
        }
        DcEditorFn._sortable(dataCard, '.' + cardModel.rowCardContainer.attr.class);
    }
    /**
     *
     * @param {TypeDataCardOptions} settings
     * @param {TypeRowDataWithId} dataRow
     * @param {JQuery} $template
     * @param {TypeFieldSettings[]} arrayFields
     * @param {string} multiFieldDataSrc
     * @param {TypeFieldSettings[]} arrayFieldsMulti
     * @param {TypeItemSrc[]} itemSrc
     * @param {DataCard} dataCard
     * @returns {JQuery<HTMLElement>}
     */
    static _updateCardData(settings, dataRow, $template, arrayFields, multiFieldDataSrc, arrayFieldsMulti, itemSrc, dataCard) {
        let $newTemplate = $template.clone(), id = Object.keys(dataRow)[0], titleSrc = settings.titleSrc, title = "";
        const tableDataRow = dataRow[id];
        if (Helpers._isStringNotEmpty(titleSrc)) {
            title = Helpers._getObjByArrayProp(tableDataRow, titleSrc.split('.'), "");
        }
        $newTemplate.attr(dcModel.dataEditorIdAttr, id).attr(dcModel.dataTitleSrcAttr, title);
        $('.' + controlModel.btn.edit.attr.class, $newTemplate).attr(dcModel.dataIdAttr, id);
        $('.' + controlModel.btn.remove.attr.class, $newTemplate).attr(dcModel.dataIdAttr, id);
        $newTemplate = DcFieldFn._updateFieldData(settings, tableDataRow, $newTemplate, arrayFields, dataCard);
        $newTemplate = DcFieldFn._updateFieldMultiData(settings, tableDataRow, $newTemplate, multiFieldDataSrc, arrayFieldsMulti, itemSrc, dataCard);
        return $newTemplate;
    }
    /**
     *
     * @param {DataCard} dataCard
     */
    static _createRowContainer(dataCard) {
        let settings = dataCard.settings();
        let $rowContainer = $('<div/>').attr(cardModel.rowCardContainer.attr).addClass("row");
        $rowContainer.appendTo(settings.jquerySelector);
    }
}
DcCardRender.debug = false;
