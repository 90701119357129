import $ from 'jquery';
import Helpers from "../../../../../dist/_utils/helpers.js";
import DcCardFn from "../card/dc-card-fn.js";
import { dcModel, controlModel, cardModel } from "../../model/dc-model.js";
export default class DcPaginationFn {
    /**
     *
     * @param {DataCard} dataCard
     * @param {TypeSegmentedData} processedData
     * @param {number} currentPage
     */
    static _drawPageList(dataCard, processedData, currentPage) {
        let pageQty = processedData.length;
        let $paginationContainer = $('#' + cardModel.pagination.attr.id);
        $paginationContainer.empty();
        let $pagination = DcPaginationFn._getPageBtnContainer();
        if (Helpers._isInteger(pageQty) && pageQty > 0) {
            let i18n = dataCard.i18n();
            if (pageQty > 1) {
                let $btnPrevious = DcPaginationFn._getPageBtn(i18n.oPaginate.sPrevious);
                $btnPrevious.attr({
                    id: "previous"
                });
                if (currentPage === 1) {
                    $btnPrevious.addClass(dcModel.disabledClass);
                }
                $pagination.append($btnPrevious);
            }
            for (let i = 1; i <= pageQty; i++) {
                let selectedPage = currentPage === i;
                let $btn = DcPaginationFn._getPageBtn(i, selectedPage)
                    .attr(dcModel.dataPageIndexAttr, i);
                $pagination.append($btn);
            }
            if (pageQty > 1) {
                let $btnNext = DcPaginationFn._getPageBtn(i18n.oPaginate.sNext);
                $btnNext.attr({
                    id: "next"
                });
                if (pageQty <= currentPage) {
                    $btnNext.addClass(dcModel.disabledClass);
                }
                $pagination.append($btnNext);
            }
            if (DcPaginationFn.debug) {
                console.log($pagination);
            }
        }
        $paginationContainer.append($pagination);
    }
    /**
     *
     * @param {DataCard} dataCard
     * @param {number} start
     * @param {number} end
     * @param {TypeRowDataWithId[]} displayData
     * @param {TypeRowDataWithId[]} data
     */
    static _updateInfo(dataCard, start, end, displayData, data) {
        let i18n = dataCard.i18n();
        let infoText = i18n.sInfo;
        let displayDataLength = displayData.length;
        let dataLength = data.length;
        infoText = infoText
            .replace("_START_", start.toString())
            .replace("_END_", end.toString())
            .replace("_TOTAL_", displayDataLength.toString());
        if (displayDataLength < dataLength) {
            let filterText = i18n.sInfoFiltered;
            filterText = filterText.replace('_MAX_', dataLength.toString());
            infoText = infoText + ' ' + filterText;
        }
        let $pageInfo = $(".info-item", '#' + cardModel.pageInfo.attr.id);
        $pageInfo.text(infoText);
    }
    /**
     *
     * @param {DataCard} dataCard
     */
    static _updateSelectedItemInfo(dataCard) {
        let i18n = dataCard.i18n();
        let settings = dataCard.settings();
        let $selectedItemInfo = $(".selected-item", '#' + cardModel.pageInfo.attr.id);
        let $selectedCard = DcCardFn._getSelectedCards(settings.jquerySelector);
        let text = i18n.select.rows["_"];
        text = text.replace("%d", $selectedCard.length.toString());
        $selectedItemInfo.text(text);
    }
    /**
     *
     * @return {JQuery<HTMLElement>}
     */
    static _getPageBtnContainer() {
        return $("<ul/>").attr({ "class": "pagination" });
    }
    /**
     *
     * @param {string | number} btnText
     * @param {boolean} selected
     * @return {JQuery|null}
     */
    static _getPageBtn(btnText, selected) {
        let $btnContainer = null;
        if (Helpers._isStringNotEmpty(btnText) || Helpers._isInteger(btnText)) {
            let $btnIndex = $('<a/>')
                .attr(controlModel.pagination.aBtn.attr)
                .text(btnText);
            $btnContainer = $('<li/>')
                .attr(controlModel.pagination.liBtn.attr)
                .append($btnIndex);
            if (Helpers._isSameType(selected, true) && selected) {
                $btnContainer.addClass(dcModel.activeClass);
            }
        }
        return $btnContainer;
    }
}
DcPaginationFn.debug = false;
