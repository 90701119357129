import { EnumEditingMode } from "./types.js";
import Helpers from "../../../../dist/_utils/helpers.js";
const debug = false;
const formBuilderDefaultsOptions = {
    language: Helpers._getLang() || "fr",
    editingMode: EnumEditingMode.bubble,
    /**
     *
     */
    ajax: "/api/form-builder",
    /**
     *
     */
    // _ids: [],
    /**
     *
     */
    // ordering: [],
    /**
     *
     */
    // fieldGroup: "",
    /**
     *
     */
    // dataSrc: "",
    /**
     *
     */
    // idSrc: "",
    /**
     *
     */
    // editorModalClass: "modal-lg",
    /**
     *
     */
    // cardTitle: "",
    /**
     *
     */
    // titleSrc: "",
    /**
     *
     */
    // title: "",
    /**
     *
     */
    // editorTemplate: "#" + dcEditorModel.template.attr.id,
    /**
     *
     */
    // columnDisplay: 4,
    /**
     *
     */
    // pageLength: 30,
    /**
     *
     */
    // lengthMenu: [30, 60, 120, -1],
    //
    // /**
    //  *
    //  */
    // fields: [],
    //
    // /**
    //  *
    //  */
    // multiFields: {
    //
    //     /**
    //      *
    //      */
    //     dataSrc: "",
    //
    //     /**
    //      *
    //      */
    //     idSrc: "",
    //
    //     /**
    //      *
    //      */
    //     titleSrc: "",
    //
    //     /**
    //      *
    //      */
    //     itemSrc: [],
    //
    //     /**
    //      *
    //      */
    //     fields: []
    // },
    //
    // /**
    //  *
    //  */
    // controlPanel: '.' + controlModel.controlPanelContainer.attr.class,
    //
    // /**
    //  *
    //  */
    // buttons: [],
    //
    // /**
    //  *
    //  */
    // cardButtons: [],
    //
    // /**
    //  *
    //  */
    // formButtons: [],
    //
    // /**
    //  *
    //  */
    // caseInsensitive: true,
    //
    // /**
    //  *
    //  */
    // filterHiddenGroup: true,
    //
    // /**
    //  *
    //  */
    // language: Helpers._getLang() || "fr",
    //
    // /**
    //  *
    //  */
    // rowReorder: "",
    //
    // /**
    //  *
    //  */
    // search: true,
    //
    // /**
    //  *
    //  */
    // sort: true,
    //
    // /**
    //  *
    //  */
    // select: true,
    //
    // /**
    //  *
    //  */
    // edit: true,
    //
    // /**
    //  *
    //  */
    // create: true,
    //
    // /**
    //  *
    //  */
    // remove: true,
    //
    // /**
    //  *
    //  */
    // fixedControl: {
    //
    //     /**
    //      *
    //      */
    //     offSet: 52
    // },
    /**
     *
     */
    formEditor: null,
    formGroupEditor: null,
    formQuestionEditor: null,
    event: {
        /**
         *
         */
        init: function (formBuilder) {
            if (debug) {
                console.log("FormBuilder init() called", {
                    formBuilder, settings: formBuilder.settings()
                });
            }
        },
        /**
         *
         */
        onStart: function (formBuilder) {
            if (debug) {
                console.log("FormBuilder onStart() called", {
                    formBuilder, settings: formBuilder.settings()
                });
            }
        },
        /**
         *
         */
        started: function (formBuilder) {
            if (debug) {
                console.log("DataCard started(settings) called", {
                    formBuilder, settings: formBuilder.settings()
                });
            }
        },
        /**
         *
         */
        initLoad: function (formBuilder) {
            if (debug) {
                console.log("FormBuilder initLoad() called", {
                    formBuilder, settings: formBuilder.settings()
                });
            }
        },
        /**
         *
         */
        preLoad: function (formBuilder) {
            if (debug) {
                console.log("FormBuilder preLoad() called", {
                    formBuilder, settings: formBuilder.settings()
                });
            }
        },
        /**
         *
         */
        onLoad: function (formBuilder) {
            if (debug) {
                console.log("FormBuilder onLoad() called", {
                    formBuilder, settings: formBuilder.settings()
                });
            }
        },
        /**
         *
         */
        postLoad: function (formBuilder) {
            if (debug) {
                console.log("FormBuilder postLoad() called", {
                    formBuilder, settings: formBuilder.settings()
                });
            }
        },
        /**
         *
         */
        initDraw: function (formBuilder) {
            if (debug) {
                console.log("FormBuilder initDraw() called", {
                    formBuilder, settings: formBuilder.settings()
                });
            }
        },
        /**
         *
         */
        preDraw: function (formBuilder) {
            if (debug) {
                console.log("FormBuilder preDraw() called", {
                    formBuilder, settings: formBuilder.settings()
                });
            }
        },
        /**
         *
         */
        onDraw: function (formBuilder) {
            if (debug) {
                console.log("FormBuilder onDraw() called", {
                    formBuilder, settings: formBuilder.settings()
                });
            }
        },
        /**
         *
         */
        postDraw: function (formBuilder) {
            if (debug) {
                console.log("FormBuilder postDraw() called", {
                    formBuilder, settings: formBuilder.settings()
                });
            }
        },
        /**
         *
         */
        postSubmit: function (formBuilder) {
            if (debug) {
                console.log("FormBuilder postDraw() called", {
                    formBuilder, settings: formBuilder.settings()
                });
            }
        }
    },
};
export { formBuilderDefaultsOptions };
