import $ from 'jquery';
import Helpers from "../../../../../dist/_utils/helpers.js";
import ColumnStatic from "../../../data-table/table/column/column-static.js";
import { controlModel, dcModel, fieldModel } from "../../model/dc-model.js";
export default class DcFieldFn {
    /**
     *
     * @param {string} type
     * @param {TypeFieldOption} fieldOptions
     * @param {DataCard} dataCard
     * @param {TypeFieldSettings} field
     * @return {(data: any, type: any, row: any, meta: CellMetaSettings) => any}
     */
    static _getFieldRender(type, fieldOptions, dataCard, field) {
        return ColumnStatic._getColumnRender(type, fieldOptions, dataCard, field);
    }
    /**
     *
     * @param {DataCard} dataCard
     */
    static _fieldsFilter(dataCard) {
        let settings = dataCard.settings();
        // let caseInsensitive = Helpers._getObjByProp(settings, __PROP.caseInsensitive, StDataCardDefaults.options.caseInsensitive);
        let caseInsensitive = settings.caseInsensitive;
        let debug = {
            _event: 'DataCardFn.field._fieldsFilter',
            dataCard: dataCard
        };
        let searchVal = "";
        let $fieldFilter = $('[' + controlModel.search.fieldIdAttr + ']', '.' + controlModel.controlPanelContainer.attr.class + '.' + dcModel.activeClass);
        let arraySearch = { fields: {}, multiFields: {}, search: "" };
        let $generalSearch = $('.' + controlModel.inputSearch.attr.class, '.' + controlModel.controlPanelContainer.attr.class + '.' + dcModel.activeClass);
        if ($generalSearch.length) {
            let inputVal = $generalSearch.val();
            if (Helpers._isStringNotEmpty(inputVal)) {
                searchVal = inputVal;
                if (caseInsensitive) {
                    searchVal = searchVal.toLowerCase();
                }
            }
            arraySearch.search = searchVal;
        }
        $fieldFilter.each(function (index, element) {
            let $elem = $(element);
            let val = $elem.val();
            let fieldName = $elem.attr(controlModel.search.fieldNameAttr);
            let fieldId = $elem.attr(controlModel.search.fieldIdAttr);
            let isMultiField = ($elem.attr(dcModel.dataMultiFieldAttr)) === "true";
            if (typeof arraySearch === "object" && Helpers._isStringNotEmpty(fieldId) && Helpers._isStringNotEmpty(val)) {
                if (isMultiField) {
                    arraySearch.multiFields[fieldId] = val;
                }
                else {
                    arraySearch.fields[fieldId] = val;
                }
            }
            debug["$elem [" + (index + 1) + "]"] = {
                elem: element,
                fieldId: fieldId,
                val: val,
                isMultiField: isMultiField,
                fieldName: fieldName
            };
        });
        debug.arraySearch = arraySearch;
        if (DcFieldFn.debug) {
            console.log(debug);
        }
        dataCard.filter(arraySearch).draw();
        $.each(arraySearch.fields, function (fieldId, fieldSearchVal) {
            let $fieldDisplay = $("." + fieldModel.item.display.attr.class, '[' + dcModel.dataEditorAttr + '="' + fieldId + '"]');
            if ($fieldDisplay.length) {
                let text = $fieldDisplay.text();
                if (caseInsensitive) {
                    text = text.toLowerCase();
                }
                if (text.includes(searchVal)) {
                    $fieldDisplay.addClass(dcModel.highlightClass);
                }
            }
            if (DcFieldFn.debug) {
                console.log({
                    fieldId: fieldId,
                    fieldSearchVal: fieldSearchVal,
                    $fieldDisplay: $fieldDisplay
                });
            }
        });
        $.each(arraySearch.multiFields, function (fieldId, fieldSearchVal) {
            let $multiFieldSet = $("." + fieldModel.item.multi.attr.class);
            $multiFieldSet.each(function () {
                let $elem = $(this);
                let dataId = $elem.attr(dcModel.dataEditorAttr);
                if (dataId.includes('.' + fieldId)) {
                    let $fieldDisplay = $("." + fieldModel.item.display.attr.class, $elem);
                    if ($fieldDisplay.length) {
                        let text = $elem.text();
                        if (caseInsensitive) {
                            text = text.toLowerCase();
                        }
                        if (text.includes(searchVal)) {
                            $fieldDisplay.addClass(dcModel.highlightClass);
                        }
                    }
                }
            });
            if (DcFieldFn.debug) {
                console.log({
                    fieldId: fieldId,
                    fieldSearchVal: fieldSearchVal,
                    $multiFieldSet: $multiFieldSet
                });
            }
        });
        if (Helpers._isStringNotEmpty(searchVal)) {
            $("." + fieldModel.item.display.attr.class).each(function () {
                let $elem = $(this);
                let text = $elem.text();
                if (caseInsensitive) {
                    text = text.toLowerCase();
                }
                if (text.includes(searchVal)) {
                    $elem.addClass(dcModel.highlightClass);
                }
                if (DcFieldFn.debug) {
                    console.log({
                        $elem: $elem,
                        text: text,
                        searchVal: searchVal
                    });
                }
            });
        }
    }
    /**
     *
     * @param {TypeDataCardOptions} settings
     * @param {TypeTableData} dataRow
     * @param {JQuery} $newTemplate
     * @param {TypeFieldSettings[]} arrayFields
     * @param {DataCard} dataCard
     * @return {JQuery}
     */
    static _updateFieldData(settings, dataRow, $newTemplate, arrayFields, dataCard) {
        // if (DcFieldFn.debug) {
        //     console.log({
        //         _event: "_updateFieldData",
        //         settings: settings,
        //         dataRow: dataRow,
        //         $newTemplate: $newTemplate,
        //         arrayFields: arrayFields,
        //         dataCard: dataCard
        //     });
        // }
        const $cardBodyTemplate = $('.card-body-template', $newTemplate);
        $.each(arrayFields, function (index, field) {
            let data = dataRow, fieldUniqueId = field._uniqueId, $field = $("[" + dcModel.dataEditorAttr + "='" + fieldUniqueId + "']", $newTemplate), $fieldValue = $('.' + fieldModel.item.value.attr.class, $field), $fieldDisplay;
            data = Helpers._getObjByArrayProp(data, field.indexes, "");
            const originalData = data;
            // console.log({data})
            data = data.toString().trim();
            if (Helpers._isStringNotEmpty(data)) {
                $fieldValue.text(data);
            }
            let renderData = data;
            // if (field.visible) {
            //     $fieldDisplay = $('.' + fieldModel.item.display.attr.class, $field);
            //     if (typeof field.render === 'function') {
            //         renderData = field.render(renderData, field.type, dataRow, settings, dataCard);
            //     }
            //     if (!Helpers._isSameType(renderData, "") || Helpers._isStringNotEmpty(renderData)) {
            //         $fieldDisplay.html(renderData);
            //         if($cardBodyTemplate.length && typeof field.name === 'string'){
            //             let cardBodyTemplateHtmlString = $cardBodyTemplate.html();
            //             cardBodyTemplateHtmlString = cardBodyTemplateHtmlString.replace(`{${field.name}}`, renderData);
            //             $cardBodyTemplate.html(cardBodyTemplateHtmlString);
            //
            //             // console.log({cardBodyTemplateHtmlString, $cardBodyTemplate});
            //         }
            //     }
            // }
            $fieldDisplay = $('.' + fieldModel.item.display.attr.class, $field);
            if (typeof field.render === 'function') {
                renderData = field.render(originalData, field.type, dataRow, settings, dataCard) || "";
            }
            if (renderData !== undefined || !Helpers._isSameType(renderData, "") || Helpers._isStringNotEmpty(renderData)) {
                $fieldDisplay.html(renderData);
                if ($cardBodyTemplate.length && typeof field.name === 'string') {
                    let cardBodyTemplateHtmlString = $cardBodyTemplate.html();
                    new RegExp(`{${field.name}}`, 'g');
                    cardBodyTemplateHtmlString = cardBodyTemplateHtmlString.replace(new RegExp(`{${field.name}}`, 'g'), renderData);
                    $cardBodyTemplate.html(cardBodyTemplateHtmlString);
                    // console.log({cardBodyTemplateHtmlString, $cardBodyTemplate});
                }
            }
            // if (DcFieldFn.debug) {
            //     console.log({
            //         field: field,
            //         renderData: renderData,
            //         $field: $field,
            //         $fieldValue: $fieldValue,
            //         $fieldDisplay: $fieldDisplay,
            //         displayClass: fieldModel.item.display.attr.class
            //     });
            // }
        });
        return $newTemplate;
    }
    /**
     *
     * @param {TypeDataCardOptions} settings
     * @param {TypeTableData} dataRow
     * @param {JQuery} $newTemplate
     * @param {string} multiFieldDataSrc
     * @param {TypeFieldSettings[]} arrayFieldsMulti
     * @param {TypeItemSrc[]} itemSrc
     * @param {DataCard} dataCard
     * @return {JQuery}
     */
    static _updateFieldMultiData(settings, dataRow, $newTemplate, multiFieldDataSrc, arrayFieldsMulti, itemSrc, dataCard) {
        if (DcFieldFn.debug) {
            console.log({
                _event: "_updateFieldMultiData",
                settings: settings,
                dataRow: dataRow,
                multiFieldDataSrc: multiFieldDataSrc,
                arrayFieldsMulti: arrayFieldsMulti,
                itemSrc: itemSrc,
                dataCard: dataCard
            });
        }
        const $cardBodyTemplate = $('.card-body-template', $newTemplate);
        if (Helpers._isSameType(arrayFieldsMulti, []) && arrayFieldsMulti.length > 0 &&
            Helpers._isSameType(itemSrc, []) && itemSrc.length > 0 &&
            Helpers._isStringNotEmpty(multiFieldDataSrc)) {
            let dataMulti = Helpers._getObjByProp(dataRow, multiFieldDataSrc, {});
            $.each(itemSrc, function (index, src) {
                // let itemSrcData = Helpers._getObjByProp<string>(src, __PROP.data, "");
                let itemSrcData = src.data || "";
                if (Helpers._isStringNotEmpty(itemSrcData)) {
                    let dataMultiItem = Helpers._getObjByProp(dataMulti, itemSrcData, {});
                    $.each(arrayFieldsMulti, function (index, fieldMulti) {
                        let itemDataSrc = "", fieldUniqueId = fieldMulti._uniqueId, data = Helpers._getObjByArrayProp(dataMultiItem, fieldMulti.indexes, "").toString();
                        if (Helpers._isStringNotEmpty(itemSrcData)) {
                            itemDataSrc = itemSrcData + ".";
                        }
                        let $fieldElement = $("[" + dcModel.dataEditorAttr + "='" + itemDataSrc + fieldUniqueId + "']", $newTemplate);
                        // console.log({data})
                        data = data.trim();
                        if (Helpers._isStringNotEmpty(data)) {
                            let $fieldValue = $('.' + fieldModel.item.value.attr.class, $fieldElement);
                            $fieldValue.text(data);
                        }
                        if (fieldMulti.visible) {
                            let $fieldDisplay = $('.' + fieldModel.item.display.attr.class, $fieldElement);
                            let renderData = data;
                            if (Helpers._isFunction(fieldMulti.render)) {
                                renderData = fieldMulti.render(data, fieldMulti.type, dataRow, settings, dataCard, src);
                            }
                            if (renderData !== undefined || !Helpers._isSameType(renderData, "") /* || Helpers._isStringNotEmpty(renderData)*/) {
                                $fieldDisplay.html(renderData);
                                if ($cardBodyTemplate.length && typeof fieldMulti.name === 'string') {
                                    let cardBodyTemplateHtmlString = $cardBodyTemplate.html();
                                    cardBodyTemplateHtmlString = cardBodyTemplateHtmlString.replace(new RegExp(`{${multiFieldDataSrc}.${itemSrcData}.${fieldMulti.name}}`, 'g'), renderData);
                                    $cardBodyTemplate.html(cardBodyTemplateHtmlString);
                                    // console.log({cardBodyTemplateHtmlString, $cardBodyTemplate});
                                }
                            }
                        }
                    });
                }
            });
        }
        return $newTemplate;
    }
}
/**
 *
 * @type {boolean}
 */
DcFieldFn.debug = false;
