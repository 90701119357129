import 'datatables.net';
import 'datatables.net-buttons';
import Helpers from "../../../../../dist/_utils/helpers.js";
export default class ButtonStatic {
    /**
     *
     * @param editor
     * @param extendType
     * @param text
     * @param title
     * @param className
     * @returns {{}}
     * @public
     */
    static _extend(editor, extendType, text, title, className = "") {
        let obj = {};
        obj.editor = editor;
        obj.extend = extendType;
        obj.text = text;
        obj.attr = {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "top",
            "data-original-title": title
        };
        obj.className = "btn-primary";
        return obj;
    }
    /**
     *
     * @param text
     * @param action
     * @param title
     * @param className
     * @returns {{}}
     * @private
     */
    static _create(text, action, title, className = "") {
        let obj = {};
        obj.text = text;
        if (Helpers._isFunction(action)) {
            obj.action = action;
        }
        obj.className = className;
        obj.attr = {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "top",
            "data-original-title": title
        };
        return obj;
    }
    /**
     *
     */
    static _list() {
        return ButtonStatic._create(ButtonStatic._icon.magic, function (event, dataTable, node, config) {
            let arrayDebug = [];
            arrayDebug.event = event;
            arrayDebug.dataTable = dataTable;
            arrayDebug.node = node;
            arrayDebug.config = config;
        }, "lister");
    }
    static _appendControlButton(dataTable, buttons) {
        new $.fn.dataTable.Buttons(dataTable, buttons);
        dataTable.buttons().container()
            .appendTo($('div.dt-btn-container'));
    }
}
/**
 *
 * @type {{cog: string, list: string, magic: string}}
 * @private
 */
ButtonStatic._icon = {
    cog: '<i class="fa fa-cog"></i>',
    list: '<i class="fab fa-searchengin"></i>',
    magic: '<i class="fas fa-magic"></i>'
};
