import $ from 'jquery';
import 'datatables.net';
import Helpers from "../../../../dist/_utils/helpers.js";
import _separator from "../../utils/_separator.js";
// import ColumnStatic from "./column/column-static.js";
import DtColumnFn from "./column/dt-column-fn.js";
import { EnumDtEvent, EnumLanguage } from "../model/dt-enum.js";
import { DtTable } from "./dt-table.js";
export default class DataTableStatic {
    /**
     *
     * @param tableId
     * @returns {null | Table}
     * @public
     */
    static _init(tableId) {
        if (DataTableStatic.debug) {
            console.log("_DataTable._init() called", { tableId: tableId });
        }
        if (typeof tableId !== typeof "") {
            tableId = DataTableStatic.tableId;
        }
        let $table = $('#' + tableId);
        if ($table.length > 0) {
            let tableIndex = DataTableStatic._getAttribute($table, DataTableStatic._const.attrTable);
            if (tableIndex !== "") {
                let table = new DtTable();
                table
                    .setIdSelector(tableId)
                    .setTableIndex(tableIndex)
                    .setIdSrc(DataTableStatic._getAttribute($table, DataTableStatic._const.attrIdSrc))
                    .setCreate(DataTableStatic._getBooleanAttribute($table, DataTableStatic._const.attrCreate))
                    .setEdit(DataTableStatic._getBooleanAttribute($table, DataTableStatic._const.attrEdit))
                    .setEditType(DataTableStatic._getAttribute($table, DataTableStatic._const.attrEditType))
                    .setRemove(DataTableStatic._getBooleanAttribute($table, DataTableStatic._const.attrRemove))
                    .setPrint(DataTableStatic._getBooleanAttribute($table, DataTableStatic._const.attrPrint))
                    .setPdf(DataTableStatic._getBooleanAttribute($table, DataTableStatic._const.attrPdf))
                    .setExcel(DataTableStatic._getBooleanAttribute($table, DataTableStatic._const.attrExcel))
                    .setTitle(DataTableStatic._getAttribute($table, DataTableStatic._const.attrTitle))
                    .setLanguage(DataTableStatic._getAttribute($table, DataTableStatic._const.attrLanguage))
                    .setFilterHead(DataTableStatic._getBooleanAttribute($table, DataTableStatic._const.attrFilterHead))
                    .setImageSrc(DataTableStatic._getAttribute($table, DataTableStatic._const.attrImageSrc, []))
                    .setRowReorder(DataTableStatic._getAttribute($table, DataTableStatic._const.attrRowReOrder))
                    .setControlColumn(DataTableStatic._getBooleanAttribute($table, DataTableStatic._const.attrControlColumn))
                    .setArrayColumn(DtColumnFn._getColumns(tableId));
                return table.draw();
            }
        }
        return null;
    }
    /**
     *
     * @param $element
     * @param attr
     * @param defaultValue
     * @returns {string}
     * @public
     */
    static _getAttribute($element, attr, defaultValue) {
        if ($element.length > 0) {
            let attribute = $element.attr(attr);
            if (typeof attribute !== "undefined") {
                defaultValue = attribute;
            }
            else {
                defaultValue = "";
            }
        }
        return defaultValue;
    }
    /**
     *
     * @param $element
     * @param attr
     * @returns {boolean}
     * @public
     */
    static _getBooleanAttribute($element, attr) {
        let attribute = DataTableStatic._getAttribute($element, attr, "false");
        return attribute === "true";
    }
    /**
     *
     * @param tableId
     * @param dataTable
     * @param columns
     */
    static _displayFilterHead(tableId, dataTable, columns) {
        dataTable
            .on(EnumDtEvent.init, function () {
            let tableHead = $("#" + tableId + ' thead');
            tableHead.append('<tr></tr>');
            let filterRow = tableHead.find('tr:eq(1)');
            let columnsApi = dataTable.columns();
            columnsApi.every(function () {
                let colApi = this;
                let index = colApi.index();
                // @ts-ignore
                if (Helpers._isFunction(colApi.responsiveHidden)) {
                    // @ts-ignore
                    let responsiveHidden = colApi.responsiveHidden();
                    let visible = colApi.visible();
                    if (visible) {
                        let display = responsiveHidden ? "" : "none";
                        let $th = $("<th></th>");
                        $th.css("display", display);
                        filterRow.append($th);
                        let col = columns[index];
                        if (col !== null && (col.isSearchable || col.searchable)) {
                            let columnOptions = col.options;
                            if (Helpers._isNotUndefined(columnOptions)) {
                                DataTableStatic._showSelectFilter($th, dataTable, index, columnOptions);
                            }
                            else {
                                DataTableStatic._showInputFilter($th, dataTable, index);
                            }
                        }
                    }
                }
            });
        })
            .on(EnumDtEvent.responsiveResize, function () {
            // @ts-ignore
            let fixedHeader = dataTable.fixedHeader;
            fixedHeader.enable(false);
            let firstRow = $('#' + tableId + ' thead tr:eq(0)');
            let secondRow = $('#' + tableId + ' thead tr:eq(1)');
            if (secondRow.length > 0) {
                firstRow.find("th").each(function (index, element) {
                    let firstRowDisplay = $(element).css("display");
                    let $secondRowTd = secondRow.find("th:eq(" + index + ")");
                    if ($secondRowTd.length > 0) {
                        $secondRowTd.css("display", firstRowDisplay);
                    }
                });
            }
            fixedHeader.adjust();
        });
    }
    /**
     *
     * @param td
     * @param dataTable
     * @param index
     * @private
     */
    static _showInputFilter(td, dataTable, index) {
        let $inputHtml = $('<input class="form-control" type="text" placeholder="">');
        // $(td).html($inputHtml);
        $(td).html('<input class="form-control" type="text" placeholder="">');
        $inputHtml.on('keyup change', function () {
            if (dataTable.columns(index).search() !== this.value) {
                dataTable.columns(index).search(this.value).draw();
            }
        });
    }
    /**
     *
     * @param td
     * @param dataTable
     * @param index
     * @param options
     * @private
     */
    static _showSelectFilter(td, dataTable, index, options) {
        let $select = $('<select class="filter form-control"><option value="">' + dataTable.i18n("menuText.all", "") + '</option></select>');
        $.each(options, function (index) {
            $select.append('<option value="' + index.toString() + '">' + index.toString() + '</option>');
        });
        $(td).append($select);
        $select.on('change', function () {
            let value = $(this).val().toString();
            let val = $.fn.dataTable.util.escapeRegex(value);
            dataTable.column(index)
                .search(val ? '' + val + '' : '', true, false)
                .draw();
        });
    }
    /**
     *
     */
    static _previewIframe(url, editor) {
        // let url = arguments[0];
        // let editor : Editor;
        // if (arguments.length > 1) {
        //     editor = arguments[1];
        // }
        // PopUp._iframe(url, {
        //     callbacks: {
        //         beforeOpen: function () {
        //             if (Helpers._isNotUndefined(editor) && Helpers._isFunction(editor.close)) {
        //                 editor.close();
        //             }
        //         },
        //         close: function () {
        //             if (Helpers._isNotUndefined(editor) && Helpers._isFunction(editor.open)) {
        //                 editor.open();
        //             }
        //         }
        //     }
        // });
    }
    static _previewAjax(url, data, editor) {
        // let url = arguments[0];
        // let data;
        // let editor : Editor;
        // if (arguments.length > 1) {
        //     data = arguments[1];
        // }
        // if (arguments.length > 2) {
        //     editor = arguments[2];
        // }
        // PopUp._ajax(url, data, {
        //     callbacks: {
        //         beforeOpen: function () {
        //             if (Helpers._isNotUndefined(editor) && Helpers._isFunction(editor.close)) {
        //                 editor.close();
        //             }
        //         },
        //         close: function () {
        //             if (Helpers._isNotUndefined(editor) && Helpers._isFunction(editor.open)) {
        //                 editor.open();
        //             }
        //         }
        //     }
        // });
        /*if ($.magnificPopup && arguments.length > 0) {
            $.magnificPopup.open({
                iframe: {
                    markup: '<div class="mfp-iframe-scaler">' +
                    '<div class="mfp-close"></div>' +
                    '<iframe class="mfp-iframe" frameborder="0" style="overflow:scroll;height:100%;width:100%" height="100%" width="100%" allowfullscreen></iframe>' +
                    '</div>' // HTML markup of popup, `mfp-close` will be replaced by the close button
                },
//                            fixedContentPos : false,
                callbacks: {
                    beforeOpen: function () {
                        if(Helpers._isNotUndefined(editor) && Helpers._isFunction(editor.close)){
                            editor.close();
                        }
                        console.log('beforeOpen of popup initialization');
                    },
                    close: function () {
                        if(Helpers._isNotUndefined(editor) && Helpers._isFunction(editor.open)){
                            editor.open();
                        }
                        console.log('Popup is completely closed');
                    }
                },
                // overflowY : "scroll",
                items: { src: url},
                ajax: {
                    settings: { type: 'POST', data: data}
                },
                type: 'ajax'
            }, 0);

        }*/
    }
}
DataTableStatic.debug = false;
DataTableStatic.tableId = "dataTable";
DataTableStatic._language = EnumLanguage;
DataTableStatic.ajaxUrl = "/_api/datatable/";
DataTableStatic._const = {
    attrFilterHead: "data-filterHead",
    attrRowReOrder: "data-rowreorder",
    attrLanguage: "data-lang",
    attrIdSrc: "data-idsrc",
    attrTable: "data-table",
    attrTitle: "data-title",
    attrEdit: "data-edit",
    attrEditType: "data-edittype",
    attrCreate: "data-create",
    attrRemove: "data-remove",
    attrPrint: "data-print",
    attrImageSrc: "data-imagesrc",
    attrExcel: "data-excel",
    attrPdf: "data-pdf",
    attrControlColumn: "data-controlcolumn"
};
DataTableStatic.format = {
    date: "DD" + _separator.date + "MM" + _separator.date + "YYYY",
    time: "HH:mm",
    datetime: "DD" + _separator.date + "MM" + _separator.date + "YY - HH:mm",
    hours: "HH",
    minutes: "mm",
    days: "DD",
    timeStamp: 'X',
    dayHoursMinute: "DD HH:mm"
};
DataTableStatic._event = {
    columnSizing: 'column-sizing.dt',
    columnVisibility: 'column-visibility.dt',
    destroy: 'destroy.dt',
    draw: 'draw.dt',
    preDraw: 'preDraw.dt',
    error: 'error.dt',
    init: 'init.dt',
    length: 'length.dt',
    order: 'order.dt',
    page: 'page.dt',
    preInit: 'preInit.dt',
    preXhr: 'preXhr.dt',
    processing: 'processing.dt',
    search: 'search.dt',
    stateLoadParams: 'stateLoadParams.dt',
    stateSaveParams: 'stateSaveParams.dt',
    xhr: 'xhr.dt',
    responsiveDisplay: 'responsive-display',
    responsiveResize: 'responsive-resize'
};
