import $ from 'jquery';
import { EnumDTEditorEvent } from "../model/dt-enum.js";
export class DtEditorFn {
    /**
     *
     * @param dataTableEditor
     * @param arrayFields
     * @public
     */
    static _enableField(dataTableEditor, arrayFields) {
        if (dataTableEditor) {
            dataTableEditor
                .on(EnumDTEditorEvent.initCreate, function () {
                arrayFields.forEach(function (item) {
                    let create = true;
                    if (item.hasOwnProperty("create")) {
                        create = Boolean((item["create"]));
                    }
                    if (create) {
                        dataTableEditor.field(item.name).enable().show();
                    }
                    else {
                        dataTableEditor.field(item.name).disable().hide(false);
                    }
                });
            })
                .on(EnumDTEditorEvent.initEdit, function () {
                arrayFields.forEach(function (item) {
                    let edit = true;
                    if (item.hasOwnProperty("edit")) {
                        edit = Boolean((item["edit"]));
                    }
                    if (edit) {
                        dataTableEditor.field(item.name).enable().show();
                    }
                    else {
                        dataTableEditor.field(item.name).disable().hide(false);
                    }
                });
            });
        }
    }
    /**
     *
     * @param {string} tableId
     * @param editor
     */
    static _inlineEdit(tableId, editor) {
        let $table = $('#' + tableId);
        if ($table.length > 0 && editor !== null) {
            $table.on('click', 'tbody td.editable', function () {
                // @ts-ignore
                editor.inline(this);
            });
        }
    }
    /**
     *
     * @param {string} tableId
     * @param editor
     */
    static _bubbleEdit(tableId, editor) {
        let $table = $('#' + tableId);
        if ($table.length > 0 && editor !== null) {
            $table.on('click', 'tbody td.editable', function () {
                // @ts-ignore
                editor.bubble(this);
            });
        }
    }
}
DtEditorFn.debug = false;
