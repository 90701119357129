import "../styles/datacard.css";
const buttonModel = {};
const cardModel = {
    rowCardContainer: {
        attr: {
            class: 'dc-row-container'
        }
    },
    rowPageLengthContainer: {
        attr: {
            class: 'dc-row-length-container'
        }
    },
    pageInfo: {
        attr: {
            class: 'dc-page-info col-lg-6 col-md-12',
            id: 'dc-page-info'
        }
    },
    pagination: {
        attr: {
            class: 'dc-pagination col-lg-6 col-md-12',
            id: 'dc-pagination'
        }
    },
    item: {
        attr: {
            class: "dc-card"
        }
    },
    container: {
        attr: {
            class: "dc-card-container",
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "top",
            title: ''
        },
        className: 'elevation p-10 m-b-10 m-t-10'
    },
    btnContainer: {
        attr: {
            class: "dc-card-btn-container"
        }
    }
};
const controlModel = {
    controlPanelContainer: {
        attr: {
            'class': "dc-control-container"
        }
    },
    btnPanel: {
        attr: {
            'class': 'dc-btn-panel'
        }
    },
    btn: {
        btnGroup: {
            attr: {
                "class": "dc-btn-group btn-group flex-wrap"
            }
        },
        item: {
            attr: {
                "class": "dc-button btn btn-sm btn-primary btn-icon m-b-0",
                "data-bs-toggle": "tooltip",
                "data-bs-placement": "top",
                title: ''
            }
        },
        reOrder: {
            attr: {
                "class": "dc-reOrder"
            }
        },
        edit: {
            attr: {
                "class": "dc-edit"
            }
        },
        select: {
            attr: {
                "class": "dc-select"
            }
        },
        remove: {
            attr: {
                "class": "dc-remove"
            }
        },
        create: {
            attr: {
                "class": "dc-create"
            }
        },
        selectAll: {
            attr: {
                "class": "dc-select-all"
            }
        },
        selectNone: {
            attr: {
                "class": "dc-select-none"
            }
        }
    },
    clone: {
        attr: {
            "class": "dc-control-clone"
        },
        stickyClass: "sticky bg-white shadow"
    },
    filterClass: "dc-control-filter",
    selectLength: {
        attr: {
            'class': 'dc-select-length'
        }
    },
    inputSearch: {
        attr: {
            type: 'search',
            'class': 'dc-input-search'
        }
    },
    pagination: {
        aBtn: {
            attr: { 'class': 'page-link' }
        },
        liBtn: {
            attr: { "class": "page-item" }
        }
    },
    sort: {
        fieldNameAttr: "sort-field-name",
        fieldIdAttr: "sort-field-id",
        'class': "dc-sort",
        asc: 'sort_asc',
        desc: "sort_desc"
    },
    search: {
        fieldNameAttr: "search-field-name",
        fieldIdAttr: "search-field-id",
        'class': "dc-search"
    }
};
const fieldModel = {
    fieldContainer: {
        attr: {
            "class": "dc-field-container"
        }
    },
    multiFieldContainer: {
        attr: {
            "class": "dc-multi-field-container"
        }
    },
    item: {
        attr: {
            "class": "dc-field"
        },
        multi: {
            attr: {
                "class": "dc-multi-field"
            }
        },
        legend: {
            attr: {
                "class": "dc-field-title"
            }
        },
        fieldSet: {
            attr: {
                "class": "dc-field-set"
            }
        },
        label: {
            attr: {
                "class": "dc-field-label"
            }
        },
        display: {
            attr: {
                "class": "dc-field-display"
            }
        },
        value: {
            attr: {
                "class": "dc-field-value"
            }
        }
    }
};
const dcModel = {
    hideClass: "hide",
    highlightClass: "dc-highlight",
    activeClass: "active",
    selectedClass: "selected",
    selectedAttr: "selected",
    disabledClass: "disabled",
    disabledAttr: "disabled",
    btnXsClass: "",
    dataIdAttr: "data-id",
    dataTitleSrcAttr: "data-title-src",
    dataPageIndexAttr: "data-page-index",
    editorField: "editor-field",
    dataEditorIdAttr: "data-editor-id",
    dataEditorFieldAttr: "data-editor-field",
    dataEditorAttr: "data-editor",
    dataMultiFieldAttr: "data-multi-field",
    dataCardButtonsAttr: "data-card-buttons",
    dataCardBtnAttr: "data-card-btn",
    dataCardFieldAttr: "data-card-field",
    dataCardMultiFieldAttr: "data-card-multi-field"
};
const dcEditorModel = {
    template: {
        attr: {
            "class": "dc-editor-template",
            id: "dc-editor-template"
        }
    }
};
export { buttonModel, cardModel, controlModel, fieldModel, dcModel, dcEditorModel };
