import Helpers from "../../../../dist/_utils/helpers.js";
import { EnumColumnType } from "../../data-table/model/dt-enum.js";
import { controlModel, dcEditorModel } from "./dc-model.js";
const debug = false;
const buttonDefault = {
    /**
     *
     * @return {any}
     */
    action() {
    },
    /**
     *
     */
    className: "",
    /**
     *
     */
    icon: "",
    /**
     *
     */
    name: "",
    /**
     *
     */
    title: ""
};
const itemSrcDefault = {
    /**
     *
     */
    title: "",
    /**
     *
     */
    data: "",
    /**
     *
     */
    visible: true,
    /**
     *
     */
    search: true,
    /**
     *
     */
    sort: true
};
const fieldDefault = {
    /**
     *
     */
    _uniqueId: "",
    /**
     *
     */
    _isMultiField: false,
    /**
     *
     */
    _isGroup: false,
    /**
     *
     */
    className: "",
    /**
     *
     */
    name: null,
    /**
     *
     */
    indexes: [],
    /**
     *
     */
    data: "",
    /**
     *
     */
    label: "",
    /**
     *
     */
    labelText: "",
    /**
     *
     */
    labelInfo: "",
    /**
     *
     */
    labelHelper: "",
    /**
     *
     */
    fieldInfo: "",
    /**
     *
     */
    fieldClass: "",
    /**
     *
     */
    fieldHelper: "",
    /**
     *
     */
    type: EnumColumnType.text,
    /**
     *
     */
    order: null,
    /**
     *
     */
    render: null,
    /**
     *
     */
    sort: true,
    /**
     *
     */
    search: true,
    /**
     *
     */
    visible: true,
    /**
     *
     */
    create: true,
    /**
     *
     */
    edit: true,
    /**
     *
     */
    display: true,
    /**
     *
     */
    debug: false,
    opts: {},
};
const multiFieldDefault = {
    /**
     *
     */
    dataSrc: "",
    /**
     *
     */
    idSrc: "",
    /**
     *
     */
    titleSrc: "",
    /**
     *
     */
    itemSrc: [],
    /**
     *
     */
    fields: []
};
const orderingDefault = {
    /**
     *
     */
    direction: 'ASC',
    /**
     *
     */
    fieldName: "",
    /**
     *
     */
    multiFields: false
};
const dcDefaultsOptions = {
    /**
     *
     */
    ajax: "/_api/datacard/",
    /**
     *
     */
    _ids: [],
    /**
     *
     */
    ordering: [],
    /**
     *
     */
    fieldGroup: "",
    /**
     *
     */
    dataSrc: "",
    /**
     *
     */
    idSrc: "",
    /**
     *
     */
    editorModalClass: "modal-lg",
    /**
     *
     */
    cardTitle: "",
    /**
     *
     */
    titleSrc: "",
    /**
     *
     */
    title: "",
    /**
     *
     */
    editorTemplate: "#" + dcEditorModel.template.attr.id,
    /**
     *
     */
    columnDisplay: 4,
    /**
     *
     */
    pageLength: 30,
    /**
     *
     */
    lengthMenu: [30, 60, 120, -1],
    /**
     *
     */
    fields: [],
    /**
     *
     */
    multiFields: {
        /**
         *
         */
        dataSrc: "",
        /**
         *
         */
        idSrc: "",
        /**
         *
         */
        titleSrc: "",
        /**
         *
         */
        itemSrc: [],
        /**
         *
         */
        fields: []
    },
    /**
     *
     */
    controlPanel: '.' + controlModel.controlPanelContainer.attr.class,
    /**
     *
     */
    buttons: [],
    /**
     *
     */
    cardButtons: [],
    /**
     *
     */
    formButtons: [],
    /**
     *
     */
    caseInsensitive: true,
    /**
     *
     */
    filterHiddenGroup: true,
    /**
     *
     */
    language: Helpers._getLang() || "fr",
    /**
     *
     */
    rowReorder: "",
    /**
     *
     */
    search: true,
    /**
     *
     */
    sort: true,
    /**
     *
     */
    select: true,
    /**
     *
     */
    edit: true,
    /**
     *
     */
    create: true,
    /**
     *
     */
    remove: true,
    /**
     *
     */
    fixedControl: {
        /**
         *
         */
        offSet: 52
    },
    /**
     *
     */
    event: {
        /**
         *
         */
        onStart: function (dataCard) {
            if (debug) {
                console.log("DataCard onStart() called", {
                    dataCard: dataCard, settings: dataCard.settings()
                });
            }
        },
        /**
         *
         */
        started: function (dataCard) {
            if (debug) {
                console.log("DataCard started(settings) called", {
                    dataCard: dataCard, settings: dataCard.settings()
                });
            }
        },
        /**
         *
         */
        initLoad: function (dataCard) {
            if (debug) {
                console.log("DataCard initLoad() called", {
                    dataCard: dataCard, settings: dataCard.settings()
                });
            }
        },
        /**
         *
         */
        preLoad: function (dataCard) {
            if (debug) {
                console.log("DataCard preLoad() called", {
                    dataCard: dataCard, settings: dataCard.settings()
                });
            }
        },
        /**
         *
         */
        onLoad: function (dataCard) {
            if (debug) {
                console.log("DataCard onLoad() called", {
                    dataCard: dataCard, settings: dataCard.settings()
                });
            }
        },
        /**
         *
         */
        postLoad: function (dataCard) {
            if (debug) {
                console.log("DataCard postLoad() called", {
                    dataCard: dataCard, settings: dataCard.settings()
                });
            }
        },
        /**
         *
         */
        initDraw: function (dataCard) {
            if (debug) {
                console.log("DataCard initDraw() called", {
                    dataCard: dataCard, settings: dataCard.settings()
                });
            }
        },
        /**
         *
         */
        preDraw: function (dataCard) {
            if (debug) {
                console.log("DataCard preDraw() called", {
                    dataCard: dataCard, settings: dataCard.settings()
                });
            }
        },
        /**
         *
         */
        onDraw: function (dataCard) {
            if (debug) {
                console.log("DataCard onDraw() called", {
                    dataCard: dataCard, settings: dataCard.settings()
                });
            }
        },
        /**
         *
         */
        postDraw: function (dataCard) {
            if (debug) {
                console.log("DataCard postDraw() called", {
                    dataCard: dataCard, settings: dataCard.settings()
                });
            }
        },
        /**
         *
         */
        postSubmit: function (dataCard) {
            if (debug) {
                console.log("DataCard postDraw() called", {
                    dataCard: dataCard, settings: dataCard.settings()
                });
            }
        }
    },
    cardTemplate: null
};
export { buttonDefault, itemSrcDefault, fieldDefault, multiFieldDefault, orderingDefault, dcDefaultsOptions };
