import $ from "jquery";
import 'datatables.net';
export default class DtRowRender {
    static _collapseAllRow(tableId, dataTable) {
        let tr = $("#" + tableId + ' tbody tr');
        tr.each(function () {
            let row = dataTable.row($(this));
            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            }
        });
    }
}
