import Helpers from "../../../../../dist/_utils/helpers.js";
import { EnumColumnType } from "../../model/dt-enum.js";
import _separator from "../../../utils/_separator.js";
export default class DtEditorField {
    constructor(name, label, edit, create, type, options, def, imageSrc) {
        this.name = "";
        this.label = "";
        this.type = "";
        this.def = "";
        this.separator = "";
        this.create = true;
        this.edit = true;
        this.required = true;
        this.imageSrc = "";
        this.create = Boolean(create);
        this.edit = Boolean(edit);
        this.name = name;
        this.label = label;
        this.required = true;
        this.imageSrc = imageSrc;
        if (Helpers._isStringNotEmpty(type) && Helpers._inObject(type, EnumColumnType)) {
            this.type = type;
            this.separator = _separator.array;
        }
        if (Helpers._isStringNotEmpty(def)) {
            this.def = def;
        }
        if (Helpers._getObjectLength(options) > 0) {
            this.options = options;
        }
        if (DtEditorField.debug) {
            console.log("Field() called", {
                name: name,
                label: label,
                edit: edit,
                type: type,
                options: options,
                def: def
            }, { field: this });
        }
    }
}
DtEditorField.debug = false;
