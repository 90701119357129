import $ from 'jquery';
import _separator from "../../src/client/utils/_separator.js";
export default class Helpers {
    /**
     *
     * @param val
     * @return {boolean}
     */
    static _isJson(val) {
        const isJson = val !== undefined && val !== null && val.constructor == Object;
        console.log({ isJson });
        return isJson;
    }
    /**
     *
     * @param val
     * @return {boolean}
     */
    static _isArray(val) {
        return typeof [] === typeof val;
    }
    static _getLang() {
        return $('html').attr('lang');
    }
    static _inObject(value, object) {
        let exist = false;
        if (object !== null && typeof object === typeof {}) {
            Object.keys(object).forEach(function (keyObject) {
                if (object[keyObject] === value) {
                    exist = true;
                }
            });
        }
        return exist;
    }
    static _isFloat(number) {
        return Number(number) === number && number % 1 !== 0;
    }
    static _isInteger(number) {
        return Number(number) === number && number % 1 === 0;
    }
    static _intToDec(integer) {
        return (integer / 100).toFixed(2);
    }
    static _minToSec(minutes) {
        return minutes * 60;
    }
    static _hoursToSec(hours) {
        return Helpers._minToSec(hours * 60);
    }
    static _dayToSec(days) {
        return Helpers._hoursToSec(days * 24);
    }
    static _secToMin(seconds) {
        return Math.floor(seconds / 60);
    }
    static _secToHours(seconds) {
        return Math.floor(Helpers._secToMin(seconds) / 60);
    }
    static _secToDay(seconds) {
        return Math.floor(Helpers._secToHours(seconds) / 60);
    }
    static _secToTime(second) {
        // var second = parseInt(val);
        const days = Helpers._secToDay(second);
        second = second - Helpers._dayToSec(days);
        const hours = Helpers._secToHours(second);
        second = second - Helpers._hoursToSec(hours);
        const minutes = Helpers._secToMin(second);
        // second = second - Helpers._minToSec(minutes);
        const hourString = hours < 10 ? '0' + hours : hours.toString();
        const minutesString = minutes < 10 ? '0' + minutes : minutes.toString();
        return hourString + _separator.time + minutesString;
    }
    static _intToDate(time) {
        time = time * 1000;
        const date = new Date(time);
        return date.toLocaleString("fr-BE", {
            // @ts-ignore TODO
            dateStyle: "short"
        });
    }
    static _intToDateTime(time) {
        time = time * 1000;
        const date = new Date(time);
        return date.toLocaleString("fr-BE", {
            // @ts-ignore TODO
            dateStyle: "short", timeStyle: "short"
        });
    }
    static _intToPrice(integer, currency = "€") {
        currency = Helpers._isSameType(currency, "") ? currency : '€';
        return Helpers._intToDec(integer) + " " + currency;
    }
    static _isSameType(value, compare) {
        return typeof value === typeof compare;
    }
    static _isStringNotEmpty(value) {
        return Helpers._isSameType(value, "") && value !== "";
    }
    static _isNotUndefined(value) {
        return typeof value !== "undefined";
    }
    static _isFunction(callable) {
        return Helpers._isSameType(callable, function () {
        });
    }
    static _isObject(value) {
        return value !== null && Helpers._isSameType(value, {});
    }
    static _isNumeric(value) {
        return !isNaN(value);
    }
    static _getObjByProp(obj, property, defValue = null) {
        if (Helpers._isSameType(obj, {}) && Helpers._isStringNotEmpty(property) && typeof property === 'string') {
            return obj.hasOwnProperty(property) ? obj[property] : defValue;
        }
        else {
            return Helpers._isNotUndefined(defValue) ? defValue : null;
        }
    }
    static _getObjByArrayProp(obj, property, defValue = null) {
        if (Helpers._getObjectLength(obj) > 0) {
            if (Helpers._isStringNotEmpty(property)) {
                return Helpers._getObjByProp(obj, property, defValue);
            }
            else if (Helpers._isSameType(property, [])) {
                let returnValue = Helpers._isNotUndefined(defValue) ? defValue : null;
                if (Array.isArray(property) && property.length > 0) {
                    property.forEach((element, index) => {
                        if (obj !== null && Helpers._isNotUndefined(obj[element])) {
                            obj = obj[element];
                        }
                        else {
                            obj = null;
                        }
                    });
                    // $.each(property, function (index, element) {
                    //     if (obj !== null && Helpers._isNotUndefined(obj[element])) {
                    //         obj = obj[element];
                    //     } else {
                    //         obj = null;
                    //     }
                    // });
                    returnValue = obj !== null ? obj : returnValue;
                }
                return returnValue;
            }
        }
        return Helpers._isNotUndefined(defValue) ? defValue : null;
    }
    static _getObjectLength(obj) {
        if (Helpers._isObject(obj)) {
            return Object.keys(obj).length;
        }
        else {
            return 0;
        }
    }
    static _convertToBoolean(value) {
        switch (typeof value) {
            case typeof "":
                return value === "true";
            case typeof 1:
                return value > 0;
            case typeof true:
                return value;
            case typeof {}:
                return Helpers._isStringNotEmpty(value);
            default:
                return false;
        }
    }
    static _isJsonString(str) {
        try {
            JSON.parse(str);
        }
        catch (e) {
            return false;
        }
        return true;
    }
    static _ucFirst(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    static _getCallerName(arg) {
        if (Helpers._isNotUndefined(arg)) {
            // return arg.callee.name;
        }
        return "";
    }
    static _getHostName() {
        const locationHost = location.host;
        let host = locationHost.replace("admin.", "");
        host = host.replace("portal.", "");
        const ret = location.origin.replace(locationHost, host);
        console.log({
            locationHost: locationHost, host: host, ret: ret
        });
        return ret;
    }
    static _toNumber(value) {
        if (typeof value === 'number') {
            return value;
        }
        if (typeof value === 'string') {
            return parseInt(value);
        }
        throw new Error(`Value is not numeric : '${value}'`);
    }
}
