import $ from 'jquery';
import Helpers from "../../../../dist/_utils/helpers.js";
import __PROP from "../../utils/_prop.js";
import _Translation from "../../utils/_translation.js";
import DcButton from "../button/dc-button.js";
import DcFn, { fieldListeners } from "../dc-fn.js";
import DcEditorFn from "../editor/dc-editor-fn.js";
import { EnumDcButton } from "../model/dc-enum.js";
import { dcModel, controlModel, cardModel } from "../model/dc-model.js";
import { buttonDefault } from "../model/dc-default.js";
import DcFieldFn from "../editor/field/dc-field-fn.js";
const controlListeners = {
    btn: function (dataCard, editor) {
        let settings = dataCard.settings();
        // let arrayBtn = Helpers._getObjByProp(settings, __PROP.buttons, []);
        let arrayBtn = settings.buttons || [];
        $.each(arrayBtn, function (index, element) {
            if (typeof element === 'object') {
                // let className = Helpers._getObjByProp(element, __PROP.className, "");
                let className = element.className || "";
                if (Helpers._isStringNotEmpty(className)) {
                    let $btn = $('.' + className, '.' + controlModel.controlPanelContainer.attr.class + '.' + dcModel.activeClass);
                    if ($btn.length) {
                        // let action = Helpers._getObjByProp(element, __PROP.action, null);
                        let action = element.action || null;
                        $btn.off('click');
                        if (Helpers._isFunction(action)) {
                            $btn.on('click', function (event) {
                                $(this).tooltip('hide');
                                action(event, dataCard, editor, this);
                            });
                        }
                    }
                }
            }
        });
    },
    generalSearch: function (dataCard) {
        $('.' + controlModel.inputSearch.attr.class, '.' + controlModel.controlPanelContainer.attr.class).off('keyup');
        $('.' + controlModel.inputSearch.attr.class, '.' + controlModel.controlPanelContainer.attr.class + '.' + dcModel.activeClass)
            .on("keyup", function () {
            DcFieldFn._fieldsFilter(dataCard);
        });
    },
    /**
     * select data length
     */
    selectLengthMenu: function (dataCard) {
        $('.' + controlModel.selectLength.attr.class, '.' + controlModel.controlPanelContainer.attr.class).off('change');
        $('.' + controlModel.selectLength.attr.class, '.' + controlModel.controlPanelContainer.attr.class + '.' + dcModel.activeClass)
            .on('change', function () {
            let $this = $(this);
            let selectValue = parseInt($this.val());
            $('option', $this).removeAttr(dcModel.selectedAttr);
            $('option[value=' + selectValue + ']', $this).attr(dcModel.selectedAttr, dcModel.selectedAttr);
            $this.val(selectValue);
            dataCard.dataLength(selectValue).draw();
        });
    },
    /**
     * select page
     */
    pageSelect: function (dataCard) {
        let $pageBtn = $('.' + controlModel.pagination.liBtn.attr.class);
        $pageBtn
            .off("click")
            .on('click', function () {
            let $thisBtn = $(this);
            if (!$thisBtn.hasClass(dcModel.activeClass)) {
                let pageIndex = $thisBtn.attr(dcModel.dataPageIndexAttr);
                if (Helpers._isNumeric(pageIndex)) {
                    dataCard.page(parseInt(pageIndex)).draw();
                }
                // console.log(pageIndex);
            }
        });
        let $pagePrevious = $("#previous").not('.' + dcModel.disabledClass);
        $pagePrevious
            .off("click")
            .on('click', function () {
            let $pageActive = $('.' + controlModel.pagination.liBtn.attr.class + '.' + dcModel.activeClass);
            let activePage = parseInt($pageActive.attr(dcModel.dataPageIndexAttr));
            dataCard.page(activePage - 1).draw();
        });
        let $pageNext = $("#next").not('.' + dcModel.disabledClass);
        $pageNext
            .off("click")
            .on('click', function () {
            let $pageActive = $('.' + controlModel.pagination.liBtn.attr.class + '.' + dcModel.activeClass);
            let activePage = parseInt($pageActive.attr(dcModel.dataPageIndexAttr));
            // console.log({
            //     activePage: activePage,
            //     $pageActive: $pageActive
            // });
            dataCard.page(activePage + 1).draw();
        });
    }
};
export default class DcControlFn {
    /**
     * merge general buttons setting with default options
     * @param {TypeDataCardOptions} mergedSettings
     * @returns {TypeDcButton[]}
     */
    static _mergeButton(mergedSettings) {
        // let arrayButtons = Helpers._getObjByProp(mergedSettings, __PROP.buttons, []);
        let arrayButtons = mergedSettings.buttons;
        DcButton._arrayButtons.forEach(function (cardButtonName) {
            if (!arrayButtons.includes(cardButtonName)) {
                arrayButtons.unshift(cardButtonName);
            }
        });
        let returnButtons = [];
        $.each(arrayButtons, function (index, btnElement) {
            if (typeof btnElement === "string" && Helpers._isSameType(btnElement, "")) {
                let btn = DcControlFn._getBtn(mergedSettings, btnElement);
                if (btn !== null && Helpers._isObject(btn)) {
                    returnButtons.push(btn);
                }
            }
            else if (Helpers._isSameType(btnElement, {})) {
                returnButtons.push($.extend({}, buttonDefault, btnElement));
            }
        });
        return returnButtons;
    }
    /**
     *
     * @param {TypeDataCardOptions} settings
     * @param {string} btnName
     * @returns {TypeDcButton | null}
     */
    static _getBtn(settings, btnName) {
        let i18n = _Translation._get(settings.language);
        let btn = $.extend({}, buttonDefault);
        switch (btnName) {
            case EnumDcButton.create:
                if (settings.create) {
                    btn = $.extend({}, buttonDefault, DcButton.control.create);
                    btn.title = i18n.create.button;
                    return btn;
                }
                break;
            case EnumDcButton.edit:
                if (settings.select && settings.edit) {
                    btn = $.extend({}, buttonDefault, DcButton.control.edit);
                    btn.title = i18n.edit.button;
                    return btn;
                }
                break;
            case EnumDcButton.remove:
                if (settings.select && settings.remove) {
                    btn = $.extend({}, buttonDefault, DcButton.control.remove);
                    btn.title = i18n.remove.button;
                    return btn;
                }
                break;
            case EnumDcButton.selectNone:
                if (settings.select && (settings.edit || settings.remove)) {
                    btn = $.extend({}, buttonDefault, DcButton.control.selectNone);
                    btn.title = i18n._custom.button.selectNone;
                    return btn;
                }
                break;
            case EnumDcButton.selectAll:
                if (settings.select && (settings.edit || settings.remove)) {
                    btn = $.extend({}, buttonDefault, DcButton.control.selectAll);
                    btn.title = i18n._custom.button.selectAll;
                    return btn;
                }
                break;
            case EnumDcButton.edit1:
                if (settings.edit) {
                    btn = $.extend({}, buttonDefault, DcButton.card.edit1);
                    btn.title = i18n.edit.button;
                    return btn;
                }
                break;
            case EnumDcButton.remove1:
                if (settings.remove) {
                    btn = $.extend({}, buttonDefault, DcButton.card.remove1);
                    btn.title = i18n.remove.button;
                    return btn;
                }
                break;
            case EnumDcButton.select1:
                if (settings.select && (settings.edit || settings.remove)) {
                    btn = $.extend({}, buttonDefault, DcButton.card.select1);
                    btn.title = i18n._custom.tooltip.clickToSelect;
                    return btn;
                }
                break;
            case EnumDcButton.reOrder:
                if (DcEditorFn._isRowReorderValid(settings)) {
                    btn = $.extend({}, buttonDefault, DcButton.card.reOrder);
                    btn.title = i18n.reorder.button;
                    return btn;
                }
                break;
        }
        return null;
    }
    /**
     *
     * @param {DataCard} dataCard
     * @param {Editor} editor
     */
    static _fixedControl(dataCard, editor) {
        let settings = dataCard.settings();
        if (settings.fixedControl) {
            let winOffSetY = window.pageYOffset;
            let $controlPanel = $("#" + controlModel.controlPanelContainer.attr.class);
            if ($controlPanel.length) {
                let fixedControlOffSet = Helpers._getObjByArrayProp(settings, [__PROP.fixedControl, __PROP.offSet], 0);
                let elemTop = $controlPanel.offset().top;
                if (winOffSetY > elemTop - fixedControlOffSet) {
                    DcControlFn._displayClone(dataCard, editor);
                }
                else {
                    DcControlFn._hideClone(dataCard, editor);
                }
            }
            let $rowContainer = $('.' + cardModel.rowCardContainer.attr.class);
            if ($rowContainer.length) {
                if (winOffSetY > $rowContainer.height()) {
                    DcControlFn._hideClone(dataCard, editor);
                }
            }
        }
    }
    static _resizeFixedControl(dataCard) {
        let $controlPanel = $("#" + controlModel.controlPanelContainer.attr.class);
        if ($controlPanel.length) {
            let $clone = $('.' + controlModel.clone.attr.class);
            if ($clone.length) {
                let fixedControlOffSet = Helpers._getObjByArrayProp(dataCard.settings(), [__PROP.fixedControl, __PROP.offSet], 0);
                let elemLeft = $controlPanel.offset().left;
                $clone
                    .width($controlPanel.width())
                    .height($controlPanel.height())
                    .css({ top: fixedControlOffSet, left: elemLeft });
                // $clone.css({top: fixedControlOffSet, left: elemLeft} as JQuery.PlainObject);
            }
        }
    }
    static _displayClone(dataCard, editor) {
        let $controlPanel = $("#" + controlModel.controlPanelContainer.attr.class);
        if ($controlPanel.length) {
            let settings = dataCard.settings();
            let $clone = $('.' + controlModel.clone.attr.class);
            if (!$clone.length) {
                // let fixedControlOffSet = Helpers._getObjByArrayProp(settings, [__PROP.fixedControl, __PROP.offSet], 0);
                let fixedControlOffSet = settings.fixedControl.offSet;
                let elemLeft = $controlPanel.offset().left;
                $clone = $controlPanel.clone(true, true);
                $clone
                    .addClass(controlModel.clone.attr.class)
                    .addClass(dcModel.activeClass)
                    .addClass(controlModel.clone.stickyClass)
                    .removeAttr('id')
                    .css({ top: fixedControlOffSet, left: elemLeft })
                    .width($controlPanel.outerWidth())
                    .height($controlPanel.outerHeight())
                    .appendTo("body");
                let focusIndex;
                let $focus = $('.form-control', $controlPanel);
                let tmpVal;
                if ($focus.length) {
                    $focus.each(function (index, element) {
                        let $this = $(element);
                        if ($this.is(':focus')) {
                            focusIndex = index;
                            tmpVal = $this.val();
                        }
                    });
                }
                $controlPanel.removeClass(dcModel.activeClass);
                if (typeof focusIndex === "number" && Helpers._isSameType(focusIndex, 1)) {
                    let $focus2 = $('.form-control', $clone);
                    $focus2.each(function (index, element) {
                        if (index === focusIndex) {
                            let $this = $(element);
                            $this.val("").focus().val(tmpVal);
                        }
                    });
                }
            }
        }
        DcFn.initListener(dataCard, editor, fieldListeners);
        DcFn.initListener(dataCard, editor, controlListeners);
    }
    static _hideClone(dataCard, editor) {
        let $controlPanel = $("#" + controlModel.controlPanelContainer.attr.class);
        let $clone = $('.' + controlModel.clone.attr.class);
        if ($clone.length && $controlPanel.length) {
            let $replace = $clone.clone(true, true);
            $replace
                .removeClass(controlModel.clone.attr.class)
                .addClass(controlModel.controlPanelContainer.attr.class)
                .removeClass(controlModel.clone.stickyClass)
                .removeAttr("style");
            $controlPanel.replaceWith($replace);
            $replace.attr('id', controlModel.controlPanelContainer.attr.class);
            let focusIndex;
            let $focus = $('.form-control', $clone);
            let tmpVal = null;
            if ($focus.length) {
                $focus.each(function (index, element) {
                    let $this = $(element);
                    if ($this.is(':focus')) {
                        focusIndex = index;
                        tmpVal = $this.val() || "";
                    }
                });
            }
            DcFn.initListener(dataCard, editor, fieldListeners);
            DcFn.initListener(dataCard, editor, controlListeners);
            if (typeof focusIndex === "string" && typeof tmpVal === "string" && Helpers._isSameType(focusIndex, 1)) {
                let $focus2 = $('.form-control', $replace);
                $focus2.each(function (index, element) {
                    if (index === focusIndex) {
                        let $this = $(element);
                        $this.val("");
                        $this.focus();
                        $this.val(tmpVal);
                    }
                });
            }
            $clone.detach();
        }
    }
}
DcControlFn.debug = false;
export { controlListeners };
