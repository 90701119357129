import $ from 'jquery';
import Helpers from "../../../../../dist/_utils/helpers.js";
import __PROP from "../../../utils/_prop.js";
import DcButton from "../../button/dc-button.js";
import DcControlFn from "../../control/dc-control-fn.js";
import DcFieldFn from "../../editor/field/dc-field-fn.js";
import { buttonDefault, fieldDefault, multiFieldDefault, orderingDefault, itemSrcDefault, dcDefaultsOptions } from "../../model/dc-default.js";
import { dcModel, cardModel } from "../../model/dc-model.js";
import { EnumColumnType } from "../../../data-table/model/dt-enum.js";
export default class DcCardFn {
    static _closeAllTooltip() {
        return Promise.resolve().then(() => {
            // const $tooltips = $('[data-toggle=tooltip]');
            const $tooltips = $('.tooltip');
            // console.log({$tooltips})
            return $tooltips.tooltip('hide');
        });
    }
    static _getRandomId(length) {
        if (length === undefined || length < 8) {
            length = 8;
        }
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
    static _mergeSetting(settings, dataCard) {
        let mergedSettings = $.extend(true, {}, dcDefaultsOptions, settings), arraySettingsFields = mergedSettings.fields, ordering = mergedSettings.ordering, 
        // dataSrc = Helpers._getObjByProp(mergedSettings, __PROP.dataSrc, "")
        dataSrc = mergedSettings.dataSrc;
        // console.log({settings, mergedSettings, arraySettingsFields, ordering, dataSrc});
        mergedSettings.fields = DcCardFn._mergeField(mergedSettings, arraySettingsFields, dataCard);
        mergedSettings.ordering = DcCardFn._mergeOrdering(mergedSettings, ordering);
        if (Helpers._isStringNotEmpty(mergedSettings.rowReorder)) {
            let rowReorderSrc = mergedSettings.rowReorder;
            if (dataSrc !== "") {
                rowReorderSrc = rowReorderSrc.includes(dataSrc) ? rowReorderSrc : dataSrc + "." + rowReorderSrc;
            }
            mergedSettings.rowReorder = rowReorderSrc;
        }
        mergedSettings.multiFields = DcCardFn._mergeMultiField(mergedSettings, dataCard);
        mergedSettings.buttons = DcControlFn._mergeButton(mergedSettings);
        mergedSettings.cardButtons = DcCardFn._mergeCardButtons(mergedSettings);
        return mergedSettings;
    }
    //TODO Verify
    static _mergeCardButtons(mergedSettings) {
        // let arrayButtons = Helpers._getObjByProp(mergedSettings, __PROP.cardButtons, []);
        let arrayButtons = mergedSettings.cardButtons;
        DcButton._arrayCardButtons.forEach(function (cardButtonName) {
            if (!arrayButtons.includes(cardButtonName)) {
                arrayButtons.unshift(cardButtonName);
            }
        });
        // console.log({arrayButtons})
        let returnButtons = [];
        $.each(arrayButtons, function (index, btnElement) {
            if (typeof btnElement === 'string') {
                let btn = DcControlFn._getBtn(mergedSettings, btnElement);
                if (btn && typeof btn === 'object') {
                    returnButtons.push($.extend({}, buttonDefault, btn));
                }
            }
            else if (typeof btnElement === 'object') {
                returnButtons.push($.extend({}, buttonDefault, btnElement));
            }
        });
        return returnButtons;
    }
    //TODO Verify
    static _mergeField(mergedSettings, arrayFields, dataCard) {
        let returnFields = [], 
        // dataSrc = Helpers._getObjByProp(mergedSettings, __PROP.dataSrc, ""),
        dataSrc = mergedSettings.dataSrc;
        let fnCaller = Helpers._getCallerName(arguments);
        // let fieldGroup = Helpers._getObjByProp(mergedSettings, __PROP.fieldGroup, "");
        let fieldGroup = mergedSettings.fieldGroup;
        $.each(arrayFields, function (index, field) {
            field = $.extend(true, {}, fieldDefault, field);
            if (!Helpers._isFunction(field.render)) {
                field.render = DcFieldFn._getFieldRender(field.type, field.options, dataCard, field);
            }
            if (!DcCardFn._sortableType.includes(field.type)) {
                field.sort = false;
            }
            if (!DcCardFn._searchableType.includes(field.type)) {
                field.search = false;
            }
            field.labelText = field.label;
            let labelHelper = $('<span/>').addClass('dc-label-helper');
            if (Helpers._isStringNotEmpty(field.labelHelper)) {
                labelHelper.attr({
                    'data-bs-toggle': 'tooltip',
                    'data-bs-placement': "right",
                    'data-html': 'true',
                    'title': field.labelHelper
                });
                labelHelper.prepend("<i class='fa fa-question-circle'></i>");
            }
            field.label = labelHelper[0].outerHTML + field.label;
            field.indexes = [];
            if (Helpers._isStringNotEmpty(field.name)) {
                let isGroup = field._isGroup = Helpers._isStringNotEmpty(fieldGroup) && fieldGroup === field.name;
                field.indexes = field.name.split('.');
                if (Helpers._isStringNotEmpty(dataSrc) && (!field.name.includes(dataSrc + ".") || field.indexes.length === 1)) {
                    field.name = dataSrc + "." + field.name;
                    field.indexes = field.name.split('.');
                }
                if (!isGroup) {
                    field._isGroup = Helpers._isStringNotEmpty(fieldGroup) && fieldGroup === field.name;
                }
            }
            let uniqueId = DcCardFn._getRandomId();
            while (mergedSettings._ids.includes(uniqueId)) {
                uniqueId = DcCardFn._getRandomId();
            }
            mergedSettings._ids.push(uniqueId);
            field._uniqueId = uniqueId;
            returnFields[index] = field;
        });
        // console.log({mergedSettings, returnFields})
        return returnFields;
    }
    /**
     * merge the multiField setting with default options
     * @param mergedSettings
     * @param orderings
     * @returns {*}
     * @private
     */
    //TODO Verify
    static _mergeOrdering(mergedSettings, orderings) {
        let returnOrdering = [];
        $.each(orderings, function (index, order) {
            order = $.extend({}, orderingDefault, order);
            returnOrdering[index] = order;
        });
        return returnOrdering;
    }
    //TODO Verify
    static _mergeMultiField(mergedSettings, dataCard) {
        // let multiField = Helpers._getObjByProp<TypeMultiFieldSettings>(mergedSettings, __PROP.multiFields, {});
        let multiField = mergedSettings.multiFields;
        const editorModalClass = mergedSettings.editorModalClass || "";
        let dataSrc = multiField.dataSrc;
        if (Helpers._isStringNotEmpty(dataSrc) && multiField.hasOwnProperty(__PROP.itemSrc)) {
            let itemSrc = multiField.itemSrc;
            // console.log({itemSrc, dataSrc})
            if (itemSrc.length > 1) {
                mergedSettings.editorModalClass = `modal-xl ${editorModalClass}`;
            }
            // let fieldsMulti = Helpers._getObjByProp(multiField, __PROP.fields, []);
            let fieldsMulti = multiField.fields;
            if (typeof fieldsMulti === typeof []) {
                let arrayItemSrc = [];
                let isSearchable = false;
                let isSortable = false;
                let isVisible = false;
                $.each(itemSrc, function (index, item) {
                    item = $.extend(true, {}, itemSrcDefault, item);
                    if (item.search) {
                        isSearchable = true;
                    }
                    if (item.sort) {
                        isSortable = true;
                    }
                    if (item.visible) {
                        isVisible = true;
                    }
                    if (Helpers._isStringNotEmpty(item.data)) {
                        arrayItemSrc.push(item);
                    }
                });
                multiField.itemSrc = arrayItemSrc;
                if (Helpers._isSameType(fieldsMulti, [])) {
                    let arrayFieldsMulti = [];
                    // let fieldGroup = Helpers._getObjByProp(mergedSettings, __PROP.fieldGroup, "");
                    let fieldGroup = mergedSettings.fieldGroup || "";
                    $.each(fieldsMulti, function (index, fieldMulti) {
                        fieldMulti.indexes = [];
                        // if(Helpers._isStringNotEmpty(fieldMulti.name)){
                        if (Helpers._isStringNotEmpty(fieldMulti.name)) {
                            // let isGroup = fieldMulti[__PROP.fieldItemOptions._isGroup] = Helpers._isStringNotEmpty(fieldGroup) && fieldGroup === fieldMulti.name;
                            let isGroup = fieldMulti._isGroup = Helpers._isStringNotEmpty(fieldGroup) && fieldGroup === fieldMulti.name;
                            fieldMulti = $.extend(true, {}, fieldDefault, fieldMulti);
                            if (!Helpers._isFunction(fieldMulti.render)) {
                                fieldMulti.render = DcFieldFn._getFieldRender(fieldMulti.type, fieldMulti.options, dataCard, fieldMulti);
                            }
                            fieldMulti.indexes = fieldMulti.name.split('.');
                            if (!isGroup) {
                                fieldMulti._isGroup = Helpers._isStringNotEmpty(fieldGroup) && fieldGroup === fieldMulti.name;
                            }
                        }
                        if (!DcCardFn._sortableType.includes(fieldMulti.type)) {
                            fieldMulti.sort = false;
                        }
                        if (!DcCardFn._searchableType.includes(fieldMulti.type)) {
                            fieldMulti.search = false;
                        }
                        fieldMulti.sort = (fieldMulti.sort && isSortable);
                        fieldMulti.search = (fieldMulti.search && isSearchable);
                        fieldMulti.visible = (fieldMulti.visible && isVisible);
                        fieldMulti._isMultiField = true;
                        let uniqueId = DcCardFn._getRandomId();
                        while (mergedSettings._ids.includes(uniqueId)) {
                            uniqueId = DcCardFn._getRandomId();
                        }
                        fieldMulti.labelText = fieldMulti.label;
                        let labelHelper = $('<span/>').addClass('dc-label-helper');
                        if (Helpers._isStringNotEmpty(fieldMulti.labelHelper)) {
                            labelHelper.attr({
                                'data-bs-toggle': 'tooltip',
                                'data-bs-placement': "right",
                                'data-html': 'true',
                                'title': fieldMulti.labelHelper
                            });
                            labelHelper.prepend("<i class='fa fa-question-circle'></i>");
                        }
                        fieldMulti.label = labelHelper[0].outerHTML + fieldMulti.label;
                        mergedSettings._ids.push(uniqueId);
                        fieldMulti._uniqueId = uniqueId;
                        arrayFieldsMulti.push(fieldMulti);
                    });
                    multiField.fields = arrayFieldsMulti;
                }
            }
        }
        else {
            multiField.itemSrc = [];
            multiField.fields = [];
        }
        multiField = $.extend({}, multiFieldDefault, multiField);
        return multiField;
    }
    /**
     * Get card element from id
     * @param {string} id
     * @param {DataCard} dataCard
     * @returns {any}
     */
    static _getCardById(id, dataCard) {
        let $card = null;
        if (Helpers._isNotUndefined(id)) {
            $card = $(`[${dcModel.dataEditorIdAttr}=${id}]`, dataCard.settings().jquerySelector);
        }
        return $card;
    }
    //TODO Verify
    static _animateCard($card, callback) {
        if ($card && $card.length) {
            let $item = $(".dc-card-container ", $card);
            $item.addClass(dcModel.highlightClass);
            setTimeout(function () {
                $item.removeClass(dcModel.highlightClass);
                if (typeof callback === 'function') {
                    callback();
                }
            }, 300);
        }
    }
    /**
     * get all the selected card from the panel
     * @param jquerySelector
     * @returns {*|HTMLElement}
     * @private
     */
    static _getSelectedCards(jquerySelector) {
        return $('.' + cardModel.item.attr.class + '.' + dcModel.selectedClass, jquerySelector);
    }
    /**
     *
     * @param {number} itemPerRow
     * @returns {string}
     */
    static _getBsColClass(itemPerRow) {
        itemPerRow = Helpers._isInteger(itemPerRow) && itemPerRow >= 1 && itemPerRow < 12 ? itemPerRow : dcDefaultsOptions.columnDisplay;
        itemPerRow = Math.round(12 / itemPerRow);
        let colClass = ' col-', xsClass = colClass + 'sm-', smClass = colClass + 'sm-', mdClass = colClass + 'md-', lgClass = colClass + 'lg-', xlClass = colClass + 'xl-';
        return xsClass + 12 + smClass + 6 + mdClass + itemPerRow + lgClass + itemPerRow + xlClass + itemPerRow;
    }
    /**
     *
     * @param {number} itemPerRow
     * @returns {string}
     */
    static _getBsRowColsClass(itemPerRow) {
        itemPerRow = Helpers._isInteger(itemPerRow) && itemPerRow >= 1 && itemPerRow < 12 ? itemPerRow : dcDefaultsOptions.columnDisplay;
        itemPerRow = Math.round(12 / itemPerRow);
        let colClass = ' row-cols-', xsClass = colClass + 'sm-', smClass = colClass + 'sm-', mdClass = colClass + 'md-', lgClass = colClass + 'lg-', xlClass = colClass + 'xl-';
        return xsClass + 1 + smClass + itemPerRow + mdClass + itemPerRow + lgClass + itemPerRow + xlClass + itemPerRow;
    }
}
DcCardFn._searchableType = [
    EnumColumnType.boolean,
    EnumColumnType.select,
    EnumColumnType.date,
    EnumColumnType.time,
    EnumColumnType.datetime,
    EnumColumnType.checkbox,
    EnumColumnType.integer,
    EnumColumnType.price,
    EnumColumnType.daterange,
    EnumColumnType.float,
    EnumColumnType.textarea,
    EnumColumnType.text
];
DcCardFn._sortableType = [
    EnumColumnType.boolean,
    EnumColumnType.select,
    EnumColumnType.date,
    EnumColumnType.time,
    EnumColumnType.datetime,
    EnumColumnType.checkbox,
    EnumColumnType.integer,
    EnumColumnType.price,
    EnumColumnType.daterange,
    EnumColumnType.float,
    EnumColumnType.textarea,
    EnumColumnType.text
];
