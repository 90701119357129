import $ from 'jquery';
import Helpers from "../../../../dist/_utils/helpers.js";
import DcCardFn from "../grid/card/dc-card-fn.js";
import { generalListeners } from "../dc-fn.js";
import { EnumDcButton } from "../model/dc-enum.js";
import { cardModel, controlModel, dcModel } from "../model/dc-model.js";
import DcPaginationFn from "../grid/pagination/dc-pagination-fn.js";
const debug = false;
// const controlButtons : TypeButtonsObject = {
//
//     create: {
//         name: EnumDcButton.create,
//         title: "",
//         className: controlModel.btn.create.attr.class,
//         icon: '<i class="fa fa-plus"></i>',
//         action: function (event, dataCard, editor) {
//             const settings = dataCard.settings();
//             // let arrayFormBtn = Helpers._getObjByProp(settings, __PROP.formButtons, []);
//             let arrayFormBtn = settings.formButtons;
//             arrayFormBtn = arrayFormBtn.slice();
//             let translation = dataCard.i18n();
//             let formCreateBtn = DcButton.form.create;
//             arrayFormBtn.push(formCreateBtn);
//             formCreateBtn.text = translation.create.button;
//             editor.title(translation.create.title)
//             editor.buttons(arrayFormBtn)
//             // @ts-ignore
//             editor.create();
//
//
//         }
//     },
//     edit: {
//         name: EnumDcButton.edit,
//         title: "",
//         className: controlModel.btn.edit.attr.class,
//         icon: '<i class="fas fa-edit"></i>',
//         action: function (event, dataCard, editor) {
//
//             let i18n = dataCard.i18n();
//             let settings = dataCard.settings();
//             let arrayId : string[] = [];
//             let arrayTitle : string[] = [];
//             // let $selectedCard = DcCardFn._getSelectedCards(settings[__PROP.jquerySelector]);
//             let $selectedCard = DcCardFn._getSelectedCards(settings.jquerySelector!);
//             $selectedCard.each(function () {
//                 let
//                     $elem = $(this),
//                     id = $elem.attr(dcModel.dataEditorIdAttr),
//                     title = $elem.attr(dcModel.dataTitleSrcAttr)
//                 ;
//                 if (Helpers._isStringNotEmpty(title)) {
//                     title = "'" + title + "'";
//                     arrayTitle.push(title);
//                 }
//
//                 if (Helpers._isStringNotEmpty(id)) {
//                     arrayId.push(id!);
//                 }
//             });
//
//             if (debug || true) {
//                 console.log({
//                     selectedCard: $selectedCard,
//                     arrayId: arrayId,
//                     arrayTitle: arrayTitle
//                 });
//             }
//
//             if (arrayId.length > 0) {
//                 let title = i18n.edit.title;
//                 if (arrayId.length === arrayTitle.length) {
//                     title += ' : ' + arrayTitle.join(', ');
//                 }
//                 // let arrayFormBtn = Helpers._getObjByProp<TypeDcButton[]>(dataCard.settings(), __PROP.formButtons, []);
//                 let arrayFormBtn = settings.formButtons;
//                 arrayFormBtn = arrayFormBtn.slice();
//                 let formEditBtn = DcButton.form.edit;
//                 arrayFormBtn.push(formEditBtn);
//                 formEditBtn.text = i18n.edit.button;
//                 // editor
//                 //     .title(title)
//                 //     .buttons(arrayFormBtn)
//                 //     .edit(arrayId);
//                 editor
//                     .title(title)
//                 editor.buttons(arrayFormBtn)
//                 // @ts-ignore
//                 editor.edit(arrayId);
//                 // let arrayFormBtn = Helpers._getObjByProp(dataCard.settings, __PROP.formButtons, []);
//                 // arrayFormBtn = arrayFormBtn.slice();
//                 // let formEditBtn = DcButton.form.edit;
//                 // arrayFormBtn.push(formEditBtn);
//                 // formEditBtn.text = i18n.edit.button;
//                 // editor
//                 //     .title(title)
//                 //     .buttons(arrayFormBtn)
//                 //     .edit(dataId);
//
//             }
//         }
//     },
//     selectAll: {
//         name: EnumDcButton.selectAll,
//         title: "",
//         className: controlModel.btn.selectAll.attr.class,
//         icon: '<i class="fas fa-check-double fa-inverse"></i>',
//         action: function (event, dataCard) {
//             let settings = dataCard.settings();
//             let $cards = $("." + cardModel.item.attr.class, settings.jquerySelector);
//             if ($cards !== null && $cards instanceof jQuery) {
//                 $cards.addClass(dcModel.selectedClass);
//                 generalListeners.enableDisableBtn(dataCard);
//             }
//             DcPaginationFn._updateSelectedItemInfo(dataCard);
//         }
//     },
//     selectNone: {
//         name: EnumDcButton.selectNone,
//         title: "",
//         className: controlModel.btn.selectNone.attr.class,
//         icon: '<i class="far fa-square"></i>',
//         action: function (event, dataCard) {
//             let settings = dataCard.settings();
//             let $cards = $("." + cardModel.item.attr.class, settings.jquerySelector);
//             if ($cards !== null && $cards instanceof jQuery) {
//                 $cards.removeClass(dcModel.selectedClass);
//                 generalListeners.enableDisableBtn(dataCard);
//             }
//             DcPaginationFn._updateSelectedItemInfo(dataCard);
//         }
//     },
//     remove: {
//         name: EnumDcButton.remove,
//         title: "",
//         className: controlModel.btn.remove.attr.class,
//         icon: '<i class="far fa-trash-alt"></i>',
//         action: function (event, dataCard, editor) {
//             let i18n = dataCard.i18n();
//             let settings = dataCard.settings();
//             let
//                 arrayId : string[] = [],
//                 arrayTitle : string[] = [],
//                 $selectedCard = DcCardFn._getSelectedCards(settings.jquerySelector!)
//             ;
//             $selectedCard.each(function (index, element) {
//                 let
//                     $elem = $(element),
//                     id = $elem.attr(dcModel.dataEditorIdAttr),
//                     title = $elem.attr(dcModel.dataTitleSrcAttr)
//                 ;
//
//                 if (Helpers._isStringNotEmpty(title)) {
//                     title = "'" + title + "'";
//                     arrayTitle.push(title);
//                 }
//                 if (Helpers._isStringNotEmpty(id)) {
//                     arrayId.push(id!);
//                 }
//             });
//
//             if (debug) {
//                 console.log({
//                     selectedCard: $selectedCard,
//                     arrayId: arrayId,
//                     arrayTitle: arrayTitle
//                 });
//             }
//
//             if (arrayId.length > 0) {
//                 let title = i18n.remove.title;
//                 if (arrayId.length === arrayTitle.length) {
//                     title += ' : ' + arrayTitle.join(', ');
//                 }
//                 editor
//                     .title(title)
//                 editor.buttons(i18n.remove.button)
//                 editor.message(i18n.remove.confirm["_"].replace("%d", arrayId.length.toString()))
//                 // @ts-ignore
//                 editor.remove(arrayId);
//
//             }
//         }
//     }
// };
// const cardButtons : {[key : string] : TypeDcButton} = {
//     select1: {
//         name: EnumDcButton.select1,
//         title: "",
//         className: controlModel.btn.select.attr.class,
//         icon: '<i class="fas fa-check"></i>',
//         action: function (event, dataCard, editor, node, dataId) {
//             let i18n = dataCard.i18n();
//             let $element = $(node).closest("." + cardModel.item.attr.class);
//             $element.toggleClass(dcModel.selectedClass);
//             let title = i18n._custom.tooltip.clickToSelect;
//             if ($element.hasClass(dcModel.selectedClass)) {
//                 title = i18n._custom.tooltip.clickToUnSelect;
//             }
//             $(node).tooltip("hide")
//                 .attr('data-title', title)
//                 .tooltip('show')
//             ;
//
//             generalListeners.enableDisableBtn(dataCard);
//             DcPaginationFn._updateSelectedItemInfo(dataCard);
//         }
//     },
//     edit1: {
//         name: EnumDcButton.edit1,
//         title: "",
//         className: controlModel.btn.edit.attr.class,
//         icon: '<i class="fas fa-edit"></i>',
//         action: function (event, dataCard, editor, node : Element, dataId, rowData) {
//             // console.log({arguments})
//             let
//                 i18n = dataCard.i18n(),
//                 $elem = $(node)
//             ;
//             if (Helpers._isStringNotEmpty(dataId)) {
//                 let
//                     title = i18n.edit.title,
//                     elementTitle = $elem.closest('.' + cardModel.item.attr.class).attr(dcModel.dataTitleSrcAttr)
//                 ;
//                 if (Helpers._isStringNotEmpty(elementTitle)) {
//                     title = title + " : " + elementTitle;
//                 }
//                 // let arrayFormBtn = Helpers._getObjByProp(dataCard.settings(), __PROP.formButtons, []);
//                 let arrayFormBtn = dataCard.settings().formButtons;
//                 arrayFormBtn = arrayFormBtn.slice();
//                 let formEditBtn = DcButton.form.edit;
//                 arrayFormBtn.push(formEditBtn);
//                 formEditBtn.text = i18n.edit.button;
//                 console.log({dataId, title, arrayFormBtn})
//                 // editor.title(title)
//                 // editor.buttons(arrayFormBtn)
//                 // @ts-ignore
//                 editor.edit(dataId, title, arrayFormBtn);
//             }
//         }
//     },
//     remove1: {
//         name: EnumDcButton.remove1,
//         title: "",
//         className: controlModel.btn.remove.attr.class,
//         icon: '<i class="far fa-trash-alt"></i>',
//         action: function (event, dataCard, editor, node, dataId ) {
//             event.stopPropagation();
//             let i18n = dataCard.i18n();
//             let $elem = $(node);
//
//             if (Helpers._isStringNotEmpty(dataId)) {
//                 let title = i18n.remove.title;
//                 let elementTitle = $elem.closest('.' + cardModel.item.attr.class).attr(dcModel.dataTitleSrcAttr);
//                 if (Helpers._isStringNotEmpty(elementTitle)) {
//                     title = title + " : " + elementTitle;
//                 }
//                 editor
//                     .title(title)
//                 editor.buttons(i18n.remove.button)
//                 editor.message(i18n.remove.confirm["1"])
//                 // @ts-ignore
//                 editor.remove(dataId)
//                 ;
//             }
//         }
//     },
//     reOrder: {
//         name: EnumDcButton.reOrder,
//         title: "",
//         className: controlModel.btn.reOrder.attr.class,
//         icon: '<i class="fas fa-arrows-alt"></i>',
//         action: null
//     }
// };
const formButton /*: {[key : string] : TypeFormButtons} */ = {
    create: {
        text: "create",
        action: function () {
            this.submit();
        }
    },
    edit: {
        text: "",
        action: function () {
            this.submit();
        }
    },
    //TODO see this action what is does
    preview: {
        text: "",
        action: function () {
            const editor = this;
            let arrFields = editor.fields();
            let arrFieldsVal = editor.val(arrFields.toString());
            // console.log({
            //     arguments: arguments,
            //     _this: this,
            //     arrFields: arrFields,
            //     arrFieldsVal: arrFieldsVal
            // });
            this.close();
            setTimeout(function () {
                editor.open();
            }, 3000);
        }
    }
};
export default class DcButton {
}
/**
 *
 * @type {EnumDcButton[]}
 */
DcButton._arrayButtons = [
    EnumDcButton.selectAll, EnumDcButton.selectNone, EnumDcButton.remove, EnumDcButton.edit, EnumDcButton.create
];
/**
 *
 * @type {EnumDcButton[]}
 */
DcButton._arrayCardButtons = [
    EnumDcButton.remove1, EnumDcButton.edit1, EnumDcButton.reOrder, EnumDcButton.select1
];
/**
 *
 * @type {{[p: string]: TypeDcButton}}
 */
DcButton.control = {
    create: {
        name: EnumDcButton.create,
        title: "",
        className: controlModel.btn.create.attr.class,
        icon: '<i class="fa fa-plus"></i>',
        action: function (event, dataCard, editor) {
            const settings = dataCard.settings();
            // let arrayFormBtn = Helpers._getObjByProp(settings, __PROP.formButtons, []);
            let arrayFormBtn = settings.formButtons;
            arrayFormBtn = arrayFormBtn.slice();
            let translation = dataCard.i18n();
            let formCreateBtn = DcButton.form.create;
            arrayFormBtn.push(formCreateBtn);
            formCreateBtn.text = translation.create.button;
            editor.title(translation.create.title);
            editor.buttons(arrayFormBtn);
            // @ts-ignore
            editor.create();
        }
    },
    edit: {
        name: EnumDcButton.edit,
        title: "",
        className: controlModel.btn.edit.attr.class,
        icon: '<i class="fas fa-edit"></i>',
        action: function (event, dataCard, editor) {
            let i18n = dataCard.i18n();
            let settings = dataCard.settings();
            let arrayId = [];
            let arrayTitle = [];
            // let $selectedCard = DcCardFn._getSelectedCards(settings[__PROP.jquerySelector]);
            let $selectedCard = DcCardFn._getSelectedCards(settings.jquerySelector);
            $selectedCard.each(function () {
                let $elem = $(this), id = $elem.attr(dcModel.dataEditorIdAttr), title = $elem.attr(dcModel.dataTitleSrcAttr);
                if (Helpers._isStringNotEmpty(title)) {
                    title = "'" + title + "'";
                    arrayTitle.push(title);
                }
                if (Helpers._isStringNotEmpty(id)) {
                    arrayId.push(id);
                }
            });
            if (debug || true) {
                console.log({
                    selectedCard: $selectedCard,
                    arrayId: arrayId,
                    arrayTitle: arrayTitle
                });
            }
            if (arrayId.length > 0) {
                let title = i18n.edit.title;
                if (arrayId.length === arrayTitle.length) {
                    title += ' : ' + arrayTitle.join(', ');
                }
                // let arrayFormBtn = Helpers._getObjByProp<TypeDcButton[]>(dataCard.settings(), __PROP.formButtons, []);
                let arrayFormBtn = settings.formButtons;
                arrayFormBtn = arrayFormBtn.slice();
                let formEditBtn = DcButton.form.edit;
                arrayFormBtn.push(formEditBtn);
                formEditBtn.text = i18n.edit.button;
                // editor
                //     .title(title)
                //     .buttons(arrayFormBtn)
                //     .edit(arrayId);
                editor
                    .title(title);
                editor.buttons(arrayFormBtn);
                // @ts-ignore
                editor.edit(arrayId);
                // let arrayFormBtn = Helpers._getObjByProp(dataCard.settings, __PROP.formButtons, []);
                // arrayFormBtn = arrayFormBtn.slice();
                // let formEditBtn = DcButton.form.edit;
                // arrayFormBtn.push(formEditBtn);
                // formEditBtn.text = i18n.edit.button;
                // editor
                //     .title(title)
                //     .buttons(arrayFormBtn)
                //     .edit(dataId);
            }
        }
    },
    selectAll: {
        name: EnumDcButton.selectAll,
        title: "",
        className: controlModel.btn.selectAll.attr.class,
        icon: '<i class="fas fa-check-double fa-inverse"></i>',
        action: function (event, dataCard) {
            let settings = dataCard.settings();
            let $cards = $("." + cardModel.item.attr.class, settings.jquerySelector);
            if ($cards !== null && $cards instanceof jQuery) {
                $cards.addClass(dcModel.selectedClass);
                generalListeners.enableDisableBtn(dataCard);
            }
            DcPaginationFn._updateSelectedItemInfo(dataCard);
        }
    },
    selectNone: {
        name: EnumDcButton.selectNone,
        title: "",
        className: controlModel.btn.selectNone.attr.class,
        icon: '<i class="far fa-square"></i>',
        action: function (event, dataCard) {
            let settings = dataCard.settings();
            let $cards = $("." + cardModel.item.attr.class, settings.jquerySelector);
            if ($cards !== null && $cards instanceof jQuery) {
                $cards.removeClass(dcModel.selectedClass);
                generalListeners.enableDisableBtn(dataCard);
            }
            DcPaginationFn._updateSelectedItemInfo(dataCard);
        }
    },
    remove: {
        name: EnumDcButton.remove,
        title: "",
        className: controlModel.btn.remove.attr.class,
        icon: '<i class="far fa-trash-alt"></i>',
        action: function (event, dataCard, editor) {
            let i18n = dataCard.i18n();
            let settings = dataCard.settings();
            let arrayId = [], arrayTitle = [], $selectedCard = DcCardFn._getSelectedCards(settings.jquerySelector);
            $selectedCard.each(function (index, element) {
                let $elem = $(element), id = $elem.attr(dcModel.dataEditorIdAttr), title = $elem.attr(dcModel.dataTitleSrcAttr);
                if (Helpers._isStringNotEmpty(title)) {
                    title = "'" + title + "'";
                    arrayTitle.push(title);
                }
                if (Helpers._isStringNotEmpty(id)) {
                    arrayId.push(id);
                }
            });
            if (debug) {
                console.log({
                    selectedCard: $selectedCard,
                    arrayId: arrayId,
                    arrayTitle: arrayTitle
                });
            }
            if (arrayId.length > 0) {
                let title = i18n.remove.title;
                if (arrayId.length === arrayTitle.length) {
                    title += ' : ' + arrayTitle.join(', ');
                }
                editor
                    .title(title);
                editor.buttons(i18n.remove.button);
                editor.message(i18n.remove.confirm["_"].replace("%d", arrayId.length.toString()));
                // @ts-ignore
                editor.remove(arrayId);
            }
        }
    }
};
/**
 *
 * @type {{[p: string]: TypeDcButton}}
 */
DcButton.card = {
    select1: {
        name: EnumDcButton.select1,
        title: "",
        className: controlModel.btn.select.attr.class,
        icon: '<i class="fas fa-check"></i>',
        action: function (event, dataCard, editor, node, dataId) {
            let i18n = dataCard.i18n();
            let $element = $(node).closest("." + cardModel.item.attr.class);
            $element.toggleClass(dcModel.selectedClass);
            let title = i18n._custom.tooltip.clickToSelect;
            if ($element.hasClass(dcModel.selectedClass)) {
                title = i18n._custom.tooltip.clickToUnSelect;
            }
            $(node).tooltip("hide")
                .attr('data-title', title)
                .tooltip('show');
            generalListeners.enableDisableBtn(dataCard);
            DcPaginationFn._updateSelectedItemInfo(dataCard);
        }
    },
    edit1: {
        name: EnumDcButton.edit1,
        title: "",
        className: controlModel.btn.edit.attr.class,
        icon: '<i class="fas fa-edit"></i>',
        action: function (event, dataCard, editor, node, dataId, rowData) {
            // console.log({arguments})
            let i18n = dataCard.i18n(), $elem = $(node);
            if (Helpers._isStringNotEmpty(dataId)) {
                let title = i18n.edit.title, elementTitle = $elem.closest('.' + cardModel.item.attr.class).attr(dcModel.dataTitleSrcAttr);
                if (Helpers._isStringNotEmpty(elementTitle)) {
                    title = title + " : " + elementTitle;
                }
                // let arrayFormBtn = Helpers._getObjByProp(dataCard.settings(), __PROP.formButtons, []);
                let arrayFormBtn = dataCard.settings().formButtons;
                arrayFormBtn = arrayFormBtn.slice();
                let formEditBtn = DcButton.form.edit;
                arrayFormBtn.push(formEditBtn);
                formEditBtn.text = i18n.edit.button;
                console.log({ dataId, title, arrayFormBtn });
                // editor.title(title)
                // editor.buttons(arrayFormBtn)
                // @ts-ignore
                editor.edit(dataId, title, arrayFormBtn);
            }
        }
    },
    remove1: {
        name: EnumDcButton.remove1,
        title: "",
        className: controlModel.btn.remove.attr.class,
        icon: '<i class="far fa-trash-alt"></i>',
        action: function (event, dataCard, editor, node, dataId) {
            event.stopPropagation();
            let i18n = dataCard.i18n();
            let $elem = $(node);
            if (Helpers._isStringNotEmpty(dataId)) {
                let title = i18n.remove.title;
                let elementTitle = $elem.closest('.' + cardModel.item.attr.class).attr(dcModel.dataTitleSrcAttr);
                if (Helpers._isStringNotEmpty(elementTitle)) {
                    title = title + " : " + elementTitle;
                }
                editor
                    .title(title);
                editor.buttons(i18n.remove.button);
                editor.message(i18n.remove.confirm["1"]);
                // @ts-ignore
                editor.remove(dataId);
            }
        }
    },
    reOrder: {
        name: EnumDcButton.reOrder,
        title: "",
        className: controlModel.btn.reOrder.attr.class,
        icon: '<i class="fas fa-arrows-alt"></i>',
        action: null
    }
};
/**
 *
 * @type {{preview: TypeFormButtons, edit: TypeFormButtons, create: TypeFormButtons}}
 */
DcButton.form = formButton;
