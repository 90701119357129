import $ from 'jquery';
import Helpers from "../../../../dist/_utils/helpers.js";
import JqueryHelpers from "../../utils/jquery-helpers.js";
import DcCardFn from "../grid/card/dc-card-fn.js";
import { dcEditorModel, dcModel, fieldModel } from "../model/dc-model.js";
import "../styles/scss/datacard/editor/editor.css";
export default class DcEditorRender {
    /**
     * create a custom template for the editor
     * @method _createEditorTemplate
     * @param settings
     * @static
     */
    static _createEditorTemplate(settings) {
        // let templateSelector = Helpers._getObjByProp(settings, __PROP.editorTemplate, StDataCardDefaultsOptions.editorTemplate);
        let templateSelector = settings.editorTemplate;
        let $editorTemplate = $(templateSelector);
        if (!$editorTemplate.length) {
            $editorTemplate = JqueryHelpers._createJqueryElement("div"
            /*, dcEditorModel.template.attr*/
            );
            let editorId = DcCardFn._getRandomId(6) + "-" + dcEditorModel.template.attr.id;
            $editorTemplate
                .addClass(dcEditorModel.template.attr.class)
                .addClass("container")
                //TODO
                // .addClass("container d-none")
                .attr("id", editorId);
            settings.editorTemplate = "#" + editorId;
            let 
            // arrayFields = Helpers._getObjByProp(settings, __PROP.fields, []),
            arrayFields = settings.fields, 
            // dataSrc = Helpers._getObjByProp(settings, __PROP.dataSrc, ""),
            dataSrc = settings.dataSrc;
            if (arrayFields.length > 0) {
                let $fieldSetNormal = DcEditorRender._createEditorFieldSetTemplate(arrayFields);
                $editorTemplate.append($fieldSetNormal);
            }
            $editorTemplate = DcEditorRender._createEditorFieldSetMultiTemplate(settings, dataSrc, $editorTemplate);
            $editorTemplate.appendTo(settings.jquerySelector);
        }
    }
    ;
    /**
     *
     * @param {TypeFieldSettings[]} arrayFields
     * @returns {JQuery<HTMLElement>}
     */
    static _createEditorFieldSetTemplate(arrayFields) {
        let $fieldSetNormal = JqueryHelpers._createJqueryElement("div", { "class": "row" });
        $.each(arrayFields, function (index, element) {
            let field = $.extend(true, {}, element);
            field.data = element.name;
            let $editorField = JqueryHelpers._createJqueryElement(dcModel.editorField, { "name": field.name, class: "align-items-center" });
            $fieldSetNormal.append($editorField);
        });
        return $fieldSetNormal;
    }
    ;
    /**
     *
     * @param {TypeDataCardOptions} settings
     * @param {string} dataSrc
     * @param {JQuery} $templateContainer
     * @returns {JQuery}
     */
    static _createEditorFieldSetMultiTemplate(settings, dataSrc, $templateContainer) {
        let 
        // multiField = Helpers._getObjByProp(settings, __PROP.multiFields, {}),
        multiField = settings.multiFields, 
        // multiDataSrc = Helpers._getObjByProp(multiField, __PROP.dataSrc, ""),
        multiDataSrc = multiField.dataSrc, 
        // multiItemSrc = Helpers._getObjByProp(multiField, __PROP.itemSrc, []),
        multiItemSrc = multiField.itemSrc, 
        // arrayMultiFields = Helpers._getObjByProp(multiField, __PROP.fields, []),
        arrayMultiFields = multiField.fields;
        let fieldSetMultiClass = "col col-lg-12";
        if (multiItemSrc.length > 1) {
            fieldSetMultiClass = "col col-lg-6 flex-fill";
        }
        let $fieldSetMulti = $('<div/>').attr({ "class": "row" });
        $.each(multiItemSrc, function (index, itemSrc) {
            let $fieldSetMultiContainer = $('<div/>').addClass(fieldSetMultiClass), $fieldSet = JqueryHelpers._createJqueryElement("fieldset", fieldModel.item.fieldSet.attr).addClass("row"), $legend = JqueryHelpers._createJqueryElement("legend", fieldModel.item.legend.attr).text(itemSrc.title);
            $fieldSet.append($legend);
            $.each(arrayMultiFields, function (multiFieldIndex, element) {
                let field = $.extend(true, {}, element);
                if (field.edit || field.create) {
                    let dataSrcMulti = Helpers._isSameType(dataSrc, '') && multiDataSrc !== "" ? multiDataSrc + '.' : "";
                    // let src = Helpers._isObject(itemSrc) && Helpers._isStringNotEmpty(Helpers._getObjByProp(itemSrc, __PROP.data, "")) ? itemSrc.data + '.' : "";
                    let src = Helpers._isObject(itemSrc) && Helpers._isStringNotEmpty(itemSrc.data) ? itemSrc.data + '.' : "";
                    field.name = dataSrcMulti + src + field.name;
                    // let $editorField = JqueryHelpers._createJqueryElement(dcModel.editorField, {"name": field.name})!;
                    let $editorField = JqueryHelpers._createJqueryElement('div', { "data-editor-template": field.name });
                    $editorField.appendTo($fieldSet);
                }
            });
            $fieldSetMulti.append($fieldSetMultiContainer.append($fieldSet));
            // $fieldSetMulti.append($fieldSet);
            $templateContainer.append($fieldSetMulti);
        });
        return $templateContainer;
    }
    ;
}
