var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import $ from 'jquery';
import Editor from "datatables.net-editor";
import "datatables.net-editor-bs5";
import "datatables.net-editor-bs5/css/editor.bootstrap5.css";
import Helpers from "../../../dist/_utils/helpers.js";
import BootstrapNotify from "../app/notification/bootstrap-notify.js";
import { EnumColumnType, EnumDTEditorEvent } from "../data-table/model/dt-enum.js";
import __PROP from "../utils/_prop.js";
import _Translation from "../utils/_translation.js";
import JqueryHelpers from "../utils/jquery-helpers.js";
import DcControlRender from "./control/dc-control-render.js";
import DcEditorRender from "./editor/dc-editor-render.js";
import DcCardFn from "./grid/card/dc-card-fn.js";
import DcFn from "./dc-fn.js";
import DcEditorFn from "./editor/dc-editor-fn.js";
import DcFieldFn from "./editor/field/dc-field-fn.js";
import DcCardRender from "./grid/card/dc-card-render.js";
import { controlModel, dcModel, cardModel } from "./model/dc-model.js";
import { dcDefaultsOptions, fieldDefault } from "./model/dc-default.js";
import io from 'socket.io-client';
import "../data-table/editor/field/_field-type.js";
import { DtEditorFn } from "../data-table/editor/dt-editor-fn";
import { EnumOrderingDirection } from "./model/dc-enum.js";
import { appActiveLanguageApiRoute } from "../../../_portal/routes/routes.js";
import { EnumServerDcCardAction } from "../../server/server-dc-card/model/server-dc-card-enum.js";
import DcPaginationFn from "./grid/pagination/dc-pagination-fn.js";
import FormBuilder from "../app/form-builder/form-builder.js";
import PortalApp from "../app/portal-app.js";
export default class DataCard {
    constructor(container, options, socketEvents) {
        /**
         * @private
         * @var {string} _lastSearchValue=""
         */
        this._lastSearchValue = "";
        /**
         *
         * @type {Editor | null}
         * @private
         */
        this._editor = null;
        /**
         * TODO
         * @type {any[]}
         * @private
         */
        this._files = [];
        /**
         *
         * @type {TypeOptionsResponseData}
         * @private
         */
        this._options = {};
        /**
         *
         * @type {TypeDcData}
         * @private
         */
        this._originalData = [];
        /**
         *
         * @type {TypeDcData}
         * @private
         */
        this._displayData = [];
        /**
         *
         * @type {number}
         * @private
         */
        this._pageLength = dcDefaultsOptions.pageLength;
        /**
         *
         * @type {number}
         * @private
         */
        this._currentPage = 1;
        /**
         *
         * @type {StTranslation}
         * @private
         */
        this._i18n = _Translation._get(Helpers._getLang());
        /**
         *
         * @type {JQuery | null}
         * @private
         */
        this._jqueryTemplate = null;
        /**
         *
         * @type {JQuery | null}
         * @private
         */
        this._selectedElements = null;
        /**
         *
         * @type {TypeFieldSettings[]}
         * @private
         */
        this._editorFields = [];
        /**
         *
         * @type {TypeFieldSettings[]}
         * @private
         */
        this._fields = [];
        /**
         *
         * @type {TypeFieldSettings[]}
         * @private
         */
        this._multiFields = [];
        /**
         *
         * @type {TypeSearch}
         * @private
         */
        this._filterArguments = { fields: {}, multiFields: {}, search: "" };
        /**
         *
         * @type {TypeSortingArguments}
         * @private
         */
        // private _sortArguments = [];
        this._sortArguments = {};
        this._isEditorEditing = false;
        const $container = $(container);
        const that = this;
        this._settings = DcCardFn._mergeSetting(options, this);
        this._fields = this._settings.fields;
        this._multiFields = this._settings.multiFields.fields;
        this._settings.jquerySelector = $container[0];
        this.ajax = {
            /**
             * <b>Initialize data information, pagination, page length</b>
             * @method module:DataCard#initData
             * @param {json|string} ajaxResponse
             * @returns {DataCard}
             */
            initData: function initData(ajaxResponse) {
                // console.log({_data : ajaxResponse._data})
                that
                    .data(ajaxResponse._data)
                    .file(ajaxResponse._files);
                that._processOptions(ajaxResponse._options);
                // that.data(Helpers._getObjByProp<TypeData[]>(ajaxResponse, __PROP.data, []));
                // that.file(Helpers._getObjByProp(ajaxResponse, __PROP.files, []));
                // that._processOptions(Helpers._getObjByProp(ajaxResponse, __PROP.options, {}));
                // that.data(ajaxResponse.data);
                // that.file(ajaxResponse.files);
                // that._processOptions(ajaxResponse.options);
                if (DataCard.debug) {
                    console.log({
                        _event: "DataCard API ajax.initData() called",
                        ajaxResponse: ajaxResponse
                    });
                }
                return that;
            },
            /**
             * <b>Initialize data information, pagination, page length</b>
             * @method module:DataCard#reload
             * @returns {DataCard}
             */
            reload: function reload() {
                let callBack;
                let isRedraw = false;
                if (arguments.length > 0) {
                    isRedraw = Helpers._isSameType(arguments[0], true) ? arguments[0] : isRedraw;
                }
                if (arguments.length > 1) {
                    callBack = arguments[1];
                }
                that
                    ._ajaxPromise()
                    .done(function (ajaxResponse) {
                    // console.log({response})
                    // const ajaxResponse = JSON.parse(response) as TypeAjaxResponse;
                    // console.log({response, ajaxResponse})
                    // that.data(Helpers._getObjByProp<TypeDcData>(ajaxResponse, __PROP.data, []));
                    // that.file(Helpers._getObjByProp(ajaxResponse, __PROP.files, []));
                    that
                        ._processOptions(Helpers._getObjByProp(ajaxResponse, __PROP.options, {}))
                        .data(ajaxResponse._data)
                        .file(ajaxResponse._files);
                    that
                        .filter()
                        .sort()
                        .updateInfo();
                    if (isRedraw) {
                        that.draw();
                    }
                    if (typeof callBack === "function" && Helpers._isFunction(callBack)) {
                        callBack();
                    }
                });
                if (DataCard.debug || true) {
                    console.log({
                        _event: "DataCard API ajax.reload() called",
                        _data: that._originalData,
                        that,
                        arguments: arguments,
                        callBack: callBack,
                        redraw: isRedraw
                    });
                }
                return that;
            }
        };
        if (io) {
            this.socketIo = io();
            if (socketEvents) {
                this.socketEvents = socketEvents;
            }
        }
        DcEditorRender._createEditorTemplate(this._settings);
        // if ($container.length) {
        //     await this._initialize();
        // }
        this._settings.event.started(this);
    }
    static _getActiveLanguage(url = `/api${appActiveLanguageApiRoute}`) {
        return fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ _lang: Helpers._getLang() })
        })
            .then(res => res.json());
        // .then((response)=>{
        //     re
        //     console.log({response})
        // })
    }
    /**
     * Private functions
     */
    /**
     *
     * @return {TypeFieldSettings[]}
     * @private
     */
    _processEditorFields() {
        let editorFields = [];
        const that = this;
        $.each(that._fields, function (index, element) {
            let fieldElement = $.extend(true, {}, fieldDefault, element);
            // fieldElement.data = fieldElement.name;
            fieldElement.data = fieldElement.data === "" ? fieldElement.name : fieldElement.data;
            fieldElement.indexes = Helpers._isStringNotEmpty(fieldElement.data) && typeof fieldElement.data === 'string' ? fieldElement.data.split(".") : [];
            fieldElement = that._processEditorFieldsType(fieldElement);
            fieldElement.className = fieldElement.className + ' ' + fieldElement.fieldClass;
            // console.log({fieldElement})
            if (Helpers._isStringNotEmpty(fieldElement.data)) {
                editorFields.push(fieldElement);
            }
        });
        let 
        // multiField = that._settings.hasOwnProperty(__PROP.multiFields) ? that._settings[__PROP.multiFields] : {},
        multiField = that._settings.multiFields, 
        // dataSrc = multiField.hasOwnProperty(__PROP.dataSrc) ? multiField[__PROP.dataSrc] : "",
        dataSrc = multiField.dataSrc, 
        // multiItemSrc = multiField.hasOwnProperty(__PROP.itemSrc) ? multiField[__PROP.itemSrc] : [],
        multiItemSrc = multiField.itemSrc;
        $.each(multiItemSrc, function (index, itemSrc) {
            $.each(that._multiFields, function (multiFieldIndex, element) {
                let multiFieldElement = $.extend(true, {}, fieldDefault, element), multiDataSrc = typeof dataSrc === typeof "" && dataSrc !== "" ? dataSrc : "";
                if (multiFieldElement.edit || multiFieldElement.create) {
                    multiFieldElement.data = multiFieldElement.name;
                    // let src = Helpers._isObject(itemSrc) && itemSrc.hasOwnProperty(__PROP.data) ? itemSrc[__PROP.data] + '.' : "";
                    let src = itemSrc.data || "";
                    if (Helpers._isStringNotEmpty(multiFieldElement.data)) {
                        multiFieldElement.name = /*multiDataSrc + src + multiFieldElement.name + */ `${multiDataSrc}.${src}.${multiFieldElement.name}`;
                        multiFieldElement.data = multiFieldElement.name;
                        multiFieldElement.indexes = multiFieldElement.name.split(".");
                        multiFieldElement._uniqueId = src + multiFieldElement._uniqueId;
                        multiFieldElement.className = multiFieldElement.className + ' ' + multiFieldElement.fieldClass;
                        editorFields.push(multiFieldElement);
                    }
                    // console.log({
                    //     multiFieldElement
                    // })
                }
            });
        });
        // console.log({
        //     multiField, dataSrc, multiItemSrc, editorFields
        // })
        // if (DataCard.debug) {
        //     console.log('DataCard._processEditorFields', {
        //         _settings: this._settings,
        //         _fields: this._fields,
        //         _multiFields: this._multiFields,
        //         editorFields
        //     });
        // }
        return editorFields;
    }
    /**
     *
     * @param {TypeFieldSettings} fieldElement
     * @return {TypeFieldSettings}
     * @private
     */
    _processEditorFieldsType(fieldElement) {
        const that = this;
        const _i18n = this._i18n;
        let dataSrc = that._settings.dataSrc;
        let fieldDataSrc = fieldElement.indexes[0];
        const _settings = that.settings();
        switch (fieldElement.type) {
            case EnumColumnType.image:
                // let imageSrc = Helpers._getObjByProp<string>(fieldElement, __PROP.imageSrc, []);
                let imageSrc = fieldElement.imageSrc;
                let filePathSrc = "";
                if (imageSrc && Helpers._isSameType(imageSrc, [])) {
                    if (imageSrc.length > 0 && Helpers._isStringNotEmpty(imageSrc[0])) {
                        fieldDataSrc = imageSrc[0];
                    }
                    if (imageSrc.length > 1 && Helpers._isStringNotEmpty(imageSrc[1])) {
                        filePathSrc = imageSrc[1];
                    }
                }
                fieldElement.clearText = _i18n.upload.clearText;
                fieldElement.noImageText = _i18n.upload.noImageText;
                fieldElement.dragDropText = _i18n.upload.dragDropText;
                fieldElement.uploadText = _i18n.upload.uploadText;
                fieldElement.noFileText = _i18n.upload.noFileText;
                fieldElement.fileReadText = _i18n.upload.uploadText;
                fieldElement.ajax = _settings.ajax + '?_lang=' + _settings.language + '&_t=' + dataSrc + '&fieldName=' + fieldElement.name;
                if (typeof filePathSrc === 'string' && Helpers._isStringNotEmpty(filePathSrc)) {
                    fieldElement.display = function (file_id) {
                        let file = that.file(fieldDataSrc, file_id);
                        if (file) {
                            let filePath = Helpers._isStringNotEmpty(file[filePathSrc]) ? file[filePathSrc] : "";
                            if (Helpers._isStringNotEmpty(filePath)) {
                                return file_id ?
                                    '<img class="img-fluid" src="' + Helpers._getHostName() + filePath + '" alt=""/>' :
                                    null;
                            }
                        }
                    };
                }
                break;
            case EnumColumnType.select:
                if (!Helpers._isNotUndefined(fieldElement.placeholder)) {
                    fieldElement.placeholder = _i18n.selectPlaceHolder;
                }
                break;
        }
        return fieldElement;
    }
    /**
     *
     * @param {TypeOptionsResponseData} ajaxOption
     * @return {DataCard}
     * @private
     */
    //TODO Verify
    _processOptions(ajaxOption) {
        const that = this;
        if (!$.isEmptyObject(ajaxOption)) {
            that._options = ajaxOption;
            that._fields.forEach(function (field) {
                let fieldOptions = Helpers._getObjByArrayProp(that._options, field.indexes, null);
                if (fieldOptions !== null) {
                    field.options = Helpers._getObjByArrayProp(that._options, field.indexes, {});
                    //TODO
                    field.render = DcFieldFn._getFieldRender(field.type, field.options, that, field);
                }
            });
            let multiDataSrc = that._settings.multiFields.dataSrc;
            let multiFieldOptions = Helpers._getObjByProp(that._options, multiDataSrc, {});
            if (multiFieldOptions && Helpers._getObjectLength(that._settings.multiFields) > 0 && that._settings.multiFields.fields.length > 0) {
                that._multiFields.forEach(function (multiField) {
                    multiField.options = Helpers._getObjByArrayProp(multiFieldOptions, multiField.indexes, {});
                    multiField.render = DcFieldFn._getFieldRender(multiField.type, multiField.options, that, multiField);
                });
            }
        }
        // if (DataCard.debug) {
        //     console.log('DataCard._processOptions', {
        //         ajaxOption: ajaxOption,
        //         options: that._options,
        //         _fields: this._fields,
        //         _multiFields: that._multiFields
        //     });
        // }
        return this;
    }
    /**
     *
     * @param {number} index
     * @return {any}
     * @private
     */
    _getDataByIndex(index) {
        let dataElement = null;
        if (index > -1 && index < this._originalData.length) {
            let dataItem = this._originalData[index];
            if (Helpers._isObject(dataItem)) {
                dataElement = dataItem;
            }
        }
        // console.log({
        //     _event: "_getDataByIndex(index) called",
        //     index: index,
        //     dataElement: dataElement
        // });
        return dataElement;
    }
    /**
     *
     * @param {string} id
     * @return {any}
     * @private
     */
    //TODO Verify
    _getDataById(id) {
        let dataElement = null;
        if (Helpers._isStringNotEmpty(id)) {
            dataElement = this._originalData.slice().find(function (_d) {
                return Object.keys(_d)[0] === id;
            }) || null;
        }
        return dataElement;
    }
    /**
     *
     * @param {string} id
     * @return {number}
     * @private
     */
    //TODO Verify
    _getDataIndexById(id) {
        let index = -1;
        if (Helpers._isStringNotEmpty(id)) {
            index = this._originalData.slice().findIndex(function (_d) {
                return Object.keys(_d)[0] === id;
            });
        }
        if (DataCard.debug) {
            console.log('DataCard._getDataIndexById', {
                id: id,
                index: index
            });
        }
        return index;
    }
    /**
     *
     * @return {TypeSegmentedData}
     * @private
     */
    //TODO Verify
    _segmentData() {
        let data = this._displayData.slice();
        let dataLength = data.length;
        let segmentedData = [];
        if (this._pageLength > 0 && dataLength > 0) {
            let pageQty = Math.ceil(dataLength / this._pageLength);
            let start = 0, end = this._pageLength;
            for (let i = 1; i <= pageQty; i++) {
                segmentedData.push(data.slice(start, end));
                start = start + this._pageLength;
                end = end + this._pageLength;
            }
        }
        else {
            segmentedData[0] = data;
        }
        // console.log('DataCard._segmentData', {
        //     pageLength: this._pageLength,
        //     currentPage: this._currentPage,
        //     _data: this._originalData,
        //     _displayData: this._displayData,
        //     segmentedData: segmentedData
        // });
        return segmentedData;
    }
    /**
     *
     * @param {string} fieldId
     * @param {TypeFieldSettings[]} arrayField
     * @return {TypeFieldSettings}
     * @private
     */
    _getFieldByUniqueId(fieldId, arrayField) {
        let returnVal = null;
        if (fieldId && arrayField) {
            if (Helpers._isSameType(fieldId, "")) {
                let field = arrayField.find(function (element) {
                    return element._uniqueId === fieldId;
                });
                returnVal = field !== undefined ? field : null;
            }
        }
        return returnVal;
    }
    /**
     *
     * @param {TypeFieldSettings} sortField
     * @param {TypeRowDataWithId} data
     * @param {string[]} arrayItemSrcData
     * @param {string} multiFieldDataSrc
     * @param {boolean} asc
     * @param {boolean} caseInsensitive
     * @return {string}
     * @private
     */
    //TODO Verify
    _getMultiDataCompareVal(sortField, data, arrayItemSrcData, multiFieldDataSrc, asc, caseInsensitive) {
        const _settings = this._settings;
        let compareValue = "";
        let dataRow = data[Object.keys(data)[0]];
        let multiDataObj = Object.entries(Helpers._getObjByProp(dataRow, multiFieldDataSrc, []));
        multiDataObj = multiDataObj.filter(function (item) {
            return arrayItemSrcData.includes(item[0]);
        });
        if (DataCard.debug) {
            console.log('DataCard._getMultiDataCompareVal', {
                sortField, multiDataObj, data, dataRow
            });
        }
        multiDataObj.sort(function (multiData_1, multiData_2) {
            let multiData_1_val = Helpers._getObjByProp(multiData_1[1], sortField.name, "");
            let multiData_2_val = Helpers._getObjByProp(multiData_2[1], sortField.name, "");
            let compareVal_1 = multiData_1_val, compareVal_2 = multiData_2_val;
            // let renderHelpers = Helpers._getObjByProp(sortField, __PROP.render, null);
            let renderHelpers = sortField.render;
            if (renderHelpers && Helpers._isFunction(renderHelpers)) {
                compareVal_1 = renderHelpers(multiData_1_val, sortField.type, dataRow, _settings);
                compareVal_2 = renderHelpers(multiData_2_val, sortField.type, dataRow, _settings);
            }
            let orderHelpers = Helpers._getObjByProp(DataCard._ext.order, sortField.type, null);
            if (Helpers._isFunction(sortField.order)) {
                orderHelpers = sortField.order;
            }
            if (typeof orderHelpers === "function" && Helpers._isFunction(orderHelpers)) {
                compareVal_1 = orderHelpers(_settings, sortField, multiData_1_val, dataRow).toString();
                compareVal_2 = orderHelpers(_settings, sortField, multiData_2_val, dataRow).toString();
            }
            if (Helpers._isStringNotEmpty(compareVal_1)) {
                compareVal_1 = multiData_1_val.trim();
            }
            if (Helpers._isStringNotEmpty(compareVal_2)) {
                compareVal_2 = multiData_2_val.trim();
            }
            let a_1_val = compareVal_1, a_2_val = compareVal_2;
            if (!asc) {
                a_1_val = compareVal_2;
                a_2_val = compareVal_1;
            }
            if (caseInsensitive && Helpers._isSameType(a_1_val, "") && Helpers._isSameType(a_2_val, "")) {
                a_1_val = a_1_val.toLowerCase();
                a_2_val = a_2_val.toLowerCase();
            }
            if (a_1_val < a_2_val) {
                return -1;
            }
            if (a_1_val > a_2_val) {
                return 1;
            }
            return 0;
        });
        if (multiDataObj.length > 0) {
            // multiDataObj = multiDataObj[0];
            const multiDataElem = multiDataObj[0];
            compareValue = Helpers._getObjByProp(multiDataElem[1], sortField.name, "");
            compareValue = compareValue.trim();
        }
        return compareValue;
    }
    /**
     *
     * @param {TypeDcData} data
     * @param {string} searchValue
     * @return {TypeRowDataWithId[]}
     * @private
     */
    //TODO Verify
    _generalSearch(data, searchValue) {
        const _settings = this._settings;
        // let isCaseInsensitive = Helpers._getObjByProp(_settings, __PROP.isCaseInsensitive, StDataCardDefaults.options.isCaseInsensitive);
        let isCaseInsensitive = _settings.caseInsensitive;
        let returnData = data.slice();
        let allFields = this._fields.concat(this._multiFields);
        if (Helpers._isStringNotEmpty(searchValue)) {
            returnData = returnData.filter(function (elementData) {
                let elementId = Object.keys(elementData)[0];
                let dataElement = elementData[elementId];
                for (let i = 0; i < allFields.length; i++) {
                    let field = allFields[i];
                    // console.log({
                    //     dataElement, field, fieldIndexes : field.indexes, search : field.search
                    // })
                    if (field.search) {
                        let fieldValue = "";
                        if (field._isMultiField) {
                            // fieldValue = Helpers._getObjByArrayProp(dataElement, field.indexes, "");
                        }
                        else {
                            fieldValue = Helpers._getObjByArrayProp(dataElement, field.indexes, "");
                            // console.log({
                            //     dataElement, field, fieldIndexes : field.indexes, fieldValue
                            // })
                        }
                        let render = field.render;
                        if (field.data !== null && Helpers._isFunction(render)) {
                            fieldValue = render(fieldValue, field.type, dataElement, _settings);
                        }
                        if (isCaseInsensitive) {
                            fieldValue = fieldValue.toLowerCase();
                        }
                        if (fieldValue.includes(searchValue)) {
                            return true;
                        }
                    }
                }
                return false;
            });
        }
        if (DataCard.debug) {
            console.log('DataCard._generalSearch', {
                _editorFields: this._editorFields,
                allFields: allFields,
                fields: this._fields,
                multiFields: this._multiFields,
                returnData: returnData,
                setting: _settings
            });
        }
        return returnData;
    }
    /**
     *
     * @param {string} name
     * @return {any}
     * @private
     */
    //TODO Verify
    _getFieldByName(name) {
        let field = null;
        if (Helpers._isStringNotEmpty(name)) {
            field = this._editorFields.slice().find(function (_f) {
                return _f.name === name;
            });
        }
        return field;
    }
    /**
     *
     * @return {JQuery.jqXHR}
     * @private
     */
    //TODO Verify
    _ajaxPromise() {
        const _settings = this._settings;
        let dataParam = $(_settings.jquerySelector).data();
        let sendData = $.extend(true, {}, dataParam);
        let ajaxUrl = _settings.ajax, dataSrc = _settings.dataSrc, dataSrcMulti = _settings.multiFields.dataSrc;
        // console.log({_settings, dataParam, sendData, dataSrc, dataSrcMulti, ajaxUrl})
        if (Helpers._isStringNotEmpty(ajaxUrl) && Helpers._isStringNotEmpty(dataSrc)) {
            let table = Helpers._getObjByProp(_settings, "_t", dataSrc);
            sendData = $.extend(true, {}, sendData, {
                _t: table, dataSrc: dataSrc, dataSrcMulti: dataSrcMulti,
                action: EnumDTEditorEvent.read, _lang: _settings.language
            });
            if (DataCard.debug) {
                console.log('DataCard._ajaxPromise', {
                    dataParam: dataParam, sendData: sendData
                });
            }
            return $.post(ajaxUrl, sendData);
        }
        return null;
    }
    /**
     *
     * @private
     */
    //TODO Verify
    _initialize() {
        let dataCardInitTime = Date.now();
        const that = this;
        const _settings = that._settings;
        _settings.event.initLoad(that);
        _settings.event.preLoad(that);
        _settings.event.onLoad(that);
        return this._ajaxPromise().then(function _ajaxPromise_done(response) {
            // response = Helpers._isJsonString(response) ? JSON.parse(response) : {};
            // console.log({response})
            if (response._status || response._status === 1) {
                // console.log({response})
                that.ajax.initData(response);
                that._editorFields = that._processEditorFields();
                // console.log({_editorFields : that._editorFields})
                _settings.event.postLoad(that);
                that.template(DcCardRender._createCardTemplate(that));
                DcControlRender._createControlPanel(that);
                DcCardRender._createRowContainer(that);
                DcControlRender._createPageInfoRow(that);
                let $select = $("select", "." + controlModel.filterClass);
                let drawItem = -1;
                if ($select.length) {
                    drawItem = parseInt($select.val().toString());
                }
                if (Helpers._isSameType(_settings.pageLength, 1)) {
                    drawItem = _settings.pageLength;
                    let selectedOption = $("option[value='" + drawItem + "']", $select);
                    selectedOption.attr(dcModel.selectedAttr, 'true');
                }
                let dataParam = $(_settings.jquerySelector).data();
                let sendData = $.extend(true, {}, dataParam);
                that._editor = new Editor({
                    ajax: {
                        url: _settings.ajax + "?_lang=" + _settings.language,
                        data: function (data) {
                            data._t = _settings.dataSrc;
                            data._lang = _settings.language;
                            data = $.extend(true, {}, sendData, data);
                            return data;
                        }
                    },
                    // template: Helpers._getObjByProp(_settings, __PROP.editorTemplate, DataCardDefaults.options.editorTemplate),
                    template: _settings.editorTemplate || dcDefaultsOptions.editorTemplate,
                    idSrc: _settings.idSrc,
                    i18n: that._i18n,
                    fields: that._editorFields
                });
                DcEditorFn.init(that, that._editor, that._editorFields);
                // let ordering = Helpers._getObjByProp<TypeOrdering[]>(_settings, __PROP.ordering, []);
                let ordering = _settings.ordering;
                if (Helpers._isSameType(ordering, [])) {
                    // let caseInsensitive = Helpers._getObjByProp<boolean>(_settings, __PROP.caseInsensitive, true);
                    let caseInsensitive = _settings.caseInsensitive;
                    $.each(ordering, function (index, element) {
                        // let fieldName = Helpers._getObjByProp<string>(element, __PROP.name, "");
                        let fieldName = element.fieldName;
                        // let isMultiField = Helpers._getObjByProp<boolean>(element, __PROP.multiFields, false);
                        let isMultiField = element.multiFields;
                        // let asc = Helpers._getObjByProp<string>(element, __PROP.direction, DcStatic._ordering.asc) === DcStatic._ordering.asc;
                        let asc = element.direction === EnumOrderingDirection.asc;
                        let field = that.fields(fieldName);
                        if (field !== null) {
                            // let fieldId = Helpers._getObjByProp(field, __PROP.fieldItemOptions._uniqueId, null);
                            let fieldId = field._uniqueId;
                            if (Helpers._isStringNotEmpty(fieldId)) {
                                // _this.sort(fieldId, asc, isMultiField, caseInsensitive);
                                that._originalData = that._sort(that._originalData, fieldId, asc, isMultiField, caseInsensitive);
                            }
                        }
                    });
                }
                // let fieldGroup = Helpers._getObjByProp<string>(_settings, __PROP.fieldGroup, "");
                let fieldGroup = _settings.fieldGroup;
                let HelpersName = 'DataCard._ajaxPromise_done';
                // if (DataCard.debug) {
                // }
                if (Helpers._isStringNotEmpty(fieldGroup)) {
                    let filterField = {};
                    let filterMultiField = {};
                    let field = that._getFieldByName(fieldGroup);
                    // console.log(HelpersName, {
                    //     fieldGroup, field
                    // })
                    if (!$.isEmptyObject(field) && field) {
                        // let fieldId = Helpers._getObjByProp<string>(field, __PROP.fieldItemOptions._uniqueId, "");
                        let fieldId = field._uniqueId || "";
                        // let fieldOption = Helpers._getObjByProp(field, __PROP.options, {});
                        let fieldOption = field.options;
                        if (Helpers._isStringNotEmpty(fieldId) && !$.isEmptyObject(fieldOption) && fieldOption) {
                            let fieldGroupVal = Object.values(fieldOption)[0];
                            if (typeof fieldGroupVal === 'number' || Helpers._isStringNotEmpty(fieldGroupVal)) {
                                if (field._isMultiField) {
                                    filterMultiField[fieldId] = fieldGroupVal;
                                }
                                else {
                                    filterField[fieldId] = fieldGroupVal;
                                }
                            }
                            // if (DataCard.debug) {
                            //     console.log(HelpersName, {
                            //         field: field,
                            //         fieldGroup: fieldGroup,
                            //         fieldGroupVal: fieldGroupVal,
                            //         fieldId: fieldId
                            //     })
                            // }
                        }
                    }
                    // console.log(HelpersName, {
                    //     filterField, filterMultiField
                    // })
                    if (!$.isEmptyObject(filterField) || !$.isEmptyObject(filterMultiField)) {
                        that.filter({
                            fields: filterField, multiFields: filterMultiField, search: ""
                        }).sort();
                    }
                }
                if (that.socketIo) {
                    that.socketIo.on(`${EnumServerDcCardAction.edit}:${_settings.dataSrc}`, (socketData) => {
                        const socketId = that.socketId();
                        const { from, data, message, title } = socketData;
                        if (socketId !== from) {
                            that.updateData(data);
                            BootstrapNotify._notify(message, title);
                        }
                        console.log(`edit:${_settings.dataSrc}`, { socketData, from, data, message, dataCard: that, socketId });
                    });
                    that.socketIo.on(`${EnumServerDcCardAction.create}:${_settings.dataSrc}`, (socketData) => {
                        const socketId = that.socketId();
                        const { from, data, message, title } = socketData;
                        if (socketId !== from) {
                            that.addData(data);
                            BootstrapNotify._notify(message, title);
                        }
                        console.log('create:app_forms', { socketData, from, data, message, dataCard: that, socketId });
                    });
                    that.socketIo.on(`${EnumServerDcCardAction.remove}:${_settings.dataSrc}`, (socketData) => {
                        const socketId = that.socketId();
                        const { from, data, message, title } = socketData;
                        if (socketId !== from) {
                            that.removeData(data);
                            BootstrapNotify._notify(message, title);
                        }
                        console.log(`remove:${_settings.dataSrc}`, { socketData, from, data, message, dataCard: that, socketId });
                    });
                    if (that.socketEvents) {
                        for (const [eventName, callback] of Object.entries(that.socketEvents)) {
                            that.socketIo.on(eventName, (data) => {
                                callback(data, that);
                            });
                            console.log({ eventName, callback });
                        }
                    }
                }
                that.draw();
                // _fieldGroup();
                // if (DataCard.debug) {
                // dataCardInitTime = Date.now() - dataCardInitTime;
                //     console.log(HelpersName, {
                //         dataCardInitTime: dataCardInitTime
                //     })
                // }
            }
            else {
                BootstrapNotify._notify(response._message);
            }
        });
    }
    /**
     *
     * @param args
     * @return {any}
     * @protected
     */
    //TODO Verify
    _sort(...args) {
        const [data, fieldId, isAsc, isMultiField, isCaseInSensitive] = args;
        const that = this;
        let returnData = [];
        if (arguments.length > 2) {
            // this._sortArguments = [data, fieldId, isAsc, isMultiField, isCaseInSensitive];
            this._sortArguments = {
                data, fieldId, isAsc, isMultiField, isCaseInSensitive
            };
        }
        if (Helpers._isNotUndefined(this._sortArguments) /*&& this._sortArguments.length > 2*/) {
            let { data, fieldId, isAsc, isMultiField, isCaseInSensitive } = this._sortArguments;
            // let
            //     // _sortData : TypeDcData = Helpers._isSameType(this._sortArguments[0], []) ? this._sortArguments[0] : [],
            //     // sortFieldId = Helpers._isSameType(this._sortArguments[1], "") ? this._sortArguments[1] : "",
            //     // asc = Helpers._isSameType(this._sortArguments[2], true) ? this._sortArguments[2] : true,
            //     // isMultiField = Helpers._isSameType(this._sortArguments[3], false) ? this._sortArguments[3] : false,
            //     // isCaseInSensitive = Helpers._isSameType(this._sortArguments[4], true) ? this._sortArguments[4] : true,
            //     processTime = Date.now()
            // ;
            if (Helpers._isStringNotEmpty(fieldId) && data && fieldId) {
                let sortField = null;
                if (isMultiField) {
                    sortField = this._getFieldByUniqueId(fieldId, this._multiFields);
                    let 
                    // arrayItemSrc = Helpers._getObjByArrayProp<TypeItemSrc[]>(this.settings, [__PROP.multiFields, __PROP.itemSrc], []),
                    arrayItemSrc = that._settings.multiFields.itemSrc, 
                    // multiFieldDataSrc = Helpers._getObjByArrayProp<string>(this.settings(), [__PROP.multiFields, __PROP.dataSrc], ""),
                    multiFieldDataSrc = that._settings.multiFields.dataSrc, arrayItemSrcData = [];
                    if (sortField !== null && !$.isEmptyObject(sortField) &&
                        Helpers._isStringNotEmpty(multiFieldDataSrc) && arrayItemSrc.length > 0) {
                        arrayItemSrc.forEach(function (item) {
                            if (Helpers._isStringNotEmpty(item.data) && item.sort && !arrayItemSrcData.includes(item.data) && item.visible) {
                                arrayItemSrcData.push(item.data);
                            }
                        });
                        if (arrayItemSrcData.length > 0) {
                            returnData = data.sort(function _sortData(a, b) {
                                let a_test = that._getMultiDataCompareVal(sortField, a, arrayItemSrcData, multiFieldDataSrc, isAsc, isCaseInSensitive);
                                let b_test = that._getMultiDataCompareVal(sortField, b, arrayItemSrcData, multiFieldDataSrc, isAsc, isCaseInSensitive);
                                // a = that._getMultiDataCompareVal(sortField!, a, arrayItemSrcData, multiFieldDataSrc, asc, isCaseInSensitive);
                                // b = that._getMultiDataCompareVal(sortField!, b, arrayItemSrcData, multiFieldDataSrc, asc, isCaseInSensitive);
                                let position = 0, a_value = a_test, b_value = b_test;
                                if (!isAsc) {
                                    a_value = b_test;
                                    b_value = a_test;
                                }
                                if (isCaseInSensitive) {
                                    a_value = a_value.toLowerCase();
                                    b_value = b_value.toLowerCase();
                                }
                                if (DataCard.debug) {
                                    console.log({
                                        a_test,
                                        b_test,
                                        arrayItemSrc: arrayItemSrc,
                                        arrayItemSrcData: arrayItemSrcData,
                                        position: position
                                    });
                                }
                                if (a_value < b_value) {
                                    position = -1;
                                }
                                if (a_value > b_value) {
                                    position = 1;
                                }
                                return position;
                            });
                        }
                    }
                }
                else {
                    sortField = that._getFieldByUniqueId(fieldId, this._fields);
                    if (sortField !== null && !$.isEmptyObject(sortField)) {
                        returnData = data.sort(function _sortData(a, b) {
                            let a_data = a[Object.keys(a)[0]];
                            let b_data = b[Object.keys(b)[0]];
                            let a_test = Helpers._getObjByArrayProp(a_data, sortField.indexes, "");
                            let b_test = Helpers._getObjByArrayProp(b_data, sortField.indexes, "");
                            // a = Helpers._getObjByArrayProp(a_data, sortField!.indexes, "");
                            // b = Helpers._getObjByArrayProp(b_data, sortField!.indexes, "");
                            let compareVal_a = a_test, compareVal_b = b_test;
                            // let renderHelpers = Helpers._getObjByProp(sortField, __PROP.render, null);
                            let renderHelpers = sortField.render;
                            if (renderHelpers && Helpers._isFunction(renderHelpers)) {
                                compareVal_a = renderHelpers(a_test, sortField.type, a_data, that._settings);
                                compareVal_b = renderHelpers(b_test, sortField.type, b_data, that._settings);
                            }
                            let orderHelpers = Helpers._getObjByProp(DataCard._ext.order, sortField.type, null);
                            if (Helpers._isFunction(sortField.order)) {
                                orderHelpers = sortField.order;
                            }
                            if (typeof orderHelpers === 'function' && Helpers._isFunction(orderHelpers)) {
                                compareVal_a = orderHelpers(that._settings, sortField, a_test, a_data);
                                compareVal_b = orderHelpers(that._settings, sortField, b_test, b_data);
                            }
                            if (Helpers._isStringNotEmpty(compareVal_a)) {
                                compareVal_a = compareVal_a.trim();
                            }
                            if (Helpers._isStringNotEmpty(compareVal_b)) {
                                compareVal_b = compareVal_b.trim();
                            }
                            let position = 0, value1 = compareVal_a, value2 = compareVal_b;
                            if (!isAsc) {
                                value1 = compareVal_b;
                                value2 = compareVal_a;
                            }
                            if (isCaseInSensitive && Helpers._isStringNotEmpty(value1) && Helpers._isStringNotEmpty(value2)) {
                                value1 = value1.toLowerCase();
                                value2 = value2.toLowerCase();
                            }
                            if (value1 < value2) {
                                position = -1;
                            }
                            if (value1 > value2) {
                                position = 1;
                            }
                            if (DataCard.debug) {
                                console.log('DataCard._sort._sortData', {
                                    orderHelpers: orderHelpers,
                                    a_test,
                                    b_test,
                                    value1: value1,
                                    value2: value2,
                                    position: position,
                                    sortField: sortField
                                });
                            }
                            return position;
                        });
                    }
                }
            }
            // if (DataCard.debug || true) {
            //     processTime = Date.now() - processTime;
            //     console.log('DataCard._sort._sortData', arguments, {
            //         isAsc,
            //         processTime: processTime,
            //         returnData: returnData
            //     })
            // }
        }
        return returnData;
    }
    socketId() {
        if (this.socketIo) {
            return this.socketIo.id;
        }
        return null;
    }
    /**
     *
     * @return {DataCard}
     */
    updateInfo() {
        DcControlRender._drawPagination(this, this._segmentData(), this._currentPage, this._pageLength, this._displayData, this._originalData);
        return this;
    }
    /**
     *
     * @param arg1
     * @param arg2
     * @return {any}
     */
    //TODO Verify
    file(arg1, arg2) {
        let argsLength = arguments.length;
        let returnVal = null;
        let type = "get";
        if (argsLength === 1) {
            // let arg1 = arguments[0];
            if (Helpers._isSameType(arg1, []) && Array.isArray(arg1)) {
                this._files = arg1;
                returnVal = this;
                type = "set";
            }
        }
        else if (argsLength > 1) {
            let dataSrc = arg1, file_id = arg2;
            if (typeof dataSrc === 'string' && Helpers._isStringNotEmpty(dataSrc) && Helpers._isStringNotEmpty(file_id)) {
                returnVal = Helpers._getObjByArrayProp(this._files, [dataSrc, file_id], null);
            }
        }
        if (DataCard.debug) {
            console.log('DataCard.file', {
                type: type,
                arguments: arguments,
                files: this._files,
                returnVal: returnVal
            });
        }
        return returnVal;
    }
    /**
     *
     * @param arg1
     * @param arg2
     * @return {any}
     */
    //TODO Verify
    data(arg1, arg2) {
        let argsLength = arguments.length;
        let ReturnVal = this._originalData;
        let type = "get";
        if (argsLength === 1) {
            // console.log({arg1, arg2})
            // console.log(typeof arg1)
            // let arg1 = arguments[0];
            if (Helpers._isSameType(arg1, {}) /* && !$.isEmptyObject(arg1)*/) {
                this._originalData = arg1;
                this._displayData = this._originalData.slice();
                type = "set";
                ReturnVal = this;
            }
            if ( /*Helpers._isStringNotEmpty(arg1) && */typeof arg1 === 'string') {
                ReturnVal = this._getDataById(arg1);
            }
            if ( /*Helpers._isSameType(arg1, 1) && arg1 > 0  && */typeof arg1 === 'number' && arg1 > 0) {
                ReturnVal = this._getDataByIndex(arg1);
            }
        }
        else if (argsLength === 2) {
            let start = arg1, end = arg2;
            if (arg1 === 'number' && arg2 === 'number' &&
                // Helpers._isSameType(start, 1) && Helpers._isSameType(end, 1) &&
                Helpers._isSameType(this._originalData, []) &&
                start <= this._originalData.length && end <= this._originalData.length) {
                end++;
                ReturnVal = this._originalData.slice(start, end);
            }
        }
        // console.log('DataCard.data', {
        //     type: type,
        //     data: this._originalData,
        //     arguments: arguments
        // });
        return ReturnVal;
    }
    /**
     *
     * @return {DataCard}
     */
    //TODO Verify
    draw() {
        console.log('draw');
        let segmentedData = this._segmentData();
        if (this._currentPage > segmentedData.length) {
            this._currentPage = segmentedData.length;
        }
        let elements = segmentedData[this._currentPage - 1];
        let processTime = Date.now();
        DcCardRender._displayCards(this, elements);
        processTime = Date.now() - processTime;
        this._settings.event.initDraw(this);
        this._settings.event.preDraw(this);
        this._settings.event.onDraw(this);
        this.updateInfo();
        DtEditorFn._enableField(this._editor, this._editorFields);
        this._settings.event.postDraw(this);
        let page = this.page();
        let length = this.dataLength();
        if (DataCard.debug) {
            console.log('DataCard.draw', {
                segmentedData: segmentedData,
                elements: elements,
                currentPage: this._currentPage,
                processTime: processTime,
                page: page,
                length: length
            });
        }
        DcFn.initListener(this, this._editor);
        return this;
    }
    /**
     *
     * @return {DataCard}
     */
    //TODO Verify
    destroy() {
        let panelSelector = $(this._settings.jquerySelector);
        if (panelSelector !== null && panelSelector instanceof jQuery) {
            panelSelector.html("");
        }
        let btnPanelSelector = $(this._settings.controlPanel);
        if (btnPanelSelector !== null && btnPanelSelector instanceof jQuery) {
            btnPanelSelector.html("");
        }
        return this;
    }
    /**
     * _jqueryTemplate Getter & Setter
     * @param {JQuery} $jqueryTemplate
     * @return {JQuery}
     */
    template($jqueryTemplate) {
        if ($jqueryTemplate) {
            this._jqueryTemplate = $jqueryTemplate;
            return this;
        }
        return this._jqueryTemplate;
    }
    /**
     * Get data element by index or by id
     * @param {string | number} dataIndexOrId
     * @returns {TypeDataRow | null}
     */
    element(dataIndexOrId) {
        if (dataIndexOrId !== undefined) {
            switch (typeof dataIndexOrId) {
                case "number":
                    return this._getDataByIndex(dataIndexOrId);
                case "string":
                    return this._getDataById(dataIndexOrId);
            }
            // if(typeof dataIndexOrId === 'string'){
            //     if(Helpers._isStringNotEmpty(dataIndexOrId)){
            //         return this._getDataById(dataIndexOrId);
            //
            //     }
            // }else if(typeof dataIndexOrId === 'number'){
            //     return this._getDataByIndex(dataIndexOrId);
            // }
        }
        return null;
    }
    /**
     *
     * @param {string | number} selector
     * @return {JQuery}
     */
    elements(selector) {
        if (Helpers._isNotUndefined(selector)) {
            switch (typeof selector) {
                case typeof "":
                    if (Helpers._isStringNotEmpty(selector)) {
                        if (JqueryHelpers.isValidSelector(selector)) {
                            this._selectedElements = $(selector);
                        }
                        else {
                            this._selectedElements = $("#" + selector);
                        }
                    }
                    //TODO ??? what is this purpose
                    // this._selectedElements = [];
                    break;
                case typeof 1:
                    break;
            }
        }
        else {
            this._selectedElements = $("." + cardModel.item.attr.class, "." + cardModel.rowCardContainer.attr.class);
        }
        if (DataCard.debug) {
            console.log('DataCard.elements', {
                selector: selector,
                _selectedElements: this._selectedElements
            });
        }
        return this._selectedElements;
    }
    /**
     *
     * @param {string} searchFieldName
     * @return {TypeFieldSettings[] | (Partial<IOptions> & {className: string, name: string, data: string, label: string, labelText: string, fieldInfo: string, fieldClass: string, fieldHelper: string, labelInfo: string, labelHelper: string, type: TypeFieldType, order: boolean | TypeOrderingFunction | null, render: TypeRenderFunction | null, sort: boolean, search: boolean, visible: boolean, create: boolean, edit: boolean, display: boolean | TypeDisplayFunction, _isMultiField: boolean, debug: boolean, _isGroup: boolean, indexes: string[], _uniqueId: string, options?: TypeFieldOption, clearText?: string, noImageText?: string, dragDropText?: string, uploadText?: string, noFileText?: string, fileReadText?: string, ajax?: string, placeholder?: string, imageSrc: string[]})}
     */
    //TODO Verify
    fields(searchFieldName) {
        let returnVal = this._fields;
        if (searchFieldName) {
            // let searchFieldName = arguments[0];
            if (Helpers._isSameType(searchFieldName, "")) {
                let field = this._fields.slice().find(function (element) {
                    return element.name === searchFieldName;
                });
                returnVal = Helpers._isNotUndefined(field) ? field : null;
            }
        }
        if (DataCard.debug) {
            console.log('DataCard.fields', {
                arguments: arguments,
                returnVal: returnVal,
                _fields: this._fields
            });
        }
        return returnVal;
    }
    /**
     *
     * @param {string} searchFieldName
     * @return {TypeFieldSettings[] | (Partial<IOptions> & {className: string, name: string, data: string, label: string, labelText: string, fieldInfo: string, fieldClass: string, fieldHelper: string, labelInfo: string, labelHelper: string, type: TypeFieldType, order: boolean | TypeOrderingFunction | null, render: TypeRenderFunction | null, sort: boolean, search: boolean, visible: boolean, create: boolean, edit: boolean, display: boolean | TypeDisplayFunction, _isMultiField: boolean, debug: boolean, _isGroup: boolean, indexes: string[], _uniqueId: string, options?: TypeFieldOption, clearText?: string, noImageText?: string, dragDropText?: string, uploadText?: string, noFileText?: string, fileReadText?: string, ajax?: string, placeholder?: string, imageSrc: string[]})}
     */
    //TODO Verify
    multiFields(searchFieldName) {
        let returnVal = this._multiFields;
        if (searchFieldName) {
            // let searchFieldName = arguments[0];
            if (Helpers._isSameType(searchFieldName, "")) {
                let multiField = this._multiFields.find(function (element) {
                    return element.name === searchFieldName;
                });
                returnVal = Helpers._isNotUndefined(multiField) ? multiField : null;
            }
        }
        if (DataCard.debug) {
            console.log('DataCard.multiFields', {
                arguments: arguments,
                returnVal: returnVal,
                _multiFields: this._multiFields
            });
        }
        return returnVal;
    }
    /**
     *
     * @return {TypeFieldSettings[]}
     */
    editorFields() {
        return this._editorFields;
    }
    /**
     *
     * @param {TypeSearch | string} filterArgument
     * @return {DataCard}
     */
    //TODO Verify
    filter(filterArgument) {
        console.log('DataCard.filter', { filterArgument });
        const that = this;
        const _settings = that._settings;
        if (filterArgument !== undefined) {
            this._filterArguments = filterArgument;
        }
        // console.log({filterArgument})
        // let debug: TypeDebug = {
        //     _data: this._originalData,
        //     _filterArguments: this._filterArguments,
        //     filterArgument
        // };
        let enableFilter = false;
        if (filterArgument && Object.keys(filterArgument).length > 0) {
            DcEditorFn._disableSortable(this, '.' + cardModel.rowCardContainer.attr.class);
            // console.log(`type of filterArguments : '${typeof filterArgument}'`)
            // let processTime = Date.now();
            // let filterArgument = this._filterArguments[0];
            if (typeof filterArgument === "string" && Helpers._isSameType(filterArgument, "")) {
                filterArgument = filterArgument.toLowerCase().trim();
                if (filterArgument !== this._lastSearchValue && Helpers._isStringNotEmpty(filterArgument)) {
                    DcEditorFn._disableSortable(this, '.' + cardModel.rowCardContainer.attr.class);
                    this._displayData = this._generalSearch(that._originalData, filterArgument);
                }
                else {
                    this._displayData = this._originalData.slice();
                }
                this._lastSearchValue = filterArgument;
            }
            else if (typeof filterArgument === 'object' && !$.isEmptyObject(filterArgument)) {
                let genSearch = filterArgument.search || "";
                this._displayData = this._generalSearch(this._originalData, genSearch);
                let 
                // fields = Helpers._getObjByProp<TypeSearchPair>(filterArgument, __PROP.fields, []),
                fields = filterArgument.fields, arraySearchFields = [], arraySearchValue = [];
                $.each(fields, function (fieldsName, searchVal) {
                    // let field = _this.fields(fieldsName);
                    let field = that._getFieldByUniqueId(fieldsName, that._fields);
                    if (typeof searchVal === 'string') {
                        searchVal = searchVal.trim().toLowerCase();
                    }
                    if (field !== null && (Helpers._isStringNotEmpty(searchVal) || typeof searchVal === 'number')) {
                        arraySearchFields.push(field);
                        arraySearchValue.push(searchVal);
                    }
                });
                if (arraySearchFields.length > 0) {
                    for (let i = 0; i < arraySearchFields.length; i++) {
                        let field = arraySearchFields[i];
                        if (field._isGroup) {
                            enableFilter = field._isGroup;
                        }
                        if (i > 0) {
                            enableFilter = false;
                        }
                        let _searchVal = arraySearchValue[i];
                        if (field.search && (Helpers._isStringNotEmpty(_searchVal) || typeof _searchVal === 'number')) {
                            that._displayData = that._displayData.filter(function _displayData_filter(element) {
                                let dataElement = element[Object.keys(element)[0]];
                                let _fieldVal = Helpers._getObjByArrayProp(dataElement, field.indexes, ""), render = field.render;
                                let _originalVal = _fieldVal;
                                // let _renderVal = _fieldVal;
                                // if (Helpers._isFunction(render) && render && typeof render === 'function') {
                                //     _renderVal = render(_renderVal, field.type, dataElement, _settings);
                                //     // fieldVal = render(fieldVal, field.type, dataElement, _settings);
                                //     // renderVal = renderVal.toString().toLowerCase();
                                // }
                                let founded = false;
                                if (typeof _fieldVal === 'string') {
                                    _fieldVal = _fieldVal.toLowerCase();
                                    founded = _fieldVal.includes(_searchVal.toString()) || _searchVal === (_fieldVal);
                                }
                                else {
                                    founded = `${_searchVal}` === `${_fieldVal}`;
                                }
                                // if (DataCard.debug || true) {
                                //     console.log('DataCard.filter._displayData_filter', {
                                //         _fieldVal,
                                //         _searchVal,
                                //         field : field,
                                //         _originalVal,
                                //         // _renderVal,
                                //         dataElement,
                                //         founded,
                                //         filterArgument,
                                //         arraySearchFields
                                //     });
                                // }
                                // return _fieldVal.includes(_searchVal) || _originalVal === (_fieldVal);
                                return founded;
                            });
                        }
                        // console.log({field : field})
                        // field = null;
                    }
                }
                let 
                // multiFields = Helpers._getObjByProp<TypeSearchPair>(filterArgument, __PROP.multiFields, {}),
                multiFields = filterArgument.multiFields, arraySearchMultiFields = [], arraySearchMultiValue = [];
                $.each(multiFields, function (multiFieldsName, multiSearchVal) {
                    // let multiField = _this.multiFields(multiFieldsName);
                    let multiField = that._getFieldByUniqueId(multiFieldsName, that._multiFields);
                    if (typeof multiSearchVal === 'string') {
                        multiSearchVal = multiSearchVal.trim().toLowerCase();
                    }
                    if (multiField !== null && Helpers._isStringNotEmpty(multiSearchVal)) {
                        arraySearchMultiFields.push(multiField);
                        arraySearchMultiValue.push(multiSearchVal);
                    }
                });
                if (arraySearchMultiFields.length > 0) {
                    // let multiFieldDataSrc = Helpers._getObjByArrayProp(_settings, [__PROP.multiFields, __PROP.dataSrc], "");
                    let multiFieldDataSrc = _settings.multiFields.dataSrc;
                    // let arrayItemSrc = Helpers._getObjByArrayProp(_settings, [__PROP.multiFields, __PROP.itemSrc], []);
                    let arrayItemSrc = _settings.multiFields.itemSrc;
                    arrayItemSrc = arrayItemSrc.filter(function (itemSrc) {
                        return itemSrc.search && Helpers._isStringNotEmpty(itemSrc.data) && itemSrc.visible;
                    });
                    // if (DataCard.debug) {
                    //     console.log('DataCard.filter', {
                    //         arrayItemSrc: arrayItemSrc,
                    //         multiFieldDataSrc: multiFieldDataSrc,
                    //         arraySearchMultiFields: arraySearchMultiFields,
                    //         arraySearchMultiValue: arraySearchMultiValue
                    //     });
                    // }
                    if (Helpers._isStringNotEmpty(multiFieldDataSrc)) {
                        for (let i_m = 0; i_m < arraySearchMultiFields.length; i_m++) {
                            let fieldMulti = arraySearchMultiFields[i_m];
                            let searchMulti = arraySearchMultiValue[i_m];
                            if (fieldMulti._isGroup) {
                                enableFilter = fieldMulti._isGroup;
                            }
                            if (i_m > 0) {
                                enableFilter = false;
                            }
                            if ((Helpers._isStringNotEmpty(searchMulti) || typeof searchMulti === 'number') && fieldMulti.search) {
                                that._displayData = that._displayData.filter(function (element) {
                                    let dataElement = element[Object.keys(element)[0]];
                                    for (let i_itemSrc = 0; i_itemSrc < arrayItemSrc.length; i_itemSrc++) {
                                        let itemSrc = arrayItemSrc[i_itemSrc];
                                        let _fieldVal = typeof fieldMulti.name === 'string' ?
                                            Helpers._getObjByArrayProp(dataElement, [multiFieldDataSrc, itemSrc.data, fieldMulti.name], "") :
                                            '';
                                        let _originalVal = _fieldVal;
                                        // let _renderVal = _fieldVal;
                                        // let renderMulti = fieldMulti.render;
                                        // if (Helpers._isFunction(renderMulti) && renderMulti && typeof renderMulti === 'function') {
                                        //     _renderVal = renderMulti(_renderVal, fieldMulti.type, dataElement, _settings);
                                        // }
                                        let founded = false;
                                        if (typeof _fieldVal === 'string') {
                                            _fieldVal = _fieldVal.toLowerCase();
                                            founded = _fieldVal.includes(`${searchMulti}`) || `${searchMulti}` === (_fieldVal);
                                        }
                                        else {
                                            founded = `${searchMulti}` === `${_fieldVal}`;
                                        }
                                        // if(typeof fieldValue === 'string'){
                                        //
                                        //     fieldValue = fieldValue.toLowerCase().toString();
                                        //
                                        //     if (fieldValue.includes(searchMulti)) {
                                        //         return true;
                                        //     }
                                        // }
                                        //TODO this is the original
                                        // if (fieldValue.includes(searchMulti)) {
                                        //     return true;
                                        // }
                                        // if (DataCard.debug || true) {
                                        //     console.log('DataCard.filter._displayData_filter.multi', {
                                        //         _fieldVal,
                                        //         searchMulti,
                                        //         fieldMulti : fieldMulti,
                                        //         _originalVal,
                                        //         // _renderVal,
                                        //         dataElement,
                                        //         founded,
                                        //         filterArgument,
                                        //         arraySearchFields
                                        //     });
                                        // }
                                        if (founded) {
                                            return founded;
                                        }
                                    }
                                    return false;
                                });
                            }
                        }
                    }
                }
                //     console.log({
                //         filterArgument,
                //         _displayData: this._displayData,
                //         _data: this._originalData,
                //         fields: fields,
                //         searchFields: arraySearchFields,
                //         searchValue: arraySearchValue
                //     });
            }
            else {
                this._displayData = this._originalData.slice();
            }
            if (this._displayData.length === this._originalData.length || enableFilter) {
                DcEditorFn._enableSortable(that, '.' + cardModel.rowCardContainer.attr.class);
            }
            // processTime = Date.now() - processTime;
            // debug.processTime = processTime;
            // debug.endData = this._displayData;
            // debug._data = this._originalData;
            // debug._filterArguments = this._filterArguments;
            // debug._lastSearchValue = this._lastSearchValue;
            //     console.log('DataCard.filter', debug)
        }
        return this;
    }
    /**
     *
     * @param args
     * @return {this}
     */
    //TODO Verify
    sort(...args) {
        // let processTime = Date.now();
        let [fieldId, isAsc, isMultiField, isCaseInSensitive] = args;
        // if(arguments.length > 2){
        //     _sortArguments = arguments;
        // }
        // if(Helpers._isNotUndefined(_sortArguments) && _sortArguments.length > 2) {
        //     let
        //         sortFieldId = Helpers._isSameType(_sortArguments[0], "") ? _sortArguments[0] : "",
        //         asc = Helpers._isSameType(_sortArguments[1], true) ? _sortArguments[1] : true,
        //         isMultiField = Helpers._isSameType(_sortArguments[2], false) ? _sortArguments[2] : false,
        //         caseInsensitive = Helpers._isSameType(_sortArguments[3], true) ? _sortArguments[3] : true
        //     ;
        //     _displayData = _sort( _displayData, sortFieldId, asc, isMultiField, caseInsensitive);
        // }
        const _this = this;
        const _settings = _this._settings;
        if (arguments.length > 2) {
            // _this._sortArguments = arguments;
            _this._sortArguments = {
                fieldId, isAsc, isMultiField, isCaseInSensitive
            };
        }
        // console.log({_sortArguments: _this._sortArguments});
        if (Helpers._isNotUndefined(_this._sortArguments) /* && _this._sortArguments.length > 2*/) {
            let { fieldId, isAsc, isMultiField, isCaseInSensitive } = this._sortArguments;
            if (typeof fieldId === "string" && Helpers._isStringNotEmpty(fieldId)) {
                let sortField;
                if (isMultiField) {
                    sortField = this._getFieldByUniqueId(fieldId, this._multiFields);
                    let 
                    // arrayItemSrc = Helpers._getObjByArrayProp<TypeItemSrc[]>(_settings, [__PROP.multiFields, __PROP.itemSrc], []),
                    arrayItemSrc = _settings.multiFields.itemSrc, 
                    // multiFieldDataSrc = Helpers._getObjByArrayProp<string>(_settings, [__PROP.multiFields, __PROP.dataSrc], ""),
                    multiFieldDataSrc = _settings.multiFields.dataSrc, arrayItemSrcData = [];
                    if (sortField !== null && !$.isEmptyObject(sortField) &&
                        Helpers._isStringNotEmpty(multiFieldDataSrc) && arrayItemSrc.length > 0) {
                        arrayItemSrc.forEach(function (item) {
                            if (Helpers._isStringNotEmpty(item.data) && item.sort && !arrayItemSrcData.includes(item.data) && item.visible) {
                                arrayItemSrcData.push(item.data);
                            }
                        });
                        if (arrayItemSrcData.length > 0) {
                            _this._displayData = _this._displayData.sort(function (a, b) {
                                let a_test = _this._getMultiDataCompareVal(sortField, a, arrayItemSrcData, multiFieldDataSrc, isAsc, isCaseInSensitive);
                                let b_test = _this._getMultiDataCompareVal(sortField, b, arrayItemSrcData, multiFieldDataSrc, isAsc, isCaseInSensitive);
                                // a = _this._getMultiDataCompareVal(sortField!, a, arrayItemSrcData, multiFieldDataSrc, isAsc!, isCaseInSensitive!);
                                // b = _this._getMultiDataCompareVal(sortField!, b, arrayItemSrcData, multiFieldDataSrc, isAsc!, isCaseInSensitive!);
                                let position = 0, a_value = a_test, b_value = b_test;
                                if (!isAsc) {
                                    a_value = b_test;
                                    b_value = a_test;
                                }
                                if (isCaseInSensitive) {
                                    a_value = a_value.toLowerCase();
                                    b_value = b_value.toLowerCase();
                                }
                                if (DataCard.debug) {
                                    console.log({
                                        a_test,
                                        // a: a,
                                        b_test,
                                        // b: b,
                                        arrayItemSrc: arrayItemSrc,
                                        arrayItemSrcData: arrayItemSrcData,
                                        position: position
                                    });
                                }
                                if (a_value < b_value) {
                                    position = -1;
                                }
                                if (a_value > b_value) {
                                    position = 1;
                                }
                                return position;
                            });
                        }
                    }
                }
                else {
                    sortField = _this._getFieldByUniqueId(fieldId, this._fields);
                    if (sortField !== null && !$.isEmptyObject(sortField)) {
                        _this._displayData = _this._displayData.sort(function _displayData_sort(a, b) {
                            let a_data = a[Object.keys(a)[0]];
                            let b_data = b[Object.keys(b)[0]];
                            let a_test = Helpers._getObjByArrayProp(a_data, sortField.indexes, "");
                            let b_test = Helpers._getObjByArrayProp(b_data, sortField.indexes, "");
                            // a = Helpers._getObjByArrayProp(a_data, sortField!.indexes, "");
                            // b = Helpers._getObjByArrayProp(b_data, sortField!.indexes, "");
                            let compareVal_a = a_test, compareVal_b = b_test;
                            let renderFn = Helpers._getObjByProp(sortField, __PROP.render, null);
                            if (Helpers._isFunction(renderFn)) {
                                compareVal_a = renderFn(a_test, sortField.type, a_data, _settings);
                                compareVal_b = renderFn(b_test, sortField.type, b_data, _settings);
                            }
                            let orderFn = Helpers._getObjByProp(DataCard._ext.order, sortField.type, null);
                            if (Helpers._isFunction(sortField.order)) {
                                orderFn = sortField.order;
                                compareVal_a = orderFn(_settings, sortField, a_test, a_data);
                                compareVal_b = orderFn(_settings, sortField, b_test, b_data);
                            }
                            if (Helpers._isStringNotEmpty(compareVal_a)) {
                                compareVal_a = compareVal_a.trim();
                            }
                            if (Helpers._isStringNotEmpty(compareVal_b)) {
                                compareVal_b = compareVal_b.trim();
                            }
                            let position = 0, value1 = compareVal_a, value2 = compareVal_b;
                            if (!isAsc) {
                                value1 = compareVal_b;
                                value2 = compareVal_a;
                            }
                            if (isCaseInSensitive && Helpers._isStringNotEmpty(value1) && Helpers._isStringNotEmpty(value2)) {
                                value1 = value1.toLowerCase();
                                value2 = value2.toLowerCase();
                            }
                            if (value1 < value2) {
                                position = -1;
                            }
                            if (value1 > value2) {
                                position = 1;
                            }
                            if (DataCard.debug) {
                                console.log('DataCard.sort', {
                                    orderFn: orderFn,
                                    a_test,
                                    b_test,
                                    value1: value1,
                                    value2: value2,
                                    position: position,
                                    sortField: sortField
                                });
                            }
                            return position;
                        });
                    }
                }
            }
            /*let
                sortFieldId = Helpers._isSameType(_this._sortArguments[0], "") ? _this._sortArguments[0] : "",
                asc = Helpers._isSameType(_this._sortArguments[1], true) ? _this._sortArguments[1] : true,
                isMultiField = Helpers._isSameType(_this._sortArguments[2], false) ? _this._sortArguments[2] : false,
                caseInsensitive = Helpers._isSameType(_this._sortArguments[3], true) ? _this._sortArguments[3] : true
            ;

            if (Helpers._isStringNotEmpty(sortFieldId)) {
                let sortField : TypeFieldSettings | null ;
                if (isMultiField) {
                    sortField = this._getFieldByUniqueId(sortFieldId, this._multiFields)!;
                    let
                        arrayItemSrc = Helpers._getObjByArrayProp<TypeItemSrc[]>(_settings, [__PROP.multiFields, __PROP.itemSrc], []),
                        multiFieldDataSrc = Helpers._getObjByArrayProp<string>(_settings, [__PROP.multiFields, __PROP.dataSrc], ""),
                        arrayItemSrcData : string[] = []
                    ;
                    if (
                        sortField !== null && !$.isEmptyObject(sortField) &&
                        Helpers._isStringNotEmpty(multiFieldDataSrc) && arrayItemSrc.length > 0
                    ) {
                        arrayItemSrc.forEach(function (item) {
                            if (Helpers._isStringNotEmpty(item.data) && item.sort && !arrayItemSrcData.includes(item.data) && item.visible) {
                                arrayItemSrcData.push(item.data);
                            }
                        });

                        if (arrayItemSrcData.length > 0) {
                            _this._displayData = _this._displayData.sort(function (a, b) {
                                a = _this._getMultiDataCompareVal(sortField!, a, arrayItemSrcData, multiFieldDataSrc, asc, caseInsensitive);
                                b = _this._getMultiDataCompareVal(sortField!, b, arrayItemSrcData, multiFieldDataSrc, asc, caseInsensitive);

                                let position = 0, a_value = a, b_value = b;
                                if (!asc) {
                                    a_value = b;
                                    b_value = a;
                                }
                                if (caseInsensitive) {
                                    a_value = a_value.toLowerCase();
                                    b_value = b_value.toLowerCase();
                                }
                                if (DataCard.debug) {
                                    console.log({
                                        a: a,
                                        b: b,
                                        arrayItemSrc: arrayItemSrc,
                                        arrayItemSrcData: arrayItemSrcData,
                                        position: position
                                    });
                                }
                                if (a_value < b_value) {
                                    position = -1;
                                }
                                if (a_value > b_value) {
                                    position = 1;
                                }
                                return position;
                            });
                        }
                    }
                } else {
                    sortField = _this._getFieldByUniqueId(sortFieldId, this._fields);
                    if (sortField !== null && !$.isEmptyObject(sortField)) {

                        _this._displayData = _this._displayData.sort(function _displayData_sort(a, b) {
                            let a_data = a[Object.keys(a)[0]];
                            let b_data = b[Object.keys(b)[0]];
                            a = Helpers._getObjByArrayProp(a_data, sortField!.indexes, "");
                            b = Helpers._getObjByArrayProp(b_data, sortField!.indexes, "");
                            let
                                compareVal_a = a,
                                compareVal_b = b
                            ;
                            let renderFn = Helpers._getObjByProp<Function>(sortField, __PROP.render, null);
                            if (Helpers._isFunction(renderFn)) {
                                compareVal_a = renderFn(a, sortField!.type, a_data, _settings);
                                compareVal_b = renderFn(b, sortField!.type, b_data, _settings);
                            }

                            let orderFn = Helpers._getObjByProp<null | boolean | Function>(DataCard._ext.order, sortField!.type, null);

                            if (Helpers._isFunction(sortField!.order)) {
                                orderFn = sortField!.order as Function;
                                compareVal_a = orderFn(_settings, sortField, a, a_data);
                                compareVal_b = orderFn(_settings, sortField, b, b_data);
                            }

                            if (Helpers._isStringNotEmpty(compareVal_a)) {
                                compareVal_a = compareVal_a.trim();
                            }
                            if (Helpers._isStringNotEmpty(compareVal_b)) {
                                compareVal_b = compareVal_b.trim();
                            }

                            let position = 0, value1 = compareVal_a, value2 = compareVal_b;

                            if (!asc) {
                                value1 = compareVal_b;
                                value2 = compareVal_a;
                            }
                            if (caseInsensitive && Helpers._isStringNotEmpty(value1) && Helpers._isStringNotEmpty(value2)) {
                                value1 = value1.toLowerCase();
                                value2 = value2.toLowerCase();
                            }
                            if (value1 < value2) {
                                position = -1;
                            }
                            if (value1 > value2) {
                                position = 1;
                            }

                            if (DataCard.debug) {
                                console.log('DataCard.sort', {
                                    orderFn: orderFn,
                                    a: a,
                                    b: b,
                                    value1: value1,
                                    value2: value2,
                                    position: position,
                                    sortField: sortField
                                });
                            }
                            return position;
                        });
                    }
                }

            }*/
            // if (DataCard.debug || true) {
            //     processTime = Date.now() - processTime;
            //     console.log('DataCard.sort', arguments, {
            //         isAsc,
            //         processTime,
            //         _displayData: this._displayData
            //     })
            // }
        }
        return _this;
    }
    /**
     *
     * @param {number} length
     * @return {any}
     */
    dataLength(length) {
        let returnVal = this._pageLength;
        if (length !== undefined) {
            if (Helpers._isNumeric(length)) {
                this._pageLength = this._settings.pageLength = length;
                returnVal = this;
            }
        }
        if (DataCard.debug) {
            console.log('DataCard.dataLength', {
                _event: "DataCard API length() called",
                arguments: arguments,
                returnVal: returnVal,
                pageLength: this._pageLength
            });
        }
        return returnVal;
    }
    /**
     *
     * @param {number} index
     * @return {number | DataCard}
     */
    page(index) {
        let returnVal = this._currentPage;
        if (index !== undefined) {
            if (Helpers._isNumeric(index)) {
                this._currentPage = index;
                returnVal = this;
            }
        }
        if (DataCard.debug) {
            console.log({
                _event: "DataCard API page() called",
                arguments: arguments,
                returnVal: returnVal,
                _currentPage: this._currentPage
            });
        }
        return returnVal;
    }
    /**
     *
     * @return {Editor}
     */
    editor() {
        return this._editor;
    }
    /**
     *
     * @return {TypeDataCardOptions}
     */
    //TODO Verify
    settings() {
        return this._settings;
    }
    /**
     *
     * @return {StTranslation}
     */
    //TODO Verify
    i18n() {
        return this._i18n;
    }
    //TODO
    updateData(arrayUpdatedData) {
        const that = this;
        const processedData = this._originalData.slice();
        for (const updatedData of arrayUpdatedData) {
            const updatedDataId = Object.keys(updatedData)[0];
            processedData.every((originalData, index, theArray) => {
                const originalDataId = Object.keys(originalData)[0];
                // console.log({arrayUpdatedData, processedData, updatedDataId, originalDataId})
                if (originalDataId === updatedDataId) {
                    const $actualCard = DcCardFn._getCardById(updatedDataId, that);
                    if ($actualCard) {
                        let settings = that.settings(), arrayFields = that.fields(), multiField = settings.multiFields, multiFieldDataSrc = multiField.dataSrc, arrayFieldsMulti = that.multiFields(), itemSrc = multiField.itemSrc, $template = that.template();
                        const $updatedCard = DcCardRender._updateCardData(settings, updatedData, $template, arrayFields, multiFieldDataSrc, arrayFieldsMulti, itemSrc, that);
                        if ($actualCard.hasClass(dcModel.selectedClass)) {
                            $updatedCard.addClass(dcModel.selectedClass);
                        }
                        $actualCard.replaceWith($updatedCard);
                    }
                    theArray[index] = {
                        [updatedDataId]: updatedData[updatedDataId]
                    };
                    return false;
                }
                return true;
            });
        }
        this.data(processedData)
            .filter(this._filterArguments)
            .sort()
            .updateInfo();
        DcPaginationFn._updateSelectedItemInfo(this);
        DcFn.initListener(this, this._editor);
        // console.log('dataCard.updateData()', {processedData, arrayUpdatedData, _originalData : this._originalData})
    }
    removeData(dataRemoved) {
        const that = this;
        const $selectedCard = DcCardFn._getSelectedCards(this._settings.jquerySelector);
        const removedIds = dataRemoved.map((item) => {
            const id = Object.keys(item)[0];
            const $actualCard = DcCardFn._getCardById(id, that);
            $actualCard.remove();
            return id;
        });
        const filteredData = this._originalData.filter((item) => {
            const itemId = Object.keys(item)[0];
            return !removedIds.includes(itemId);
        });
        this.data(filteredData)
            .filter(this._filterArguments)
            .sort()
            .updateInfo();
        // console.log('dataCard.removeData()', {_originalData : this._originalData, dataRemoved, removedIds, filteredData, $selectedCard})
        DcPaginationFn._updateSelectedItemInfo(this);
    }
    addData(_data) {
        const data = this._originalData;
        const mergeData = data.concat(_data);
        const $selectedCard = DcCardFn._getSelectedCards(this._settings.jquerySelector);
        this.data(mergeData)
            .filter(this._filterArguments)
            .sort()
            .updateInfo()
            .draw();
        for (const _insertData of _data) {
            let elementId = Object.keys(_insertData)[0];
            DcCardFn._animateCard(DcCardFn._getCardById(elementId, this));
        }
        // console.log('dataCard.addData()', {data, _data, mergeData, $selectedCard, })
        $.each($selectedCard, (index, elem) => {
            const id = $(elem).attr(dcModel.dataEditorIdAttr);
            let $element = DcCardFn._getCardById(id, this);
            // console.log({$element,id, elem})
            $element.addClass(dcModel.selectedClass);
        });
        DcPaginationFn._updateSelectedItemInfo(this);
    }
    /**
     *
     * @param {JQuery.Selector} sourceElementIdSelector
     * @param {Partial<TypeDataCardOptions>} options
     * @param socketEvents
     * @return {DataCard}
     */
    static create(sourceElementIdSelector, options, socketEvents) {
        return __awaiter(this, void 0, void 0, function* () {
            // const dataCard =  new DataCard(sourceElementIdSelector, options);
            // return Promise.resolve(dataCard);
            return new Promise((resolve) => __awaiter(this, void 0, void 0, function* () {
                const dataCard = yield new DataCard(sourceElementIdSelector, options, socketEvents);
                resolve(dataCard._initialize().then(() => dataCard));
            }));
        });
    }
}
/**
 *
 * @type {{[p: string]: TypeOrderExtension}}
 */
DataCard._ext = {
    order: {
        datetime: function $_fn_dataCard_ext_datetime(settings, field, data, rowData) {
            // if (DataCard.debug) {
            //     console.log('DataCard.$_fn_dataCard_ext_datetime')
            // }
            return parseInt(data);
        },
        time: function $_fn_dataCard_ext_time(settings, field, data, rowData) {
            // if (DataCard.debug) {
            //     console.log('DataCard.$_fn_dataCard_ext_time')
            // }
            return parseInt(data);
        },
        date: function $_fn_dataCard_ext_date(settings, field, data, rowData) {
            // if (DataCard.debug) {
            //     console.log('DataCard.$_fn_dataCard_ext_date')
            // }
            return parseInt(data);
        }
    }
};
/**
 *
 * @type {boolean}
 */
DataCard.debug = false;
(function () {
    window.DataCard = DataCard;
    window.FormBuilder = FormBuilder;
    window.PortalApp = PortalApp;
})();
