const __PROP = {
    action: "action",
    buttons: "buttons",
    cardButtons: 'cardButtons',
    caseInsensitive: "caseInsensitive",
    className: "className",
    columnDisplay: "columnDisplay",
    controlPanel: "controlPanel",
    create: "create",
    data: "data",
    dataSrc: "dataSrc",
    direction: "direction",
    edit: "edit",
    editorTemplate: 'editorTemplate',
    fields: "fields",
    files: "files",
    filterHiddenGroup: 'filterHiddenGroup',
    fixedControl: "fixedControl",
    formButtons: "formButtons",
    icon: "icon",
    idSrc: "idSrc",
    imageSrc: "imageSrc",
    itemSrc: "itemSrc",
    jquerySelector: "jquerySelector",
    language: "language",
    lengthMenu: "lengthMenu",
    message: "message",
    multiFields: "multiFields",
    name: "name",
    offSet: "offSet",
    options: "options",
    ordering: "ordering",
    fieldGroup: "fieldGroup",
    pageLength: "pageLength",
    render: "render",
    remove: "remove",
    rowReorder: "rowReorder",
    search: "search",
    select: "select",
    template: "template",
    title: "title",
    cardTitle: "cardTitle",
    titleSrc: "titleSrc",
    value: "value"
};
export default __PROP;
