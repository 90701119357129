var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import $ from "jquery";
import FormBuilderEditorStatic from "../../form-builder-editor-static.js";
import FormResponseBuilderStatic from "./form-response-builder/form-response-builder-static.js";
const appFormQuestionEditorItem = 'app_form_questions';
const appFormQuestionI18nEditorItem = 'app_form_question_i18ns';
export { appFormQuestionEditorItem, appFormQuestionI18nEditorItem };
export default class FormQuestionBuilderStatic {
    static _renderQuestion(formBuilder, formQuestion, $questionAccordion, formGroupQuestionParentAccordionId) {
        return __awaiter(this, void 0, void 0, function* () {
            const { formQuestionId, label, order, isSubQuestion, responses, isActive, isEnabled, questionType, translations, required } = formQuestion;
            const formQuestionAccordionBodyId = `form-question-accordion-collapse-id-${formQuestionId}`;
            const $questionAccordionItem = $(`
            <div class="form-question accordion-item border">
                
            </div>
        `);
            $questionAccordion.append($questionAccordionItem);
            console.log(`FormQuestionBuilderStatic._renderQuestion`, {
                formBuilder, formQuestion, $questionAccordion
            });
            yield FormQuestionBuilderStatic._renderFormQuestionHeader(formBuilder, formQuestion, $questionAccordionItem, formQuestionAccordionBodyId);
            yield FormQuestionBuilderStatic._renderFormQuestionBody(formBuilder, formQuestion, $questionAccordionItem, formQuestionAccordionBodyId, formGroupQuestionParentAccordionId);
        });
    }
    static _renderFormQuestionHeader(formBuilder, formQuestion, $formGroupQuestionElement, formQuestionAccordionBodyId) {
        return __awaiter(this, void 0, void 0, function* () {
            const { formQuestionId, label, order, isSubQuestion, responses, isActive, isEnabled, questionType, translations, required } = formQuestion;
            const $formGroupQuestionHeader = $(`
            <div class="form-question-header accordion-header d-flex align-items-center border ps-2">
                <h5 class="flex-fill">
                    Question Label : ${FormBuilderEditorStatic._createEditorField(appFormQuestionEditorItem, formQuestionId, 'label', label)} <br>
                    Question type : ${FormBuilderEditorStatic._createEditorField(appFormQuestionEditorItem, formQuestionId, 'questionType', questionType)} <br>
                </h5>
                <button 
                    style="width: auto" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" 
                    data-bs-target="#${formQuestionAccordionBodyId}" aria-expanded="false" aria-controls="${formQuestionAccordionBodyId}">
                
               
                </button>
            </div>
        `);
            console.log(`FormQuestionBuilderStatic._renderFormQuestionHeader`, {
                formBuilder, formQuestion, $formGroupQuestionElement
            });
            $formGroupQuestionElement.append($formGroupQuestionHeader);
        });
    }
    static _renderFormQuestionBody(formBuilder, formQuestion, $formGroupQuestionElement, formQuestionAccordionBodyId, formGroupQuestionParentAccordionId) {
        return __awaiter(this, void 0, void 0, function* () {
            const { formQuestionId, label, order, isSubQuestion, responses, isActive, isEnabled, questionType, translations, required } = formQuestion;
            const $formQuestionAccordion = FormBuilderEditorStatic._createAccordionCollapse('form-question-body', formQuestionAccordionBodyId, formGroupQuestionParentAccordionId);
            // const $formQuestionAccordion = $(`
            //     <div class="form-question-body accordion-collapse collapse"  id="${formQuestionAccordionBodyId}" data-bs-parent="#${formGroupQuestionParentAccordionId}">
            //         <div class="accordion-body">
            //         </div>
            //     </div>
            // `);
            $formGroupQuestionElement.append($formQuestionAccordion);
            const $formQuestionBody = $('.accordion-body', $formQuestionAccordion);
            yield FormQuestionBuilderStatic._renderFormQuestionContent(formBuilder, formQuestion, $formQuestionBody);
            console.log(`FormQuestionBuilderStatic._renderFormQuestionBody`, {
                formBuilder, formQuestion, $formGroupQuestionElement
            });
            // if(responses){
            const formResponseParentAccordionId = `form-question-accordion-id-${formQuestionId}`;
            const $formResponseElement = $(`<div class="responses"/>`);
            $formQuestionBody.append($formResponseElement);
            const $responseAccordion = FormBuilderEditorStatic._createAccordion('responses-accordion', formResponseParentAccordionId);
            // const $responseAccordion = $(`
            //     <div class="accordion accordion-flush border" id="${formResponseParentAccordionId}">
            //
            //     </div>
            // `);
            $formResponseElement.append($responseAccordion);
            yield FormResponseBuilderStatic._renderResponses(formBuilder, formQuestion, responses, $responseAccordion);
            // for(const response of responses){
            //     await FormResponseBuilderStatic._renderResponse(formBuilder, formQuestion, response, $responseAccordion)
            // }
            // }
        });
    }
    static _renderFormQuestionContent(formBuilder, formQuestion, $formQuestionBody) {
        return __awaiter(this, void 0, void 0, function* () {
            const { formQuestionId, label, order, isSubQuestion, responses, isActive, isEnabled, questionType, translations, required } = formQuestion;
            console.log(`FormQuestionBuilderStatic._renderFormQuestionContent`, {
                formBuilder, formQuestion
            });
            const $formQuestionContent = $(`<div class="form-question-content"></div>`);
            $formQuestionBody.append($formQuestionContent);
            yield FormQuestionBuilderStatic._renderFormQuestionContentTranslations(formBuilder, formQuestion, $formQuestionContent);
        });
    }
    static _renderFormQuestionContentTranslations(formBuilder, formQuestion, $formQuestionContent) {
        return __awaiter(this, void 0, void 0, function* () {
            const { formQuestionId, label, order, isSubQuestion, responses, isActive, isEnabled, questionType, translations, required } = formQuestion;
            console.log(`FormQuestionBuilderStatic._renderFormQuestionContentTranslations`, {
                formBuilder, formQuestion
            });
            if (translations) {
                // const formIdCode = formGroupQuestion.formIdCode;
                const $formQuestionContentTranslations = $(`<div class="form-question-content-translations"/>`);
                $formQuestionContent.append($formQuestionContentTranslations);
                const $navTabs = $(`<ul class="nav nav-tabs nav-fill" id="form-question-navs-${formQuestionId}" role="tablist"/>`);
                $formQuestionContentTranslations.append($navTabs);
                const $tabContent = $(`<div class="tab-content" id="form-question-tab-content-${formQuestionId}"/>`);
                $formQuestionContentTranslations.append($tabContent);
                for (const [language, formQuestionDataTranslation] of Object.entries(translations)) {
                    const tabPaneId = `form-question-tab-pane-${formQuestionId}-${language}`;
                    const $navList = $(`<li class="nav-item">
                        <button class="nav-link" data-bs-toggle="pill" data-bs-target="#${tabPaneId}" 
                            type="button" role="tab" aria-controls="pills-home" aria-selected="false">
                                ${language}
                        </button>
                    </li>`);
                    $navTabs.append($navList);
                    const $tabPane = $(`<div class="tab-pane p-5 border border-top-0" id="${tabPaneId}" />`);
                    $tabContent.append($tabPane);
                    yield FormQuestionBuilderStatic._renderFormQuestionContentTranslationElement(formBuilder, language, formQuestionDataTranslation, $tabPane);
                }
                $('.nav-link', $navTabs).first().addClass('active').attr({ 'aria-selected': 'true' });
                $('.tab-pane', $tabContent).first().addClass('show active');
            }
        });
    }
    static _renderFormQuestionContentTranslationElement(formBuilder, language, formGroupQuestionDataTranslation, $tabPane) {
        return __awaiter(this, void 0, void 0, function* () {
            const { formQuestionId, questionDescription, questionMessage, questionName, questionTitle, id } = formGroupQuestionDataTranslation;
            const $formI18n = $(`<div class="app_form_question_i18ns row"/>`);
            $tabPane.append($formI18n);
            const $formName = $(`
            <div data-field="questionName">Question Name : ${yield FormBuilderEditorStatic._createTranslationEditorField(appFormQuestionI18nEditorItem, id, language, `questionName`, questionName)}</div>
        `);
            $formI18n.append($formName);
            const $formTitle = $(`
            <div data-field="questionTitle">Question Title : ${yield FormBuilderEditorStatic._createTranslationEditorField(appFormQuestionI18nEditorItem, id, language, `questionTitle`, questionTitle)}</div>
        `);
            $formI18n.append($formTitle);
            // const $formDescription = $(``);
            // const $formTitle = $(``);
            // const $formMessage = $(``);
            // const $formIdCode = $(``);
            console.log(`FormQuestionBuilderStatic._renderFormQuestionContentTranslationElement`, {
                formBuilder, language, formGroupQuestionDataTranslation
            });
        });
    }
}
