const _timeHelper = {
    minSec: 60,
    month: 12,
    hours: 24,
    min: 60,
    sec: 60,
    type: {
        year: "year",
        month: "month",
        hour: "hour",
        day: "day",
        min: "min",
        sec: "sec"
    }
};
export default _timeHelper;
