import { appActiveLanguageApiRoute } from "../../../_portal/routes/routes.js";
import Helpers from "../../../dist/_utils/helpers.js";
export default class PortalApp {
    static _fetchAll(...args) {
        return Promise.all(args.map(item => {
            const { url, bodyJsonStringify, method = "POST" } = item;
            return PortalApp._fetch(url, bodyJsonStringify, method);
        }));
    }
    static _fetch(url, bodyJsonStringify, method = 'POST') {
        return fetch(url, {
            method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: bodyJsonStringify
        }).then(res => res.json());
    }
    static _getActiveLanguage(url = `/api${appActiveLanguageApiRoute}`) {
        return PortalApp._fetch(url, JSON.stringify({ _lang: Helpers._getLang() }));
        // return fetch(url, {
        //
        //     method : "POST",
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     },
        //     body : JSON.stringify({_lang : Helpers._getLang()})
        // })
        //     .then(res => res.json())
        // .then((response)=>{
        //     re
        //     console.log({response})
        // })
    }
    static _initParameter() {
        return PortalApp._fetchAll({ url: `/api${appActiveLanguageApiRoute}`, bodyJsonStringify: JSON.stringify({ _lang: Helpers._getLang() }) }, { url: `/api${appActiveLanguageApiRoute}`, bodyJsonStringify: JSON.stringify({ _lang: Helpers._getLang() }) });
    }
}
