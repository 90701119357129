import $ from "jquery";
import DtTableStatic from "./dt-table-static.js";
export default class DtTableRender {
    static _printTableTitle(title, tableTitleId) {
        if (typeof tableTitleId === "undefined") {
            tableTitleId = DtTableStatic._tableTitleDef;
        }
        let $tableTitle = $("#" + tableTitleId);
        if ($tableTitle.length > 0) {
            if (title !== "") {
                $tableTitle.html(title);
            }
        }
    }
}
