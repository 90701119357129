var EnumDcButton;
(function (EnumDcButton) {
    EnumDcButton["create"] = "create";
    EnumDcButton["edit"] = "edit";
    EnumDcButton["remove"] = "remove";
    EnumDcButton["selectAll"] = "selectAll";
    EnumDcButton["selectNone"] = "selectNone";
    EnumDcButton["select1"] = "select1";
    EnumDcButton["edit1"] = "edit1";
    EnumDcButton["remove1"] = "remove1";
    EnumDcButton["reOrder"] = "reOrder";
})(EnumDcButton || (EnumDcButton = {}));
var EnumDcEvent;
(function (EnumDcEvent) {
    EnumDcEvent["onStart"] = "onStart";
    EnumDcEvent["started"] = "started";
    EnumDcEvent["initLoad"] = "initLoad";
    EnumDcEvent["preLoad"] = "preLoad";
    EnumDcEvent["onLoad"] = "onLoad";
    EnumDcEvent["postLoad"] = "postLoad";
    EnumDcEvent["initDraw"] = "initDraw";
    EnumDcEvent["preDraw"] = "preDraw";
    EnumDcEvent["onDraw"] = "onDraw";
    EnumDcEvent["postDraw"] = "postDraw";
    EnumDcEvent["postSubmit"] = "postSubmit";
})(EnumDcEvent || (EnumDcEvent = {}));
var EnumOrderingDirection;
(function (EnumOrderingDirection) {
    EnumOrderingDirection["asc"] = "ASC";
    EnumOrderingDirection["desc"] = "DESC";
})(EnumOrderingDirection || (EnumOrderingDirection = {}));
export { EnumDcButton, EnumDcEvent, EnumOrderingDirection };
