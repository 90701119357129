/**
 *
 */
var EnumServerDcCardAction;
(function (EnumServerDcCardAction) {
    EnumServerDcCardAction["read"] = "read";
    EnumServerDcCardAction["create"] = "create";
    EnumServerDcCardAction["edit"] = "edit";
    EnumServerDcCardAction["remove"] = "remove";
    EnumServerDcCardAction["upload"] = "upload";
})(EnumServerDcCardAction || (EnumServerDcCardAction = {}));
/**
 *
 */
var EnumServerDcCardEvents;
(function (EnumServerDcCardEvents) {
    EnumServerDcCardEvents["preGet"] = "preGet";
    EnumServerDcCardEvents["postGet"] = "postGet";
    EnumServerDcCardEvents["preCreate"] = "preCreate";
    EnumServerDcCardEvents["writeCreate"] = "writeCreate";
    EnumServerDcCardEvents["postCreate"] = "postCreate";
    EnumServerDcCardEvents["preEdit"] = "preEdit";
    EnumServerDcCardEvents["writeEdit"] = "writeEdit";
    EnumServerDcCardEvents["postEdit"] = "postEdit";
    EnumServerDcCardEvents["preRemove"] = "preRemove";
    EnumServerDcCardEvents["postRemove"] = "postRemove";
    EnumServerDcCardEvents["preUpload"] = "preUpload";
    EnumServerDcCardEvents["postUpload"] = "postUpload";
    EnumServerDcCardEvents["processed"] = "processed";
})(EnumServerDcCardEvents || (EnumServerDcCardEvents = {}));
export { EnumServerDcCardAction, EnumServerDcCardEvents };
