// import {IFieldType} from "datatables.net-editor/types/model/fieldType.js";
// import type {IFieldType} from "datatables.net-editor/types/model/fieldType.js";
// import $ from 'jquery';
import 'datatables.net';
// import 'datatables.net-editor';
import Editor from "datatables.net-editor";
import Helpers from "../../../../../dist/_utils/helpers.js";
// import Log from "../../../../src/_utils/log.js";
import _separator from "../../../utils/_separator.js";
import _timeHelper from "../../../utils/_time-helper.js";
import ColumnStatic from "../../table/column/column-static.js";
import { editorFieldsTypeDefault } from "../../model/dt-default.js";
import DataTableStatic from "../../table/data-table-static.js";
import moment from 'moment';
import './field-type.css';
(function ($) {
    // let DataTable = $.fn.dataTable;
    let FieldSetting = {
        placeholderValue: '',
        optionsPair: ''
    };
    let _fieldTypesDebug = false;
    let _triggerChange = function (input) {
        let arrayDebug = [];
        arrayDebug[0] = "_triggerChange called";
        arrayDebug.input = input;
        setTimeout(function () {
            input.trigger('change', { editor: true, editorSet: true }); // editorSet legacy
        }, 0);
        // Log.trace(arrayDebug, _fieldTypesDebug);
    };
    // if (!DataTable.ext.editorFields) {
    //     DataTable.ext.editorFields = {};
    // }
    if (!Editor.fieldTypes) {
        Editor.fieldTypes = {};
    }
    // let Editor = $.fn.dataTable.Editor;
    // let _fieldTypes /*: {[key: string] : TypeFieldSettings}*/ = DataTable.ext.editorFields;
    let _fieldTypes = Editor.fieldTypes;
    let baseFieldType = $.extend(true, {}, editorFieldsTypeDefault, {
        _debug: false,
        create: function (fieldSetting) {
            fieldSetting._input = $('<input/>').attr($.extend({
                id: Editor.safeId(fieldSetting.id),
                type: 'text'
            }, fieldSetting.attr || {}));
            // if (_fieldTypesDebug || baseFieldType._debug) {
            //     console.log("baseFieldType.create(fieldSetting)->called", {
            //         fieldSetting: fieldSetting
            //     });
            // }
            return fieldSetting._input[0];
        },
        set: function (fieldSetting, val) {
            let editable = typeof fieldSetting.editable === "undefined" || fieldSetting.editable !== "false";
            let content = val;
            let options = fieldSetting.options;
            if (editable) {
                fieldSetting._input.val(val);
            }
            else {
                // if (content !== "") {
                //     if (options === undefined) {
                //         content = val;
                //     } else {
                //         Object.keys(options).forEach(function (key) {
                //             if (options[key] === val) {
                //                 content = key;
                //             }
                //         });
                //     }
                // }
                if (typeof options === "object") {
                    Object.keys(options).forEach(function (key) {
                        if (options[key] === val) {
                            content = key;
                        }
                    });
                }
                $(fieldSetting._input).html(content);
            }
            // if (_fieldTypesDebug || baseFieldType._debug) {
            //     console.log("baseFieldType.set(fieldSetting, val)->called", {
            //         fieldSetting: fieldSetting,
            //         val: val,
            //         editable: editable,
            //         content: content,
            //         options: options
            //
            //     });
            // }
            _triggerChange(fieldSetting._input);
        },
        get: function (fieldSetting) {
            let val = fieldSetting._input.val();
            // if (_fieldTypesDebug || baseFieldType._debug) {
            //     console.log("baseFieldType.get(fieldSetting)->called", {
            //         fieldSetting: fieldSetting,
            //         val: val
            //     });
            // }
            return val;
        },
        hide: function (fieldSetting) {
            // if (_fieldTypesDebug || baseFieldType._debug) {
            //     console.log("baseFieldType.hide(fieldSetting)->called", {
            //         fieldSetting: fieldSetting
            //     });
            // }
            fieldSetting._input.hide();
        },
        show: function (fieldSetting) {
            // if (_fieldTypesDebug || baseFieldType._debug) {
            //     console.log("baseFieldType.show()->called");
            // }
            fieldSetting._input.hide();
        },
        enable: function (fieldSetting) {
            fieldSetting._input.prop('disabled', false);
            // if (_fieldTypesDebug || baseFieldType._debug) {
            //     console.log("baseFieldType.enable(fieldSetting)->called", {
            //         fieldSetting: fieldSetting
            //     });
            // }
        },
        disable: function (fieldSetting) {
            fieldSetting._input.prop('disabled', true);
            // if (_fieldTypesDebug || baseFieldType._debug) {
            //     console.log("baseFieldType.disable(fieldSetting)->called", {
            //         fieldSetting: fieldSetting
            //     });
            // }
        },
        canReturnSubmit: function (fieldSetting, node) {
            let arrayDebug = [];
            arrayDebug[0] = "canReturnSubmit called";
            arrayDebug.fieldSetting = fieldSetting;
            arrayDebug.node = node;
            // Log.trace(arrayDebug, _fieldTypesDebug);
            return true;
        }
    });
    _fieldTypes.text = $.extend(true, {}, baseFieldType, {
        _debug: false,
        create: function (fieldSetting) {
            fieldSetting._input = $('<input/>').attr($.extend({
                id: Editor.safeId(fieldSetting.id),
                type: 'text'
            }, fieldSetting.attr || {}));
            // if (_fieldTypesDebug || _fieldTypes.text._debug) {
            //     console.log("_fieldTypes.text.create(fieldSetting)->called", {
            //         fieldSetting: fieldSetting
            //     });
            // }
            return fieldSetting._input[0];
        },
        enable: function (fieldSetting) {
            fieldSetting._input.prop('disabled', false);
            // if (_fieldTypesDebug || _fieldTypes.text._debug) {
            //     console.log("_fieldTypes.text.enable(fieldSetting)->called", {
            //         fieldSetting: fieldSetting
            //     });
            // }
        },
        disable: function (fieldSetting) {
            fieldSetting._input.prop('disabled', true);
            // if (_fieldTypesDebug || _fieldTypes.text._debug) {
            //     console.log("_fieldTypes.text.disable(fieldSetting)->called", {
            //         fieldSetting: fieldSetting
            //     });
            // }
        }
    });
    _fieldTypes.select = $.extend(true, {}, baseFieldType, {
        _debug: true,
        // Locally "private" function that can be reused for the create and update methods
        _addOptions: function (fieldSetting, opts, append) {
            // console.log({
            //     arguments: arguments
            // });
            let elOpts = fieldSetting._input[0].options;
            let countOffset = 0;
            if (!append) {
                elOpts.length = 0;
                if (fieldSetting.placeholder !== undefined) {
                    let placeholderValue = fieldSetting.placeholderValue !== undefined ?
                        fieldSetting.placeholderValue :
                        '';
                    countOffset += 1;
                    elOpts[0] = new Option(fieldSetting.placeholder, placeholderValue);
                    let disabled = fieldSetting.placeholderDisabled !== undefined ?
                        fieldSetting.placeholderDisabled :
                        true;
                    elOpts[0].hidden = disabled; // can't be hidden if not disabled!
                    elOpts[0].disabled = disabled;
                    elOpts[0]._editor_val = placeholderValue;
                }
            }
            else {
                countOffset = elOpts.length;
            }
            if (opts) {
                Editor.pairs(opts, FieldSetting.optionsPair, function (val, label, i, attr) {
                    let option = new Option(label, val);
                    // @ts-ignore TODO
                    option._editor_val = val;
                    if (attr) {
                        $(option).attr(attr);
                    }
                    elOpts[i + countOffset] = option;
                });
            }
            // let elOpts = fieldSetting._input[0].options;
            // let countOffset = 0;
            //
            // if (_fieldTypesDebug || _fieldTypes.select._debug) {
            //     console.log("_fieldTypes.select._addOptions(fieldSetting, opts, append)->called", {
            //         fieldSetting : fieldSetting,
            //         opts: opts,
            //         append: append,
            //         elOpts: elOpts,
            //         countOffset: countOffset
            //     });
            // }
            //
            // if (opts) {
            //     Editor.pairs(opts, fieldSetting.optionsPair, function (val, label, i, attr) {
            //         let option = new Option(label, val);
            //         option._editor_val = val;
            //
            //         if (attr) {
            //             $(option).attr(attr);
            //         }
            //
            //         elOpts[i + countOffset] = option;
            //     });
            // }
        },
        create: function (fieldSetting) {
            fieldSetting._enabled = true;
            fieldSetting._input = $('<select/>')
                .attr($.extend({
                required: true,
                id: Editor.safeId(fieldSetting.id),
                multiple: fieldSetting.multiple === true
            }, fieldSetting.attr || {}))
                .on('change.dte', function (e, d) {
                // On change, get the user selected value and store it as the
                // last set, so `update` can reflect it. This way `_lastSet`
                // always gives the intended value, be it set via the API or by
                // the end user.
                if (!d || !d.editor) {
                    fieldSetting._lastSet = _fieldTypes.select.get(fieldSetting);
                }
            });
            // if (_fieldTypesDebug || _fieldTypes.select._debug) {
            //     console.log("_fieldTypes.select.create(fieldSetting)->called", {
            //         fieldSetting: fieldSetting
            //     });
            // }
            _fieldTypes.select._addOptions(fieldSetting, fieldSetting.options || fieldSetting.ipOpts, false);
            return fieldSetting._input[0];
        },
        update: function (fieldSetting, options, append) {
            // if (_fieldTypesDebug || _fieldTypes.select._debug) {
            //     console.log("_fieldTypes.select.update(fieldSetting, opts, append)->called", {
            //         fieldSetting : fieldSetting,
            //         options,
            //         append: append
            //     });
            // }
            _fieldTypes.select._addOptions(fieldSetting, options, append);
            // Attempt to set the last selected value (set by the API or the end
            // user, they get equal priority)
            let lastSet = fieldSetting._lastSet;
            if (lastSet !== undefined) {
                _fieldTypes.select.set(fieldSetting, lastSet, true);
            }
            _triggerChange(fieldSetting._input);
        },
        get: function (fieldSetting) {
            let val = fieldSetting._input.find('option:selected').map(function () {
                return this._editor_val;
            }).toArray();
            if (fieldSetting.multiple) {
                return fieldSetting.separator ?
                    val.join(fieldSetting.separator) :
                    val;
            }
            let value = val.length ? val[0] : null;
            // if (_fieldTypesDebug || _fieldTypes.select._debug) {
            //     console.log("_fieldTypes.select.get(fieldSetting)->called", {
            //         fieldSetting: fieldSetting,
            //         value: value,
            //         val
            //     });
            // }
            return value;
        },
        set: function (fieldSetting, val, localUpdate) {
            if (!localUpdate) {
                fieldSetting._lastSet = val;
            }
            // Can't just use `$().val()` because it won't work with strong types
            if (fieldSetting.multiple && fieldSetting.separator && !Array.isArray(val)) {
                val = typeof val === 'string' ?
                    val.split(fieldSetting.separator) :
                    [];
            }
            else if (!Array.isArray(val)) {
                val = [val];
            }
            let i, len = val.length, found, allFound = false;
            let options = fieldSetting._input.find('option');
            fieldSetting._input.find('option').each(function () {
                // console.log({_this : this})
                found = false;
                for (i = 0; i < len; i++) {
                    // Weak typing
                    if (this._editor_val == val[i]) {
                        found = true;
                        allFound = true;
                        break;
                    }
                }
                this.selected = found;
            });
            // If there is a placeholder, we might need to select it if nothing else
            // was selected. It doesn't make sense to select when multi is enabled
            if (fieldSetting.placeholder && !allFound && !fieldSetting.multiple && options.length) {
                options[0].selected = true;
            }
            // Update will call change itself, otherwise multiple might be called
            if (!localUpdate) {
                // _triggerChange( fieldSetting._input );
            }
            // if (_fieldTypesDebug || _fieldTypes.select._debug) {
            //     console.log("_fieldTypes.select.set(fieldSetting, val, localUpdate)->called", {
            //         fieldSetting: fieldSetting,
            //         val: val,
            //         localUpdate: localUpdate
            //     });
            // }
            _triggerChange(fieldSetting._input);
            // if(!fieldSetting._enabled){
            //
            //     _fieldTypes.select.disable( fieldSetting);
            // }else {
            //
            //
            //     _fieldTypes.select.enable( fieldSetting);
            // }
            return allFound;
        },
        enable: function (fieldSetting) {
            $(fieldSetting._input).prop('disabled', false);
            fieldSetting._enabled = true;
            // if (_fieldTypesDebug || _fieldTypes.select._debug) {
            //     console.log("_fieldTypes.select.enable(fieldSetting)->called", {
            //         fieldSetting: fieldSetting
            //     });
            // }
        },
        disable: function (fieldSetting) {
            fieldSetting._enabled = false;
            $(fieldSetting._input).prop('disabled', true);
            // if (_fieldTypesDebug || _fieldTypes.select._debug) {
            //     console.log("_fieldTypes.select.disable(fieldSetting)->called", {
            //         fieldSetting: fieldSetting
            //     });
            // }
            return fieldSetting._input;
        },
        destroy: function (fieldSetting) {
            fieldSetting._input.off('change.dte');
            // if (_fieldTypesDebug || _fieldTypes.select._debug) {
            //     console.log("_fieldTypes.select.destroy(fieldSetting)->called", {
            //         fieldSetting : fieldSetting
            //     });
            // }
        }
    });
    _fieldTypes.checkbox = $.extend(true, {}, baseFieldType, {
        // Locally "private" function that can be reused for the create and update methods
        _addOptions: function (fieldSetting, opts, append) {
            // let val, label;
            let jqInput = fieldSetting._input;
            let offset = 0;
            if (!append) {
                jqInput.empty();
            }
            else {
                offset = $('input', jqInput).length;
            }
            if (opts) {
                Editor.pairs(opts, FieldSetting.optionsPair, function (val, label, i, attr) {
                    jqInput.append('<div class="form-check col-6 col-md-4">' +
                        '<input class="form-check-input" id="' + Editor.safeId(fieldSetting.id) + '_' + (i + offset) + '" type="checkbox" />' +
                        '<label class="form-check-label" for="' + Editor.safeId(fieldSetting.id) + '_' + (i + offset) + '">' +
                        '<span class="badge badge-pill badge-primary">' + label + '</span>'
                        + '</label>' +
                        '</div>');
                    // @ts-ignore TODO
                    $('input:last', jqInput).attr('value', val)[0]._editor_val = val;
                    if (attr) {
                        $('input:last', jqInput).attr(attr);
                    }
                });
            }
        },
        create: function (fieldSetting) {
            let container = $('<div />').addClass("container");
            fieldSetting._input = $('<div class="row"/>').appendTo(container);
            _fieldTypes.checkbox._addOptions(fieldSetting, fieldSetting.options || fieldSetting.ipOpts);
            return fieldSetting._input[0];
        },
        get: function (fieldSetting) {
            let out = [];
            let selected = fieldSetting._input.find('input:checked');
            if (selected.length) {
                selected.each(function () {
                    out.push(this._editor_val);
                });
            }
            else if (fieldSetting.unselectedValue !== undefined) {
                out.push(fieldSetting.unselectedValue);
            }
            let separator = fieldSetting.separator === undefined ? _separator.array : fieldSetting.separator;
            return out.join(separator);
            // let returnVal = out.join(separator);
            // return returnVal;
        },
        set: function (fieldSetting, val) {
            let jqInputs = fieldSetting._input.find('input');
            if (!Array.isArray(val) && typeof val === 'string') {
                val = val.split(fieldSetting.separator || _separator.array);
            }
            else if (!Array.isArray(val)) {
                val = [val];
            }
            let i, len = val.length, found;
            jqInputs.each(function () {
                found = false;
                for (i = 0; i < len; i++) {
                    if (this._editor_val === val[i]) {
                        found = true;
                        break;
                    }
                }
                this.checked = found;
            });
            _triggerChange(jqInputs);
        },
        enable: function (fieldSetting) {
            fieldSetting._input.find('input').prop('disabled', false);
        },
        disable: function (fieldSetting) {
            fieldSetting._input.find('input').prop('disabled', true);
        },
        update: function (fieldSetting, options, append) {
            // Get the current value
            let checkbox = _fieldTypes.checkbox;
            let currVal = checkbox.get(fieldSetting);
            checkbox._addOptions(fieldSetting, options, append);
            checkbox.set(fieldSetting, currVal);
        }
    });
    _fieldTypes.radio = $.extend(true, {}, baseFieldType, {
        // Locally "private" function that can be reused for the create and update methods
        _addOptions: function (fieldSetting, opts, append) {
            // let val, label;
            let jqInput = fieldSetting._input;
            let offset = 0;
            if (!append) {
                jqInput.empty();
            }
            else {
                offset = $('input', jqInput).length;
            }
            // console.log({
            //     fieldSetting: fieldSetting
            // });
            if (opts) {
                Editor.pairs(opts, FieldSetting.optionsPair, function (val, label, i, attr) {
                    jqInput.append(`<div class=" col-6">
                            <div class="form-check">
                            <input class="form-check-input" id="${Editor.safeId(fieldSetting.id)}_${(i + offset)}" type="radio" name="dt-radio-${fieldSetting.name}"/>
                            <label class="form-check-label" for="${Editor.safeId(fieldSetting.id)}_${(i + offset)}">
                            ${label}
<!--                                <span class="badge badge-pill badge-primary">${label}</span>-->
                            </label>
                        </div>
                    `
                    // +
                    // '<div class="form-check col-6">' +
                    // '<input class="form-check-input" id="' + Editor.safeId(fieldSetting.id) + '_' + (i + offset) + '' +
                    // '" type="radio" name="dt-radio-' + fieldSetting.name + '" />' +
                    // '<label class="form-check-label" for="' + Editor.safeId(fieldSetting.id) + '_' + (i + offset) + '">' +
                    // '<span class="badge badge-pill badge-primary">' + label + '</span>'
                    // + '</label>' +
                    // '</div>'
                    );
                    // @ts-ignore TODO
                    $('input:last', jqInput).attr('value', val)[0]._editor_val = val;
                    if (attr) {
                        $('input:last', jqInput).attr(attr);
                    }
                });
            }
        },
        create: function (fieldSetting) {
            let container = $('<div />').addClass("container");
            fieldSetting._input = $('<div class="row"/>');
            _fieldTypes.radio._addOptions(fieldSetting, fieldSetting.options || fieldSetting.ipOpts);
            return fieldSetting._input[0];
        },
        get: function (fieldSetting) {
            let out = [];
            let selected = fieldSetting._input.find('input:checked');
            if (selected.length) {
                selected.each(function () {
                    out.push(this._editor_val);
                });
            }
            else if (fieldSetting.unselectedValue !== undefined) {
                out.push(fieldSetting.unselectedValue);
            }
            let separator = fieldSetting.separator === undefined ? _separator.array : fieldSetting.separator;
            return out.join(separator);
            // let returnVal = out.join(separator);
            // return returnVal;
        },
        set: function (fieldSetting, val) {
            let jqInputs = fieldSetting._input.find('input');
            if (!Array.isArray(val) && typeof val === 'string') {
                val = val.split(fieldSetting.separator || _separator.array);
            }
            else if (!Array.isArray(val)) {
                val = [val];
            }
            let i, len = val.length, found;
            jqInputs.each(function () {
                found = false;
                for (i = 0; i < len; i++) {
                    if (this._editor_val === val[i]) {
                        found = true;
                        break;
                    }
                }
                this.checked = found;
            });
            _triggerChange(jqInputs);
        },
        enable: function (fieldSetting) {
            fieldSetting._input.find('input').prop('disabled', false);
        },
        disable: function (fieldSetting) {
            fieldSetting._input.find('input').prop('disabled', true);
        },
        update: function (fieldSetting, options, append) {
            // Get the current value
            let radio = _fieldTypes.radio;
            let currVal = radio.get(fieldSetting);
            radio._addOptions(fieldSetting, options, append);
            radio.set(fieldSetting, currVal);
        }
    });
    _fieldTypes.textarea = $.extend(true, {}, baseFieldType, {
        create: function (fieldSetting) {
            let arrayDebug = [];
            arrayDebug[0] = "textrea create called";
            arrayDebug.fieldSetting = fieldSetting;
            arrayDebug.field = fieldSetting.data;
            let editable = fieldSetting.editable;
            /*if(typeof editable === "undefined" || editable !== "false"){

                fieldSetting._input = $('<textarea/>').attr( $.extend( {
                    id: Editor.safeId( fieldSetting.id ),
                    type: 'textarea'
                }, fieldSetting.attr || {} ) );
                Log.trace(arrayDebug, _fieldTypesDebug);

                return fieldSetting._input[0];
            }
            else {
                fieldSetting._input = $('<div></div>').attr( $.extend( {
                    id: Editor.safeId( fieldSetting.id )
                }, fieldSetting.attr || {} ) );
                Log.trace(arrayDebug, _fieldTypesDebug);

                return fieldSetting._input[0];
            }*/
            fieldSetting._input = $('<textarea/>').attr($.extend({
                id: Editor.safeId(fieldSetting.id)
            }, fieldSetting.attr || {}));
            // Log.trace(arrayDebug, _fieldTypesDebug);
            return fieldSetting._input[0];
        },
        canReturnSubmit: function () {
            return false;
        }
    });
    _fieldTypes.boolean = $.extend(true, {}, baseFieldType, {
        create: function (fieldSetting) {
            fieldSetting._div =
                $(`<div style="width:auto;" class="text-center">
                        <div class="form-check form-switch">
                            <input type="checkbox" name="switch" id="${Editor.safeId(fieldSetting.id)}" />
                        </div>
                    </div>`);
            // if (_fieldTypesDebug || _fieldTypes.boolean._debug) {
            //     console.log("_fieldTypes.boolean.create(fieldSetting)->called", {
            //         fieldSetting: fieldSetting
            //     });
            // }
            return fieldSetting._div;
        },
        set: function (fieldSetting, data) {
            let arrayDebug = [];
            arrayDebug[0] = "_fieldTypes.boolean boolean set called";
            arrayDebug.fieldSetting = fieldSetting;
            arrayDebug.data = data;
            let $input = $("input", fieldSetting._div);
            $input.prop('checked', parseInt(data) === 1);
            // Log.trace(arrayDebug, _fieldTypesDebug);
            _triggerChange($input);
        },
        get: function (fieldSetting) {
            let arrayDebug = [];
            arrayDebug[0] = "_fieldTypes.boolean boolean get called";
            arrayDebug.fieldSetting = fieldSetting;
            let $input = $("input", fieldSetting._div);
            arrayDebug.$input = $input;
            let checked = $input.prop('checked');
            let value = checked ? '1' : '0';
            arrayDebug.checked = checked;
            arrayDebug.value = value;
            // Log.trace(arrayDebug, _fieldTypesDebug);
            return value;
        },
        canReturnSubmit: function () {
            return true;
        },
        enable: function (fieldSetting) {
            let arrayDebug = [];
            arrayDebug[0] = "_fieldTypes.boolean enable called";
            arrayDebug.fieldSetting = fieldSetting;
            arrayDebug.field = fieldSetting.data;
            let $input = $("input", fieldSetting._div);
            $input.prop('disabled', false);
            // Log.trace(arrayDebug, _fieldTypesDebug);
        },
        disable: function (fieldSetting) {
            let arrayDebug = [];
            arrayDebug[0] = "disable called";
            arrayDebug.fieldSetting = fieldSetting;
            arrayDebug.field = fieldSetting.data;
            let $input = $("input", fieldSetting._div);
            $input.prop('disabled', true);
            // Log.trace(arrayDebug, _fieldTypesDebug);
        }
    });
    _fieldTypes.color = $.extend(true, {}, baseFieldType, {
        create: function (fieldSetting) {
            let arrayDebug = [];
            arrayDebug[0] = "color create called";
            arrayDebug.fieldSetting = fieldSetting;
            fieldSetting._enabled = true;
            let group = '<div class="input-group ">' +
                '<div class="input-group-prepend">' +
                '<span class="input-group-text color-value">' +
                '</span>' +
                '</div>' +
                '<input class="form-control colorPicker" value="" id="' + Editor.safeId(fieldSetting.id) + '">' +
                '</div>';
            fieldSetting._input = $(group);
            // Log.trace(arrayDebug, _fieldTypesDebug);
            return fieldSetting._input;
        },
        get: function (fieldSetting) {
            let arrayDebug = [];
            arrayDebug[0] = "color get called";
            // Log.trace(arrayDebug, _fieldTypesDebug);
            return $('input', fieldSetting._input).val();
        },
        set: function (fieldSetting, val) {
            let arrayDebug = [];
            let $input = $('input', fieldSetting._input);
            $input.val(val);
            arrayDebug.fieldSetting = fieldSetting;
            if (val === "") {
                // val = "transparent";
            }
            let $colorValue = $('.color-value', fieldSetting._input);
            $colorValue.css('background-color', val);
            //TODO
            $input.colorpicker({
                color: val,
                format: 'hex',
                // @ts-ignore TODO
                debug: false
            })
                .on('colorpickerCreate', function (e) {
                e.preventDefault();
                // @ts-ignore TODO
                e.colorpicker.setValue(val);
            })
                .on('colorpickerShow', function (e) {
                // @ts-ignore TODO
                e.colorpicker.setValue($input.val());
            })
                .on('colorpickerHide', function (e) {
                let value = $input.val();
                arrayDebug.event = 'colorpickerHide';
                // @ts-ignore TODO
                arrayDebug.colorToString = e.color.toString();
                arrayDebug.e = e;
                if (ColumnStatic._validColor(value)) {
                    // @ts-ignore TODO
                    e.colorpicker.setValue(value);
                    // @ts-ignore TODO
                    $colorValue.css('background-color', e.color.toString());
                }
                // Log.trace(arrayDebug, _fieldTypesDebug);
            })
                .on('colorpickerChange', function (e) {
                e.preventDefault();
                let inputValue = $input.val();
                if (ColumnStatic._validColor(inputValue)) {
                    // @ts-ignore TODO
                    $colorValue.css('background-color', e.color.toString());
                }
            });
            // Log.trace(arrayDebug, _fieldTypesDebug);
        },
        canReturnSubmit: function () {
            return false;
        }
    });
    _fieldTypes.integer = $.extend(true, {}, baseFieldType, {
        _debug: false,
        create: function (fieldSetting) {
            // if (_fieldTypes.integer._debug) {
            //     console.log(fieldSetting);
            // }
            fieldSetting._enabled = true;
            fieldSetting._input = $('<input id="' + Editor.safeId(fieldSetting.id) + '" type="number" step="1" class="" min="0" value="0">');
            return fieldSetting._input;
        },
        set: function (fieldSetting, val) {
            val = Helpers._isStringNotEmpty(val) ? parseInt(val) : 0;
            $(fieldSetting._input).val(val);
        },
        get: function (fieldSetting) {
            return parseInt($(fieldSetting._input).val());
        }
    });
    _fieldTypes.password = $.extend(true, {}, baseFieldType, {
        create: function (fieldSetting) {
            // console.log(fieldSetting);
            fieldSetting._enabled = true;
            fieldSetting._input = $('<input id="' + Editor.safeId(fieldSetting.id) + '" type="password" class="form-control" value="">');
            return fieldSetting._input;
        },
        set: function (fieldSetting, val) {
            $(fieldSetting._input).val(val);
        },
        get: function (fieldSetting) {
            return $(fieldSetting._input).val();
        }
    });
    _fieldTypes.price = $.extend(true, {}, baseFieldType, {
        create: function (fieldSetting) {
            // console.log({ arguments});
            fieldSetting._enabled = true;
            let attr = fieldSetting.attr ? fieldSetting.attr : {};
            fieldSetting._input = $('<input id="' + Editor.safeId(fieldSetting.id) + '" type="number" step="0.01" value="" min="0">').attr(attr);
            return fieldSetting._input;
        },
        set: function (fieldSetting, val) {
            val = Helpers._isStringNotEmpty(val) ? parseInt(val) : 0;
            val = Helpers._intToDec(val);
            $(fieldSetting._input).val(val);
        },
        get: function (fieldSetting) {
            let val = $(fieldSetting._input).val();
            val = Math.round((parseFloat(val) * 100));
            return val;
        }
    });
    _fieldTypes.float = $.extend(true, {}, baseFieldType, {
        create: function (fieldSetting) {
            // console.log(fieldSetting);
            fieldSetting._enabled = true;
            fieldSetting._input = $('<input id="' + Editor.safeId(fieldSetting.id) + '" type="number" step="0.01" class="" value="0" min="0">');
            return fieldSetting._input;
        },
        set: function (fieldSetting, val) {
            val = Helpers._isStringNotEmpty(val) ? parseInt(val) : 0;
            $(fieldSetting._input).val(Helpers._intToDec(val));
        },
        get: function (fieldSetting) {
            let val = $(fieldSetting._input).val();
            val = Helpers._isStringNotEmpty(val) ? parseInt(val) * 100 : 0;
            return val;
        }
    });
    _fieldTypes.email = $.extend(true, {}, baseFieldType, {
        create: function (fieldSetting) {
            // console.log(fieldSetting);
            fieldSetting._enabled = true;
            fieldSetting._input = $('<input id="' + Editor.safeId(fieldSetting.id) + '" type="email" value="" required>');
            return fieldSetting._input;
        },
        get: function (fieldSetting) {
            return $(fieldSetting._input).val();
        },
        set: function (fieldSetting, val) {
            $(fieldSetting._input).val(val);
        }
    });
    _fieldTypes.icon = $.extend(true, {}, baseFieldType, {
        create: function (fieldSetting) {
            let arrayDebug = [];
            arrayDebug[0] = "icon create called";
            arrayDebug.fieldSetting = fieldSetting;
            fieldSetting._enabled = true;
            let group = '<div class="input-group ">' +
                '<div class="input-group-prepend">' +
                '<span class="input-group-text">' +
                '<i class=""></i>' +
                '</span>' +
                '</div>' +
                '<input class="form-control" value="" id="' + Editor.safeId(fieldSetting.id) + '">' +
                '</div>';
            fieldSetting._input = $(group);
            // Log.trace(arrayDebug, _fieldTypesDebug);
            return fieldSetting._input;
        },
        get: function (fieldSetting) {
            let arrayDebug = [];
            arrayDebug[0] = "color get called";
            // Log.trace(arrayDebug, _fieldTypesDebug);
            return $('input', fieldSetting._input).val();
        },
        set: function (fieldSetting, val) {
            let arrayDebug = [];
            let $input = $('input', fieldSetting._input);
            $input.val(val);
            arrayDebug.fieldSetting = fieldSetting;
            if (val === "") {
                // val = "transparent";
            }
            let $icon = $('i', fieldSetting._input);
            $icon.removeClass();
            $icon.addClass(val);
            // Log.trace(arrayDebug, _fieldTypesDebug);
        },
        canReturnSubmit: function () {
            return true;
        }
    });
    _fieldTypes.flag = $.extend(true, {}, baseFieldType, {
        create: function (fieldSetting) {
            let arrayDebug = [];
            arrayDebug[0] = "icon create called";
            arrayDebug.fieldSetting = fieldSetting;
            fieldSetting._enabled = true;
            let group = '<div class="input-group ">' +
                '<div class="input-group-prepend">' +
                '<span class="input-group-text">' +
                '<div class="text-center"><span class="flag-icon "></span></div>' +
                '</span>' +
                '</div>' +
                '<input class="form-control" value="" id="' + Editor.safeId(fieldSetting.id) + '">' +
                '</div>';
            fieldSetting._input = $(group);
            // Log.trace(arrayDebug, _fieldTypesDebug);
            return fieldSetting._input;
        },
        get: function (fieldSetting) {
            let arrayDebug = [];
            arrayDebug[0] = "color get called";
            // Log.trace(arrayDebug, _fieldTypesDebug);
            return $('input', fieldSetting._input).val();
        },
        set: function (fieldSetting, val) {
            let className = 'flag-icon';
            let arrayDebug = [];
            let $input = $('input', fieldSetting._input);
            $input.val(val);
            arrayDebug.fieldSetting = fieldSetting;
            if (val === "") {
                // val = "transparent";
            }
            let $icon = $('.' + className, fieldSetting._input);
            $icon.removeClass();
            $icon.addClass(className);
            $icon.addClass(val);
            // Log.trace(arrayDebug, _fieldTypesDebug);
        },
        canReturnSubmit: function () {
            return true;
        }
    });
    // _fieldTypes.image = _fieldTypes.upload;
    _fieldTypes.imageFinder = $.extend(true, {}, baseFieldType, {
        _debug: false,
        create: function (fieldSetting) {
            // console.log({arguments});
            fieldSetting._enabled = true;
            let attr = fieldSetting.attr ? fieldSetting.attr : {};
            let safeId = Editor.safeId(fieldSetting.id);
            fieldSetting._input = $("<div/>")
                .addClass('imgContainer')
                .attr({
                id: safeId
            });
            //TODO
            /*if (CKFinder) {
                fieldSetting._input.click(function () {

                    CKFinder.modal(
                        $.extend({
                            chooseFiles: true,
                            onInit: function (finder) {
                                fieldSetting.onStart();
                                // finder.on( 'toolbar:reset:Main', function( evt ) {
                                //     evt.data.toolbar.push( {
                                //         type: 'button',
                                //         priority: 100,
                                //         label: 'X',
                                //         action: function() {
                                //             finder.request('closePopup');
                                //             fieldSetting.onStop();
                                //         }
                                //     } );
                                // } );
                                finder.on('app:start', function (evt) {
                                    finder.request('maximize');
                                });
                                finder.on('minimized', function (evt) {

                                    finder.request('closePopup');
                                    fieldSetting.onStop();
                                });

                                finder.on('files:choose', function (evt) {
                                    let file = evt.data.files.first();
                                    console.log({
                                        file: file
                                    });
                                    _fieldTypes.imageFinder.set(fieldSetting, file.getUrl());
                                    fieldSetting.onStop();
                                });
                                finder.on('file:choose:resizedImage', function (evt) {
                                    document.getElementById('url').value = evt.data.resizedUrl;
                                });
                                console.log("onInit datacard");
                            }
                        }, fieldSetting.opts)
                    );
                })
            }*/
            return fieldSetting._input;
        },
        set: function (fieldSetting, val) {
            // console.log({arguments});
            val = Helpers._isStringNotEmpty(val) ? val : null;
            if (val) {
                $(fieldSetting._input).html($('<img class="img-fluid" src="' + val + '" alt=""/>').html());
            }
            else {
                $(fieldSetting._input).html($("<span/>")
                    .text(fieldSetting.noImageText || '').html());
            }
        },
        get: function (fieldSetting) {
            // console.log({arguments});
            let val = "";
            let $img = $("img", fieldSetting._input);
            if ($img.length) {
                let src = $img.attr('src');
                val = Helpers._isStringNotEmpty(src) ? src : val;
            }
            return val;
        }
    });
    _fieldTypes.daterange = $.extend(true, {}, baseFieldType, {
        //TODO check this
        create: function (fieldSetting) {
            let editor = this;
            // let translation = editor.i18n;
            // console.log("_fieldTypes.datetime create called", $.fn.daterangepicker);
            fieldSetting._input = $('<input />').attr($.extend({
                id: Editor.safeId(fieldSetting.id),
                type: 'text'
            }, fieldSetting.attr));
            // if ($.fn.daterangepicker) {
            //     $(fieldSetting._input).daterangepicker($.extend({
            //         // singleDatePicker: true,
            //         showDropdowns: true,
            //         startDate: moment(),
            //         "locale": {
            //             "applyLabel": translation.apply,
            //             "cancelLabel": translation.cancel,
            //             "format": DataTableStatic.format.date,
            //             "monthNames": translation.datetime.months,
            //             "daysOfWeek": translation.datetime.weekdays,
            //             "firstDay": 1
            //         }
            //     }, fieldSetting.opts));
            // } else {
            //     // HTML5 (only Chrome and Edge on the desktop support this atm)
            //     fieldSetting._input.attr('type', 'date');
            // }
            fieldSetting._input.attr('type', 'date');
            return fieldSetting._input[0];
        },
        // use default get method as will work for all
        set: function (fieldSetting, val) {
            /*if ($.fn.daterangepicker && moment) {
                let date;
                let mom = val !== "" ? moment(val, DataTableStatic.format.timeStamp) : moment();
                if (mom.isValid()) {
                    date = mom.format(DataTableStatic.format.date);
                } else {
                    date = moment().format(DataTableStatic.format.date);
                    console.log("date is not valid", date);
                }
                $(fieldSetting._input).data("daterangepicker").setStartDate(date);

            } else {

                $(fieldSetting._input).val(Helpers._intToDate(val));
            }*/
            $(fieldSetting._input).val(Helpers._intToDate(val));
        },
        get: function (fieldSetting) {
            let val = $(fieldSetting._input).val();
            // console.log(val);
            if (moment) {
                let mom = moment(val, DataTableStatic.format.date);
                if (mom.isValid()) {
                    val = mom.format(DataTableStatic.format.timeStamp);
                }
            }
            return val;
        },
        enable: function (fieldSetting) {
            $(fieldSetting._input).prop('disabled', false);
        },
        disable: function (fieldSetting) {
            $(fieldSetting._input).prop('disabled', true);
        }
    });
    _fieldTypes.date = $.extend(true, {}, baseFieldType, {
        create: function (fieldSetting) {
            let translation = this.i18n;
            let id = Editor.safeId(fieldSetting.id);
            let input = '<div class="input-group date " data-target-input="nearest">' +
                '<input type="text" class="form-control datetimepicker-input"  data-toggle="datetimepicker" data-target="#' + id + '" ' +
                'placeholder="Select date &amp; time">' +
                '</div>';
            fieldSetting._input = $(input).attr($.extend({
                id: id
            }, fieldSetting.attr));
            /*if ($.fn.datetimepicker) {
                $(fieldSetting._input).datetimepicker($.extend({
                    locale: translation.locale,
                    format: DataTableStatic.format.date,
                    widgetPositioning: {
                        horizontal: 'left'
                    }
                }, fieldSetting.opts));
                setTimeout(function () {


                }, 10);
            } else {
                $('input', fieldSetting._input).attr('type', 'date');
            }*/
            $('input', fieldSetting._input).attr('type', 'date');
            return fieldSetting._input[0];
        },
        // use default get method as will work for all
        set: function (fieldSetting, val) {
            /*if ($.fn.datetimepicker && moment) {
                let mom = val !== "" ? moment(val, DataTableStatic.format.timeStamp) : moment();
                let date = val;
                mom = moment(mom);
                if (mom.isValid()) {
                    date = mom.format(DataTableStatic.format.date);
                } else {
                    date = moment().format(DataTableStatic.format.date);
                }
                $('input', fieldSetting._input).val(date);

            } else {

                $('input', fieldSetting._input).val(Helpers._intToDate(val));
            }*/
            $('input', fieldSetting._input).val(Helpers._intToDate(val));
        },
        get: function (fieldSetting) {
            let val = $('input', fieldSetting._input).val();
            if (moment) {
                let mom = moment(val, DataTableStatic.format.date);
                if (mom.isValid()) {
                    val = mom.format(DataTableStatic.format.timeStamp);
                }
            }
            // console.log({ dateVal: val });
            return val;
        },
        enable: function (fieldSetting) {
            $(fieldSetting._input).prop('disabled', false);
        },
        disable: function (fieldSetting) {
            $(fieldSetting._input).prop('disabled', true);
        }
    });
    _fieldTypes.time = $.extend(true, {}, baseFieldType, {
        create: function (fieldSetting) {
            // let translation = this.i18n;
            let id = Editor.safeId(fieldSetting.id);
            let input = '<div class="input-group date " data-target-input="nearest">' +
                '<input type="text" class="form-control datetimepicker-input"  data-toggle="datetimepicker" data-target="#' + id + '" ' +
                'placeholder="Select date &amp; time">' +
                '</div>';
            fieldSetting._input = $(input).attr($.extend({
                id: id
            }, fieldSetting.attr));
            /*if ($.fn.datetimepicker) {
                setTimeout(function () {
                    $(fieldSetting._input).datetimepicker($.extend({
                        format: DataTableStatic.format.time,
                        locale: translation.locale,
                        stepping: 5,
                        widgetPositioning: {
                            horizontal: 'left'
                        }
                    }, fieldSetting.opts));

                }, 10);
            } else {
                $('input', fieldSetting._input).attr('type', 'date');
            }*/
            $('input', fieldSetting._input).attr('type', 'date');
            return fieldSetting._input[0];
        },
        // use default get method as will work for all
        set: function (fieldSetting, val) {
            /*if ($.fn.datetimepicker && moment) {
                let mom = val !== "" ? moment(val, DataTableStatic.format.timeStamp) : moment();
                let date = val;
                if (mom.isValid()) {
                    date = mom.utc().format(DataTableStatic.format.time);
                }
                $('input', fieldSetting._input).val(date);

            } else {

                $('input', fieldSetting._input).val(Helpers._intToDate(val));
            }*/
            $('input', fieldSetting._input).val(Helpers._intToDate(val));
        },
        get: function (fieldSetting) {
            let val = $('input', fieldSetting._input).val();
            if (moment) {
                let mom = moment(val, DataTableStatic.format.time);
                if (mom.isValid()) {
                    let min = mom.minutes();
                    let hour = mom.hours();
                    let newDate = moment(0, DataTableStatic.format.timeStamp).utc().hours(hour).minutes(min);
                    // val = newDate.format(_DataTable.format.timeStamp);
                    val = newDate.format(DataTableStatic.format.timeStamp);
                }
            }
            return val;
        },
        enable: function (fieldSetting) {
            $(fieldSetting._input).prop('disabled', false);
        },
        disable: function (fieldSetting) {
            $(fieldSetting._input).prop('disabled', true);
        }
    });
    /*
    if ($.fn.datetimepicker) {
        $.fn.datetimepicker.Constructor.Default = $.extend({}, $.fn.datetimepicker.Constructor.Default, {
            icons: {
                time: "fa fa-clock",
                date: "fa fa-calendar",
                up: "fa fa-arrow-up",
                down: "fa fa-arrow-down",
                previous: "fa fa-chevron-left",
                next: "fa fa-chevron-right",
                today: "fa fa-clock",
                clear: "fa fa-trash",
                close: 'fa fa-times'
            } });
    }
    */
    _fieldTypes.datetime = $.extend(true, {}, baseFieldType, {
        create: function (fieldSetting) {
            let editor = this;
            // let translation = editor.i18n;
            let id = Editor.safeId(fieldSetting.id);
            let input = '<div class="input-group date " data-target-input="nearest">' +
                '<input type="text" class="form-control datetimepicker-input"  data-toggle="datetimepicker" data-target="#' + id + '" ' +
                'placeholder="Select date &amp; time">' +
                '</div>';
            fieldSetting._input = $(input).attr($.extend({
                id: id
            }, fieldSetting.attr));
            /*if ($.fn.datetimepicker) {
                $(fieldSetting._input).datetimepicker($.extend({
                    locale: translation.locale,
                    format: DataTableStatic.format.datetime,
                    widgetPositioning: {
                        horizontal: 'left'
                    }
                }, fieldSetting.opts));

            } else {
                $('input', fieldSetting._input).attr('type', 'date');
            }*/
            $('input', fieldSetting._input).attr('type', 'date');
            return fieldSetting._input[0];
        },
        // use default get method as will work for all
        set: function (fieldSetting, val) {
            /*if ($.fn.datetimepicker && moment) {
                let mom = val !== "" ? moment(val, DataTableStatic.format.timeStamp) : moment();
                let date = val;
                mom = moment(mom);
                if (mom.isValid()) {
                    date = mom.format(DataTableStatic.format.datetime);
                } else {
                    date = moment().format(DataTableStatic.format.datetime);
                }
                $('input', fieldSetting._input).val(date);

            } else {

                $('input', fieldSetting._input).val(Helpers._intToDate(val));
            }*/
            $('input', fieldSetting._input).val(Helpers._intToDate(val));
        },
        get: function (fieldSetting) {
            let val = $('input', fieldSetting._input).val();
            if (moment) {
                let mom = moment(val, DataTableStatic.format.datetime);
                if (mom.isValid()) {
                    val = mom.format(DataTableStatic.format.timeStamp);
                }
            }
            return val;
        },
        enable: function (fieldSetting) {
            $(fieldSetting._input).prop('disabled', false);
        },
        disable: function (fieldSetting) {
            $(fieldSetting._input).prop('disabled', true);
        }
    });
    _fieldTypes.timestep = $.extend(true, {}, baseFieldType, {
        create: function (fieldSetting) {
            let translation = this.i18n;
            let id = Editor.safeId(fieldSetting.id);
            let css = {
                width: "initial",
                display: "inline"
            };
            let $input = $('<input/>')
                .attr({
                type: "number",
                'class': 'form-control',
                min: 0
            })
                .css(css);
            let $select = $('<select/>')
                .attr({
                'class': 'form-control'
            })
                .css(css);
            $select
                .append(new Option(translation.datetime.minutes, _timeHelper.type.min))
                .append(new Option(translation.datetime.hours, _timeHelper.type.hour));
            let inputGroup = $('<div/>').addClass('input-group').append($input).append($select);
            fieldSetting._input = inputGroup.attr($.extend({
                id: id
            }, fieldSetting.attr));
            return fieldSetting._input[0];
        },
        // use default get method as will work for all
        set: function (fieldSetting, val) {
            let inputVal = 0;
            let selectVal;
            if (val !== "" && val.includes(_separator.array)) {
                val = val.split(fieldSetting.separator || _separator.array);
                if (val.length === 2) {
                    inputVal = val[0];
                    selectVal = val[1];
                }
            }
            if (selectVal) {
                $('select', fieldSetting._input).val(selectVal);
            }
            $('input', fieldSetting._input).val(inputVal);
        },
        get: function (fieldSetting) {
            let inputVal = $('input', fieldSetting._input).val();
            let selectVal = $('select', fieldSetting._input).val();
            return inputVal + _separator.array + selectVal;
        },
        enable: function (fieldSetting) {
            $('select', fieldSetting._input).prop('disabled', false);
            $('input', fieldSetting._input).prop('disabled', false);
        },
        disable: function (fieldSetting) {
            $('input', fieldSetting._input).prop('disabled', true);
            $('select', fieldSetting._input).prop('disabled', true);
        }
    });
    _fieldTypes.select2 = {
        _addOptions: function (fieldSetting, opts) {
            let elOpts = fieldSetting._input[0].options;
            elOpts.length = 0;
            if (opts) {
                Editor.pairs(opts, FieldSetting.optionsPair, function (val, label, i, attr) {
                    elOpts[i] = new Option(label, val);
                });
            }
        },
        create: function (fieldSetting) {
            fieldSetting._input = $('<select/>')
                .attr($.extend({
                id: Editor.safeId(fieldSetting.id)
            }, fieldSetting.attr || {}));
            let options = $.extend({
                width: '100%'
            }, fieldSetting.opts);
            console.log({ options, fieldSetting });
            _fieldTypes.select2._addOptions(fieldSetting, fieldSetting.options || fieldSetting.ipOpts);
            fieldSetting._input.select2(options);
            let open;
            fieldSetting._input
                .on('select2:open', function () {
                open = true;
            })
                .on('select2:close', function () {
                open = false;
            });
            // On open, need to have the instance update now that it is in the DOM
            this.one('open.select2-' + Editor.safeId(fieldSetting.id), function () {
                fieldSetting._input.select2(options);
                if (open) {
                    fieldSetting._input.select2('open');
                }
            });
            return fieldSetting._input[0];
        },
        get: function (fieldSetting) {
            let val = fieldSetting._input.val();
            val = fieldSetting._input.prop('multiple') && val === null ?
                [] :
                val;
            return fieldSetting.separator ?
                val.join(fieldSetting.separator) :
                val;
        },
        set: function (fieldSetting, val) {
            if (fieldSetting.separator && !Array.isArray(val)) {
                val = val.split(fieldSetting.separator);
            }
            // Clear out any existing tags
            if (fieldSetting.opts && fieldSetting.opts.tags) {
                fieldSetting._input.val([]);
            }
            // The value isn't present in the current options list, so we need to add it
            // in order to be able to select it. Note that this makes the set action async!
            // It doesn't appear to be possible to add an option to select2, then change
            // its label and update the display
            let needAjax = false;
            if (fieldSetting.opts && fieldSetting.opts.ajax) {
                if (Array.isArray(val)) {
                    for (let i = 0, ien = val.length; i < ien; i++) {
                        if (fieldSetting._input.find('option[value="' + val[i].postCode + '"]').length === 0) {
                            needAjax = true;
                            break;
                        }
                    }
                }
                else {
                    if (fieldSetting._input.find('option[value="' + val.postCode + '"]').length === 0) {
                        needAjax = true;
                    }
                }
            }
            if (needAjax && val) {
                $.ajax($.extend({
                    beforeSend: function (jqXhr, settings) {
                        // Add an initial data request to the server, but don't
                        // override `data` since the dev might be using that
                        let initData = fieldSetting.urlDataType === undefined || fieldSetting.urlDataType === 'json'
                            ? 'id=' + val
                            // ? '_q=postCode&lang=fr&id=' + val
                            : $.param({ initialValue: true, value: val });
                        if (typeof fieldSetting.opts.ajax.url === 'function') {
                            settings.url = fieldSetting.opts.ajax.url();
                        }
                        if (settings.method === 'GET') {
                            settings.url += settings.url.indexOf('?') === -1 ?
                                '?' + initData :
                                '&' + initData;
                        }
                        else {
                            settings.data = settings.data ?
                                settings.data + '&' + initData :
                                initData;
                        }
                        settings.url = settings.url + `&${initData}`;
                        // settings.data = {id:val}
                        console.log({ settings });
                    },
                    success: function (json) {
                        let data = json.results;
                        console.log({ json, data });
                        let addOption = function (option) {
                            // console.log({option});
                            if (fieldSetting._input.find('option[value="' + option.id + '"]').length === 0) {
                                let op = $('<option/>')
                                    .attr('value', option.id)
                                    .text(option.text)
                                    .appendTo(fieldSetting._input);
                                // console.log({ op });
                            }
                        };
                        if (Array.isArray(data)) {
                            for (let i_a = 0, i_a_en = data.length; i_a < i_a_en; i_a++) {
                                addOption(data[i_a]);
                            }
                        }
                        else if (data && Array.isArray(data)) {
                            for (let i = 0, ien = data.length; i < ien; i++) {
                                addOption(data[i]);
                            }
                        }
                        else {
                            addOption(data);
                        }
                        fieldSetting._input
                            .val(val)
                            .trigger('change', { editor: true });
                    }
                }, fieldSetting.opts.ajax));
            }
            else {
                fieldSetting._input
                    .val(val)
                    .trigger('change', { editor: true });
            }
        },
        enable: function (fieldSetting) {
            $(fieldSetting._input).removeAttr('disabled');
        },
        disable: function (fieldSetting) {
            $(fieldSetting._input).attr('disabled', 'disabled');
        },
        // Non-standard Editor methods - custom to this plug-in
        inst: function (fieldSetting) {
            let args = Array.prototype.slice.call(arguments);
            args.shift();
            return fieldSetting._input.select2.apply(fieldSetting._input, args);
        },
        update: function (fieldSetting, data) {
            let val = _fieldTypes.select2.get(fieldSetting);
            _fieldTypes.select2._addOptions(fieldSetting, data);
            // Restore null value if it was, to let the placeholder show
            if (val === null) {
                _fieldTypes.select2.set(fieldSetting, null);
            }
            $(fieldSetting._input).trigger('change', { editor: true });
        },
        focus: function (fieldSetting) {
            if (fieldSetting._input.is(':visible') && fieldSetting.onFocus === 'focus') {
                fieldSetting._input.select2('open');
            }
        },
        owns: function (fieldSetting, node) {
            let $node = $(node);
            return !!($node.closest('.select2-container').length || $node.closest('.select2').length ||
                $node.hasClass('select2-selection__choice__remove'));
        },
        canReturnSubmit: function () {
            return false;
        }
    };
    _fieldTypes.postCode = {
        _addOptions: function (fieldSetting, opts) {
            let elOpts = fieldSetting._input[0].options;
            elOpts.length = 0;
            if (opts) {
                Editor.pairs(opts, FieldSetting.optionsPair, function (val, label, i, attr) {
                    elOpts[i] = new Option(label, val);
                });
            }
        },
        create: function (fieldSetting) {
            fieldSetting._input = $('<select/>')
                .attr($.extend({
                id: Editor.safeId(fieldSetting.id)
            }, fieldSetting.attr || {}));
            let options = $.extend({
                width: '100%'
            }, fieldSetting.opts);
            _fieldTypes.select2._addOptions(fieldSetting, fieldSetting.options || fieldSetting.ipOpts);
            fieldSetting._input.select2(options);
            let open;
            fieldSetting._input
                .on('select2:open', function () {
                open = true;
            })
                .on('select2:close', function () {
                open = false;
            });
            // On open, need to have the instance update now that it is in the DOM
            this.one('open.select2-' + Editor.safeId(fieldSetting.id), function () {
                fieldSetting._input.select2(options);
                if (open) {
                    fieldSetting._input.select2('open');
                }
            });
            return fieldSetting._input[0];
        },
        get: function (fieldSetting) {
            let val = fieldSetting._input.val();
            val = fieldSetting._input.prop('multiple') && val === null ?
                [] :
                val;
            return fieldSetting.separator ?
                val.join(fieldSetting.separator) :
                val;
        },
        set: function (fieldSetting, val) {
            if (fieldSetting.separator && !Array.isArray(val)) {
                val = val.split(fieldSetting.separator);
            }
            // Clear out any existing tags
            if (fieldSetting.opts && fieldSetting.opts.tags) {
                fieldSetting._input.val([]);
            }
            // The value isn't present in the current options list, so we need to add it
            // in order to be able to select it. Note that this makes the set action async!
            // It doesn't appear to be possible to add an option to select2, then change
            // its label and update the display
            let needAjax = false;
            if (fieldSetting.opts && fieldSetting.opts.ajax) {
                if (Array.isArray(val)) {
                    for (let i = 0, ien = val.length; i < ien; i++) {
                        if (fieldSetting._input.find('option[value="' + val[i].postCode + '"]').length === 0) {
                            needAjax = true;
                            break;
                        }
                    }
                }
                else {
                    if (fieldSetting._input.find('option[value="' + val.postCode + '"]').length === 0) {
                        needAjax = true;
                    }
                }
            }
            if (needAjax && val) {
                $.ajax($.extend({
                    beforeSend: function (jqXhr, settings) {
                        // Add an initial data request to the server, but don't
                        // override `data` since the dev might be using that
                        let initData = fieldSetting.urlDataType === undefined || fieldSetting.urlDataType === 'json'
                            ? '_q=postCode&lang=fr&id=' + val
                            : $.param({ initialValue: true, value: val });
                        if (typeof fieldSetting.opts.ajax.url === 'function') {
                            settings.url = fieldSetting.opts.ajax.url();
                        }
                        if (settings.type === 'GET') {
                            settings.url += settings.url.indexOf('?') === -1 ?
                                '?' + initData :
                                '&' + initData;
                        }
                        else {
                            settings.data = settings.data ?
                                settings.data + '&' + initData :
                                initData;
                        }
                    },
                    success: function (json) {
                        let data = json.data;
                        // console.log({ data });
                        let addOption = function (option) {
                            // console.log({option});
                            if (fieldSetting._input.find('option[value="' + option.id + '"]').length === 0) {
                                let op = $('<option/>')
                                    .attr('value', option.id)
                                    .text(option.text)
                                    .appendTo(fieldSetting._input);
                                // console.log({op});
                            }
                        };
                        if (Array.isArray(data)) {
                            for (let i = 0, ien = data.length; i < ien; i++) {
                                addOption(data[i]);
                            }
                        }
                        else if (data && Array.isArray(data)) {
                            for (let i = 0, ien = data.length; i < ien; i++) {
                                addOption(data[i]);
                            }
                        }
                        else {
                            addOption(data);
                        }
                        fieldSetting._input
                            .val(val)
                            .trigger('change', { editor: true });
                    }
                }, fieldSetting.opts.ajax));
            }
            else {
                fieldSetting._input
                    .val(val)
                    .trigger('change', { editor: true });
            }
        },
        enable: function (fieldSetting) {
            $(fieldSetting._input).removeAttr('disabled');
        },
        disable: function (fieldSetting) {
            $(fieldSetting._input).attr('disabled', 'disabled');
        },
        // Non-standard Editor methods - custom to this plug-in
        inst: function (fieldSetting) {
            let args = Array.prototype.slice.call(arguments);
            args.shift();
            return fieldSetting._input.select2.apply(fieldSetting._input, args);
        },
        update: function (fieldSetting, data) {
            let val = _fieldTypes.select2.get(fieldSetting);
            _fieldTypes.select2._addOptions(fieldSetting, data);
            // Restore null value if it was, to let the placeholder show
            if (val === null) {
                _fieldTypes.select2.set(fieldSetting, null);
            }
            $(fieldSetting._input).trigger('change', { editor: true });
        },
        focus: function (fieldSetting) {
            if (fieldSetting._input.is(':visible') && fieldSetting.onFocus === 'focus') {
                fieldSetting._input.select2('open');
            }
        },
        owns: function (fieldSetting, node) {
            return !!($(node).closest('.select2-container').length || $(node).closest('.select2').length ||
                $(node).hasClass('select2-selection__choice__remove'));
        },
        canReturnSubmit: function () {
            return false;
        }
    };
    _fieldTypes.ckEditor = {
        create: function (conf) {
            var id = Editor.safeId(conf.id);
            conf._input = $('<div id="' + id + '"></div>');
            window['ClassicEditor'].create(conf._input[0], conf.opts)
                .then(function (editor) {
                conf._ckeditor = editor;
            });
            return conf._input;
        },
        get: function (conf) {
            const data = conf._ckeditor.getData();
            console.log({ data });
            return conf._ckeditor.getData();
        },
        set: function (conf, val) {
            console.log({ conf, val });
            conf._ckeditor.setData(val);
        },
        enable: function (conf) { },
        disable: function (conf) { },
        destroy: function (conf) {
            conf._ckeditor.destroy();
        },
        inst: function (conf) {
            return conf._ckeditor;
        }
    };
    _fieldTypes.permissions = {
        // Locally "private" function that can be reused for the create and update methods
        _addOptions: function (fieldSetting, opts, append) {
            // let val, label;
            let jqInput = fieldSetting._input;
            let offset = 0;
            if (!append) {
                jqInput.empty();
            }
            else {
                offset = $('input', jqInput).length;
            }
            if (opts) {
                Editor.pairs(opts, FieldSetting.optionsPair, function (val, label, i, attr) {
                    jqInput.append('<div class="form-check col-6 col-md-4">' +
                        '<input class="form-check-input" id="' + Editor.safeId(fieldSetting.id) + '_' + (i + offset) + '" type="checkbox" />' +
                        '<label class="form-check-label" for="' + Editor.safeId(fieldSetting.id) + '_' + (i + offset) + '">' +
                        '<span class="badge badge-pill badge-primary">' + label + '</span>'
                        + '</label>' +
                        '</div>');
                    // @ts-ignore TODO
                    $('input:last', jqInput).attr('value', val)[0]._editor_val = val;
                    if (attr) {
                        $('input:last', jqInput).attr(attr);
                    }
                });
            }
        },
        create: function (fieldSetting) {
            let container = $('<div />').addClass("container");
            fieldSetting._input = $('<div class="row"/>').appendTo(container);
            _fieldTypes.permissions._addOptions(fieldSetting, fieldSetting.options || fieldSetting.ipOpts);
            return fieldSetting._input[0];
        },
        get: function (fieldSetting) {
            let out = [];
            let selected = fieldSetting._input.find('input:checked');
            if (selected.length) {
                selected.each(function () {
                    out.push(this._editor_val);
                });
            }
            else if (fieldSetting.unselectedValue !== undefined) {
                out.push(fieldSetting.unselectedValue);
            }
            let separator = fieldSetting.separator === undefined ? _separator.array : fieldSetting.separator;
            return out.join(separator);
            // let returnVal = out.join(separator);
            // return returnVal;
        },
        set: function (fieldSetting, val) {
            let jqInputs = fieldSetting._input.find('input');
            if (!Array.isArray(val) && typeof val === 'string') {
                val = val.split(fieldSetting.separator || _separator.array);
            }
            else if (!Array.isArray(val)) {
                val = [val];
            }
            let i, len = val.length, found;
            jqInputs.each(function () {
                found = false;
                for (i = 0; i < len; i++) {
                    if (this._editor_val === val[i]) {
                        found = true;
                        break;
                    }
                }
                this.checked = found;
            });
            _triggerChange(jqInputs);
        },
        enable: function (fieldSetting) {
            fieldSetting._input.find('input').prop('disabled', false);
        },
        disable: function (fieldSetting) {
            fieldSetting._input.find('input').prop('disabled', true);
        },
        update: function (fieldSetting, options, append) {
            // Get the current value
            let checkbox = _fieldTypes.checkbox;
            let currVal = checkbox.get(fieldSetting);
            checkbox._addOptions(fieldSetting, options, append);
            checkbox.set(fieldSetting, currVal);
        }
    };
    // If there are field types available on DataTables we copy them in (after the
    // built in ones to allow overrides) and then expose the field types object.
    //     if (DataTable.ext.editorFields) {
    //         $.extend(_fieldTypes, DataTable.ext.editorFields);
    //     }
    if (Editor.fieldTypes) {
        $.extend(_fieldTypes, Editor.fieldTypes);
    }
    // DataTable.ext.editorFields = _fieldTypes;
    // @ts-ignore
    Editor.fieldTypes = _fieldTypes;
    // @ts-ignore
    $.fn.dataTable.ext.type.order['date-pre'] = function (data) {
        let timeStamp = parseInt(moment(data, DataTableStatic.format.date).format(DataTableStatic.format.timeStamp));
        // if (_fieldTypesDebug) {
        //     console.log({
        //         timeStamp: timeStamp,
        //         arguments: arguments
        //     });
        // }
        return timeStamp;
    };
    // @ts-ignore
    $.fn.dataTable.ext.type.order['datetime-pre'] = function (data) {
        let timeStamp = parseInt(moment(data, DataTableStatic.format.datetime).format(DataTableStatic.format.timeStamp));
        // if (_fieldTypesDebug) {
        //     console.log({
        //         timeStamp: timeStamp,
        //         arguments: arguments
        //     });
        // }
        return timeStamp;
    };
    // @ts-ignore
    $.fn.dataTable.ext.type.order['time-pre'] = function (data) {
        let timeStamp = parseInt(moment(data, DataTableStatic.format.time).format(DataTableStatic.format.timeStamp));
        // if (_fieldTypesDebug) {
        //     console.log({
        //         timeStamp: timeStamp,
        //         arguments: arguments
        //     });
        // }
        return timeStamp;
    };
})(jQuery);
