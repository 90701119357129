import DataTableStatic from "../data-table/table/data-table-static.js";
const _Translation = {
    /**
     *
     * @param language
     * @returns {*}
     * @public
     */
    _get: function (language) {
        switch (language) {
            case DataTableStatic._language.en:
                return _Translation.en;
            case DataTableStatic._language.de:
                return _Translation.de;
            case DataTableStatic._language.fr:
                return _Translation.fr;
            case DataTableStatic._language.nl:
                return _Translation.nl;
            default:
                return _Translation.fr;
        }
    },
    en: {
        "sEmptyTable": "No data available in table",
        "sInfo": "_START_ to _END_ / _TOTAL_ entries",
        "sInfoEmpty": "Showing 0 to 0 of 0 entries",
        "sInfoFiltered": "(_MAX_ total entries)",
        "sInfoPostFix": "",
        "sInfoThousands": ",",
        "sLengthMenu": "Show _MENU_ entries",
        "sLoadingRecords": "Loading...",
        "sProcessing": "Processing...",
        "sSearch": "Search :",
        "sZeroRecords": "No matching records found",
        "oPaginate": {
            "sFirst": "First",
            "sLast": "Last",
            "sNext": "Next",
            "sPrevious": "Previous"
        },
        "oAria": {
            "sSortAscending": ": activate to sort column ascending",
            "sSortDescending": ": activate to sort column descending"
        },
        "locale": "en",
        "cancel": "cancel",
        "apply": "apply",
        "select": {
            "rows": {
                "_": "(%d elements selected)",
                "0": "0 element selected",
                "1": "1 element selected"
            }
        },
        "menuText": {
            "all": "All"
        },
        "selectPlaceHolder": "please select",
        "create": {
            "button": "Add",
            "title": "Add item",
            "submit": "Add"
        },
        "reorder": {
            "button": "Reorder"
        },
        "edit": {
            "button": "Edit",
            "title": "Edit item",
            "submit": "Save"
        },
        "remove": {
            "button": "Delete",
            "title": "Delete item",
            "submit": "Delete",
            "confirm": {
                "_": "Delete %d item(s)?",
                "1": "Delete 1 Delete?"
            }
        },
        "print": {
            "button": "Print"
        },
        "excel": {
            "button": "Excel"
        },
        "pdf": {
            "button": "Pdf"
        },
        "error": {
            "system": "Une erreur est survenue (Plus d'informations)"
        },
        "multi": {
            "title": "Valeur multiple",
            "info": "Les éléments sélectionnés contiennent des valeurs différentes pour ce champ. " +
                "Pour éditer et définir tous les éléments de ce champ avec la même valeur, cliquez ou tapez ici, sinon ils conserveront leurs valeurs individuelles.",
            "restore": "Annuler les changements",
            "noMulti": "Cet élément peut être modifiée individuellement, mais ne fait pas partie d'un groupe."
        },
        "datetime": {
            "day": "day(s)",
            "hour": "hour(s)",
            "minute": "minute(s)",
            "previous": "Précédent",
            "next": "Suivant",
            "months": ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
            "weekdays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            "amPm": ["am", "pm"],
            "unknown": "-"
        },
        "clickToEdit": "click to edit",
        "enterToSave": "press enter to save",
        "upload": {
            "uploadText": "Choose file",
            "clearText": "Clear",
            "dragDropText": "Drag and drop a file here to upload",
            'noImageText': "No image",
            'noFileText': "No file",
            "fileReadText": "uploading file"
        },
        "_custom": {
            "tooltip": {
                "clickToEdit": "click to edit",
                "enterToSave": "press enter to save",
                "details": "details",
                "clickToSelect": "click to select",
                "clickToUnSelect": "click to deselect"
            },
            "button": {
                "selectAll": 'select all',
                "selectNone": 'select none'
            }
        }
    },
    fr: {
        "sEmptyTable": "Aucune donnée disponible dans le tableau",
        "sInfo": " _START_ à _END_ / _TOTAL_ élément(s)",
        "sInfoEmpty": "0 à 0 sur 0 élément",
        "sInfoFiltered": "(_MAX_ élément(s) au total)",
        "sInfoPostFix": "",
        "sInfoThousands": ",",
        "sLengthMenu": "Afficher _MENU_ élément(s)",
        "sLoadingRecords": "Chargement...",
        "sProcessing": "Traitement...",
        "sSearch": "Rechercher :",
        "sZeroRecords": "Aucun élément correspondant trouvé",
        "oPaginate": {
            "sFirst": "Premier",
            "sLast": "Dernier",
            "sNext": "Suivant",
            "sPrevious": "Précédent"
        },
        "oAria": {
            "sSortAscending": ": activer pour trier la colonne par ordre croissant",
            "sSortDescending": ": activer pour trier la colonne par ordre décroissant"
        },
        "locale": "fr",
        "cancel": "annuler",
        "apply": "appliquer",
        "select": {
            "rows": {
                "_": "(%d élément(s) sélectionné(s))",
                "0": "Aucun élément sélectionné",
                "1": "1 élément sélectionné"
            }
        },
        "menuText": {
            "all": "Tout"
        },
        "selectPlaceHolder": "veuillez sélectionner",
        "create": {
            "button": "Ajouter",
            "title": "Ajouter élément",
            "submit": "Ajouter"
        },
        "reorder": {
            "button": "Réorganiser"
        },
        "edit": {
            // "button": "Éditer",
            "button": "Éditer",
            "title": "Éditer élément",
            "submit": "Sauvegarder"
        },
        "remove": {
            "button": "Supprimer",
            "title": "Suppression élément",
            "submit": "Supprimer",
            "confirm": {
                "_": "Supprimer %d élément(s)?",
                "1": "Supprimer 1 élément?"
            }
        },
        "print": {
            "button": "Imprimer"
        },
        "excel": {
            "button": "Excel"
        },
        "pdf": {
            "button": "Pdf"
        },
        "error": {
            "system": "Une erreur est survenue (Plus d'informations)"
        },
        "multi": {
            "title": "Valeur multiple",
            "info": "Les éléments sélectionnés contiennent des valeurs différentes pour ce champ. " +
                "Pour éditer et définir tous les éléments de ce champ avec la même valeur, cliquez ou tapez ici, sinon ils conserveront leurs valeurs individuelles.",
            "restore": "Annuler les changements",
            "noMulti": "Cet élément peut être modifiée individuellement, mais ne fait pas partie d'un groupe."
        },
        "datetime": {
            "day": "jour(s)",
            "hour": "heure(s)",
            "minute": "minute(s)",
            "previous": "Précédent",
            "next": "Suivant",
            "months": ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
            "weekdays": ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
            "amPm": ["am", "pm"],
            "unknown": "-"
        },
        "clickToEdit": "Cliquer pour éditer",
        "enterToSave": "Appuyer sur 'entrer' pour sauvegarder",
        "upload": {
            "uploadText": "choisir fichier",
            "clearText": "effacer",
            "dragDropText": "Déposez un fichier ici pour le télécharger",
            'noImageText': "pas d'image",
            'noFileText': "pas de fichier",
            "fileReadText": "chargement"
        },
        "_custom": {
            "tooltip": {
                "clickToEdit": "Cliquer pour éditer",
                "enterToSave": "Appuyer sur 'entrer' pour sauvegarder",
                "details": "Détail",
                "clickToSelect": "cliquer pour sélectionner",
                "clickToUnSelect": "cliquer pour désélectionner"
            },
            "button": {
                "selectAll": 'Tout sélectionner',
                "selectNone": 'Tout désélectionner'
            }
        }
    },
    nl: {
        "sProcessing": "Bezig...",
        "sLengthMenu": "_MENU_ resultaten weergeven",
        "sZeroRecords": "Geen resultaten gevonden",
        "sInfo": "_START_ tot _END_ / _TOTAL_ resultaten",
        "sInfoEmpty": "Geen resultaten om weer te geven",
        "sInfoFiltered": " (_MAX_ resultaten)",
        "sInfoPostFix": "",
        "sSearch": "Zoeken:",
        "sEmptyTable": "Geen resultaten aanwezig in de tabel",
        "sInfoThousands": ".",
        "sLoadingRecords": "Een moment geduld aub - bezig met laden...",
        "oPaginate": {
            "sFirst": "Eerste",
            "sLast": "Laatste",
            "sNext": "Volgende",
            "sPrevious": "Vorige"
        },
        "oAria": {
            "sSortAscending": ": activeer om kolom oplopend te sorteren",
            "sSortDescending": ": activeer om kolom aflopend te sorteren"
        },
        "locale": "nl",
        "cancel": "cancel",
        "apply": "apply",
        "select": {
            "rows": {
                "_": "(%d resultat sélectionnées)",
                "0": "Aucune ligne sélectionnée",
                "1": "1 ligne sélectionnée"
            }
        },
        "menuText": {
            "all": "Alles"
        },
        "selectPlaceHolder": "please select",
        "reorder": {
            "button": "Reorder"
        },
        "create": {
            "button": "Ajouter",
            "title": "Ajouter nouveau élément",
            "submit": "Ajouter"
        },
        "edit": {
            "button": "Bewerk",
            "title": "Bewerk item(s)",
            "submit": "Bewerk"
        },
        "remove": {
            "button": "verwijderen",
            "title": "verwijderen item(s)",
            "submit": "verwijderen",
            "confirm": {
                "_": "Are you sure you wish to delete %d rows?",
                "1": "Are you sure you wish to delete 1 row?"
            }
        },
        "print": {
            "button": "Print"
        },
        "excel": {
            "button": "Excel"
        },
        "pdf": {
            "button": "Pdf"
        },
        "error": {
            "system": "A system error has occurred (More information)"
        },
        "multi": {
            "title": "Multiple values",
            "info": "The selected items contain different values for this input. To edit and set all items for this input to the same value, click or tap here, otherwise they will retain their individual values.",
            "restore": "Undo changes",
            "noMulti": "This input can be edited individually, but not part of a group."
        },
        "datetime": {
            "day": "day(s)",
            "hour": "hour(s)",
            "minute": "minute(s)",
            "previous": "Previous",
            "next": "Next",
            "months": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            "weekdays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            "amPm": ["am", "pm"],
            "unknown": "-"
        },
        "clickToEdit": "click to edit",
        "enterToSave": "press enter to save",
        "upload": {
            "uploadText": "Choose file",
            "clearText": "Clear",
            "dragDropText": "Drag and drop a file here to upload",
            'noImageText': "No image",
            'noFileText': "No file",
            "fileReadText": "uploading file"
        },
        "_custom": {
            "tooltip": {
                "clickToEdit": "click to edit",
                "enterToSave": "press enter to save",
                "details": "details",
                "clickToSelect": "click to select",
                "clickToUnSelect": "click to deselect"
            },
            "button": {
                "selectAll": 'select all',
                "selectNone": 'select none'
            }
        }
    },
    de: {
        "sEmptyTable": "Keine Daten in der Tabelle vorhanden",
        "sInfo": "_START_ bis _END_ / _TOTAL_ Einträgen",
        "sInfoEmpty": "Keine Daten vorhanden",
        "sInfoFiltered": "(_MAX_ Einträgen)",
        "sInfoPostFix": "",
        "sInfoThousands": ".",
        "sLengthMenu": "_MENU_ Einträge anzeigen",
        "sLoadingRecords": "Wird geladen ..",
        "sProcessing": "Bitte warten ..",
        "sSearch": "Suchen",
        "sZeroRecords": "Keine Einträge vorhanden",
        "oPaginate": {
            "sFirst": "Erste",
            "sPrevious": "Zurück",
            "sNext": "Nächste",
            "sLast": "Letzte"
        },
        "oAria": {
            "sSortAscending": ": aktivieren, um Spalte aufsteigend zu sortieren",
            "sSortDescending": ": aktivieren, um Spalte absteigend zu sortieren"
        },
        "locale": "de",
        "select": {
            "rows": {
                "_": "%d Zeilen ausgewählt",
                "0": "",
                "1": "1 Zeile ausgewählt"
            }
        },
        "apply": "apply",
        "cancel": "cancel",
        "buttons": {
            "print": "Drucken",
            "colvis": "Spalten",
            "copy": "Kopieren",
            "copyTitle": "In Zwischenablage kopieren",
            "copyKeys": "Taste <i>ctrl</i> oder <i>\u2318</i> + <i>C</i> um Tabelle<br>in Zwischenspeicher zu kopieren.<br><br>Um abzubrechen die Nachricht anklicken oder Escape drücken.",
            "copySuccess": {
                "_": "%d Zeilen kopiert",
                "1": "1 Zeile kopiert"
            },
            "pageLength": {
                "-1": "Zeige alle Zeilen",
                "_": "Zeige %d Zeilen"
            }
        },
        "menuText": {
            "all": "Alle"
        },
        "selectPlaceHolder": "please select",
        "reorder": {
            "button": "Reorder"
        },
        "create": {
            "button": "Add",
            "title": "Ajouter nouveau élément",
            "submit": "Ajouter"
        },
        "edit": {
            "button": "Éditer",
            "title": "Éditer élément(s)",
            "submit": "Éditer"
        },
        "remove": {
            "button": "supprimer",
            "title": "Supprimer élément(s)",
            "submit": "supprimer",
            "confirm": {
                "_": "Are you sure you wish to delete %d rows?",
                "1": "Are you sure you wish to delete 1 row?"
            }
        },
        "print": {
            "button": "Print"
        },
        "excel": {
            "button": "Excel"
        },
        "pdf": {
            "button": "Pdf"
        },
        "error": {
            "system": "A system error has occurred (More information)"
        },
        "multi": {
            "title": "Multiple values",
            "info": "The selected items contain different values for this input. To edit and set all items for this input to the same value, click or tap here, otherwise they will retain their individual values.",
            "restore": "Undo changes",
            "noMulti": "This input can be edited individually, but not part of a group."
        },
        "datetime": {
            "day": "day(s)",
            "hour": "hour(s)",
            "minute": "minute(s)",
            "previous": "Previous",
            "next": "Next",
            "months": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            "weekdays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            "amPm": ["am", "pm"],
            "unknown": "-"
        },
        "clickToEdit": "click to edit",
        "enterToSave": "press enter to save",
        "upload": {
            "uploadText": "Choose file",
            "clearText": "Clear",
            "dragDropText": "Drag and drop a file here to upload",
            'noImageText': "No image",
            'noFileText': "No file",
            "fileReadText": "uploading file"
        },
        "_custom": {
            "tooltip": {
                "clickToEdit": "click to edit",
                "enterToSave": "press enter to save",
                "details": "details",
                "clickToSelect": "click to select",
                "clickToUnSelect": "click to deselect"
            },
            "button": {
                "selectAll": 'select all',
                "selectNone": 'select none'
            }
        }
    }
};
export default _Translation;
