var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import $ from "jquery";
export default class FormBuilderEditorStatic {
    static _getEditorOption(url, language, idSrc, fields) {
        return {
            ajax: {
                url: `${url}?_lang=${language}`,
                data: function (data) {
                    data._lang = language;
                    // data = $.extend(true, {}, sendData, data);
                    return data;
                }
            },
            // template: Helpers._getObjByProp(_settings, __PROP.editorTemplate, DataCardDefaults.options.editorTemplate),
            // template: _settings.editorTemplate || dcDefaultsOptions.editorTemplate,
            idSrc: idSrc,
            // i18n: that._i18n,
            // fields: that._editorFields
            fields: fields
        };
    }
    static _getTranslationFields(appFormI18nEditorOptionsFields, languages = []) {
        return __awaiter(this, void 0, void 0, function* () {
            const multiFields = [];
            for (const language of languages) {
                for (const multiField of appFormI18nEditorOptionsFields) {
                    const elem = Object.assign({}, multiField);
                    elem.name = `${language}.${elem.name}`;
                    multiFields.push(elem);
                }
            }
            // return multiFields;
            return appFormI18nEditorOptionsFields;
        });
    }
    static _createEditorField(editorItem, id, fieldName, value) {
        return `<dd class="m-0" data-editor-item="${editorItem}" data-editor-id="${id}" data-editor-field="${fieldName}" data-editor-value="${value}">${value}</dd>`;
    }
    static _createTranslationEditorField(editorItem, id, language, fieldName, value) {
        // return `<!--<dd class="m-0" data-editor-item="${editorItem}" data-translation="${language}" data-editor-field="${language}.${fieldName}">${value}</dd>-->`
        return `<dd class="m-0" data-editor-item="${editorItem}" data-translation="${language}" data-editor-id="${id}" data-editor-field="${fieldName}" data-editor-value="${value}">${value}</dd>`;
    }
    static _createAccordionCollapse(className, htmlId, dataBsParent) {
        return $(`
            <div class="${className} accordion-collapse collapse"  id="${htmlId}" data-bs-parent="#${dataBsParent}">
                <div class="accordion-body">
                </div>
            </div>
        `);
    }
    static _createAccordion(className, htmlId) {
        return $(`
            <div class="accordion accordion-flush border ${className}" id="${htmlId}">

            </div>
        `);
    }
}
