var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import $ from 'jquery';
import Editor from "datatables.net-editor";
import "datatables.net-editor-bs5";
import "datatables.net-editor-bs5/css/editor.bootstrap5.css";
import BootstrapNotify from "../notification/bootstrap-notify.js";
import { EnumDTEditorEvent } from "../../data-table/model/dt-enum.js";
import { appFormEditorItem, appFormI18nEditorItem, FormBuilderStatic } from "./form-builder-static.js";
import "./style.css";
import FormBuilderEditorStatic from "./form-builder-editor-static.js";
import { appFormGroupQuestionEditorItem, appFormGroupQuestionI18nEditorItem } from "./form-group-question-builder/form-group-question-static.js";
import { appFormQuestionEditorItem, appFormQuestionI18nEditorItem } from "./form-group-question-builder/form-question-builder/form-question-builder-static.js";
const appFormEditorFields = [
    { label: "id", name: "formIdCode", create: false, edit: false, search: false, visible: true, display: false },
    {
        label: "Category",
        name: "formCategoryId",
        visible: true,
        create: true,
        edit: true,
        display: true,
        type: "select",
        className: 'col-12 col-md-6 m-0'
    },
    { label: "create date", name: "createdAt", visible: false, create: false, edit: false, display: false },
    { label: "update date", name: "updatedAt", visible: false, create: false, edit: false, display: false },
    { label: "order", name: "order", visible: false, search: false, create: false, edit: false, display: false },
    { label: "label", name: "label", visible: true },
    {
        label: "active", name: "isActive", type: 'boolean', className: 'col-12 col-md-4 m-0'
    },
    {
        label: "isEnabled", name: "isEnabled", type: 'boolean', className: 'col-12 col-md-4 m-0'
    },
    {
        label: "signature", name: "requireSignature", type: 'boolean', className: 'col-12 col-md-4 m-0'
    },
    {
        label: "send mail notification", name: "sendEmail", type: 'boolean', className: 'col-12 col-md-4 m-0'
    },
    {
        label: "create pdf", name: "createPdf", type: 'boolean', className: 'col-12 col-md-4 m-0'
    }
];
const appFormI18nEditorFields = [
    // {label: 'id', name: 'id', visible: false, display: false, edit: false, create: false},
    { label: 'name for pdf', name: 'formName' },
    { label: 'title', name: 'formTitle' },
    { label: 'language', name: 'language', visible: false },
    { label: 'form description', name: 'formDescription', visible: false, type: 'textarea' },
    { label: 'form message for user', name: 'formMessage', visible: false, type: 'textarea' },
];
const appFormGroupQuestionEditorFields = [
    {
        label: "id",
        name: "formGroupQuestionId",
        create: false,
        edit: false,
        search: false,
        visible: false,
        display: false
    },
    {
        label: "form category",
        name: "formCategoryId",
        create: false,
        edit: false,
        search: false,
        visible: true,
        display: false,
        type: "select"
    },
    {
        label: "form", name: "formIdCode", type: "select",
        visible: true, create: true, edit: true, display: true
    },
    { label: "label", name: "label", visible: true },
    { label: "create date", name: "createdAt", visible: false, create: false, edit: false, display: false },
    { label: "update date", name: "updatedAt", visible: false, create: false, edit: false, display: false },
    {
        label: "active", name: "isActive", type: 'boolean', className: 'col-12 col-md-6 m-0', search: false, visible: true /*, options : {"yes" : 1, 'no' : 0}*/
    },
    {
        label: "isEnabled", name: "isEnabled", type: 'boolean', className: 'col-12 col-md-6 m-0', search: false /*, options : {"yes" : 1, 'no' : 0}*/
    },
    { label: "order", name: "order", visible: false, create: false, edit: false, display: false }
];
const appFormGroupQuestionI18nEditorFields = [
    { label: 'name', name: 'groupQuestionName' },
    { label: 'language', name: 'language', visible: false },
    { label: 'title', name: 'groupQuestionTitle' },
    { label: 'description', name: 'groupQuestionDescription', type: 'textarea', search: false },
    { label: 'message', name: 'groupQuestionMessage', type: 'textarea', visible: true, search: false },
];
const appFormQuestionEditorFields = [{
        label: "id",
        name: "formQuestionId",
        create: false,
        edit: false,
        search: false,
        visible: false,
        display: false
    },
    {
        label: "Group", name: "formGroupQuestionId", visible: true,
        className: 'col col-12 col-md-12 m-0', type: 'select', edit: true, display: true
    },
    { label: "label", name: "label", visible: true },
    { label: "type", name: "questionType", type: "select" },
    { label: "create date", name: "createdAt", visible: false, create: false, edit: false, display: false },
    { label: "update date", name: "updatedAt", visible: false, create: false, edit: false, display: false },
    { label: "order", name: "order", visible: false, create: false, edit: false, display: false },
    {
        label: "active", name: "isActive", visible: true,
        create: true, edit: true, type: 'boolean', className: 'col col-6 col-md-3 m-0'
    },
    {
        label: "enable", name: "isEnabled", visible: true,
        create: true, edit: true, type: 'boolean', className: 'col col-6 col-md-3 m-0',
    },
    {
        label: "required", name: "required", visible: true,
        create: true, edit: true, type: 'boolean', className: 'col col-6 col-md-3 m-0',
    },
    {
        label: "isSubQuestion", name: "isSubQuestion", visible: true,
        create: true, edit: true, type: 'boolean', className: 'col col-6 col-md-3 m-0',
    }
];
const appFormQuestionI18nEditorFields = [
    { label: 'name', name: 'questionName' },
    { label: 'title', name: 'questionTitle' },
    { label: 'language', name: 'language', visible: false },
    { label: 'description', name: 'questionDescription', type: 'textarea', visible: false },
    { label: 'message', name: 'questionMessage', type: "textarea", visible: false },
];
export default class FormBuilder {
    constructor(container, _formIdCode, options) {
        this._options = {};
        const $container = $(container);
        this._formIdCode = _formIdCode;
        this._settings = FormBuilderStatic._mergeSetting(options, this);
        this._jquerySelector = $container[0];
        this._settings.event.init(this);
    }
    data(_formData) {
        if (_formData !== undefined) {
            this._formData = _formData;
            return this;
        }
        return this._formData;
    }
    draw() {
        return __awaiter(this, void 0, void 0, function* () {
            // if (FormBuilder.debug) {
            //     console.log('FormBuilder.draw')
            // }
            yield this._settings.event.initDraw(this);
            yield this._settings.event.preDraw(this);
            yield this._settings.event.onDraw(this);
            yield FormBuilderStatic._renderForm(this);
            yield this._settings.event.postDraw(this);
            return this;
        });
    }
    settings() {
        return this._settings;
    }
    initData(ajaxResponse) {
        // console.log({_data : ajaxResponse._data})
        this
            .data(ajaxResponse._data);
        this._processOptions(ajaxResponse._options);
        return this;
    }
    _ajaxPromise() {
        const _settings = this._settings;
        let dataParam = $(this._jquerySelector).data();
        let sendData = $.extend(true, {}, dataParam);
        let ajaxUrl = _settings.ajax;
        sendData = $.extend(true, {}, sendData, {
            action: EnumDTEditorEvent.read, formId: this._formIdCode
        });
        return $.post(ajaxUrl, sendData);
    }
    _processOptions(ajaxOption) {
        if (!$.isEmptyObject(ajaxOption)) {
            this._options = ajaxOption;
        }
        // if (DataCard.debug) {
        //     console.log('DataCard._processOptions', {
        //         ajaxOption: ajaxOption,
        //         options: that._options,
        //         _fields: this._fields,
        //         _multiFields: that._multiFields
        //     });
        // }
        return this;
    }
    /**
     *
     * @returns {JQuery.PromiseBase}
     * @protected
     */
    _initialize() {
        let dataCardInitTime = Date.now();
        const that = this;
        const _settings = this._settings;
        _settings.event.initLoad(that);
        _settings.event.preLoad(that);
        _settings.event.onLoad(that);
        return this._ajaxPromise().then(function _ajaxPromise_done(response) {
            return __awaiter(this, void 0, void 0, function* () {
                // response = Helpers._isJsonString(response) ? JSON.parse(response) : {};
                // console.log({response})
                if (response._status || response._status === 1) {
                    // console.log({response})
                    yield that.initData(response);
                    that.appFormEditor = yield new Editor(yield FormBuilderEditorStatic._getEditorOption('/api/app-form/', that._settings.language, 'formIdCode', appFormEditorFields));
                    that.appFormI18nEditor = yield new Editor(yield FormBuilderEditorStatic._getEditorOption('/api/dt/app-form-i18n/', that._settings.language, 
                    // 'id',  await FormBuilderEditorStatic._getTranslationFields(appFormI18nEditorFields, ['fr', 'nl', 'en'])
                    'id', appFormI18nEditorFields));
                    that.appFormGroupQuestionEditor = yield new Editor(yield FormBuilderEditorStatic._getEditorOption('/api/app-form-group-question/', that._settings.language, 'formGroupQuestionId', appFormGroupQuestionEditorFields));
                    that.appFormGroupQuestionI18nEditor = yield new Editor(yield FormBuilderEditorStatic._getEditorOption('/api/dt/app-form-group-question-i18n/', that._settings.language, 'id', appFormGroupQuestionI18nEditorFields));
                    that.appFormQuestionEditor = yield new Editor(yield FormBuilderEditorStatic._getEditorOption('/api/app-form-group-question/', that._settings.language, 'formGroupQuestionId', appFormQuestionEditorFields));
                    that.appFormQuestionI18nEditor = yield new Editor(yield FormBuilderEditorStatic._getEditorOption('/api/dt/app-form-group-question-i18n/', that._settings.language, 'id', appFormQuestionI18nEditorFields));
                    // console.log({_editorFields : that._editorFields})
                    yield _settings.event.postLoad(that);
                    yield that.draw();
                    dataCardInitTime = Date.now() - dataCardInitTime;
                }
                else {
                    //TODO
                    // BootstrapNotify.danger(response.message);
                    yield BootstrapNotify._notify(response._message);
                }
            });
        });
    }
    /**
     *
     * @param {JQuery.Selector} sourceElementIdSelector
     * @param {TypeFormIdCode} formIdCode
     * @param {TypeFormBuilderOptions} options
     * @returns {Promise<FormBuilder>}
     */
    static _init(sourceElementIdSelector, formIdCode, options) {
        return new Promise((resolve) => __awaiter(this, void 0, void 0, function* () {
            // const queryString = window.location.search;
            // const urlParams = new URLSearchParams(queryString);
            // const formId = urlParams.get('formId') ||'';
            // // console.log({formId : urlParams.get('formId'), search : window.location.search})
            // if(Helpers._isStringNotEmpty(formId)){
            //
            // }
            const formBuilder = yield new FormBuilder(sourceElementIdSelector, formIdCode, options);
            resolve(formBuilder._initialize()
                .then(() => formBuilder)
                .then(() => {
                listenEdit(formBuilder.appFormEditor, appFormEditorItem);
                listenEdit(formBuilder.appFormI18nEditor, appFormI18nEditorItem);
                listenEdit(formBuilder.appFormGroupQuestionEditor, appFormGroupQuestionEditorItem);
                listenEdit(formBuilder.appFormGroupQuestionI18nEditor, appFormGroupQuestionI18nEditorItem);
                listenEdit(formBuilder.appFormQuestionEditor, appFormQuestionEditorItem);
                listenEdit(formBuilder.appFormQuestionI18nEditor, appFormQuestionI18nEditorItem);
                return formBuilder;
            }));
        }));
    }
}
function listenEdit(editor, editorItem) {
    $(`[data-editor-item=${editorItem}]`).on('click', function (e) {
        const $this = $(this);
        const id = $this.attr('data-editor-id');
        const dataItem = $this.attr('data-editor-item');
        const language = $this.attr('data-translation');
        const dataTranslationAttr = language ? `[data-translation=${language}]` : '';
        const fields = $(`[data-editor-item=${dataItem}]${dataTranslationAttr}[data-editor-id=${id}]`);
        const fieldsVal = {};
        if (language) {
            fieldsVal['language'] = language;
        }
        fields.each((index, element) => {
            const fieldName = $(element).attr('data-editor-field');
            // const fieldValue = $(element).attr('data-editor-value');
            fieldsVal[fieldName] = $(element).attr('data-editor-value');
        });
        // console.log({$this : $(this), language, fields, fieldsVal})
        editor
            // @ts-ignore
            .bubble(this, {
            buttons: '_basic'
        })
            // @ts-ignore
            .set(fieldsVal);
    });
}
