import $ from 'jquery';
import Helpers from "../../../dist/_utils/helpers.js";
import DcCardFn from "./grid/card/dc-card-fn.js";
import DcControlFn, { controlListeners } from "./control/dc-control-fn.js";
import DcFieldFn from "./editor/field/dc-field-fn.js";
import { cardModel, controlModel, dcModel } from "./model/dc-model.js";
const debug = false;
const generalListeners = {
    /**
     * if fixedControl option is true, clone control panel and make sticky
     * @param dataCard
     * @param editor
     */
    fixedControl: function (dataCard, editor) {
        if (debug) {
            console.log({
                _event: "DataCardFn.listener.fixedControl"
            });
        }
        let settings = dataCard.settings();
        if (settings.fixedControl) {
            $(window).scroll(function () {
                DcControlFn._fixedControl(dataCard, editor);
            });
        }
    },
    /**
     * enable or disable button (edit, remove, select all, select none
     * @param dataCard
     */
    enableDisableBtn: function (dataCard) {
        if (debug) {
            console.log({
                _event: "DataCardFn.listener.enableDisableBtn"
            });
        }
        let settings = dataCard.settings();
        if (Helpers._isStringNotEmpty(settings.controlPanel)) {
            let $editBtn = $(`.${controlModel.btn.edit.attr.class}`, settings.controlPanel);
            let $removeBtn = $(`.${controlModel.btn.remove.attr.class}`, settings.controlPanel);
            let $selectNoneBtn = $(`.${controlModel.btn.selectNone.attr.class}`);
            let $selectedCards = DcCardFn._getSelectedCards(settings.jquerySelector);
            if ($selectedCards.length > 0) {
                $editBtn.prop(dcModel.disabledAttr, false).removeClass(dcModel.disabledClass);
                $removeBtn.prop(dcModel.disabledAttr, false).removeClass(dcModel.disabledClass);
                $selectNoneBtn.prop(dcModel.disabledAttr, false).removeClass(dcModel.disabledClass);
            }
            else {
                $editBtn.prop(dcModel.disabledAttr, true).addClass(dcModel.disabledClass);
                $removeBtn.prop(dcModel.disabledAttr, true).addClass(dcModel.disabledClass);
                $selectNoneBtn.prop(dcModel.disabledAttr, true).addClass(dcModel.disabledClass);
            }
        }
    },
    windowResize: function (dataCard) {
        $(window).resize(function () {
            setTimeout(function () {
                DcControlFn._resizeFixedControl(dataCard);
            }, 100);
        });
    },
    tooltip: function () {
        /*let tooltip = */
        $('[data-toggle="tooltip"]').tooltip();
        // if(tooltip.data('tooltip')){
        //     tooltip.tooltip('dispose');
        // }
    }
};
const cardListeners = {
    btn: function (dataCard, editor) {
        let settings = dataCard.settings();
        // let arrayBtn = Helpers._getObjByProp(settings, __PROP.cardButtons, []);
        let arrayBtn = settings.cardButtons;
        // console.log({arrayBtn})
        $.each(arrayBtn, function (index, element) {
            if (typeof element === 'object') {
                // let className = Helpers._getObjByProp(element, __PROP.className, "");
                let className = element.className;
                if (Helpers._isStringNotEmpty(className)) {
                    let $btn = $('.' + className, settings.jquerySelector);
                    if ($btn.length) {
                        // let action = Helpers._getObjByProp(element, __PROP.action, null);
                        let action = element.action;
                        $btn.off('click');
                        // console.log({element, action, $btn})
                        if ( /*Helpers._isFunction(action) && */action !== null && typeof action === 'function') {
                            $btn.on('click', function (event) {
                                $(this).tooltip('hide');
                                let $card = $(this).closest("." + cardModel.item.attr.class);
                                let dataId = $card.attr(dcModel.dataEditorIdAttr);
                                let rowData = dataCard.data(dataId);
                                // console.log({$card, dataId, rowData});
                                action(event, dataCard, editor, this, dataId, rowData);
                            });
                        }
                    }
                }
            }
        });
    }
};
const fieldListeners = {
    fieldFilter: function (dataCard) {
        $('[' + controlModel.search.fieldIdAttr + ']').off('keyup').off('change');
        $('[' + controlModel.search.fieldIdAttr + ']', '.' + controlModel.controlPanelContainer.attr.class + '.' + dcModel.activeClass).each(function () {
            let $this = $(this);
            let tagName = $this.prop("tagName");
            if (tagName.toLowerCase() === "input") {
                $this.on("keyup", function () {
                    DcFieldFn._fieldsFilter(dataCard);
                });
            }
            else if (tagName.toLowerCase() === "select") {
                $this.on("change", function () {
                    let selectedVal = $this.val();
                    $('option', $this).removeAttr(dcModel.selectedAttr);
                    $('option[value="' + selectedVal + '"]', $this).attr(dcModel.selectedAttr, dcModel.selectedAttr);
                    DcFieldFn._fieldsFilter(dataCard);
                });
            }
        });
    },
    fieldSort: function (dataCard) {
        let $fieldFilter = $('[' + controlModel.sort.fieldIdAttr + ']', '.' + controlModel.controlPanelContainer.attr.class + '.' + dcModel.activeClass);
        $fieldFilter.each(function (index, element) {
            let $this = $(element);
            $this.off('click').on('click', function () {
                let $_this = $(this);
                let fieldId = $_this.attr(controlModel.sort.fieldIdAttr);
                let isMultiField = ($_this.attr(dcModel.dataMultiFieldAttr)) === "true";
                if (typeof fieldId === "string" && fieldId !== "" && Helpers._isStringNotEmpty(fieldId)) {
                    let asc = true;
                    if ($_this.hasClass(controlModel.sort.asc) || $_this.hasClass(controlModel.sort.desc)) {
                        asc = $_this.hasClass(controlModel.sort.desc);
                    }
                    $fieldFilter.removeClass(controlModel.sort.desc).removeClass(controlModel.sort.asc);
                    if (asc) {
                        $_this.removeClass(controlModel.sort.desc).addClass(controlModel.sort.asc);
                    }
                    else {
                        $_this.removeClass(controlModel.sort.asc).addClass(controlModel.sort.desc);
                    }
                    dataCard.sort(fieldId, asc, isMultiField, true).draw();
                }
            });
        });
    }
};
export default class DcFn {
    static initListener(dataCard, editor, listeners) {
        if (listeners === undefined) {
            listeners = { cardListeners, controlListeners, fieldListeners, generalListeners };
        }
        // if (arguments.length > 2) {
        //     // listeners = arguments[2];
        // }
        $.each(listeners, function (index, listenerElement) {
            // console.log({index, listenerElement})
            if (Helpers._isFunction(listenerElement) && typeof listenerElement === "function") {
                listenerElement(dataCard, editor);
            }
            else if (typeof listenerElement === 'object') {
                DcFn.initListener(dataCard, editor, listenerElement);
            }
        });
        // console.log("")
    }
    ;
}
export { fieldListeners, cardListeners, generalListeners };
