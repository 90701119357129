import Editor from "datatables.net-editor";
import "datatables.net-editor-bs5";
import $ from 'jquery';
import 'jquery-ui';
import Helpers from "../../../../dist/_utils/helpers.js";
import BootstrapNotify, { EnumNotificationType } from "../../app/notification/bootstrap-notify.js";
// import type ServerDcCardResponse from "../../../src/server-dc-card/response/server-dc-card-response.js";
import { EnumDTEditorEvent } from "../../data-table/model/dt-enum.js";
import { generalListeners } from "../dc-fn.js";
import DcCardFn from "../grid/card/dc-card-fn.js";
import { controlModel, dcModel, cardModel } from "../model/dc-model.js";
export default class DcEditorFn {
    /**
     *
     * @param {TypeDataCardOptions} settings
     * @return {boolean}
     */
    static _isRowReorderValid(settings) {
        let inArrayField = false;
        // let arrayFields = Helpers._getObjByProp(settings, __PROP.fields, []);
        let arrayFields = settings.fields;
        // let rowReorder = Helpers._getObjByProp(settings, __PROP.rowReorder, "");
        let rowReorder = settings.rowReorder;
        if (Helpers._isStringNotEmpty(rowReorder) && Helpers._isSameType(arrayFields, []) && arrayFields.length > 0) {
            arrayFields.forEach(function (field) {
                if (Helpers._isStringNotEmpty(field.name)) {
                    if (field.name === rowReorder) {
                        inArrayField = true;
                    }
                }
            });
        }
        return inArrayField;
    }
    /**
     *
     * @param {DataCard} dataCard
     * @param {JQuery.Selector} jquerySelector
     */
    static _enableSortable(dataCard, jquerySelector) {
        if ($.ui) {
            let sortableInstance = $(jquerySelector).sortable("instance");
            if (sortableInstance) {
                sortableInstance.enable();
            }
            // if (Helpers._isNotUndefined(sortableInstance)) {
            //     sortableInstance.sortable('enable');
            //     // sortableInstance.enable();
            // }
        }
    }
    /**
     *
     * @param {DataCard} dataCard
     * @param {JQuery.Selector} jquerySelector
     */
    static _disableSortable(dataCard, jquerySelector) {
        // if (DcEditorFn.debug || true) {
        //     console.log("_disableSortable() called");
        // }
        if ($.ui) {
            let sortableInstance = $(jquerySelector).sortable("instance");
            // console.log({sortableInstance})
            if (sortableInstance) {
                sortableInstance.disable();
            }
            // if (Helpers._isNotUndefined(sortableInstance)) {
            //     console.log({sortableInstance})
            //     sortableInstance.sortable('disable');
            //     // sortableInstance.disable();
            // }
        }
    }
    /**
     *
     * @param {DataCard} dataCard
     * @param {JQuery.Selector} jquerySelector
     */
    static _revertSortable(dataCard, jquerySelector) {
        // if (DcEditorFn.debug || true) {
        //     console.log("_revertSortable() called");
        // }
        if ($.ui) {
            let sortableInstance = $(jquerySelector).sortable("instance");
            if (Helpers._isNotUndefined(sortableInstance)) {
                sortableInstance.cancel();
                // sortableInstance.cancel();
                //TODO notification
                BootstrapNotify._notify('test', 'une erreur est survenue');
                // BootstrapNotify.danger("une erreur est survenue");
            }
        }
    }
    /**
     *
     * @param {DataCard} dataCard
     * @param {JQuery.Selector} jquerySelector
     */
    static _sortable(dataCard, jquerySelector) {
        // console.log("_sortable()");
        let settings = dataCard.settings();
        if ($.ui && DcEditorFn._isRowReorderValid(settings)) {
            let originalIndex = -1;
            /**
             *make jquerySelector sortable
             */
            $(jquerySelector).sortable({
                tolerance: "pointer",
                items: " > ." + cardModel.item.attr.class,
                connectWith: cardModel.rowCardContainer.attr.class,
                handle: '.' + controlModel.btn.reOrder.attr.class,
                placeholder: "ui-state-highlight",
                start: function (event, ui) {
                    DcCardFn._closeAllTooltip().then(() => {
                        // const outerHeight = ui.item.outerHeight();
                        // const innerHeight = ui.item.innerHeight();
                        // const outerWidth = ui.item.outerWidth();
                        // const innerWidth = ui.item.innerWidth();
                        // console.log({uiItem : ui.item, outerHeight, innerHeight, outerWidth, innerWidth})
                        ui.placeholder.height(ui.item.height());
                        // ui.placeholder.width(ui.item.width()!);
                        ui.placeholder.addClass(ui.item.attr('class'));
                        originalIndex = (ui.item.index());
                        // console.log({originalIndex})
                    });
                },
                stop: function (event, ui) {
                    let editor = dataCard.editor();
                    let pageIndex = dataCard.page();
                    let selectLength = dataCard.dataLength();
                    let rowReorderSrc = settings.rowReorder;
                    // console.log({selectLength, originalIndex, pageIndex})
                    // let processTime = Date.now();
                    let addIndex = 1 + ((pageIndex - 1) * selectLength);
                    let newIndex = (ui.item.index()), begin = originalIndex, end = newIndex, arrayUpdateOrder = {}, arrayId = [];
                    if (end <= begin) {
                        begin = newIndex;
                        end = originalIndex;
                    }
                    // console.log({newIndex, originalIndex, end, begin})
                    let multiSet = {};
                    let $selectedCard = $("." + cardModel.item.attr.class, "." + cardModel.rowCardContainer.attr.class).slice(begin, end + 1);
                    if ($selectedCard.length > 1) {
                        $selectedCard.each(function () {
                            let $this = $(this);
                            let id = $this.attr(dcModel.dataEditorIdAttr);
                            if (Helpers._isStringNotEmpty(id)) {
                                arrayUpdateOrder[id.toString()] = ($this.index() + addIndex);
                                // console.log({thisIndex : $this.index(),index :$this.index() + addIndex, rowId : id })
                                arrayId.push(id.toString());
                            }
                        });
                        multiSet[rowReorderSrc] = arrayUpdateOrder;
                        // console.log({multiSet, arrayId})
                        editor.one(EnumDTEditorEvent.submitUnsuccessful, function (e, json) {
                            DcEditorFn._revertSortable(dataCard, '.' + cardModel.rowCardContainer.attr.class);
                            // console.log({
                            //     _event: EnumDTEditorEvent.submitUnsuccessful,
                            //     dataCard: dataCard,
                            //     editor: editor,
                            //     json: json,
                            //     e: e
                            // });
                        });
                        editor.one(EnumDTEditorEvent.submitSuccess, function ( /*e : TypeJqueryEvent, json : object*/) {
                            editor.off(EnumDTEditorEvent.submitUnsuccessful, null);
                        });
                        // @ts-ignore TODO
                        editor.edit(arrayId, false)
                            // @ts-ignore
                            .multiSet(multiSet)
                            .submit();
                    }
                }
            });
        }
    }
    static init(dataCard, editor, editorFields) {
        let allListener = DcEditorFn.listener;
        $.each(allListener, function (index, listener) {
            if (Helpers._isFunction(listener)) {
                listener(dataCard, editor, editorFields);
            }
        });
        /*var allEditorListener = EnumDTEditorEvent;
        $.each(allEditorListener, function (index, elem) {
            editor.on(elem, function (e) {
                console.log({
                    _event : elem,
                    editorFields: editorFields,
                    editor: editor,
                    e: e
                });
            })
        });*/
    }
    ;
}
DcEditorFn.debug = false;
DcEditorFn.listener = {
    editorListener: function (dataCard, editor, editorFields) {
        // editor
        // .on(EnumDTEditorEvent.initSubmit, function (e : TypeJqueryEvent, data : TypeDataSubmittedToServer, action : TypeAction) {
        //     console.log({data})
        //     // let dataParam = $(dataCard.settings().jquerySelector!).data();
        //     // $.each(dataParam, function (name, property) {
        //     //     console.log({data, dataParam})
        //     //     data[`${name}`] = property;
        //     // });
        //     if (DcEditorFn.debug || true) {
        //         console.log({
        //             eventName: EnumDTEditorEvent.initSubmit, arguments, data
        //         });
        //     }
        // })
        editor
            .on(EnumDTEditorEvent.preOpen, function (e, mode, action) {
            if (DcEditorFn.debug) {
                console.log(EnumDTEditorEvent.preOpen, {
                    editorFields, editor, arguments
                });
            }
            editorFields.forEach(function (field) {
                if (field.display === true) {
                    editor.field(field.name).show();
                }
            });
            let editorModalClass = dataCard.settings().editorModalClass;
            if (Helpers._isStringNotEmpty(editorModalClass)) {
                // @ts-ignore TODO
                $('.modal-dialog', Editor.display.bootstrap.node(editor)).addClass(editorModalClass);
            }
        })
            .on(EnumDTEditorEvent.open, function (e, mode, action) {
            // if (DcEditorFn.debug) {
            //     console.log({
            //         _event: EnumDTEditorEvent.open, editorFields: editorFields, editor: editor, arguments
            //     });
            // }
            generalListeners.tooltip();
        })
            // .on(EnumDTEditorEvent.postRemove, function (e : TypeJqueryEvent, json : TypeDataReturnedFromServer, ids : string[]) {
            //     // if (DcEditorFn.debug) {
            //         console.log({
            //             _event: EnumDTEditorEvent.postRemove,
            //             editorFields: editorFields,
            //             editor: editor, arguments
            //         });
            //     // }
            //     BootstrapNotify._notify('Operation successful', 'Element was remove');
            //     // dataCard.removeData()
            //     // dataCard.ajax.reload(true);
            // })
            // .on(EnumDTEditorEvent.postEdit, function (e : TypeJqueryEvent, json : TypeDataReturnedFromServer, data : string[]) {
            //     // if (DcEditorFn.debug) {
            //         console.log(EnumDTEditorEvent.postEdit, {
            //             editorFields: editorFields,
            //             editor: editor, arguments
            //         });
            //     // }
            //     // console.log(EnumDTEditorEvent.postEdit);
            //     BootstrapNotify._notify('Operation successful', 'Element modified!');
            //     dataCard.updateData();
            // })
            // .on(EnumDTEditorEvent.postCreate, function (e : TypeJqueryEvent, json : TypeDataReturnedFromServer, data : string[]) {
            //     // if (DcEditorFn.debug) {
            //         console.log(EnumDTEditorEvent.postCreate, {
            //             editorFields: editorFields,
            //             editor: editor, arguments
            //         });
            //     // }
            //     // console.log(EnumDTEditorEvent.postEdit);
            //     BootstrapNotify._notify('Operation successful', 'Element modified!');
            //     dataCard.updateData();
            // })
            .on(EnumDTEditorEvent.uploadXhrSuccess, function (e, fieldName, json) {
            // if (DcEditorFn.debug) {
            //     console.log({
            //         eventName: EnumDTEditorEvent.uploadXhrSuccess, arguments
            //     });
            // }
            let files = Helpers._getObjByProp(json, "files", []);
            dataCard.file(files);
        })
            .on(EnumDTEditorEvent.postUpload, function (e, fieldName, json) {
            // if (DcEditorFn.debug) {
            //     console.log({
            //         eventName: EnumDTEditorEvent.postUpload, arguments
            //     });
            // }
        })
            .on(EnumDTEditorEvent.preSubmit, function (e, sendData, action) {
            let dataParam = $(dataCard.settings().jquerySelector).data();
            const socketId = dataCard.socketId();
            $.each(dataParam, function (name, property) {
                sendData[`${name}`] = property;
            });
            sendData['socketId'] = socketId;
            sendData.data = Object.entries(sendData.data).map(([rowId, value]) => {
                return Object.assign({ rowId }, value);
            });
        })
            .on(EnumDTEditorEvent.preUpload, function (e, fieldName, file, ajaxData) {
            // formData = $.extend(true, formData, dataParam);
            ajaxData.append("uploadField", fieldName);
            // let dataParam = $(dataCard.settings[__PROP.jquerySelector]).data();
            let dataParam = $(dataCard.settings().jquerySelector).data();
            $.each(dataParam, function (name, property) {
                ajaxData.append(name, property);
            });
            return true;
        })
            .on(EnumDTEditorEvent.uploadXhrError, function (e, fieldName, xhr) {
            // if (DcEditorFn.debug) {
            //     console.log({
            //         eventName: EnumDTEditorEvent.uploadXhrError, arguments
            //     });
            // }
        })
            .on(EnumDTEditorEvent.postSubmit, function (e, json, data, action, xhr) {
            const { _data, _status, _message } = json;
            DcCardFn._closeAllTooltip()
                .then(() => {
                switch (action) {
                    case "create":
                        dataCard.addData(_data);
                        break;
                    case "edit":
                        dataCard.updateData(_data);
                        break;
                    case "remove":
                        dataCard.removeData(_data);
                        break;
                }
                if (_status === 1) {
                    BootstrapNotify._notify(_message, '', EnumNotificationType.success);
                }
                else {
                    BootstrapNotify._notify(_message, '', EnumNotificationType.danger);
                }
            });
            dataCard.settings().event.postSubmit(dataCard);
            // if (DcEditorFn.debug) {
            console.log(EnumDTEditorEvent.postSubmit, {
                e: e, json: json, data: data,
                action: action, xhr: xhr
            });
            // }
        });
    }
};
