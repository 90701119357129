import Helpers from "../../../../../dist/_utils/helpers.js";
import _separator from "../../../utils/_separator.js";
import { EnumColumnType } from "../../model/dt-enum.js";
import ColumnStatic from "./column-static.js";
export class DtColumn {
    constructor(columnIndex, title) {
        this.searchable = true;
        this.orderable = true;
        this.visible = true;
        this.width = "";
        this.type = "";
        this.className = "";
        this.orderDataType = "";
        this.orderType = '';
        this.separator = "";
        this.imageSrc = "";
        this.editable = true;
        this.create = true;
        this.options = {};
        this.data = columnIndex;
        let hasIndex = Helpers._isStringNotEmpty(columnIndex) && columnIndex !== "NULL";
        if (columnIndex) {
            this.name = columnIndex;
        }
        let isSpecialIndex = columnIndex === "NULL";
        this.title = title;
        this.setClassName("");
        this.setEditable(hasIndex);
        this.setOrderable(hasIndex);
        this.setSearchable(hasIndex);
        this.setVisible(isSpecialIndex);
        this.setDefaultContent("");
    }
    isSearchable() {
        return this.searchable;
    }
    ;
    getData() {
        return this.data;
    }
    ;
    setEditable(editable) {
        this.editable = editable;
        let className = this.getClassName();
        if (this.editable) {
            className += ColumnStatic._editableClass;
        }
        else {
            className = className.replace(ColumnStatic._editableClass, "");
        }
        return this.setClassName(className);
    }
    ;
    setCreate(create) {
        this.create = Boolean(create);
        return this;
    }
    ;
    setWidth(width) {
        this.width = width;
        return this;
    }
    ;
    setOrderable(orderable) {
        this.orderable = Boolean(orderable);
        return this;
    }
    ;
    setOrderSequence(sequence) {
        if (Helpers._isStringNotEmpty(sequence)) {
            this.orderType = sequence;
        }
        return this;
    }
    ;
    setSearchable(searchable) {
        this.searchable = Boolean(searchable);
        return this;
    }
    ;
    setImageSrc(imageSrc) {
        this.imageSrc = imageSrc;
        return this;
    }
    ;
    setVisible(visible) {
        this.visible = Boolean(visible);
        return this;
    }
    ;
    setDefaultContent(defaultContent) {
        this.defaultContent = defaultContent;
        return this;
    }
    ;
    setRender(render) {
        if (Helpers._isFunction(render)) {
            this.render = render;
        }
        return this;
    }
    ;
    setDefaultValue(defaultValue) {
        if (Helpers._isStringNotEmpty(defaultValue)) {
            this.defaultValue = defaultValue;
        }
        return this;
    }
    ;
    setOptions(options) {
        if (Helpers._isObject(options)) {
            this.options = options;
        }
        return this;
    }
    ;
    setType(type, options, defaultValue) {
        if (Helpers._isStringNotEmpty(type) && Helpers._inObject(type, EnumColumnType)) {
            this.type = type;
            this.separator = _separator.array;
            this.setOptions(options);
            this.setDefaultValue(defaultValue);
            let render = ColumnStatic._getColumnRender(type, options);
            this.setRender(render);
            switch (type) {
                case EnumColumnType.color:
                    this.setWidth("150px");
                    break;
                case EnumColumnType.boolean:
                    this.setWidth("40px");
                    this.setSearchable(false);
                    break;
                case EnumColumnType.icon:
                    this.setWidth("50px");
                    break;
                case EnumColumnType.flag:
                    this.setWidth("40px");
                    break;
                case EnumColumnType.image:
                    this.setWidth("150px");
                    break;
                case EnumColumnType.datetime:
                    this.orderDataType = type;
                    break;
                case EnumColumnType.time:
                    this.orderDataType = type;
                    break;
                case EnumColumnType.date:
                    this.orderDataType = type;
                    break;
            }
        }
        return this;
    }
    ;
    getClassName() {
        return this.className;
    }
    ;
    setClassName(className) {
        if (className) {
            this.className = className;
        }
        return this;
    }
    ;
    addClass(className) {
        let columnClassName = this.getClassName();
        columnClassName = columnClassName !== "" ? columnClassName + " " + className : className;
        return this.setClassName(columnClassName);
    }
    ;
}
